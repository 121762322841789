<template>
  <div class="min-h-full">
    <AppBar>
      <h4 class="text-h5">Admin Management</h4>
    </AppBar>

    <v-main class="h-full grey lighten-5">
      <v-container class="md-px-0 pt-9">
        <v-row>
          <v-col cols="12" sm="9" md="11">
            <v-tabs v-model="tab" background-color="transparent" show-arrows>
              <v-tab href="#users">Users</v-tab>
              <v-tab href="#incoming-requests">Incoming Requests
                <v-chip color="primary" small class="ml-2">{{ incomingCount }}</v-chip>
              </v-tab>
              <v-tab href="#organisations">Organisations</v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="12" sm="3" md="1" class="d-flex justify-end">
            <CreateUserDialog v-if="$store.getters.userHasPermission('accounts.add_user')
              && tab !== 'organisations'">
              <v-btn color="primary" outlined large block>
                <v-icon>add</v-icon> Add User
              </v-btn>
            </CreateUserDialog>
            <CreateOrganisationDialog
              v-else-if="$store.getters.userHasPermission('accounts.add_user')
                && tab === 'organisations'">
              <v-btn color="primary" outlined large block>
                <v-icon>add</v-icon> Add Organisation
              </v-btn>
            </CreateOrganisationDialog>
          </v-col>
        </v-row>

        <v-tabs-items v-model="tab">
          <v-tab-item value="users">
            <UsersTab />
          </v-tab-item>
          <v-tab-item value="incoming-requests">
            <IncomingRequestsTab />
          </v-tab-item>
          <v-tab-item value="organisations">
            <OrganisationsTab />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppBar from '@/components/layout/AppBar.vue';
import CreateOrganisationDialog from './CreateOrganisationDialog.vue';
import CreateUserDialog from './CreateUserDialog.vue';
import IncomingRequestsTab from './IncomingRequestsTab.vue';
import OrganisationsTab from './OrganisationsTab.vue';
import UsersTab from './UsersTab.vue';

export default {
  name: 'UserManagementPage',
  components: {
    AppBar,
    CreateOrganisationDialog,
    CreateUserDialog,
    IncomingRequestsTab,
    OrganisationsTab,
    UsersTab,
  },
  data() {
    return {
      tab: 'users',
    };
  },
  computed: {
    ...mapState({
      incomingCount: (state) => state.auth.incomingList.count,
    }),
  },
  mounted() {
    this.tab = this.$route.query.tab;
    this.$store.dispatch('loadUsersWithOrgs', {});
    this.$store.dispatch('loadIncomingRequests', {});
    this.$store.dispatch('loadDevices');
    this.$store.dispatch('loadRegions');
  },
  watch: {
    tab(newTab) {
      if (newTab === this.$route.query.tab) return;
      this.$router.replace({ query: { tab: newTab } });
    },
  },
};
</script>

<style scoped>
.min-h-full {
  min-height: 100%;
}

.h-full {
  height: 100%;
}

.v-tabs-items {
  background-color: transparent;
}

.gap-8px {
  gap: 8px;
}
</style>
