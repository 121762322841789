<template>
  <v-dialog v-if="hgcase" v-model="showDialog" max-width="650px">
    <v-form ref="orderSimulationForm" @submit.native.prevent="submit"  v-model="isValid">
      <v-card>
        <v-card-title class="text-h5 justify-center">
          Order a simulation for {{ hgcase.caseIdentifier }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <v-alert v-if="isOrderNonClinical" text dense type="warning">
            Simulation analysis will be for non-clinical use only.
          </v-alert>

          <p>Order a simulation analysis in addition to the anatomical
            analysis you ordered earlier.</p>

          <div v-if="errors._All__">
            <v-alert :key=error v-for="error in errors._All__" type="error">
              {{ error }}
            </v-alert>
          </div>

          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-if="devices.length > 1"
                :items="devices"
                item-text="name"
                item-value="id"
                outlined
                dense
                v-model="form.selectedDeviceId"
                clearable
                hide-details="auto"
                label="Select device"
                :rules="[isRequired]"
              >
                <template v-slot:label>
                  <span class="red--text">* </span>Select device
                </template>
              </v-select>
              <div v-else>Device: {{ devices[0].name }}</div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                :items="variants"
                item-text="name"
                item-value="id"
                label="Select size(s)"
                outlined
                dense
                v-model="form.deviceVariantIds"
                multiple
                hide-details="auto"
                :rules=[limitedAmount]
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6 pa-4">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="showDialog = false">
            Close
          </v-btn>
          <v-btn color="primary" depressed type="submit" :disabled="!isValid">
            Order
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

import constants from '@/store/modules/cases/constants';

export default {
  name: 'OrderSimulationDialog',
  data() {
    return {
      errors: {},
      form: {
        selectedDeviceId: null,
        deviceVariantIds: [],
      },
      isValid: false,
      isRequired: (v) => !!v || 'This field is required',
      limitedAmount: (v) => v.length <= this.hgcase.intervention.variantLimit || !this.hgcase.intervention.variantLimit || `This field is limited to ${this.hgcase.intervention.variantLimit} choices.`,
    };
  },
  computed: {
    caseId() {
      // If the case id on the store changes, it means
      // we have to reset this form. Otherwise, if the user closed
      // the dialog without submitting, the previously selected deviceVariants
      // would be submitted.
      // this.reset();
      return this.$store.getters.orderSimulationDialog.caseId;
    },
    deviceId() {
      // Return the selected device id of this form if there are multiple related
      // devices to this hgcase, or just the single known device Id
      return this.form.selectedDeviceId
        || (this.devices.length === 1 && this.devices[0].id);
    },
    devices() {
      // devices selected by the AA orrder AND a license is available
      return this.hgcase.devices.all()
        .filter((d) => this.hgcase.organisation.devices
          .find((da) => da.id === d.id && constants.SERVICE.SIMULATION in da.services));
    },
    hgcase() {
      return this.$store.getters.hgcaseById(this.caseId);
    },
    isOrderNonClinical() {
      if (!this.deviceId) return false;
      const device = this.hgcase.organisation.devices.find((d) => d.id === this.deviceId);
      const service = (device.services || [])
        .find((s) => s.service === constants.SERVICE.SIMULATION);
      if (!service) return false;
      return service.isClinical === false; // explicit value in case property doesn't exist
    },
    variants() {
      if (this.deviceId) {
        const { variants } = this.$store.getters.deviceById(this.deviceId);
        return variants.sort((a, b) => parseInt(a.name, 10) - parseInt(b.name, 10));
      }
      return [];
    },
    showDialog: {
      get() {
        return this.$store.getters.orderSimulationDialog.show;
      },
      set(newValue) {
        return this.$store.dispatch('setOrderSimulationDialog', { show: newValue, caseId: this.caseId });
      },
    },
  },
  methods: {
    reset() {
      this.form.selectedDeviceId = null;
      this.form.deviceVariantIds = [];
    },
    async submit() {
      try {
        await this.$store.dispatch('createOrder', {
          caseId: this.caseId,
          service: constants.SERVICE.SIMULATION,
          deviceIds: [this.deviceId], // The deviceId is expected
          deviceVariantIds: this.form.deviceVariantIds,
          isInitial: false,
        });
        this.$store.dispatch('retrieveCase', this.caseId);
        this.showDialog = false;
        this.reset();
      } catch (error) {
        this.errors = error.response && error.response.data;
      }
    },
  },
  watch: {
    caseId() {
      this.reset();
    },
  },
};
</script>
