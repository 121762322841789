<template>
  <div>
    <AppBar>
      <h4 class="text-h5">{{ hgcase && hgcase.caseIdentifier }}</h4>
    </AppBar>

    <v-main v-if="hgcase" class="text-body-2 grey lighten-5">
      <v-container>
        <CaseDetail :hgcase="hgcase" class="mb-8"/>

        <!-- Study Info -->
        <StudyDetail v-if="hgcase.study" :study="hgcase.study"/>

        <div class="mt-16 mb-4">
          <CaseRejectButton v-if="$store.getters.user.canRejectCase" :hgcase="hgcase"
            :disabled="!canRejectCase" />
        </div>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AppBar from '@/components/layout/AppBar.vue';
import CaseDetail from './CaseDetail.vue';
import CaseRejectButton from './CaseRejectButton.vue';
import StudyDetail from './StudyDetail.vue';
import constants from '@/store/modules/cases/constants';

export default {
  name: 'CasesDetailPage',
  components: {
    AppBar,
    CaseDetail,
    CaseRejectButton,
    StudyDetail,
  },
  props: ['id'],
  created() {
    this.$store.dispatch('updateDegradationReasons');
    this.$store.dispatch('updateRejectionReasons');
    this.$store.dispatch('retrieveCase', this.id)
      .then((hgc) => {
        this.$store.dispatch('retrieveUser', hgc.createdById);
        this.$store.dispatch('retrieveUser', hgc.caseResponsibleId);
        this.$store.dispatch('retrieveOrganisationLicense', { id: hgc.organisationId });
      });
    this.$store.dispatch('loadDevices', true);
    this.$store.dispatch('loadDeviceVariants', true);
  },
  computed: {
    hgcase() {
      return this.$store.getters.hgcaseById(this.id);
    },
    canRejectCase() {
      const { isRejectable, orders } = this.hgcase;
      // if the case is in progress (rejectable) and there are no delivered orders
      return isRejectable && orders.every((o) => o.status !== constants.ORDER_STATUSES.DELIVERED);
    },
  },
};
</script>

<style scoped>
.v-main {
  min-height: 100vh;
}
</style>
