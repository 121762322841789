import Vue from 'vue';
import * as THREE from 'three';
import VueGtag from 'vue-gtag';

import App from './components/App.vue';
import store from './store';
import router from './router';
import './filters';
import './plugins';
import vuetify from './plugins/vuetify';

/**
 * Configuration
 */
Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_ID,
  },
  // We don't want to track the user until they've accepted the cookie policy
  // so we don't bootstrap the tracker yet.
  bootstrap: false,
});

const onKeydown = (event) => {
  const keyCode = event.which;
  if (keyCode === 82) { /* r */
    store.dispatch('cameraReset', 0);
  }
};

/**
 * Initial
 */

THREE.DefaultLoadingManager.onProgress = (url, loaded, total) => {
  store.dispatch('progressLoading', { url, loaded, total });
};
THREE.DefaultLoadingManager.onLoad = () => {
  store.dispatch('objectsLoaded');
};
window.addEventListener('resize', () => {
  store.dispatch('onResize');
}, false);
window.addEventListener('keydown', onKeydown, false);

/**
 * Mount Vue
 */
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
