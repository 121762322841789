<template>
  <div>
    <template v-for="v in visualisations">
      <v-list-group :key="v.id"
        v-if="v.missingObjects.length > 0 || v.customObjects.length">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ v.name }}</v-list-item-title>
            <v-list-item-subtitle v-if="v.missingObjects.length > 0">
              {{ v.missingObjects.length }} Geometries not defined
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else-if="v.customObjects.length > 0">
              {{ v.customObjects.length }} Geometries
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <div class="pl-6 pr-1 mt-2 mb-4">
          <v-expansion-panels>
            <v-expansion-panel v-for="name, index in v.missingObjects" :key="name + index">
              <v-expansion-panel-header expand-icon="add" disable-icon-rotate>
                <div>
                  {{ name }}
                  <v-chip color="black" dark small class="ml-2">Undefined</v-chip>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <VisualisationObjectForm :name="name" :visualisationId="v.id"
                  :studyId="study.id" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-for="o in v.customObjects" :key="o.id">
              <v-expansion-panel-header>{{ o.name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <VisualisationObjectForm :name="o.name" :visualisationId="v.id"
                  :studyId="study.id" :vObject="o" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-list-group>

      <v-list-item v-else :key="v.id+'else'">
        <v-list-item-content>
          <v-list-item-title>{{ v.name }}</v-list-item-title>
          <v-list-item-subtitle>No Geometries</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </div>
</template>

<script>
import VisualisationObjectForm from './VisualisationObjectForm.vue';

export default {
  props: {
    visualisations: {
      type: Array,
      default: () => [],
    },
    study: Object,
  },
  methods: {
  },
  components: {
    VisualisationObjectForm,
  },
};
</script>
