// SplineTubeWidget/index.js
// ...
import macro from '@kitware/vtk.js/macros';
import vtkSplineWidget from '@kitware/vtk.js/Widgets/Widgets3D/SplineWidget';
import vtkSphereHandleRepresentation from '@kitware/vtk.js/Widgets/Representations/SphereHandleRepresentation';
import { ViewTypes } from '@kitware/vtk.js/Widgets/Core/WidgetManager/Constants';

import vtkSplineTubeCutterContextRepresentation from '../SplineTubeCutterContextRepresentation';

function vtkSplineTubeCutterWidget(publicAPI, model) {
  model.classHierarchy.push('vtkSplineTubeCutterWidget');

  /* eslint-disable */
  model.methodsToLink = [
    'boundaryCondition',
    'close',
    'outputBorder',
    'fill',
    'borderColor',
    'errorBorderColor',
    'plane',
  ];

  publicAPI.getRepresentationsForViewType = (viewType) => {
    switch (viewType) {
      case ViewTypes.DEFAULT:
      case ViewTypes.GEOMETRY:
      case ViewTypes.SLICE:
      case ViewTypes.VOLUME:
      default:
        return [
          {
            builder: vtkSphereHandleRepresentation,
            labels: ['handles', 'moveHandle'],
            initialValues: {
              scaleInPixels: true,
            },
          },
          {
            builder: vtkSplineTubeCutterContextRepresentation,
            labels: ['handles', 'moveHandle'],
          },
        ];
    }
  };
}

const DEFAULT_VALUES = {};

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  vtkSplineWidget.extend(publicAPI, model, initialValues);

  vtkSplineTubeCutterWidget(publicAPI, model);
}

export const newInstance = macro.newInstance(extend, 'vtkSplineTubeCutterWidget');
// ----------------------------------------------------------------------------

export default { newInstance, extend };
