<template>
  <div>
    <AppBar>
      <h4 class="text-h5">Licenses</h4>
      <OrganisationSearch v-model="selectedOrganisationId" class="mx-4"
        backgroundColor="secondary lighten-2" @input="doSetOrganisation" />
    </AppBar>

    <v-main>
      <v-alert v-if="!selectedOrganisation" warning>
        <span v-if="!licenseLoading">
          Select an organisation to see its license(s).
        </span>
        <span v-else>
          Loading...
        </span>
      </v-alert>
      <v-container v-else>
        <v-row>
          <v-col>
            <!-- Regular license -->
            <LicenseCard
              :deviceAccesses="selectedOrganisation.deviceAccesses.filter(da=>!da.trialId)"
              :organisation="selectedOrganisation"
            />
          </v-col>
          <v-col
            v-if="$store.getters.user.canEditLicense"
          >
            <SubscriptionCard :organisation="selectedOrganisation"/>
          </v-col>
        </v-row>
        <v-row v-if="selectedOrganisation.trialLicenses.length>0" >
          <v-col
            :key="trial.id"
            v-for="trial in selectedOrganisation.trialLicenses"
          >
            <!-- Trial licenses -->
            <LicenseCard
              :key="trial.id"
              :deviceAccesses="trial.deviceAccesses"
              :trial="trial"
              :organisation="selectedOrganisation"
            />
          </v-col>
        </v-row>
        <div class="my-4">
          <AddTrialButton
            v-if="$store.getters.user.canEditLicense"
            :organisation="selectedOrganisation"
          />
        </div>

      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OrganisationSearch from '@/components/common/OrganisationSearch.vue';
import AppBar from '@/components/layout/AppBar.vue';

import LicenseCard from './LicenseCard.vue';
import SubscriptionCard from './SubscriptionCard.vue';
import AddTrialButton from './AddTrialButton.vue';

export default {
  name: 'LicensePage',
  components: {
    AddTrialButton,
    AppBar,
    LicenseCard,
    SubscriptionCard,
    OrganisationSearch,
  },
  data() {
    return {
      selectedOrganisationId: null,
    };
  },

  created() {
    // Set the selected organisation
    this.selectedOrganisationId = this.$store.getters.selectValidOrganisation(
      this.$route.query.org,
    );

    this.$store.dispatch('updateOrganisationLicense', { id: this.selectedOrganisationId });
    this.$store.dispatch('updateDevices');
    this.$store.dispatch('updateInterventions');
    this.$store.dispatch('listDeviceAccessGroups');
  },
  computed: {
    ...mapGetters(['user', 'licenseLoading']),
    selectedOrganisation() {
      return this.$store.getters.organisationById(this.selectedOrganisationId);
    },
    devices() {
      if (this.selectedOrganisation) {
        return this.selectedOrganisation.devices.filter((device) => !device.trialId);
      }
      return undefined;
    },
  },
  methods: {
    async doSetOrganisation(org) {
      this.$store.dispatch('updateOrganisationLicense', { id: org });
      if (this.$route.query.org !== org) {
        this.$router.push({ path: this.$route.path, query: { ...this.$route.query, org } });
      }
    },
  },
  watch: {
    $route(newRoute) {
      if (newRoute.query.org !== this.selectedOrganisationId) {
        const org = newRoute.query.org || null;
        this.doSetOrganisation(org);
      }
    },
  },
};
</script>
