export default {
  interventionById: (state, getters) => (id) => {
    const intervention = state.interventions[id];
    if (!intervention) {
      return {};
    }
    return {
      ...intervention,
      get trainingRequired() {
        if (getters.user.canSkipTraining) {
          // FEops staff don't need to follow trainings
          return false;
        }
        return getters.trainingRequired(intervention.id, getters.user.primaryOrganisation.region);
      },
      devices: intervention.deviceIds.map((deviceId) => getters.deviceById(deviceId)),
    };
  },

  interventions: (state, getters) => Object.keys(state.interventions).map(
    (id) => getters.interventionById(id),
  ),

  interventionsForPrimary: (state, getters) => {
    const primaryOrganisationId = getters.user.primaryOrganisation.id;
    const primaryOrganisation = getters.organisationById(primaryOrganisationId);
    if (!primaryOrganisation) {
      return [];
    }
    return getters.interventions.map(
      (intervention) => ({
        ...intervention,
        devices: primaryOrganisation.devices.filter((d) => d.interventionId === intervention.id),
      }),
    ).filter((i) => i.devices.length > 0);
  },

  interventionByPrefix: (state, getters) => (prefix) => getters.interventions
    .find((i) => i.prefix.toLowerCase() === prefix.toLowerCase()),
  deviceById: (state) => (id) => {
    const device = state.devices[id];
    if (device) {
      return {
        ...device,
      };
    }
    return undefined;
  },
  devices: (state, getters) => {
    const devices = Object.keys(state.devices).map(
      (id) => getters.deviceById(id),
    );
    return devices.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  },
  deviceVariantById: (state) => (id) => {
    const deviceVariant = state.deviceVariants[id];
    return {
      ...deviceVariant,
    };
  },
  deviceVariants: (state, getters) => Object.keys(state.deviceVariants).map(
    (id) => getters.deviceVariantById(id),
  ).sort((a, b) => parseInt(a.name, 10) - parseInt(b.name, 10)),
};
