<template>
  <v-data-table :headers="headers"
    disable-sort
    :items-per-page="15"
    :items="users"
    item-key="sub"
    class="elevation-1 mb-1"
    selectable-key="isSelected"
    single-select
    show-select
    @click:row="rowClick"
    :footer-props="{ 'disable-items-per-page': true }"
    :page.sync="innerPage"
    :server-items-length="pageCount"
    :loading="loading"
    @update:page="$emit('paginate', $event)"
    no-data-text="No users found">
    <template v-slot:[`item.data-table-select`]="{ isSelected }">
      <v-icon class="w-24px" color="primary">
        <template v-if="isSelected">arrow_forward</template>
      </v-icon>
    </template>

    <template v-slot:[`item.name`]="{ item }">
      {{ item.title }} {{ item.name }}
    </template>
    <template v-slot:[`item.primaryOrganisation`]="{ item }">
      {{ item.primaryOrganisation && item.primaryOrganisation.name || '-' }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'UserTable',
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    pageCount: Number,
    loading: Boolean,
    page: Number,
    incoming: Boolean,
  },
  data() {
    return {
      innerPage: 1,
      organisation: null,
      terms: null,
    };
  },
  computed: {
    headers() {
      return [
        { text: 'Name', value: 'name' },
        this.incoming
          ? { text: 'Organisation request', value: 'organisationRequest' }
          : { text: 'Primary Organisation', value: 'primaryOrganisation' },
        { text: 'Email', value: 'email' },
        { text: 'Phone number', value: 'phoneNumber' },
        { text: '', value: 'data-table-select' },
      ];
    },
  },
  methods: {
    rowClick(item, row) {
      row.select(true);
      this.$emit('selected', item.sub);
    },
  },
  watch: {
    page() {
      this.innerPage = this.page;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table__selected.v-data-table__selected.v-data-table__selected {
  background-color: #FDF3EE; // primary 8% opacity on white bg

  &.v-data-table__selected.v-data-table__selected:hover {
    background-color: #FBE7DB; // primary 16% opacity on white bg
  }
}

.w-24px {
  width: 24px;
}
</style>
