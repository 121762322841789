export default {
  configById: (state, getters) => (id) => {
    const config = state.configs[id];
    if (!config) return null;
    return {
      ...config,
      visualisations: config.visualisationIds
        .map((vId) => getters.visualisationById(vId))
        .filter((v) => v),
    };
  },
  configs: (state, getters) => Object.keys(state.configs).map((id) => getters.configById(id)),
  configsIsLoading: (state) => state.isLoading,

  visualisationById: (state, getters) => (id) => {
    const visual = state.visualisations[id];
    if (!visual) return null;

    return {
      ...visual,
      get visualisationObjects() {
        return (visual.visualisationObjectIds || [])
          .map((objectId) => getters.visualisationObjectById(objectId))
          .filter((obj) => !!obj);
      },
      get customObjects() {
        return getters.visualisationObjects
          .filter((obj) => obj.visualisationId === visual.id)
          .filter((obj) => !!obj.studyId);
      },
    };
  },
  visualisationObjects: (state) => Object.values(state.visualisationObjects),
  visualisationObjectById: (state) => (id) => state.visualisationObjects[id],
  visualisationObjectsForStudy: (state, getters) => (studyId) => getters
    .visualisationObjects.filter((v) => v.studyId === studyId),
};
