import Resource from '@/services/resource';
import client from './axiosClient';

export default new Resource(client, 'studies', {
  patchConfig(id, options) {
    const axiosOptions = options;
    const url = `/${this.base}/${id}/legacy-config/`;
    // next = next page with same options
    return this.client.patch(url, axiosOptions);
  },
});
