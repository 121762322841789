import { trainingsAPI, trainingrecordsAPI } from '@/services/heartguide';

import {
  TRAININGS_REQUEST,
  TRAININGS_SUCCESS,
  RESET_STATE_TRAININGS_MODULE,
} from '@/store/mutation-types';

export default {
  getTrainings({ commit }) {
    commit(TRAININGS_REQUEST);
    trainingsAPI.get()
      .then(({ data }) => { commit(TRAININGS_SUCCESS, data); })
      .catch();
  },
  completeTraining({ commit, dispatch }, { trainingId }) {
    commit(TRAININGS_REQUEST);
    trainingrecordsAPI.post({ trainingId })
      .then(() => {
        dispatch('getTrainings');
      });
  },
  async resetState({ commit }) {
    commit(RESET_STATE_TRAININGS_MODULE);
  },
};
