<template>
<!--
  Because this is a wrapper component around v-dialog
  we need to pass the signals to the parent components with v-on="$listeners"
-->
<v-dialog
  :value="value"
  @click:outside="hide"
  v-on="$listeners"
  max-width="540"
>
  <v-card>
    <v-card-title class="text-h5 justify-center">
      {{
        hgcase.patientInformation && hgcase.patientInformation.identifier
        || hgcase.caseIdentifier
      }} - Reject
    </v-card-title>
    <v-divider />
    <v-card-text class="pt-4">
      <v-form
        @submit.native.prevent="handleSubmit"
        v-model="isValid"
      >
        <v-select
          outlined
          dense
          label="Reason"
          :items="rejectionReasons"
          item-text="name"
          item-value="id"
          v-model="caseRejection.reasonId"
          :rules="[isRequired]"
        >
        </v-select>
        <v-textarea
          outlined dense
          v-model="caseRejection.comment"
          label="Message"
          full-width
          height="150px"
          :rules="[isRequired]"
        ></v-textarea>
      </v-form>
    </v-card-text>
    <v-card-actions class="px-6 pa-4">
      <v-spacer></v-spacer>
      <v-btn outlined @click="hide" depressed>
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        depressed
        :disabled="!isValid || processing"
        :loading="processing"
        @click="handleSubmit"
      >
        Reject
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>

export default {
  name: 'CaseRejectDialog',
  props: [
    'hgcase',
    'value',
  ],
  data: () => ({
    isValid: false,
    caseRejection: {
      reasonId: undefined,
      comment: '',
    },
    processing: false,
    isRequired: (v) => !!v || 'This field is required',
  }),
  computed: {
    rejectionReasons() {
      return this.$store.getters.rejectionReasons
        .filter((r) => r.interventionId === this.hgcase.interventionId);
    },
    selectedReason() {
      return this.$store.getters.rejectionReasonById(this.caseRejection.reasonId);
    },
  },
  methods: {
    hide() {
      this.$emit('close');
    },
    reset() {
      this.processing = false;
      this.caseRejection = {
        reasonId: undefined,
        comment: '',
      };
    },
    handleSubmit() {
      this.processing = true;
      this.$store.dispatch('rejectCase', {
        caseRejection: {
          ...this.caseRejection,
          id: this.hgcase.id,
          status: this.$store.state.cases.constants.REJECTED,
        },
      }).then(() => {
        this.hide();
        this.reset();
      }).catch((error) => {
        this.$toasted.global.error({ error });
        this.processing = false;
      });
    },
  },
  watch: {
    selectedReason(reason) {
      if (reason) {
        this.caseRejection.comment = reason.message;
      } else {
        this.caseRejection.comment = '';
      }
    },
  },
};
</script>
