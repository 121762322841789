<template>
  <v-card v-if="user" class="mb-1">
    <v-card-title class="justify-space-between">
      <h5 class="text-h5 primary--text">{{ user.title }} {{ user.name || '-' }} {{ isValid }}</h5>

      <ChangePasswordDialog :user="user" />
    </v-card-title>

    <v-card-text class="text-primary pa-0">

      <v-divider />

      <v-row class="mx-0 mb-0 px-1">
        <v-col cols="6" sm="3" md="6" lg="3">
          <p class="size-12px text--disabled mb-0">Organisation request</p>
          <p class="mt-2 mb-0">{{ user.organisationRequest || '-' }}</p>
        </v-col>
        <v-col cols="6" sm="3" md="6" lg="3">
          <p class="size-12px text--disabled mb-0">Date joined</p>
          <p class="mt-2 mb-0">{{ user.dateCreated | localeDateTime }}</p>
        </v-col>
        <v-col cols="6" sm="3" md="6" lg="3">
          <p class="size-12px text--disabled mb-0">Email</p>
          <p class="mt-2 mb-0">{{ user.email }}</p>
        </v-col>
      </v-row>

      <v-divider />
        <section class="pa-4 pb-0">
        <v-row class="">
          <v-col sm="4">
            <v-text-field v-model="form.givenName" :rules="[isRequired]"
              :error-messages="errors.givenName" outlined dense>
              <template v-slot:label>
                First Name <span class="red--text">*</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col sm="4">
            <v-text-field v-model="form.middleName" label="Middle Name"
              :error-messages="errors.middleName" maxlength="30" outlined dense />
          </v-col>
          <v-col sm="4">
            <v-text-field v-model="form.familyName" :rules="[isRequired]"
              :error-messages="errors.familyName" outlined dense>
              <template v-slot:label>
                Last Name <span class="red--text">*</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col sm="4">
            <v-select v-model="form.title" label="Title" outlined dense :items="titleChoices"
              :error-messages="errors.title"/>
          </v-col>
          <v-col sm="8">
            <v-text-field v-model="form.phoneNumber"
              :error-messages="errors.phoneNumber" maxlength="150" outlined dense>
              <template v-slot:label>
                Phone number
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </section>

      <v-divider />

      <section class="pa-4">
        <h6 class="text-secondary size-inherit mb-3">Organisation</h6>

        <div class="d-flex gap-8px form-row mt-2" v-for="(org, index) in form.orgs"
          :key="`org-${index}`">
          <div class="max-w-400px w-full">
            <OrganisationSearch outlined v-model="org.id" hideAll :solo="false" />
          </div>

          <v-btn-toggle :value="org.isPrimary" @change="togglePrimaryOrg(index, $event)">
            <v-tooltip bottom open-delay="750" max-width="240px">
              <template v-slot:activator="{on, attrs}">
                <v-btn color="primary" outlined :value="true" :disabled="!org.id" v-on="on"
                  v-bind="attrs">Is Primary</v-btn>
              </template>
              A user can have only one primary organisation.
            </v-tooltip>

          </v-btn-toggle>

          <v-btn-toggle v-model="org.contact">
            <v-tooltip bottom open-delay="750" max-width="240px">
              <template v-slot:activator="{on, attrs}">
                <v-btn color="primary" outlined :value="true" :disabled="!org.id" v-on="on"
                  v-bind="attrs">Contact</v-btn>
              </template>
              Can be assigned to users who can be contacted in the name of the organisation.
            </v-tooltip>
          </v-btn-toggle>

          <v-btn icon color="error" @click="removeOrg(index)"
            :disabled="!index && form.orgs.length === 1">
            <v-icon small>delete</v-icon>
          </v-btn>
        </div>

        <div class="mt-3 d-flex gap-16px">
          <v-btn text class="text-capitalize px-0 btn-link" color="primary"
            @click="appendNewOrg">
            Add another organisation
          </v-btn>

          <CreateOrganisationDialog>
            <v-btn text class="text-capitalize px-0 btn-link" color="primary">
              Add a new organisation
            </v-btn>
          </CreateOrganisationDialog>
        </div>
      </section>

      <v-divider />

      <section class="pa-4">
        <h6 class="text-secondary size-inherit mb-3">User role</h6>

        <div class="d-flex gap-8px form-row mt-2" v-for="(role, index) in form.roles"
          :key="`user-${index}`">
          <div class="max-w-400px w-full">
            <v-autocomplete outlined dense :items="roles" v-model="role.id" hide-details
              item-text="name" item-value="id" placeholder="Select role" />
          </div>

          <v-btn icon color="error" @click="removeRole(index)"
            :disabled="(!index && !role.id) && form.roles.length === 1">
            <v-icon small>delete</v-icon>
          </v-btn>
        </div>

        <div class="mt-3">
          <v-btn text class="text-capitalize px-0 btn-link" color="primary"
            @click="appendNewRole">
            Add another role
          </v-btn>
        </div>
      </section>

      <template v-if="hasSpecialistRole">
        <v-divider />

        <section class="pa-4">
          <h6 class="text-secondary size-inherit mb-2">Linked devices
            <span class="red--text">*</span>
          </h6>
          <p class="mb-3">
            As a Field Clinical Specialist, please select at least one device this user
            specializes in
          </p>

          <div class="d-flex gap-8px form-row mt-2" v-for="(device, index) in form.devices"
            :key="`device-${index}`">
            <div class="max-w-400px w-full">
              <v-autocomplete v-model="device.id" outlined dense hide-details :items="devices"
                item-value="id" item-text="name" placeholder="Select device" />
            </div>

            <v-btn icon color="error" @click="removeDevice(index)"
              :disabled="!index && form.devices.length === 1">
              <v-icon small>delete</v-icon>
            </v-btn>
          </div>

          <div class="mt-3">
            <v-btn text class="text-capitalize px-0 btn-link" color="primary"
              @click="appendNewDevice">
              Add another device
            </v-btn>
          </div>
        </section>
      </template>
      <v-checkbox
          v-model="form.hasApprovedTerms"
          hide-details class="mt-0"
          :error-messages="errors.hasApprovedTerms"
          label="Has approved terms"
        />
    </v-card-text>

    <v-divider />

    <v-card-actions class="pt-2 pb-3">
      <v-btn text color="error" v-if="user.isActive" @click="markInactive">Mark as Inactive</v-btn>
      <v-btn text color="primary" v-else @click="markActive">Mark as Active</v-btn>

      <v-btn text class="ml-auto" @click="resetForm">Reset</v-btn>
      <v-btn text color="primary" :disabled="!isValid" @click="validateAndSubmit">Save</v-btn>
    </v-card-actions>

    <ConfirmationDialog ref="confirm" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';
import OrganisationSearch from '@/components/common/OrganisationSearch.vue';
import ChangePasswordDialog from './ChangePasswordDialog.vue';
import CreateOrganisationDialog from './CreateOrganisationDialog.vue';
import { convertArrayToObject } from '@/utils';

export default {
  name: 'UserForm',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    ConfirmationDialog,
    ChangePasswordDialog,
    CreateOrganisationDialog,
    OrganisationSearch,
  },
  data() {
    return {
      form: {
        orgs: [],
        roles: [],
        devices: [],
        givenName: this.user.givenName,
        middleName: this.user.middleName,
        familyName: this.user.familyName,
        phoneNumber: this.user.phoneNumber,
        email: this.user.email,
        title: this.user.title,
        hasApprovedTerms: this.user.hasApprovedTerms,
      },
      errors: {},
      titleChoices: [
        '',
        'Dr.',
        'Prof. Dr.',
        'Mr.',
        'Mrs.',
        'Ms.',
      ],
    };
  },
  computed: {
    ...mapGetters(['devices', 'organisationById']),
    ...mapState({
      roles: (state) => state.auth.groups,
    }),
    hasSpecialistRole() {
      const specialistRole = this.roles.find((r) => r.name === 'Field Clinical Specialist');
      return this.form.roles.map((r) => r.id).includes(specialistRole.id);
    },
    isRequired() {
      return (v) => !!v || 'This field is required';
    },
    isValid() {
      /**
       * Valid if
       * - changes to initial state (org/roles/devices), primary Organisation
       * - if specialist chosen, at least one device
       *
       * Duplicates ignored and will be filtered out during form validation
       */
      const { groups, organisationMemberships } = this.user;
      const { orgs, roles, devices } = this.form;

      const memberships = organisationMemberships.map((m) => ({
        id: m.organisationId,
        isPrimary: m.isPrimary,
        contact: m.contact,
      }));
      const roleIds = roles.map((r) => r.id).filter((r) => !!r);
      const deviceIds = devices.map((d) => d.id).filter((d) => !!d);

      const formInput = {
        givenName: this.form.givenName,
        middleName: this.form.middleName,
        familyName: this.form.familyName,
        phoneNumber: this.form.phoneNumber,
        email: this.form.email,
        title: this.form.title,
        hasApprovedTerms: this.form.hasApprovedTerms,
      };

      const userValues = {
        givenName: this.user.givenName,
        middleName: this.user.middleName,
        familyName: this.user.familyName,
        phoneNumber: this.user.phoneNumber,
        email: this.user.email,
        title: this.user.title,
        hasApprovedTerms: this.user.hasApprovedTerms,
      };

      if (
        (
          this.isEqual(orgs.filter((o) => !!o.id), memberships)
          && this.isEqual(roleIds, groups)
          && this.isEqual(deviceIds, this.user.specialisations.map((s) => s.deviceId))
          && this.isEqual(formInput, userValues)
        ) // need proper device check later
        || (this.hasSpecialistRole && deviceIds.length < 1)
      ) {
        return false;
      }

      return true;
    },
  },
  methods: {
    ...mapActions([
      'createGroupMembership',
      'createOrganisationMembership',
      'deleteOrganisationMembership',
      'createSpecialisation',
      'deleteSpecialisation',
      'deleteUser',
      'loadOrganisationMemberships',
      'patchOrganisationMembership',
      'patchUser',
      'retrieveAndDeleteMembership',
      'retrieveSpecialisation',
      'retrieveUser',
    ]),
    appendNewDevice() {
      this.form.devices.push({ id: null });
    },
    appendNewOrg() {
      this.form.orgs.push({ id: null, isPrimary: false, contact: false });
    },
    appendNewRole() {
      this.form.roles.push({ id: null });
    },
    async getUser() {
      this.$emit('refreshList');
      await this.retrieveUser(this.user.sub).then((user) => {
        if (!user.specialisationIds || !user.specialisationIds.length) return;
        user.specialisationIds.forEach((id) => this.retrieveSpecialisation(id));
      });
    },
    isEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    async markActive() {
      await this.$refs.confirm.open(
        'Confirm',
        'Are you sure you want to mark this user as active?',
        { yesLabel: 'Yes, mark as active', noLabel: 'Cancel' },
      ).then((confirmation) => {
        if (!confirmation) return;
        this.patchUser({ id: this.user.sub, isActive: true })
          .then(() => this.retrieveUser(this.user.sub))
          .then(() => this.$toasted.global.success({ message: 'User marked as active' }))
          .catch((error) => this.$toasted.global.error({ message: error }));
      });
    },
    async markInactive() {
      await this.$refs.confirm.open(
        'Confirm',
        'Are you sure you want to mark this user as inactive?',
        { yesLabel: 'Yes, mark as inactive', noLabel: 'Cancel' },
      ).then((confirmation) => {
        if (!confirmation) return;
        this.deleteUser(this.user.sub)
          .then(() => this.retrieveUser(this.user.sub))
          .then(() => this.$toasted.global.success({ message: 'User marked inactive' }))
          .catch((error) => this.$toasted.global.error({ message: error }));
      });
    },
    removeDevice(index) {
      if (this.form.devices.length === 1) return;
      this.form.devices.splice(index, 1);
    },
    removeOrg(index) {
      if (this.form.orgs.length === 1) return;
      this.form.orgs.splice(index, 1);
    },
    removeRole(index) {
      // allows deleting all roles
      if (this.form.roles.length === 1) {
        this.form.roles = [{ id: null }];
      } else {
        this.form.roles.splice(index, 1);
      }
    },
    resetForm() {
      const { groups, organisationMemberships, specialisations } = this.user;
      this.form = {
        orgs: organisationMemberships?.length
          ? organisationMemberships.map((membership) => ({
            id: membership.organisationId,
            isPrimary: membership.isPrimary,
            contact: membership.contact,
          }))
          : [{ id: null, isPrimary: false, contact: false }],
        roles: groups?.length ? groups.map((id) => ({ id })) : [{ id: null }],
        devices: specialisations?.length
          ? specialisations.map((spec) => ({ id: spec.deviceId }))
          : [{ id: null }],
        givenName: this.user.givenName,
        middleName: this.user.middleName,
        familyName: this.user.familyName,
        phoneNumber: this.user.phoneNumber,
        email: this.user.email,
        title: this.user.title,
        hasApprovedTerms: this.user.hasApprovedTerms,
      };
    },
    togglePrimaryOrg(index, value) {
      this.form.orgs = this.form.orgs.map((o, orgIndex) => ({
        ...o,
        isPrimary: orgIndex === index ? !!value : false,
      }));
    },
    async validateAndSubmit() {
      const {
        groups,
        sub,
        organisationMemberships,
        specialisations,
      } = this.user;
      const {
        roles, orgs, devices,
        hasApprovedTerms, givenName, middleName, familyName, phoneNumber, title,
      } = this.form;

      // filter null and duplicates (first one selected)
      const organisationIds = organisationMemberships.map((m) => m.organisationId);
      const roleIds = Array.from(new Set(roles.map((r) => r.id).filter((r) => !!r)));
      const uniqueOrgs = Object.values(convertArrayToObject(orgs.filter((o) => !!o.id)));
      const uniqueOrgsIds = uniqueOrgs.map((o) => o.id);
      const deviceIds = Array.from(new Set(devices.map((d) => d.id).filter((d) => !!d)));
      const specDeviceIds = specialisations.map((spec) => spec.deviceId);

      // if they don't have specialist role, delete all specialisations
      const specActions = this.hasSpecialistRole
        ? [
          // add devices not in specialisations
          ...deviceIds.filter((d) => !specDeviceIds.includes(d))
            .map((deviceId) => this.createSpecialisation({ userId: sub, deviceId })),
          // delete specialisations not in devices
          ...specDeviceIds.filter((d) => !deviceIds.includes(d)).map((deviceId) => {
            const spec = specialisations.find((s) => s.deviceId === deviceId);
            return this.deleteSpecialisation(spec.id);
          }),
        ]
        : specDeviceIds.map((deviceId) => {
          const spec = specialisations.find((s) => s.deviceId === deviceId);
          return this.deleteSpecialisation(spec.id);
        });

      await Promise.all([
        ...uniqueOrgs.map((o) => {
          if (!organisationIds.includes(o.id)) {
            // add orgs not in organisationIds
            return this.createOrganisationMembership({
              organisationId: o.id,
              userId: sub,
              isPrimary: o.isPrimary,
              contact: !!o.contact,
            });
          }
          // update membership
          const membership = organisationMemberships.find((m) => m.organisationId === o.id);
          return this.patchOrganisationMembership({
            ...membership,
            isPrimary: o.isPrimary,
            contact: !!o.contact,
          });
        }),
        // delete memberships not in orgs
        ...organisationIds.filter((o) => !uniqueOrgsIds.includes(o)).map((o) => {
          const membership = organisationMemberships.find((oM) => oM.organisationId === o);
          return this.deleteOrganisationMembership(membership.id);
        }),

        // add roles not in groups
        ...roleIds.filter((r) => !groups.includes(r))
          .map((group) => this.createGroupMembership({ group, userId: sub })),
        // delete from groups not in roles
        ...groups.filter((r) => !roleIds.includes(r))
          .map((group) => this.retrieveAndDeleteMembership({ group, user__uuid: sub })),

        ...specActions,

        // update user
        this.patchUser({
          id: sub,
          hasApprovedTerms,
          givenName,
          middleName,
          familyName,
          phoneNumber,
          title,
        }),
      ])
        .then(() => Promise.all([
          this.getUser(),
          this.loadOrganisationMemberships({ user_id: sub }),
        ]))
        .then(() => this.$toasted.global.success({ message: 'User successfully updated' }))
        .catch((error) => this.$toasted.global.error({ message: error }));
    },
  },
  watch: {
    user: {
      handler: 'resetForm',
      deep: true,
    },
  },
  mounted() {
    this.resetForm();
  },
};
</script>

<style lang="scss" scoped>
.size-12px {
  font-size: 12px;
}

.size-inherit {
  font-size: inherit;
}

::v-deep .v-input__slot.v-input__slot {
  min-height: 40px;

  &.v-input__slot.v-input__slot {
    padding: 0 16px;
  }
}

.gap-8px {
  gap: 8px;
}

.gap-16px {
  gap: 16px;
}

.form-row .v-btn.v-btn.v-btn {
  height: 40px;
}

.v-btn--outlined.v-btn--outlined.v-btn--outlined.v-btn--outlined {
  border-color: currentColor !important;
}

.btn-link.btn-link {
  height: auto;
}

.max-w-400px {
  max-width: 400px;
}

.w-full {
  width: 100%;
}
</style>
