<template>
  <v-expansion-panel>
    <v-expansion-panel-header v-slot="{ open }">
      <v-row no-gutters
        :class="order.delivery && order.delivery.isObsoleted && 'text-decoration-line-through'"
      >
        <v-col cols="6" md="3"
          class="align-self-center"
        >{{ analysis.name }}</v-col>
        <v-col cols="6" md="9" class="align-self-center">
          <v-fade-transition>
            <v-row no-gutters v-if="!open">
              <v-col class="align-self-center">{{ analysis.id }}</v-col>
              <v-col class="d-none d-md-block align-self-center">{{ analysis.typeDisplay }}</v-col>
              <v-col class="d-none d-md-block align-self-center"
                v-if="showDevices">{{ deviceNames }}</v-col>
            </v-row>
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div class="content-override mx-n6 mb-n4">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Type</th>
                <th>ID</th>
                <th v-if="showDevices">Devices</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ analysis.typeDisplay }}</td>
                <td>{{ analysis.id }}</td>
                <td v-if="showDevices">{{ deviceNames }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-divider />

        <div class="pa-4">
          <v-row>
            <v-col cols="12" sm="6" lg="3">
              <h4 class="grey--text mb-2">Landmarks</h4>
              <div class="grey--text text--darken-4" v-for="landmark in analysis.landmarks"
                :key="landmark.id">{{ landmark.name }}</div>
              <p v-if="analysis.landmarks.length < 1">No landmarks available.</p>
            </v-col>

            <v-col cols="12" sm="6" lg="3">
              <h4 class="grey--text mb-2">Geometries</h4>
              <AnalysisResource v-for="resource in geometryResources"
                :key="resource.id"
                :analysisResource="resource"
                :hasDelivery="!!order.delivery"
                :isGeometryResource="true" />
              <p v-if="geometryResources.length < 1">No geometries available.</p>
            </v-col>

            <v-col cols="12" sm="6" lg="3">
              <h4 class="grey--text mb-2">Images</h4>
              <template v-for="(arr, name) in imageResources">
                <v-list-group :key="name" v-if="arr.length > 1" class="image-resource-group">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>{{ name }}</v-list-item-title>
                      <v-list-item-subtitle>Has duplicates</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>

                  <v-list class="pl-8">
                    <AnalysisResource v-for="(image, index) in arr" :key="image.id"
                      :analysisResource="image"
                      :hasDelivery="!!order.delivery"
                      :isLatest="index === arr.length - 1" />
                  </v-list>
                </v-list-group>
                <AnalysisResource v-else :key="name"
                  :analysisResource="arr[0]"
                  :hasDelivery="!!order.delivery" />
              </template>

              <p v-if="Object.keys(imageResources).length < 1">No images available.</p>
            </v-col>

            <v-col cols="12" sm="6" lg="3">
              <h4 class="grey--text mb-2">Other</h4>
              <AnalysisResource v-for="resource in otherResources"
                :key="resource.id"
                :analysisResource="resource"
                :hasDelivery="!!order.delivery" />
              <p v-if="otherResources.length < 1">None available.</p>
            </v-col>
          </v-row>
        </div>

        <template v-if="canUploadImages
          && (user.canAddAnalysisResource || user.canAddVisualisationObject)">
          <v-divider />

          <div class="pa-4">
            <v-btn v-if="user.canAddAnalysisResource" text outlined
              @click="uploadScreenshotDialog = true">Upload Images</v-btn>
            <v-btn v-if="user.canAddVisualisationObject" text outlined
              @click="uploadGeometriesDialog = true" class="ml-2">Upload Geometries</v-btn>
          </div>
        </template>
      </div>
    </v-expansion-panel-content>

    <UploadScreenshotDialog
      v-if="uploadScreenshotDialog && user.canAddAnalysisResource"
      v-model="uploadScreenshotDialog"
      :analysis="analysis" />
    <UploadGeometriesDialog
      v-if="uploadGeometriesDialog && user.canAddVisualisationObject"
      v-model="uploadGeometriesDialog"
      :analysis="analysis" />
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import AnalysisResource from './AnalysisResource.vue';
import UploadScreenshotDialog from './UploadScreenshotDialog.vue';
import UploadGeometriesDialog from './UploadGeometriesDialog.vue';

export default {
  name: 'AnalysisDetail',
  components: {
    AnalysisResource,
    UploadScreenshotDialog,
    UploadGeometriesDialog,
  },
  props: {
    analysis: {
      type: Object,
      required: true,
    },
    canUploadImages: {
      type: Boolean,
      default: true,
    },
    order: {
      type: Object,
    },
  },
  data() {
    return {
      uploadScreenshotDialog: false,
      uploadGeometriesDialog: false,
    };
  },
  created() {
    this.$store.dispatch('listAnalysisResources', { analysis_id: this.analysis.id });
    this.$store.dispatch('updateLandmarks', this.analysis.id);
  },
  computed: {
    ...mapGetters(['user', 'devices']),
    deviceNames() {
      const devices = this.analysis.deviceVariantIds.map((dv) => {
        const device = this.devices.find((d) => d.variantIds.includes(dv));
        return device.variants.find((v) => v.id === dv);
      });
      return devices.map((d) => d.name).join(', ');
    },
    geometryResources() {
      return this.analysis.resources
        .filter((r) => r.type === this.$store.state.studies.constants.SCENE_OBJECT);
    },
    imageResources() {
      return this.analysis.resources
        .filter((r) => r.type === this.$store.state.studies.constants.IMAGE)
        .reduce((acc, curr) => {
          const name = curr.filename;
          if (!acc[name]) acc[name] = [];
          acc[name].push(curr);
          return acc;
        }, {});
    },
    otherResources() {
      return this.analysis.resources.filter((r) => !([
        this.$store.state.studies.constants.SCENE_OBJECT,
        this.$store.state.studies.constants.IMAGE,
      ].includes(r.type)));
    },
    showDevices() {
      return this.analysis.deviceVariantIds.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-resource-group {
  margin-left: -16px;
  width: calc(100% + 16px);
}
</style>
