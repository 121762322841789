<template>
  <div>
    <div
      class="c-dropzone"
      @dragover.prevent
      @drop="onDrop"
      @click="$refs.uploadConfig.click()"
    >
      <input type="file" ref="uploadConfig" @change="onFileInputAdd" style="display: none" />
      <i class="el-icon-setting"></i>
      <p>
        Accepted file names: <br/>
        {{ study.caseIdentifier }}.zip<br/>
        {{ study.caseIdentifier }}.json<br/>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LegacyConfigCreator',
  props: [
    'study',
  ],
  methods: {
    uploadFile(config) {
      this.$store.dispatch('uploadConfig', {
        studyId: this.study.id,
        config,
        caseIdentifier: this.study.caseIdentifier,
      })
        .then(() => {
          this.$toasted.global.success({ message: 'Legacy Simulation created' });
          this.$store.dispatch('listAnalyses', { study: this.study.id });
        })
        .catch((error) => {
          const { message } = error;
          this.$toasted.global.error({ message });
        });
    },
    testAndUpload(file) {
      if (file.name.startsWith(this.study.caseIdentifier)) {
        this.uploadFile(file);
      } else {
        this.$toasted.global.error({ message: 'Filename does not match case identifier' });
      }
    },
    onFileInputAdd(e) {
      /* Triggered by clicking the dropzone, which triggers clicking the hidden
      file input */
      e.preventDefault();
      this.testAndUpload(e.target.files[0]);
    },
    onDrop(e) {
      /* Triggered by dropping on the dropzone */
      e.preventDefault();
      this.testAndUpload(e.dataTransfer.files[0]);
    },
  },
};
</script>
