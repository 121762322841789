<template>
  <div>
    <v-menu v-model="openMenu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small icon color="white" v-bind="attrs" v-on="on" class="case-card-menu-button">
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-if="hgcase.hasPdfReport"
          @click="$store.dispatch('downloadStudyResource', { id: hgcase.study.pdfReportId }) "
        >
          Download PDF Report
        </v-list-item>
        <v-list-item
          v-if="!hgcase.isSharedToCurrentUser
            && (hgcase.canBeCanceled || canCancelUnderEvaluationCase)"
          @click="$store.dispatch('showCancelCaseDialog', hgcase.id)"
          data-cy="casecard-menu-cancel"
        >
          Cancel
        </v-list-item>
        <v-list-item
          data-cy="edit-case-list-item"
          v-if="user.canAccessCaseDetails"
          :to="{ name: 'case-detail', params: {id: hgcase.id } }"
          >
          Detail
        </v-list-item>

        <v-list-item
          data-cy="share-case-list-item"
          @click="$store.dispatch('showShareCaseDialog', hgcase.id)"
          v-if="user.canShareCase && !hgcase.isSharedToCurrentUser"
        >
          Share Case
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import constants from '@/store/modules/cases/constants';

export default {
  name: 'CaseCardMenu',
  components: {

  },
  props: {
    hgcase: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      openMenu: false,
    };
  },
  computed: {
    ...mapGetters(
      ['user'],
    ),
    canCancelUnderEvaluationCase() {
      const { CASE_STATUSES, ORDER_STATUSES } = constants;
      const { status, orders } = this.hgcase;
      // if case is in progress, and all orders are under evaluation, and user has permission
      return status === CASE_STATUSES.IN_PROGRESS
        && (orders.length > 0 && orders.every((o) => o.status === ORDER_STATUSES.IN_PROGRESS))
        && this.user.canCancelUnderEvaluationCase;
    },
  },
  methods: {
    downloadInputData() {
      if (this.hgcase.lastInputData) {
        this.$store.dispatch('downloadInputData', { id: this.hgcase.lastInputData.id });
      }
    },
  },
};
</script>
