<template>
  <v-tooltip bottom color="warning">
    <template v-slot:activator="{ on, attrs }">
      <v-icon small color="warning" v-bind="attrs"
        v-on="on">
        error
      </v-icon>
    </template>
    <span>
      <div class="d-flex" style="max-width: 350px;">
        <v-icon color="white" class="mr-4">
          error
        </v-icon>
        <div>
          <h5 class="text-h6">
            Updated Patient identifier.
          </h5>
          <p>
            The patient identifier has been updated since the original request.
          </p>
        </div>
      </div>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'PatientIdentifierChangedTooltip',
};
</script>
