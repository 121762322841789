var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{staticStyle:{"z-index":"200"},attrs:{"dark":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-body-2 user-menu-button",attrs:{"text":"","height":"auto"}},'v-btn',attrs,false),on),[_c('v-gravatar',{staticClass:"mr-2",attrs:{"email":_vm.$store.getters.user.email,"size":40,"default-img":"mm"}}),_c('div',{staticClass:"text-left"},[_c('div',{staticClass:"text-h6 text-none",staticStyle:{"line-height":"normal"}},[_vm._v(_vm._s(_vm.userNameDisplay))]),_c('div',{staticClass:"text-subtitle-2 text-none secondary--text text--lighten-5"},[(_vm.$store.getters.user.hasRole)?_c('span',[_vm._v(" "+_vm._s(_vm.$store.getters.user.roles[0])+" ")]):_vm._e(),_vm._v("@ "+_vm._s(_vm.organisationDisplay)+" ")])]),_c('v-icon',{staticClass:"ml-4",attrs:{"color":"white"}},[_vm._v("expand_more")])],1)]}}])},[_c('v-list',{staticClass:"links-list"},[_c('v-list-item-group',[(!_vm.insideIframe)?[(_vm.$store.getters.user.hasRole)?_c('v-list-item',{attrs:{"to":{
              name: 'cases',
              query: {
                org: _vm.$store.state.cases.casesPage.organisationId,
                search: _vm.$store.state.cases.casesPage.searchTerm,
              },
            }}},[_c('v-list-item-title',[_vm._v(" Analyses ")])],1):_vm._e(),(_vm.$store.getters.user.hasRole)?_c('v-list-item',{attrs:{"to":{ name: 'settings' }}},[_c('v-list-item-title',{staticClass:"settings-link"},[_vm._v(" Settings ")])],1):_vm._e(),(_vm.$store.getters.user.hasRole)?_c('v-list-item',{attrs:{"to":{ name: 'licenses', query: { org: _vm.$store.getters.selectedOrganisationId } }}},[_c('v-list-item-title',[_vm._v(" Licenses ")])],1):_vm._e(),(_vm.$store.getters.user.canAddTemplate)?_c('v-list-item',{attrs:{"to":{name: 'templates'}}},[_c('v-list-item-title',[_vm._v(" License templates ")])],1):_vm._e(),(_vm.$store.getters.user.canEditUsersAndOrganisations)?_c('v-list-item',{attrs:{"to":{name: 'users-and-organisations'}}},[_c('v-list-item-title',[_vm._v(" Users & Organisations ")])],1):_vm._e()]:_vm._e(),(_vm.$store.getters.user.hasRole)?_c('v-list-item',{attrs:{"to":{ name: 'documentation' }}},[_c('v-list-item-title',[_vm._v(" Documentation ")])],1):_vm._e(),_c('v-list-item',{attrs:{"to":{ name: 'contact' }}},[_c('v-list-item-title',[_vm._v(" Contact Us ")])],1)],2)],1),(!_vm.insideIframe)?[_c('v-divider',{staticClass:"secondary lighten-1"}),_c('v-list',{staticClass:"logout-list"},[_c('v-list-item-group',[_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('v-list-item-title',[_vm._v(" Log out ")])],1)],1)],1)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }