/*
 * Auth mutations
 */
// After sending auth details
export const AUTH_REQUEST = 'AUTH_REQUEST';
// Successful authentication. Takes token as argument
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
// Error when authenticating
export const AUTH_ERROR = 'AUTH_ERROR';
// After a succesful call to the auth api
export const AUTH_REQUEST_SUCCESS = 'AUTH_REQUEST_SUCCESS';

export const USERINFO_REQUEST = 'USERINFO_REQUEST';
export const USERINFO_SUCCESS = 'USERINFO_SUCCESS';
export const USERINFO_ERROR = 'USERINFO_ERROR';

export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_UPDATE = 'USERS_UPDATE';
export const USERS_ERROR = 'USERS_ERROR';
export const USERS_UPDATE_LIST = 'USERS_UPDATE_LIST';
export const INCOMING_UPDATE_LIST = 'INCOMING_UPDATE_LIST';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CHANGE_USER_PASSWORD_REQUEST = 'CHANGE_USER_PASSWORD_REQUEST';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_ERROR = 'CHANGE_USER_PASSWORD_ERROR';
export const PATCH_USER_REQUEST = 'PATCH_USER_REQUEST';
export const PATCH_USER_SUCCESS = 'PATCH_USER_SUCCESS';
export const PATCH_USER_ERROR = 'PATCH_USER_ERROR';

// Patching getting settings
export const SETTINGS_PATCH = 'SETTINGS_PATCH';
// Patching getting settings
export const PROFILE_PATCH = 'PROFILE_PATCH';
// Set case list to table view
export const USER_SET_TABLEVIEW = 'USER_SET_TABLEVIEW';
export const RESET_STATE_AUTH_MODULE = 'RESET_STATE_AUTH_MODULE';

/*
 * Permission mutations
 */

export const PERMISSIONS_REQUEST = 'PERMISSIONS_REQUEST';
export const PERMISSIONS_SUCCESS = 'PERMISSIONS_SUCCESS';
export const PERMISSIONS_ERROR = 'PERMISSIONS_ERROR';

/*
 * Cases
 */
export const CASE_UPDATE = 'Single case successfully loaded';
export const CASE_SHARE_DIALOG_SHOW = 'CASE_SHARE_DIALOG_SHOW';
export const CASE_CANCEL_DIALOG_SHOW = 'CASE_CANCEL_DIALOG_SHOW';
export const CASES_CLEAR = 'Clearing cases';
export const CASES_HIDE_ALL_PATIENT_INFORMATION = 'hide all the patient identifiers';
export const CASES_ISLOADING = 'Set loading of the cases API';
export const CASES_NEXTPAGE = 'Set next page for cases requests';
export const CASES_SET_CASES_PAGE = 'Set case search term';
export const CASES_SET_ORDER_SIMULATION_DIALOG = 'CASES_SET_ORDER_SIMULATION_DIALOG';
export const CASES_SET_STATS = 'CASES_SET_STATS';
export const CASES_UPDATE = 'Update multiple cases';
export const RESET_STATE_CASES_MODULE = 'RESET_STATE_CASES_MODULE';
export const PATCH_CASE_ERROR = 'PATCH_CASE_ERROR';

/*
 * Heartguide
 */
export const RESET_STATE_HEARTGUIDE_MODULE = 'RESET_STATE_HEARTGUIDE_MODULE';

/*
* Orders
*/
export const ORDERS_ISLOADING = 'Set loading of the orders API';
export const ORDERS_UPDATE = 'Update multiple orders';

// Case Rejected
export const CASEREJECTION_SUCCESS = 'CASEREJECTION_SUCCESS';
// Could not reject Case
export const CASEREJECTION_ERROR = 'CASEREJECTION_ERROR';

/*
 * Viewer mutations
 */
// Load a simulator config file
export const VIEWER_REQUEST = 'VIEWER_REQUEST';
// Successfully loaded a simulator config file
export const VIEWER_SUCCESS = 'VIEWER_SUCCESS';
// Percentage of combined model file size loaded. Takes integer as argument.
export const VIEWER_LOADING_PROGRESS = 'VIEWER_LOADING_PROGRESS';
// Succesfully loaded model files
export const VIEWER_LOADED = 'VIEWER_LOADED';
// Error loading config
export const VIEWER_ERROR = 'VIEWER_ERROR';

// Initialize a viewport. Takes an element.
export const VIEWPORT_INIT = 'VIEWPORT_INIT';
// Change the viewport's scene. Takes a scene number (index from scenes) and viewport ID
export const VIEWER_SET_SCENE = 'VIEWER_SET_SCENE';
// Change the viewport's visualisation. Takes a visualisation number (index from scenes)
// and viewport ID
export const VIEWER_SET_VISUALISATION = 'VIEWER_SET_VISUALISATION';
// Update the viewport's 'report'. Takes a viewport ID and the report containing x, y and z.
export const VIEWER_UPDATE_REPORT = 'VIEWER_UPDATE_REPORT';

export const VIEWER_OBJECT_LOADED = 'VIEWER_OBJECT_LOADED';

// Add a new viewport. Initializes it with scene/visualisation 0/0.
export const VIEWER_ADD_VIEWPORT = 'VIEWER_ADD_VIEWPORT';

// Sets the global opacity of a visualisation. Takes a visualisation index and new opacity.
export const SET_GLOBAL_OPACITY = 'SET_GLOBAL_OPACITY';
// Sets the opacity of an object in a visualisation. Takes a visualisation index, object name,
// and new opacity.
export const SET_OBJECT_OPACITY = 'SET_OBJECT_OPACITY';
// Sets the color of an object in a visualisation. Takes a visualisation index, object name,
// and the new color.
export const SET_OBJECT_COLOR = 'SET_OBJECT_COLOR';
export const SET_OBJECT_DOUBLESIDED = 'SET_OBJECT_DOUBLESIDED';
export const SET_OBJECT_VISIBILITY = 'SET_OBJECT_VISIBILITY';

export const SET_LANDMARK_VISIBILITY = 'SET_LANDMARK_VISIBILITY';

export const VIEWER_RESET_SELECTED_VISUALISATION = 'VIEWER_RESET_SELECTED_VISUALISATION';

export const RESET_STATE_VIEWER_MODULE = 'RESET_STATE_VIEWER_MODULE';

/*
 * License mutations
 */
// Start loading licenses
export const LICENSES_REQUEST = 'LICENSES_REQUEST';
// Licenses successfully loaded
export const LICENSES_SUCCESS = 'LICENSES_SUCCESS';
// Problem loading licenses
export const LICENSES_ERROR = 'LICENSES_ERROR';

/*
 * Organisation mutations
 */
export const ORGANISATIONS_CLEAR = 'Clearing organisations';
export const ORGANISATIONS_ERROR = 'ORGANISATIONS_ERROR';
export const ORGANISATIONS_NEXTPAGE = 'Set next page for organisations requests';
export const ORGANISATIONS_ISLOADING = 'Set loading of the organisations API';
export const ORGANISATIONS_UPDATE = 'Update multiple organisations';
export const RESET_STATE_ORGANISATIONS_MODULE = 'RESET_STATE_ORGANISATIONS_MODULE';
export const MANAGEMENT_ORGS_UPDATE = 'MANAGEMENT_ORGS_UPDATE';
export const MANAGEMENT_ORGS_INSERT = 'MANAGEMENT_ORGS_INSERT';
export const DELETE_ORGANISATION_SUCCESS = 'DELETE_ORGANISATION_SUCCESS';

/**
 * Organisation Memberships
 */
export const ORG_MEMBERSHIP_REQUEST = 'ORG_MEMBERSHIP_REQUEST';
export const ORG_MEMBERSHIP_UPDATE = 'ORG_MEMBERSHIP_UPDATE';
export const ORG_MEMBERSHIP_ERROR = 'ORG_MEMBERSHIP_ERROR';
export const ORG_MEMBERSHIP_REMOVE = 'ORG_MEMBERSHIP_REMOVE';

/*
 * Pages mutations
 */
export const PAGES_SUCCESS = 'PAGES_SUCCESS';
export const RESET_STATE_PAGES_MODULE = 'RESET_STATE_PAGES_MODULE';

/*
 * Trainings mutations
 */

export const TRAININGS_REQUEST = 'TRAININGS_REQUEST';
export const TRAININGS_SUCCESS = 'TRAININGS_SUCCESS';
export const RESET_STATE_TRAININGS_MODULE = 'RESET_STATE_TRAININGS_MODULE';

/*
 * Landmarks mutations
 */
export const LANDMARKS_CLEAR = 'LANDMARKS_CLEAR';
export const LANDMARK_DELETE = 'LANDMARK_DELETE';
export const LANDMARKS_UPDATE = 'LANDMARKS_UPDATE';
export const LANDMARK_UPDATE = 'LANDMARK_UPDATE';
export const LANDMARK_TYPES_UPDATE = 'LANDMARK_TYPES_UPDATE';

// Mutations for the 'new landmark' widget
export const NEW_LANDMARK_UPDATE = 'NEW_LANDMARK_UPDATE';

/*
 * Rejection reasons
 */

export const REJECTION_REASONS_UPDATE = 'REJECTION_REASONS_UPDATE';

/*
 * Degradation reasons
 */
export const DEGRADATION_REASONS_UPDATE = 'DEGRADATION_REASONS_UPDATE';

/*
 * Input data
 */
export const INPUT_DATA_ISLOADING = 'INPUT_DATA_ISLOADING';
export const INPUT_DATA_NEXTPAGE = 'INPUT_DATA_NEXTPAGE';
export const INPUT_DATA_UPDATE = 'INPUT_DATA_UPDATE';

/*
 * Configs
 */
export const CONFIG_UPDATE = 'CONFIG_UPDATE';
export const CONFIGS_ISLOADING = 'CONFIGS_ISLOADING';
export const CONFIGS_UPDATE = 'CONFIGS_UPDATE';
export const RESET_STATE_CONFIGS_MODULE = 'RESET_STATE_CONFIGS_MODULE';

/*
 * Devices
 */
export const DEVICE_UPDATE = 'DEVICE_UPDATE';
export const DEVICE_VARIANTS_CLEAR = 'DEVICE_VARIANTS_CLEAR';
export const DEVICE_VARIANTS_UPDATE = 'DEVICE_VARIANTS_UPDATE';
export const DEVICE_VARIANT_UPDATE = 'DEVICE_VARIANT_UPDATE';
export const DEVICES_CLEAR = 'DEVICES_CLEAR';
export const DEVICES_UPDATE = 'DEVICES_UPDATE';
export const RESET_STATE_INTERVENTIONS_MODULE = 'RESET_STATE_INTERVENTIONS_MODULE';

/*
 * Interventions
 */
export const INTERVENTION_UPDATE = 'INTERVENTION_UPDATE';
export const INTERVENTIONS_UPDATE = 'INTERVENTIONS_UPDATE';

/*
 * Studies and analyses
 */
export const ANALYSES_ISLOADING = 'ANALYSES_ISLOADING';
export const ANALYSES_NEXTPAGE = 'ANALYSES_NEXTPAGE';
export const ANALYSES_UPDATE = 'ANALYSES_UPDATE';
export const ANALYSIS_RESOURCE_DELETE = 'ANALYSIS_RESOURCE_DELETE';
export const ANALYSIS_RESOURCES_ISLOADING = 'ANALYSIS_RESOURCES_ISLOADING';
export const ANALYSIS_RESOURCES_NEXTPAGE = 'ANALYSIS_RESOURCES_NEXTPAGE';
export const ANALYSIS_RESOURCES_UPDATE = 'ANALYSIS_RESOURCES_UPDATE';
export const ANALYSIS_UPDATE = 'ANALYSIS_UPDATE';
export const STUDIES_ISLOADING = 'STUDIES_ISLOADING';
export const STUDIES_UPDATE = 'STUDIES_UPDATE';
export const STUDY_RESOURCE_DELETE = 'STUDY_RESOURCE_DELETE';
export const STUDY_RESOURCES_NEXTPAGE = 'STUDY_RESOURCES_NEXTPAGE';
export const STUDY_RESOURCES_ISLOADING = 'STUDY_RESOURCES_ISLOADING';
export const STUDY_RESOURCES_UPDATE = 'STUDY_RESOURCES_UPDATE';
export const RESET_STATE_STUDIES_MODULE = 'RESET_STATE_STUDIES_MODULE';

/*
 * PDF Report
 */
export const GENERATING_REPORT = 'GENERATING_REPORT';

/*
 * Upload mutations
 */
export const UPLOAD_ADD_FILE = 'UPLOAD_ADD_FILE';
export const UPLOAD_START = 'UPLOAD_START';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const UPLOAD_RESET = 'UPLOAD_RESET';
export const UPLOAD_FILE_CANCELLER = 'UPLOAD_FILE_CANCELLER';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_SET_RESOURCE = 'UPLOAD_SET_RESOURCE';
export const RESET_STATE_UPLOAD_MODULE = 'RESET_STATE_UPLOAD_MODULE';
export const SET_UPLOAD_IN_PROGRESS = 'SET_UPLOAD_IN_PROGRESS';

/**
 * Groups
 */
export const GROUPS_REQUEST = 'GROUPS_REQUEST';
export const GROUPS_UPDATE = 'GROUPS_UPDATE';
export const GROUPS_ERROR = 'GROUPS_ERROR';

/**
 * Group memberships
 */
export const GROUP_MEMBERSHIP_REQUEST = 'GROUP_MEMBERSHIP_REQUEST';
export const GROUP_MEMBERSHIP_SUCCESS = 'GROUP_MEMBERSHIP_SUCCESS';
export const GROUP_MEMBERSHIP_ERROR = 'GROUP_MEMBERSHIP_REQUEST';

/**
 * Regions
 */
export const REGIONS_REQUEST = 'REGIONS_REQUEST';
export const REGIONS_UPDATE = 'REGIONS_UPDATE';
export const REGIONS_ERROR = 'REGIONS_ERROR';

/**
 * Specialisations
 */
export const SPECIALISATIONS_REQUEST = 'SPECIALISATIONS_REQUEST';
export const SPECIALISATIONS_SUCCESS = 'SPECIALISATIONS_SUCCESS';
export const SPECIALISATIONS_ERROR = 'SPECIALISATIONS_ERROR';
