<template>
  <div class="pt-6">
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="mb-1">
          <div class="search-and-filter d-flex gap-8px pa-2">
            <v-text-field v-model="searchTerms" outlined dense hide-details
              placeholder="Search User" @input="resetPageAndSearch" clearable>
              <template v-slot:prepend-inner>
                <v-icon>search</v-icon>
              </template>
            </v-text-field>

            <OrganisationSearch v-model="organisation" :solo="false" outlined dense
              @input="resetPageAndSearch" placeholder="Select organisation" />
          </div>

          <UserTable :users="users" @selected="selectedUser = $event" :pageCount="pageCount"
            :loading="loading" :page="page" @paginate="updatePageAndFilter" />
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <UserForm v-if="user" :user="user" @refreshList="loadUsers" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapActions, mapGetters, mapState } from 'vuex';
import OrganisationSearch from '@/components/common/OrganisationSearch.vue';
import UserForm from './UserForm.vue';
import UserTable from './UserTable.vue';

export default {
  name: 'UsersTab',
  components: {
    OrganisationSearch,
    UserTable,
    UserForm,
  },
  data() {
    return {
      debounceFilter: null,
      organisation: null,
      page: 1,
      searchTerms: null,
      selectedUser: null,
    };
  },
  computed: {
    ...mapGetters({
      users: 'usersList',
      userItem: 'userItem',
      organisationById: 'organisationById',
    }),
    ...mapState({
      loading: (state) => state.auth.usersList.loading,
      pageCount: (state) => state.auth.usersList.count,
    }),
    user() {
      return this.userItem(this.selectedUser);
    },
  },
  methods: {
    ...mapActions([
      'loadOrganisationMemberships',
      'loadUsersWithOrgs',
      'retrieveOrganisation',
      'retrieveSpecialisation',
    ]),
    loadUsers() {
      this.loadUsersWithOrgs({
        page: this.page,
        search: this.searchTerms,
        org: this.organisation,
      });
    },
    resetPageAndSearch() {
      this.page = 1;
      this.debounceFilter();
    },
    async retrieveData() {
      if (!this.user) return;
      await this.loadOrganisationMemberships({ user_id: this.user.sub });
      const { specialisationIds, organisationMemberships } = this.user;
      const organisationIds = organisationMemberships.map((m) => m.organisationId);

      if (organisationIds.length) {
        // only query organisations not saved yet, only need ID and name
        organisationIds.forEach((id) => {
          const org = this.organisationById(id);
          if (org) return;
          this.retrieveOrganisation(id);
        });
      }

      if (specialisationIds) {
        specialisationIds.forEach((id) => this.retrieveSpecialisation(id));
      }
    },
    updatePageAndFilter(page) {
      this.page = page;
      this.debounceFilter();
    },
  },
  mounted() {
    this.debounceFilter = debounce(this.loadUsers, 500);
  },
  watch: {
    selectedUser: 'retrieveData',
  },
};
</script>

<style lang="scss" scoped>
.gap-8px {
  gap: 8px;
}
</style>
