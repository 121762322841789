<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        @click="dialog=true"
        text
        outlined
        class="mr-2"
        :small="buttonSmall"
      >
        {{ buttonLabel }}
      </v-btn>
      <UploadStudyResourceDialog
        v-if="dialog"
        :study="study"
        v-model="dialog"
        :orderId="orderId"
      />
    </template>
    {{ buttonLabel }}
  </v-tooltip>
</template>

<script>
import UploadStudyResourceDialog from './UploadStudyResourceDialog.vue';

export default {
  name: 'UploadStudyResourceButton',
  props: {
    study: {
      type: Object,
      required: true,
    },
    buttonLabel: {
      type: String,
      default: 'Upload study resource',
    },
    buttonSmall: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    UploadStudyResourceDialog,
  },
};
</script>
