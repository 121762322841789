import constants from './constants';

export default {
  // Simple state deductors
  analysisById: (state, getters) => (id) => {
    const analysis = state.analyses[id];
    if (analysis) {
      return {
        ...analysis,
        get resources() {
          return analysis.analysisResourceIds.map((srId) => getters.analysisResourceById(srId))
            .filter((ar) => ar);
        },
        get landmarks() {
          return analysis.landmarkIds.map((srId) => getters.landmarkById(srId))
            .filter((l) => l);
        },
        get typeDisplay() {
          return constants.ANALYSIS_TYPE_LABELS[this.type];
        },
      };
    }
    return undefined;
  },
  analysisResourceById: (state) => (id) => {
    const analysisResource = state.analysisResources[id];
    if (analysisResource) {
      return {
        ...analysisResource,
      };
    }
    return undefined;
  },
  studyById: (state, getters) => (id) => {
    const study = state.studies[id];
    if (study) {
      return {
        ...study,
        get hasDicom() {
          return Boolean(study.dicomVolumeId);
        },
        // TODO:
        get analyses() {
          return study.analysisIds.map((aId) => getters.analysisById(aId))
            .filter((a) => a);
        },
        get resources() {
          return study.resourceIds.map((srId) => getters.studyResourceById(srId))
            .filter((sr) => sr);
        },
        get caseIdentifier() {
          // Required to upload a legacy simulation, which requires the caseIdentifier
          return getters.hgcaseById(study.caseId)
            && getters.hgcaseById(study.caseId).caseIdentifier;
        },
        get hasPdfReport() {
          return !!study.pdfReportId;
        },

      };
    }
    return undefined;
  },
  studyResourceById: (state) => (id) => {
    const studyResource = state.studyResources[id];
    if (studyResource) {
      return {
        ...studyResource,
      };
    }
    return undefined;
  },

  // eslint-disable-next-line
  missingObjectsInVisualisation: (state, getters) => (study, visualisation) => {
    if (!visualisation) return [];
    const { analysisType, visualisationObjects } = visualisation;
    return getters.studySceneObjectsByAnalysisType(study, analysisType)
      .reduce((acc, sceneObject) => {
        if (!acc.includes(sceneObject.name)) acc.push(sceneObject.name);
        return acc;
      }, [])
      .filter((name) => !visualisationObjects.map((obj) => obj.name).includes(name));
  },

  studySceneObjectsByAnalysisType: () => (study, type) => study.analyses
    // reduce the analyses to an object by analysis type and a list of scene objects
    .filter((analysis) => analysis.type === type)
    .map((analysis) => analysis.resources
      .filter((resource) => resource.type === constants.SCENE_OBJECT))
    .flat(1),

  // return the scene objects for a study that are not in the visualisation of the same type
  studyMissingSceneObjectsByType: (state, getters) => (study, config, analysisType) => {
    if (!config) return [];
    // Note: This works on analysisType because object names are shared accross visualisations,
    // so custom geometries must use unique names not used in any visualisation
    const listOfallObjectsInTheVisualisations = config.visualisations
      .filter((visualisation) => visualisation.analysisType === analysisType)
      .reduce((acc, visualisation) => {
        acc.push(
          ...visualisation.visualisationObjects
            .map((obj) => obj.name)
            .filter((name) => !acc.includes(name)),
        );
        return acc;
      }, []);
    const studySceneObjects = getters.studySceneObjectsByAnalysisType(study, analysisType)
      .reduce((acc, sceneObject) => {
        if (!acc.includes(sceneObject.name)) acc.push(sceneObject.name);
        return acc;
      }, []);
    return studySceneObjects
      .filter((name) => !listOfallObjectsInTheVisualisations.includes(name));
  },
};
