var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-2 d-flex flex-column",attrs:{"width":"345px","loading":_vm.hgcase.loading || _vm.studyLoading,"color":"white","data-cy":"case-card"}},[_c('div',{staticClass:"pa-3 white--text",class:_vm.getClass},[_c('div',{staticClass:"d-flex justify-space-between flex-grow-1 align-center"},[_c('div',{staticClass:"text-h6 d-flex align-center"},[_c('span',{staticClass:"mr-2",attrs:{"data-cy":"case-card-identifier"}},[_vm._v(_vm._s(_vm.hgcase.caseIdentifier))]),(_vm.hgcase.canRequestPatientInformation && !_vm.hgcase.isSharedToCurrentUser)?_c('ShowSensitiveDataButton',{attrs:{"hgcase":_vm.hgcase,"inverse":true}}):_vm._e(),(_vm.hgcase.physicianComment)?_c('v-tooltip',{attrs:{"bottom":"","color":"secondary","open-on-focus":false,"open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","plain":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"material-icons-outlined",attrs:{"color":"white"}},[_vm._v(" $infoOutline ")])],1)]}}],null,false,1879435183)},[_c('span',[_vm._v(_vm._s(_vm.hgcase.physicianComment))])]):_vm._e()],1),_c('Menu',{attrs:{"hgcase":_vm.hgcase}})],1),(_vm.hgcase.patientInformation && !_vm.hgcase.isSharedToCurrentUser)?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.hgcase.patientInformation.identifier)+" - "+_vm._s(_vm.sex)+" "+_vm._s(_vm.hgcase.patientInformation.age)+"y "),(_vm.hgcase.hasUpdatedPatientIdentifier)?_c('PatientIdentifierChangedTooltip'):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"mt-2 grey--text text--lighten-1 text-caption"},[_c('span',[_vm._v(" "+_vm._s(_vm.hgcase.isProspective ? 'Prospective' : 'Retrospective')+" ")]),(_vm.hgcase.interventionDatetime
          && _vm.hgcase.patientInformation
          && !_vm.hgcase.isSharedToCurrentUser)?_c('span',[_vm._v(" "+_vm._s(_vm._f("localeDateTime")(_vm.hgcase.interventionDatetime))+" ")]):_vm._e()])]),_c('v-card-text',{staticClass:"grow d-flex flex-column position-relative"},[(_vm.user.canShareCase || _vm.hgcase.isSharedToCurrentUser)?_c('ShareButtons',{attrs:{"hgCase":_vm.hgcase}}):_vm._e(),(_vm.hgcase.status===_vm.CANCELED)?_c('div',{staticClass:"text-center my-auto primary--text"},[_c('p',[_vm._v("Analyses canceled")]),_c('v-icon',{attrs:{"x-large":"","color":"error"}},[_vm._v(" do_disturb_alt ")])],1):(_vm.hgcase.status===_vm.REJECTED)?_c('div',[_c('ServiceAndOrder',{attrs:{"hgcase":_vm.hgcase,"rejected":true}}),_c('h4',{staticClass:"primary--text"},[_vm._v("Could not be processed")]),_c('ExpandCollapseText',{staticClass:"mt-4",attrs:{"text":_vm.hgcase.analystComment || ''}}),_c('div',{staticClass:"text-center my-auto mt-4"},[(_vm.hgcase.study && _vm.hgcase.study.dicomVolumeId)?_c('v-btn',{attrs:{"color":"primary","depressed":"","to":{
              name: 'mprviewer',
              params: { id: _vm.hgcase.study.id }
            }}},[_vm._v("View dicom")]):_vm._e()],1)],1):_c('div',[_c('ServiceAndOrder',{staticClass:"mb-2",attrs:{"hgcase":_vm.hgcase}}),(_vm.hgcase.status===_vm.CREATED && _vm.user.canAddInputData)?_c('UploadImagesInputBox',{attrs:{"hgcase":_vm.hgcase}}):_vm._e(),(_vm.hasRecentUpload && _vm.hgcase.status === _vm.IN_PROGRESS)?_c('v-alert',{staticClass:"text-center",attrs:{"color":"success","text":""}},[_vm._v(" Images uploaded! ")]):_vm._e(),(_vm.hgcase.study && _vm.hgcase.hasDeliveredOrder)?_c('div',{staticClass:"py-4 text-center my-auto primary--text"},[_c('v-btn',{attrs:{"depressed":"","to":{
              name: 'study',
              params: { id: _vm.hgcase.study.id }
            },"color":"primary"}},[_vm._v(" View result ")]),_c('br')],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"mt-auto px-4 pt-0 pb-2"},[_c('div',{staticClass:"text--secondary body-2"},[_vm._v("Expected Delivery Date "),_c('strong',[_vm._v(_vm._s(_vm._f("localeDateTime")(_vm.hgcase.expectedDeliveryDatetime)))])])]),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-4 text-caption"},[_c('div',[_c('span',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.hgcase.organisation && _vm.hgcase.organisation.name)+" ")]),_c('br'),_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" Updated: "+_vm._s(_vm._f("localeDateTime")(_vm.hgcase.dateModified))+" ")])]),_c('v-spacer'),(_vm.hgcase.hasPdfReport && (_vm.hgcase.hasDeliveredOrder || _vm.user.canViewUndeliveredAnalyses))?_c('report-button',{attrs:{"hgcase":_vm.hgcase}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }