/* eslint-disable no-param-reassign */
import * as types from '@/store/mutation-types';

import initialState from './state';

export default {
  [types.RESET_STATE_UPLOAD_MODULE](state) {
    Object.assign(state, initialState());
  },

  /* TODO: Replace with 'UPDATE' mutation */
  [types.UPLOAD_ADD_FILE]: (state, uploads) => {
    state.uploads = { ...state.uploads, ...uploads };
  },
  /* TODO: Replace following with state mutations, or move to functions */
  [types.UPLOAD_START]: (state, upload) => {
    upload.inProgress = true;
  },
  [types.UPLOAD_ERROR]: (state, upload) => {
    upload.inProgress = false;
  },
  [types.UPLOAD_RESET]: (state, upload) => {
    upload.file = null;
    upload.progress = 0;
    upload.canceller = null;
    upload.inProgress = false;
  },
  [types.UPLOAD_FILE_CANCELLER]: (state, { upload, canceller }) => {
    upload.canceller = canceller;
  },

  [types.UPLOAD_PROGRESS]: (state, { upload, progress }) => {
    upload.progress = progress;
  },

  [types.UPLOAD_SET_RESOURCE]: (state, { upload, resource }) => {
    state.uploadByResourceId[resource.id] = upload;
  },
  [types.SET_UPLOAD_IN_PROGRESS]: (state, value) => {
    state.uploadInProgress = value;
  },
};
