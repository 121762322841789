import {
  interventionsAPI,
  devicesAPI,
  deviceVariantsAPI,

} from '@/services/heartguide';

import {
  DEVICE_VARIANTS_CLEAR,
  DEVICE_VARIANTS_UPDATE,
  DEVICES_CLEAR,
  DEVICES_UPDATE,
  INTERVENTIONS_UPDATE,
  RESET_STATE_INTERVENTIONS_MODULE,
} from '@/store/mutation-types';

import { convertArrayToObject } from '@/utils';

export default {
  async updateInterventions({ commit }) {
    return interventionsAPI.get()
      .then((response) => {
        const interventions = convertArrayToObject(response.data);
        commit(INTERVENTIONS_UPDATE, interventions);
        return interventions;
      })
      .catch((error) => {
        throw error;
      });
  },
  async updateDevices({ commit }) {
    return devicesAPI.get()
      .then((response) => {
        const devices = convertArrayToObject(response.data);
        commit(DEVICES_UPDATE, devices);
        return devices;
      })
      .catch((error) => {
        throw error;
      });
  },
  async loadDevices({ getters, commit, dispatch }, skipIfNotEmpty = false) {
    if (skipIfNotEmpty && getters.devices.length > 0) return;
    commit(DEVICES_CLEAR);
    dispatch('updateDevices');
  },
  async loadDeviceVariants({ getters, commit, dispatch }, skipIfNotEmpty = false) {
    if (skipIfNotEmpty && getters.devices.length > 0) return;
    commit(DEVICE_VARIANTS_CLEAR);
    dispatch('updateDeviceVariants');
  },
  async updateDeviceVariants({ commit }) {
    return deviceVariantsAPI.get()
      .then((response) => {
        const deviceVariants = convertArrayToObject(response.data);
        commit(DEVICE_VARIANTS_UPDATE, deviceVariants);
        return deviceVariants;
      })
      .catch((error) => {
        throw error;
      });
  },

  async resetState({ commit }) {
    commit(RESET_STATE_INTERVENTIONS_MODULE);
  },
};
