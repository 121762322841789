<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        @click="dialog=true"
        text
        outlined
        color="error"
        :disabled="disabled"
      >
        <!-- <v-icon left> clear </v-icon> -->
        Reject
      </v-btn>
      <CaseRejectDialog
        v-if="dialog"
        :hgcase="hgcase"
        :value="dialog"
        @close="dialog=false"
        @click:outside="dialog=false"
      />
    </template>
    Reject case
  </v-tooltip>
</template>

<script>
import CaseRejectDialog from './CaseRejectDialog.vue';

export default {
  name: 'CaseRejectButton',
  props: {
    hgcase: Object,
    disabled: Boolean,
  },
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    CaseRejectDialog,
  },
};
</script>
