export default () => ({
  userinfoIsLoading: false,
  usersIsLoading: false,
  status: '',
  user: {},
  users: {},
  usersList: {
    loading: false,
    ids: [],
    page: 1,
    search: null,
    count: 0,
    organisation_id: null,
  },
  incomingList: {
    loading: false,
    ids: [],
    page: 1,
    count: 0,
  },
  permissions: [],
  casesTableView: false,
  groups: [],
  specialisations: {},
});
