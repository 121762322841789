import axios from 'axios';

const baseURL = process.env.VUE_APP_FEOPSAPI_BACKEND_URL;

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use((config) => {
  const accesstoken = localStorage.getItem('accessToken');
  if (accesstoken) {
    // eslint-disable-next-line
    config.headers.Authorization = `Bearer ${accesstoken}`;
  }
  return config;
});

const clearTokens = (error) => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  window.location.assign(`/login?redirect=${window.location.pathname}`);
  throw error;
};

instance.interceptors.response.use(
  undefined,
  async (error) => {
    if (error.response && error.response.status !== 401) {
      // Throw any error that is not due to authentication and
      // handle it by other handlers.
      throw error;
    }

    // If the previous request was a failed request for a refresh token,
    // log out the user and redirect him/her to the login page.
    if (error.config && error.config.url.includes('refreshtoken')) {
      clearTokens(error);
    }

    // If the token was invalid, try to get a new one by using the refresh token.
    if (error.response && error.response.data.code === 'token_not_valid') {
      const resp = await axios.post(`${baseURL}refreshtoken/`, {
        refresh: localStorage.getItem('refreshToken'),
      })
        .then((response) => {
          localStorage.setItem('accessToken', response.data.access);
          localStorage.setItem('refreshToken', response.data.refresh);
          const { config } = error;
          config.headers.Authorization = `Bearer ${response.data.access}`;
          return axios.request(config);
        })
        .catch((refreshError) => {
          clearTokens(refreshError);
        });
      return resp;
    }

    // If none of the conditions was met, this a 401 error that was
    // raised for some other reason than an bad or expired token.
    // e.g. unexisting user. So throw the error so it can be handled upstream.
    throw error;
  },
);

export default instance;
