<template>
  <v-select
    hide-details
    label="Config"
    outlined
    dense
    @change="setStudyConfig"
    :items="configs"
    item-text="name"
    item-value="id"
    :loading="$store.getters.configsIsLoading"
    :value="study.configId">
  </v-select>
</template>

<script>

export default {
  name: 'ConfigSelect',
  props: ['study'],
  created() {
    this.$store.dispatch('listConfigs', { intervention_id: this.study.interventionId });
  },
  computed: {
    configs() {
      return this.$store.getters.configs;
      // .filter(
      //   (c) => c.interventionId === this.study.interventionId,
      // );
    },
  },
  methods: {
    setStudyConfig(id) {
      this.$store.dispatch('updateStudy', { id: this.study.id, configId: id });
      this.$emit('change', id);
    },
  },
};
</script>
