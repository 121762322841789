<template>
  <v-app id="app-wrapper">
    <!-- app-wrapper *must* be the top element. When in doubt, check the DICOM viewer -->
    <!-- We want the cookie dialog to come in front of everything else. -->
    <CookieDialog></CookieDialog>
    <router-view></router-view>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import CookieDialog from '@/components/common/CookieDialog.vue';

export default {
  components: {
    CookieDialog,
  },
  computed: {
    ...mapState({
      hasUploadInProgress: (state) => state.upload.uploadInProgress,
    }),
  },
  methods: {
    unloadHandler(e) {
      const event = e || window.event;

      if (this.hasUploadInProgress) {
        // for modern browsers
        event.preventDefault();

        // for legacy browsers
        e.returnValue = 'yes';
        return 'yes';
      }

      return null;
    },
    messageHandler(e) {
      const { access, refresh } = e.data;
      if (!access || !refresh) return;
      // if the access token is different from the one in the store, save it
      const accesstoken = localStorage.getItem('accessToken');
      if (accesstoken !== access) {
        this.$store.dispatch('saveMessage', { access, refresh }).then(() => {
          if (this.$route.query.redirect) this.$router.push(this.$route.query.redirect);
          // eslint-disable-next-line no-self-assign
          else document.location.href = document.location.href;
        });
      }
    },
  },
  created() {
    window.addEventListener('message', this.messageHandler, false);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.messageHandler);
    // window.removeEventListener('beforeunload', this.unloadHandler);
  },
  mounted() {
    this.$store.dispatch('detectIframe');
  },
  watch: {
    hasUploadInProgress(newVal) {
      if (newVal) window.addEventListener('beforeunload', this.unloadHandler);
      else window.removeEventListener('beforeunload', this.unloadHandler);
    },
  },
};
</script>

<style lang="scss">
// @import '@/assets/styles/main.scss';

// Elements:
html {
  font-family: Lato, sans-serif;
}
svg {
    fill: currentColor;
}

// Components:
.toasted-feops {
    min-height: 38px;
    line-height: 1.1em;
    background-color: var(--v-secondary-base);
    padding: 6px 20px;
    font-size: 15px;
    font-weight: 300;
    color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.toasted-feops.success {
    background: var(--v-success-base);
}

.toasted-feops.error {
    background: var(--v-error-base);
}

.toasted-feops i {
    margin-right: 1.5em;
}

.v-btn--column .v-btn__content {
  flex-direction: column;
  text-transform: none;
}

.v-btn--column {
  padding: 10px !important;
  height: unset !important;
  /* font-size: 0.5em !important; */
}
</style>
