<template>
<!-- Button that opens the uploadImages Dialog -->

  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ma-2"
        v-bind="attrs"
        v-on="on"
        @click="dialog=true"
        icon
      >
        <v-icon left> upload </v-icon>
      </v-btn>
      <DetailedUploadInstructionsDialog :hgcase="hgcase" :value="dialog"
        @close="dialog=false"  @click:outside="dialog=false"
      />
    </template>
    Upload patient images
  </v-tooltip>
</template>

<script>
import DetailedUploadInstructionsDialog from '@/components/Cases/DetailedUploadInstructionsDialog.vue';

export default {
  name: 'UploadImagesButton',
  props: ['hgcase'],
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    DetailedUploadInstructionsDialog,
  },
};
</script>
