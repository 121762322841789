import Auth from '@/services/auth';
import { permissionsAPI, specialisationsAPI } from '@/services/heartguide';

import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_REQUEST_SUCCESS,
  SETTINGS_PATCH,
  PROFILE_PATCH,
  USERINFO_SUCCESS,
  USERINFO_REQUEST,
  USERINFO_ERROR,
  USERS_UPDATE,
  USERS_REQUEST,
  USERS_ERROR,
  PERMISSIONS_REQUEST,
  PERMISSIONS_SUCCESS,
  PERMISSIONS_ERROR,
  RESET_STATE_AUTH_MODULE,
  USER_SET_TABLEVIEW,
  USERS_UPDATE_LIST,
  GROUPS_REQUEST,
  GROUPS_UPDATE,
  GROUPS_ERROR,
  GROUP_MEMBERSHIP_REQUEST,
  GROUP_MEMBERSHIP_SUCCESS,
  GROUP_MEMBERSHIP_ERROR,
  INCOMING_UPDATE_LIST,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  SPECIALISATIONS_REQUEST,
  SPECIALISATIONS_SUCCESS,
  SPECIALISATIONS_ERROR,
  CREATE_USER_REQUEST,
  CHANGE_USER_PASSWORD_REQUEST,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_ERROR,
  PATCH_USER_REQUEST,
  PATCH_USER_SUCCESS,
  PATCH_USER_ERROR,
} from '@/store/mutation-types';

import { convertArrayToObject } from '@/utils';

export default {
  async login({ commit }, credentials) {
    commit(AUTH_REQUEST);
    return Auth.tokens.post(credentials)
      .then((response) => {
        commit(AUTH_SUCCESS);
        localStorage.setItem('accessToken', response.data.access);
        localStorage.setItem('refreshToken', response.data.refresh);
        return response.data;
      })
      .catch((error) => {
        commit(AUTH_ERROR);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        throw error;
      });
  },

  // sample only
  saveMessage({ commit }, { access, refresh }) {
    commit(AUTH_SUCCESS);
    localStorage.setItem('accessToken', access);
    localStorage.setItem('refreshToken', refresh);
  },

  async logout({ dispatch }) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    dispatch('resetState');
  },
  async getUserInfo({ commit, dispatch }) {
    commit(USERINFO_REQUEST);
    const userinfo = await Auth.userinfo.get()
      .then((response) => {
        commit(USERINFO_SUCCESS, response.data);
        return response.data;
      })
      .catch((error) => {
        commit(USERINFO_ERROR);
        throw error;
      });
    // Wait for the permissions to be loaded, so we can check them in the router guards
    await dispatch('getPermissions');
    await dispatch('loadGroups');
    return userinfo;
  },

  async loadUsers({ commit }, params) {
    return Auth.users.get({ params })
      .then((response) => {
        const users = response.data.results;
        commit(USERS_UPDATE, convertArrayToObject(users, 'sub'));
        return response.data;
      })
      .catch((error) => {
        commit(USERS_UPDATE_LIST, { loading: false });
        throw error;
      });
  },
  async loadUsersWithOrgs({ commit, dispatch }, { page, search, org }) {
    const params = {
      page: page || 1,
      page_size: 15,
      name_email_or_title: search,
      organisation_id: org,
      has_organisation: true,
    };
    commit(USERS_UPDATE_LIST, { loading: true });
    return dispatch('loadUsers', params)
      .then((response) => commit(USERS_UPDATE_LIST, {
        page: params.page,
        search,
        organisation_id: org,
        ids: response.results.map((u) => u.sub),
        count: response.count,
        loading: false,
      }));
  },
  async loadIncomingRequests({ commit, dispatch }, { page }) {
    const params = {
      page: page || 1,
      page_size: 15,
      has_organisation: false,
    };
    commit(INCOMING_UPDATE_LIST, { loading: true });
    return dispatch('loadUsers', params)
      .then((response) => commit(INCOMING_UPDATE_LIST, {
        page: params.page,
        ids: response.results.map((u) => u.sub),
        count: response.count,
        loading: false,
      }));
  },

  async createUser({ commit }, user) {
    commit(CREATE_USER_REQUEST);
    return Auth.users.post(user)
      .then((response) => {
        const resUser = response.data;
        commit(USERS_UPDATE, convertArrayToObject([user], 'sub'));
        return resUser;
      })
      .catch((error) => {
        commit(USERS_ERROR);
        throw error;
      });
  },
  async retrieveUser({ commit }, sub) {
    commit(USERS_REQUEST);
    return Auth.users.get(sub)
      .then((response) => {
        const user = response.data;
        commit(USERS_UPDATE, convertArrayToObject([user], 'sub'));
        return user;
      })
      .catch((error) => {
        commit(USERS_ERROR);
        throw error;
      });
  },
  async patchUser({ commit }, user) {
    commit(PATCH_USER_REQUEST);
    return Auth.users.patch(user.id, user)
      .then((response) => {
        const editedUser = response.data;
        commit(USERS_UPDATE, convertArrayToObject([editedUser], 'sub'));
        commit(PATCH_USER_SUCCESS);
        return editedUser;
      })
      .catch((error) => {
        commit(PATCH_USER_ERROR);
        throw error;
      });
  },
  async deleteUser({ commit }, id) {
    commit(DELETE_USER_REQUEST);
    return Auth.users.delete(id)
      .then(() => commit(DELETE_USER_SUCCESS))
      .catch((error) => {
        commit(USERS_ERROR);
        throw error;
      });
  },
  async changeUserPassword({ commit }, { userId, data }) {
    commit(CHANGE_USER_PASSWORD_REQUEST);
    return Auth.users.updatePassword(userId, data)
      .then(() => commit(CHANGE_USER_PASSWORD_SUCCESS))
      .catch((error) => {
        commit(CHANGE_USER_PASSWORD_ERROR);
        throw error;
      });
  },

  register: ({ commit }, form) => {
    commit(AUTH_REQUEST);
    return new Promise((resolve, reject) => {
      Auth.register(form)
        .then((response) => {
          commit(AUTH_REQUEST_SUCCESS);
          resolve(response);
        })
        .catch((error) => {
          commit(AUTH_ERROR);
          reject(error);
        });
    });
  },
  emailVerification: ({ commit }, verificationKey) => {
    commit(AUTH_REQUEST);
    return new Promise((resolve, reject) => {
      Auth.emailVerification(verificationKey)
        .then((response) => {
          commit(AUTH_REQUEST_SUCCESS);
          resolve(response);
        })
        .catch((error) => {
          commit(AUTH_ERROR);
          reject(error);
        });
    });
  },
  resetPassword: ({ commit }, data) => {
    commit(AUTH_REQUEST);
    return new Promise((resolve, reject) => {
      Auth.passwordReset(data)
        .then((response) => {
          commit(AUTH_REQUEST_SUCCESS);
          resolve(response);
        })
        .catch((error) => {
          commit(AUTH_ERROR);
          reject(error);
        });
    });
  },
  resetPasswordConfirmation: ({ commit }, { uid, token, data }) => {
    commit(AUTH_REQUEST);
    return new Promise((resolve, reject) => {
      Auth.passwordResetConfirmation(uid, token, data)
        .then((response) => {
          commit(AUTH_REQUEST_SUCCESS);
          resolve(response);
        })
        .catch((error) => {
          commit(AUTH_ERROR);
          reject(error);
        });
    });
  },
  async acceptTerms({ commit }, { data }) {
    commit(AUTH_REQUEST);
    return Auth.acceptTerms(data)
      .then(() => {
        commit(AUTH_REQUEST_SUCCESS);
      })
      .catch((error) => {
        commit(AUTH_ERROR);
        throw error;
      });
  },
  changePassword: ({ commit }, { data }) => {
    commit(AUTH_REQUEST);
    return new Promise((resolve, reject) => {
      Auth.passwordChange(data)
        .then((response) => {
          commit(AUTH_REQUEST_SUCCESS);
          resolve(response);
        })
        .catch((error) => {
          commit(AUTH_ERROR);
          reject(error);
        });
    });
  },
  changeProfile: ({ commit }, { data }) => {
    commit(AUTH_REQUEST);
    return new Promise((resolve, reject) => {
      Auth.profileChange(data)
        .then((response) => {
          commit(PROFILE_PATCH, response);
          commit(AUTH_REQUEST_SUCCESS);
          resolve(response);
        })
        .catch((error) => {
          commit(AUTH_ERROR);
          reject(error);
        });
    });
  },
  patchSettings({ commit, dispatch }, { patch }) {
    return new Promise((resolve, reject) => {
      Auth.patchSettings({ settings: patch })
        .then((response) => {
          commit(SETTINGS_PATCH, { patch });

          if ('cases_default_table_view' in patch) {
            dispatch('setTableView', { tableView: response.data.casesDefaultTableView });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async setTableView({ commit }, { tableView }) {
    commit(USER_SET_TABLEVIEW, tableView);
  },

  async getPermissions({ commit }) {
    commit(PERMISSIONS_REQUEST);
    return permissionsAPI.get()
      .then((response) => {
        commit(PERMISSIONS_SUCCESS, response.data);
      })
      .catch((error) => {
        commit(PERMISSIONS_ERROR);
        throw error;
      });
  },

  async loadGroups({ commit }) {
    commit(GROUPS_REQUEST);
    return Auth.groups.get()
      .then((response) => {
        commit(GROUPS_UPDATE, response.data);
      })
      .catch((error) => {
        commit(GROUPS_ERROR);
        throw error;
      });
  },
  async createGroupMembership({ commit }, params) {
    commit(GROUP_MEMBERSHIP_REQUEST);
    return Auth.groupMemberships.post(params)
      .then((response) => {
        commit(GROUP_MEMBERSHIP_SUCCESS);
        return response.data;
      })
      .catch((error) => {
        commit(GROUP_MEMBERSHIP_ERROR);
        throw error;
      });
  },
  async retrieveGroupMembership({ commit }, params) {
    commit(GROUP_MEMBERSHIP_REQUEST);
    return Auth.groupMemberships.get({ params })
      .then((response) => {
        commit(GROUP_MEMBERSHIP_SUCCESS);
        // assuming API only returns one membership
        return response.data.results[0];
      })
      .catch((error) => {
        commit(GROUP_MEMBERSHIP_ERROR);
        throw error;
      });
  },
  async deleteGroupMembership({ commit }, id) {
    commit(GROUP_MEMBERSHIP_REQUEST);
    return Auth.groupMemberships.delete(id)
      .then((response) => {
        commit(GROUP_MEMBERSHIP_SUCCESS);
        return response.data;
      })
      .catch((error) => {
        commit(GROUP_MEMBERSHIP_ERROR);
        throw error;
      });
  },
  async retrieveAndDeleteMembership({ dispatch }, params) {
    return dispatch('retrieveGroupMembership', params)
      .then(({ id }) => dispatch('deleteGroupMembership', id));
  },

  async createSpecialisation({ commit }, params) {
    commit(SPECIALISATIONS_REQUEST);
    return specialisationsAPI.post(params)
      .then((response) => {
        const specialisation = response.data;
        commit(SPECIALISATIONS_SUCCESS, convertArrayToObject([specialisation]));
        return specialisation;
      })
      .catch((error) => {
        commit(SPECIALISATIONS_ERROR);
        throw error;
      });
  },
  async retrieveSpecialisation({ commit }, id) {
    commit(SPECIALISATIONS_REQUEST);
    return specialisationsAPI.get(id)
      .then((response) => {
        const specialisation = response.data;
        commit(SPECIALISATIONS_SUCCESS, convertArrayToObject([specialisation]));
        return specialisation;
      })
      .catch((error) => {
        commit(SPECIALISATIONS_ERROR);
        throw error;
      });
  },
  async deleteSpecialisation({ commit }, id) {
    commit(SPECIALISATIONS_REQUEST);
    return specialisationsAPI.delete(id)
      .then((response) => {
        commit(SPECIALISATIONS_SUCCESS);
        return response.data;
      })
      .catch((error) => {
        commit(SPECIALISATIONS_ERROR);
        throw error;
      });
  },

  async resetState({ commit }) {
    commit(RESET_STATE_AUTH_MODULE);
  },
};
