<template>
  <v-card class="ma-1">
    <v-card-title>
      {{ deviceAccessGroup.name }}
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-list>
        <v-list-item
          :key="device.id" v-for="device in devices">
          <v-list-item-content>
            <v-list-item-title class="pb-0">
              {{ device.name }}
              <v-btn
                @click="deleteDevice(device)" icon text>
                <v-icon small color="error">delete</v-icon>
              </v-btn>
            </v-list-item-title>
            <v-list-item-subtitle>
              <div v-for="service in device.services" :key="service.id">
                {{ SERVICE_NAMES[service.service] }}:
                <span v-if="service.isClinical">
                  clinical use
                </span>
                <span v-else class="warning--text">
                  non-clinical use only
                </span>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <AddDeviceButton :deviceAccessGroup="deviceAccessGroup"/>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions class="px-6 pa-4">
      <v-spacer />
      <v-btn color="error" depressed small  @click="deleteDeviceAccessGroup">
        <v-icon left>
          delete
        </v-icon>
        Delete Template
      </v-btn>
    </v-card-actions>
    <ConfirmationDialog ref="confirm"/>

  </v-card>
</template>

<script>
import AddDeviceButton from '@/components/pages/LicensesPage/AddDeviceButton.vue';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';

export default {
  name: 'DeviceAccessGroupCard',
  components: {
    AddDeviceButton,
    ConfirmationDialog,
  },
  props: ['devices', 'deviceAccessGroup'],
  data() {
    return {
      SERVICE_NAMES: Object.freeze(['Anatomical analysis', 'Simulation', 'Results']),
    };
  },
  computed: {
  },
  methods: {
    deleteDevice(device) {
      device.deviceAccesses
        .forEach((dA) => {
          this.$store.dispatch('deleteDeviceAccess', { deviceAccess: dA });
        });
    },
    async deleteDeviceAccessGroup() {
      if (
        await this.$refs.confirm.open(
          'Confirm',
          'Are you sure you want to delete this template? Make sure no organisations are subscribed to this template!',
        )
      ) {
        this.$store.dispatch('deleteDeviceAccessGroup', { deviceAccessGroup: this.deviceAccessGroup });
      }
    },
  },
};
</script>
