<template>
  <div>
    <AppBar>
      <h4 class="text-h5">License templates</h4>
      <v-btn color="primary"
        class="mx-4"
        @click="showCreateDeviceAccessGroupDialog=true"
        depressed small>
        <v-icon left>
          add
        </v-icon>
        Add Template
      </v-btn>
    </AppBar>
    <v-main>
      <v-container >

        <v-alert type="error" outlined dense>
          Modifying existing templates has an impact on the licenses of all organisations
          subscribed to that template.
        </v-alert>

        <v-row>
          <v-col v-for="deviceAccessGroup in deviceAccessGroups" :key="deviceAccessGroup.id">
            <!-- Regular license -->
            <DeviceAccessGroupCard
              :devices="deviceAccessGroup.devices"
              :deviceAccessGroup="deviceAccessGroup"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <DeviceAccessGroupCreateDialog v-model="showCreateDeviceAccessGroupDialog" />
  </div>
</template>

<script>
import { } from 'vuex';
import AppBar from '@/components/layout/AppBar.vue';

import DeviceAccessGroupCard from './DeviceAccessGroupCard.vue';
import DeviceAccessGroupCreateDialog from './DeviceAccessGroupCreateDialog';

export default {
  name: 'DeviceAccessGroupPage',
  components: {
    AppBar,
    DeviceAccessGroupCard,
    DeviceAccessGroupCreateDialog,
  },
  data() {
    return {
      showCreateDeviceAccessGroupDialog: false,
    };
  },
  computed: {
    deviceAccessGroups() {
      return this.$store.getters.deviceAccessGroups;
    },
  },
  created() {
    this.$store.dispatch('updateDevices');
    this.$store.dispatch('updateInterventions');
    this.$store.dispatch('listDeviceAccessGroups');
  },
};
</script>
