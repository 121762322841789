<template>
  <v-autocomplete
    class="org-select"
    :hide-details="hideDetails"
    :single-line="singleLine"
    :dense="dense"
    :solo="solo"
    :background-color="backgroundColor"
    :items="items"
    item-text="name"
    item-value="id"
    :loading="organisationsIsLoading"
    :search-input.sync="search"
    v-model="innerVal"
    :outlined="outlined"
    no-data-text="No organisations found"
    :placeholder="placeholder"
    :rules="required ? [isRequired] : []"
    >
    <template v-slot:label>
      <slot>Organisation</slot>
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce } from 'debounce';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OrganisationSearch',
  props: {
    value: String,
    backgroundColor: String,
    outlined: Boolean,
    hideAll: Boolean,
    placeholder: String,
    dense: {
      type: Boolean,
      default: true,
    },
    singleLine: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    required: Boolean,
  },
  data() {
    return {
      debounceSearch: null,
      innerVal: null,
      page: 1,
      pageSize: 15,
      search: null,
    };
  },
  computed: {
    ...mapGetters(['organisations', 'organisationsIsLoading']),
    items() {
      const orgs = [...this.organisations || []];
      return this.hideAll ? orgs : [
        { name: 'All', id: null },
        ...orgs,
      ];
    },
  },
  methods: {
    ...mapActions(['getOrganisations']),
    isRequired: (v) => !!v || 'This field is required',
    searchOrganisations() {
      this.getOrganisations({
        page: this.page,
        page_size: this.pageSize,
        name__icontains: this.search === 'All' ? null : this.search,
      });
    },
  },
  created() {
    this.debounceSearch = debounce(this.searchOrganisations, 500);
    this.debounceSearch();
    this.innerVal = this.value; // declared here for an initial list of 15 orgs
  },
  watch: {
    innerVal(newVal) {
      this.$emit('input', newVal);
    },
    search() {
      if (this.organisationsIsLoading) return;
      this.debounceSearch();
    },
    value(newVal) {
      this.innerVal = newVal;
    },
  },
};
</script>
