<template>
  <v-dialog :value="value" @input="emitInput" max-width="680px">
    <v-card>

      <v-card-title class="text-h5 justify-center">
        {{ analysis.name }} - Upload Geometries
      </v-card-title>
      <v-divider />

      <v-card-text class="text-center pt-4">
        <v-form
          @submit.native.prevent="handleSubmit"
          v-model="isValid"
          ref="uploadGeometriesForm"
          lazy-validation
        >

          <!-- Drop Zone -->
          <div
            class="c-dropzone primary50 pa-4 primary--text"
            style="border-color: var(--v-primary-base) !important;"
          >
            <input
              type="file"
              @change="onFileInputAdd"
              ref="fileInput"
              style="display: none"
              multiple="multiple"
            />
            <div
              class="my-auto"
              @dragover.prevent
              @drop="onDrop"
              @click="$refs.fileInput.click()"
            >
             Click here or drag files into this area.<br />
              <v-icon x-large color="primary">cloud_upload</v-icon>
            </div>
          </div>
        </v-form>

        <!-- Filelist -->
        <div v-if="uploads.length > 0" class="mt-2">
          <v-form ref="uploadNames" v-model="hasValidNames" lazy-validation>
            <div class="upload d-flex" v-for="upload in uploads"
              :key="upload.id">
              <div class="upload__contents pl-4 pr-3 py-2 text-left d-flex flex-column
                justify-center grow">
                <strong class="upload__filename">{{ upload.filename }}</strong>

                <div class="red--text"
                  v-if="upload.hasFile && !upload.hasCaseIdInFilename(study.caseIdentifier)">
                  The file does not match the case-identifier.
                </div>

                <v-text-field outlined v-model="upload.name" dense :rules="[isRequired, isUnique]"
                  class="file-field mt-2">
                  <template v-slot:label>Name
                    <span class="red--text">*</span>
                  </template>
                  <template v-slot:append-outer>
                    <v-tooltip bottom v-if="!upload.hasFile
                      || !upload.hasCaseIdInFilename(study.caseIdentifier)">
                      <template v-slot:activator="{on, attrs}">
                        <v-btn type="button" icon v-on="on" v-bind="attrs"
                          @click="removeUpload(upload)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </template> Remove Upload
                    </v-tooltip>
                    <v-progress-circular v-else
                      :text-inside="true" :stroke-width="38" :value="upload.progress"
                      color="primary"
                    />
                  </template>
                </v-text-field>
              </div>
            </div>
          </v-form>

          <p class="mt-4">
            Uploading may take several minutes depending on your connection and
            the size of the file.
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined @click="hide">
          Close
        </v-btn>
        <v-btn outlined @click="cancelUpload" :disabled="uploads.length===0">
          Clear
        </v-btn>
        <v-btn
          depressed
          color="primary"
          @click="startUpload"
          :disabled="!canUploadFiles"
        >
          Upload all objects
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UploadGeometriesDialog',
  props: ['analysis', 'value'],
  data() {
    return {
      dialog: false,
      isValid: false,
      processing: false,
      uploads: [],
      inProgress: false,
      resourceType: this.$store.state.studies.constants.SCENE_OBJECT,
      hasValidNames: false,
    };
  },
  computed: {
    canUploadFiles() {
      return !this.processing && this.uploads.length > 0
        && this.uploads.some((u) => u.hasCaseIdInFilename(this.study.caseIdentifier)
          && this.hasUniqueName(u.name));
    },
    geometryResources() {
      return this.analysis.resources
        .filter((r) => r.type === this.resourceType)
        .map((r) => r.name);
    },
    study() {
      return this.$store.getters.studyById(this.analysis.studyId);
    },
  },
  methods: {
    addFile(file) {
      this.$store.dispatch('addFile', { file }).then((upload) => {
        // eslint-disable-next-line no-param-reassign
        this.uploads.push(upload);
      });
    },
    addFiles(files) {
      for (let i = 0; i < files.length; i += 1) {
        const file = files.item(i);
        this.addFile(file);
      }
    },
    beforeClose(event) {
      if (!this.inProgress) {
        this.cancelUpload();
        this.uploads.forEach((upload) => {
          this.$store.dispatch('resetUpload', upload);
        });
      } else {
        event.stop();
        this.$toasted.global.info({
          message: 'Please cancel your upload before closing the dialog',
        });
      }
    },
    cancelUpload() {
      this.processing = false;
      this.uploads.forEach((upload) => {
        this.$store.dispatch('cancelUpload', upload);
      });
      this.uploads = [];
    },
    emitInput(newVal) {
      this.$emit('input', newVal);
    },
    hasUniqueName(name) {
      if (!name) return false;
      const uploadNames = this.uploads.map((u) => u.name);
      return (!this.geometryResources.includes(name)
        && uploadNames.filter((n) => n === name).length <= 1);
    },
    isUnique(n) {
      return this.hasUniqueName(n) || 'Please use a unique name';
    },
    hide() {
      this.emitInput(false);
    },
    isRequired(v) {
      return !!v || 'This field is required';
    },
    metadata(upload) {
      if (upload.hasFile) {
        return { mimeType: upload.file.type || 'application/octet-stream' };
      }
      return {};
    },
    onDrop(e) {
      /* Triggered by dropping on the dropzone */
      e.preventDefault();
      this.addFiles(e.dataTransfer.files);
    },
    onFileInputAdd(e) {
      /* Triggered by clicking the dropzone, which triggers clicking the hidden
      file input */
      e.preventDefault();
      this.addFiles(e.target.files);
    },
    removeUpload(upload) {
      this.uploads = this.uploads.filter((u) => u.id !== upload.id);
    },
    startUpload() {
      this.processing = true;
      this.uploads
        .filter((upload) => upload.hasCaseIdInFilename(this.study.caseIdentifier))
        .filter((upload) => upload.name && upload.progress === 0)
        .forEach((upload) => {
          this.$store
            .dispatch('createAnalysisResource', {
              type: this.resourceType,
              analysisId: this.analysis.id,
              metadata: this.metadata(upload),
              name: upload.name,
              filename: upload.filename,
            })
            .then((analysisResource) => {
              this.$store
                .dispatch('startUpload', {
                  upload,
                  writeUrl: analysisResource.writeUrl,
                  resource: analysisResource,
                })
                .then(() => {
                  this.$store.dispatch('analysisResourceConfirmUpload',
                    { id: analysisResource.id });
                  this.$toasted.global.success({ message: 'Analysis Resource uploaded!' });
                  this.$store.dispatch('retrieveAnalysis', this.analysis.id);
                  this.$store.dispatch('listAnalysisResources',
                    { analysis_id: this.analysis.id });

                  this.processing = false;
                  this.hide();
                })
                .catch((error) => {
                  // eslint-disable-next-line
                  if (error && error.__CANCEL__) {
                    this.$toasted.global.error({ message: error.message });
                  } else {
                    this.$toasted.global.error({ error });
                  }
                });
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.file-field.file-field.file-field ::v-deep .v-input__append-outer {
  margin: 4px 0 0 12px;
}

.upload {
  border: 1px solid rgba(#000, 0.12);
  border-radius: 4px;

  &:not(:first-child) {
    margin-top: 8px;
  }
}

.upload__filename {
  font-size: 11px;
  color: rgba(#000, 0.54);
}

::v-deep .v-text-field__details.v-text-field__details {
  margin-bottom: 0;
}
</style>
