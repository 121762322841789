import Vue from 'vue';

const localeDateTime = (iso8601) => {
  // Converts a iso-8601 string that represents a date and time
  // to a locale sensitive string.
  if (iso8601) {
    return new Date(iso8601).toLocaleString([], {
      dateStyle: 'short',
      timeStyle: 'short',
    });
  }
  return 'N/A';
};

Vue.filter('localeDateTime', localeDateTime);

export default { localeDateTime };
