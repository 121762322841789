import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import closeFullscreen from '@/components/common/icons/closeFullscreen.vue';
import contrast from '@/components/common/icons/contrast.vue';
import infoOutline from '@/components/common/icons/infoOutline.vue';
import landmarks from '@/components/common/icons/landmarks.vue';
import layoutOne from '@/components/common/icons/layoutOne.vue';
import layoutTwo from '@/components/common/icons/layoutTwo.vue';
import layoutThree from '@/components/common/icons/layoutThree.vue';
import layoutFour from '@/components/common/icons/layoutFour.vue';
import openInFull from '@/components/common/icons/openInFull.vue';
import restartAlt from '@/components/common/icons/restartAlt.vue';
import pan from '@/components/common/icons/pan.vue';
import share from '@/components/common/icons/share.vue';
import sharedToUser from '@/components/common/icons/sharedToUser.vue';
import zoom from '@/components/common/icons/zoom.vue';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#e96922',
        secondary: '#212121',
        accent: '#82B1FF',
        error: '#CB3E3E',
        info: '#2196F3',
        success: '#8BCB3E',
        warning: '#E39F0D',
        primary50: '#FFD7C1',

        // intervention colors:
        tavi: '#287091',
        laao: '#9E3F2B',
        tmvr: '#708930',
        tpvr: '#cc7722',
        ttvr: '#513B60',
        nv: '#944346',
        other: '#3D3D3D',
      },
    },
  },
  icons: {
    iconfont: 'md',
    values: {
      closeFullscreen: {
        component: closeFullscreen,
      },
      landmarks: {
        component: landmarks,
      },
      infoOutline: {
        component: infoOutline,
      },
      layout1: {
        component: layoutOne,
      },
      layout2: {
        component: layoutTwo,
      },
      layout3: {
        component: layoutThree,
      },
      layout4: {
        component: layoutFour,
      },
      openInFull: {
        component: openInFull,
      },
      restartAlt: {
        component: restartAlt,
      },
      contrast: {
        component: contrast,
      },
      pan: {
        component: pan,
      },
      zoom: {
        component: zoom,
      },
      sharing: {
        component: share,
      },
      sharedToUser: {
        component: sharedToUser,
      },
    },
  },
});
