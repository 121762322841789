import {
  organisationMembershipsAPI,
  organisationsAPI,
  regionsAPI,
} from '@/services/heartguide';

import {
  ORGANISATIONS_CLEAR,
  ORGANISATIONS_ERROR,
  ORGANISATIONS_ISLOADING,
  ORGANISATIONS_NEXTPAGE,
  ORGANISATIONS_UPDATE,
  RESET_STATE_ORGANISATIONS_MODULE,
  REGIONS_REQUEST,
  REGIONS_UPDATE,
  REGIONS_ERROR,
  ORG_MEMBERSHIP_REQUEST,
  ORG_MEMBERSHIP_UPDATE,
  ORG_MEMBERSHIP_ERROR,
  ORG_MEMBERSHIP_REMOVE,
  MANAGEMENT_ORGS_UPDATE,
  MANAGEMENT_ORGS_INSERT,
  DELETE_ORGANISATION_SUCCESS,
} from '@/store/mutation-types';

import { convertArrayToObject } from '@/utils';

// to track organisation first query
let firstOrganisationsLoad = false;

export default {

  async getOrganisations({ commit }, params) {
    commit(ORGANISATIONS_ISLOADING, true);
    return organisationsAPI.get({ params })
      .then((response) => {
        const { results, count } = response.data;
        commit(ORGANISATIONS_ISLOADING, false);
        const organisations = convertArrayToObject(results);
        commit(ORGANISATIONS_UPDATE, organisations);

        if (!firstOrganisationsLoad) {
          commit(MANAGEMENT_ORGS_UPDATE, {
            ids: results.map((org) => org.id),
            count,
          });
          firstOrganisationsLoad = true;
        }

        return response.data;
      })
      .catch((error) => {
        commit(ORGANISATIONS_ISLOADING, false);
        commit(ORGANISATIONS_ERROR);
        throw error;
      });
  },
  async loadOrganisations({ dispatch, commit }, params) {
    await dispatch('getOrganisations', params)
      .then((responseData) => {
        const { results, count } = responseData;
        commit(MANAGEMENT_ORGS_UPDATE, {
          ids: results.map((org) => org.id),
          count,
        });
      });
  },
  async retrieveOrganisation({ commit, dispatch }, id) {
    commit(ORGANISATIONS_ISLOADING, true);
    return organisationsAPI.get(id)
      .then((response) => {
        commit(ORGANISATIONS_ISLOADING, false);
        const organisation = response.data;
        commit(ORGANISATIONS_UPDATE, convertArrayToObject([organisation]));

        // query user specialisations
        const { specialisations } = organisation;
        (specialisations || []).forEach((spec) => dispatch('retrieveUser', spec.userId));

        return organisation;
      })
      .catch((error) => {
        commit(ORGANISATIONS_ISLOADING, false);
        commit(ORGANISATIONS_ERROR);
        throw error;
      });
  },
  async patchOrganisation({ commit }, org) {
    commit(ORGANISATIONS_ISLOADING, true);
    return organisationsAPI.patch(org.id, org)
      .then((response) => {
        const organisation = response.data;
        commit(ORGANISATIONS_UPDATE, convertArrayToObject([organisation]));
        return organisation;
      })
      .catch((error) => {
        commit(ORGANISATIONS_ISLOADING, false);
        commit(ORGANISATIONS_ERROR);
        throw error;
      });
  },
  async deleteOrganisation({ commit }, id) {
    commit(ORGANISATIONS_ISLOADING, true);
    return organisationsAPI.delete(id)
      .then(() => commit(DELETE_ORGANISATION_SUCCESS, id))
      .catch((error) => {
        commit(ORGANISATIONS_ISLOADING, false);
        commit(ORGANISATIONS_ERROR);
        throw error;
      });
  },
  async setOrganisations({ commit, dispatch }) {
    commit(ORGANISATIONS_CLEAR);
    commit(ORGANISATIONS_NEXTPAGE, 1);
    dispatch('updateOrganisations');
  },
  async updateOrganisations({ commit, state }) {
    commit(ORGANISATIONS_ISLOADING, true);
    const params = {
      page: state.nextPage,
    };
    return organisationsAPI.get({ params })
      .then((response) => {
        commit(ORGANISATIONS_ISLOADING, false);
        const organisations = convertArrayToObject(response.data.results);
        commit(ORGANISATIONS_UPDATE, organisations);

        if (response.data.next) {
          commit(ORGANISATIONS_NEXTPAGE, state.nextPage + 1);
        }
        return organisations;
      })
      .catch((error) => {
        commit(ORGANISATIONS_ISLOADING, false);
        commit(ORGANISATIONS_ERROR);
        throw error;
      });
  },
  async createOrganisation({ commit }, params) {
    commit(ORGANISATIONS_ISLOADING, true);
    return organisationsAPI.post(params)
      .then((response) => {
        const organisation = response.data;
        commit(ORGANISATIONS_UPDATE, convertArrayToObject([organisation]));
        commit(ORGANISATIONS_ISLOADING, false);
        commit(MANAGEMENT_ORGS_INSERT, organisation);
        return organisation;
      })
      .catch((error) => {
        commit(ORGANISATIONS_ISLOADING, false);
        commit(ORGANISATIONS_ERROR);
        throw error;
      });
  },

  async retrieveOrganisationLicense({ commit, getters }, { id, cancelToken }) {
    const org = getters.organisationById(id);
    if (org && org.hasLicenseLoaded) {
      // Don't load the license twice, use updateOrganisationLicense for that
      return org;
    }
    return organisationsAPI.get(id, { cancelToken })
      .then((response) => {
        const organisations = convertArrayToObject([response.data]);
        commit(ORGANISATIONS_UPDATE, organisations);
        return response.data;
      })
      .catch((error) => {
        commit(ORGANISATIONS_ERROR);
        throw error;
      });
  },
  async updateOrganisationLicense({ commit }, { id }) {
    if (id) {
      return organisationsAPI.get(id)
        .then((response) => {
          const organisations = convertArrayToObject([response.data]);
          commit(ORGANISATIONS_UPDATE, organisations);
          return response.data;
        })
        .catch((error) => {
          commit(ORGANISATIONS_ERROR);
          throw error;
        });
    }
    return undefined;
  },

  async loadRegions({ commit }) {
    commit(REGIONS_REQUEST);
    return regionsAPI.get()
      .then((response) => {
        const regions = response.data.results;
        commit(REGIONS_UPDATE, convertArrayToObject(regions));
        return regions;
      })
      .catch((error) => {
        commit(REGIONS_ERROR);
        throw error;
      });
  },

  async loadOrganisationMemberships({ commit }, params) {
    commit(ORG_MEMBERSHIP_REQUEST);
    return organisationMembershipsAPI.get({ params })
      .then((response) => {
        const memberships = response.data.results;
        commit(ORG_MEMBERSHIP_UPDATE, convertArrayToObject(memberships));
        return memberships;
      })
      .catch((error) => {
        commit(ORG_MEMBERSHIP_ERROR);
        throw error;
      });
  },
  async createOrganisationMembership({ commit }, params) {
    commit(ORG_MEMBERSHIP_REQUEST);
    return organisationMembershipsAPI.post(params)
      .then((response) => {
        const membership = response.data;
        commit(ORG_MEMBERSHIP_UPDATE, convertArrayToObject([membership]));
        return membership;
      })
      .catch((error) => {
        commit(ORG_MEMBERSHIP_ERROR);
        throw error;
      });
  },
  async deleteOrganisationMembership({ commit }, id) {
    commit(ORG_MEMBERSHIP_REQUEST);
    return organisationMembershipsAPI.delete(id)
      .then((response) => {
        commit(ORG_MEMBERSHIP_REMOVE, id);
        return response.data;
      })
      .catch((error) => {
        commit(ORG_MEMBERSHIP_ERROR);
        throw error;
      });
  },
  async patchOrganisationMembership({ commit }, membership) {
    commit(ORG_MEMBERSHIP_REQUEST);
    return organisationMembershipsAPI.patch(membership.id, membership)
      .then((response) => {
        const membershipResponse = response.data;
        commit(ORG_MEMBERSHIP_UPDATE, convertArrayToObject([membership]));
        return membershipResponse;
      })
      .catch((error) => {
        commit(ORG_MEMBERSHIP_ERROR);
        throw error;
      });
  },

  async resetState({ commit }) {
    commit(RESET_STATE_ORGANISATIONS_MODULE);
  },
};
