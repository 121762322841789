import Vue from 'vue';
import Vuex from 'vuex';

import upload from '@/modules/upload/store';

import auth from './modules/auth';
import cases from './modules/cases';
import configs from './modules/configs';
import interventions from './modules/interventions';
import heartguide from './modules/heartguide';
import licenses from './modules/licenses';
import organisations from './modules/organisations';
import pages from './modules/pages';
import studies from './modules/studies';
import trainings from './modules/trainings';
import viewer from './modules/viewer';
import layout from './modules/layout';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    cases,
    configs,
    interventions,
    heartguide,
    licenses,
    organisations,
    pages,
    studies,
    trainings,
    upload,
    viewer,
    layout,
  },
});
