<template>
  <div>
    <AppBar>
      <h4 class="text-h5">Documentation</h4>
    </AppBar>

    <v-main>
      <v-container>
        <div v-for="doc in docs" :key="doc.region">
          <h3 v-if="docs.length > 1">{{ doc.title }}</h3>
          <div v-html="doc.htmlSnippet" />
        </div>
      </v-container>
    </v-main>
    <div v-if="showVersion" class="version caption text-center">
      Web application test version: {{ version }}
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import { mapState } from 'vuex';
import AppBar from '@/components/layout/AppBar.vue';

export default {
  name: 'documentation',
  components: {
    AppBar,
  },
  data() {
    return {
      region: process.env.VUE_APP_REGION || 'eu',
    };
  },
  computed: {
    ...mapState({
      version: (state) => (state.heartguide.version.frontend.version),
      canCreateCases: (state) => (
        state.auth.permissions
        && state.auth.permissions.includes('cases.add_heartguidecase')
      ),
      docs: (state) => (state.pages.pages.docs),
    }),
    // Show this.version if it contains 'dev'
    showVersion: function showVersion() {
      return this.version.includes('dev');
    },
  },
  mounted() {
    this.$store.dispatch('getPages', { slug: 'docs' });
  },
};
</script>
