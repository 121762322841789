<template>
  <div class="pt-6">
    <v-row>
      <v-col cols="12" md="6">
        <UserTable :users="users" @selected="selectedUser = $event" :pageCount="pageCount"
          :page="page" @paginate="updatePageAndFilter" incoming />
      </v-col>

      <v-col cols="12" md="6">
        <UserForm v-if="user" :user="user" @refreshList="updatePageAndFilter(page)" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import UserForm from './UserForm.vue';
import UserTable from './UserTable.vue';

export default {
  name: 'IncomingRequestsTab',
  components: {
    UserForm,
    UserTable,
  },
  data() {
    return {
      selectedUser: null,
      page: 1,
    };
  },
  computed: {
    ...mapGetters({
      users: 'incomingList',
      userItem: 'userItem',
    }),
    ...mapState({
      loading: (state) => state.auth.incomingList.loading,
      pageCount: (state) => state.auth.incomingList.count,
    }),
    user() {
      return this.userItem(this.selectedUser);
    },
  },
  methods: {
    updatePageAndFilter(page) {
      this.page = page;
      this.$store.dispatch('loadIncomingRequests', { page });
    },
  },
};
</script>
