/* eslint-disable */
import macro from '@kitware/vtk.js/macros';
import vtkSplineContextRepresentation from '@kitware/vtk.js/Widgets/Representations/SplineContextRepresentation';
import vtkTubeFilter from '@kitware/vtk.js/Filters/General/TubeFilter';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkCutter from '@kitware/vtk.js/Filters/Core/Cutter';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkProperty from '@kitware/vtk.js/Rendering/Core/Property';

import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkSpline3D from '@kitware/vtk.js/Common/DataModel/Spline3D';

function vtkSplineTubeCutterContextRepresentation(publicAPI, model) {
  model.classHierarchy.push('vtkSplineTubeCutterContextRepresentation');

  model._pipelines['cutter'] = {
    actor: vtkActor.newInstance({ parentProp: publicAPI }),
    mapper: vtkMapper.newInstance(),
    cutter: vtkCutter.newInstance(),
    tubeFilter: vtkTubeFilter.newInstance(),
  }

  model._pipelines['tube'] = {
    actor: vtkActor.newInstance({ parentProp: publicAPI }),
    mapper: vtkMapper.newInstance(),
    tubeFilter: vtkTubeFilter.newInstance(),
  }

  model._pipelines.tube.tubeFilter.setInputConnection(
    model._pipelines.border.filter.getOutputPort(),
  );
  model._pipelines.tube.tubeFilter.setRadius(0.3);
  model._pipelines.tube.tubeFilter.setNumberOfSides(50);
  model._pipelines.tube.tubeFilter.setCapping(false);
  model._pipelines.tube.mapper.setInputConnection(model._pipelines.tube.tubeFilter.getOutputPort());
  model._pipelines.tube.actor.setMapper(model._pipelines.tube.mapper);

  model._pipelines.cutter.tubeFilter.setInputConnection(
      model._pipelines.border.filter.getOutputPort(),
  );
  model._pipelines.cutter.tubeFilter.setRadius(1.2);
  model._pipelines.cutter.tubeFilter.setNumberOfSides(50);
  model._pipelines.cutter.tubeFilter.setCapping(false);

  model._pipelines.cutter.cutter.setInputConnection(model._pipelines.cutter.tubeFilter.getOutputPort());

  model._pipelines.cutter.mapper.setInputConnection(model._pipelines.cutter.cutter.getOutputPort());
  model._pipelines.cutter.actor.setMapper(model._pipelines.cutter.mapper);
  publicAPI.addActor(model._pipelines.cutter.actor);

  publicAPI.addActor(model._pipelines.tube.actor);

  publicAPI.requestData = (inData, outData) => {
    if (model.deleted) {
      return;
    }

    const polydata = vtkPolyData.newInstance();
    const widgetState = inData[0];
    const closed = widgetState.getSplineClosed();

    const list = publicAPI
      .getRepresentationStates(widgetState)
      .filter(
        (state) =>
          state.getVisible &&
          state.getVisible() &&
          state.getOrigin &&
          state.getOrigin()
      );

    const inPoints = list.map((state) => state.getOrigin());
    if (inPoints.length <= 1) {
      outData[0] = polydata;
      return;
    }

    let numVertices = inPoints.length;

    if (!closed) {
      --numVertices;
    } else {
      inPoints.push(inPoints[0]);
    }

    const spline = vtkSpline3D.newInstance({
      close: widgetState.getSplineClosed(),
      kind: widgetState.getSplineKind(),
      tension: widgetState.getSplineTension(),
      bias: widgetState.getSplineBias(),
      continuity: widgetState.getSplineContinuity(),
      boundaryCondition: widgetState.getSplineBoundaryCondition(),
      boundaryConditionValues: widgetState.getSplineBoundaryConditionValues(),
    });
    spline.computeCoefficients(inPoints);

    const outPoints = new Float32Array(
      3 * ((numVertices + !closed) * model.resolution)
    );
    const outCells = new Uint32Array(numVertices * model.resolution + 2);
    outCells[0] = numVertices * model.resolution + 1;
    outCells[numVertices * model.resolution + 1] = 0;

    for (let i = 0; i < numVertices; i++) {
      for (let j = 0; j < model.resolution; j++) {
        const t = j / model.resolution;
        const point = spline.getPoint(i, t);

        outPoints[3 * (i * model.resolution + j) + 0] = point[0];
        outPoints[3 * (i * model.resolution + j) + 1] = point[1];
        outPoints[3 * (i * model.resolution + j) + 2] = point[2];
        outCells[i * model.resolution + j + 1] = i * model.resolution + j;
      }
    }

    if (closed) {
      outCells[numVertices * model.resolution + 1] = 0;
    } else {
      const lastPointIndex = numVertices * model.resolution + 1;
      const lastPoint = spline.getPoint(numVertices, 0);
      outPoints[3 * lastPointIndex + 0] = lastPoint[0];
      outPoints[3 * lastPointIndex + 1] = lastPoint[1];
      outPoints[3 * lastPointIndex + 2] = lastPoint[2];
      outCells[numVertices * model.resolution + 1] = lastPointIndex;
    }

    polydata.getPoints().setData(outPoints);
    if (model.fill) {
      polydata.getPolys().setData(outCells);
    }

    polydata.getLines().setData(model.outputBorder ? outCells : []);

    outData[0] = polydata;

    model._pipelines.area.filter.update();
    model._pipelines.border.actor
      .getProperty()
      .setColor(
        ...(inPoints.length <= 3 ||
        model._pipelines.area.filter.getErrorCount() === 0
          ? model.borderColor
          : model.errorBorderColor)
      );
    model._pipelines.tube.actor.getProperty().setColor(model.borderColor);
    model._pipelines.cutter.cutter.setCutFunction(model.plane);
    const cutProperty = model._pipelines.cutter.actor.getProperty();
    cutProperty.setRepresentation(vtkProperty.Representation.SURFACE);
    cutProperty.setColor([1, 1, 0.3]);
  };
}

const DEFAULT_VALUES = {
  resolution: 16,
  fill: true,
  // boundaryCondition : BoundaryCondition.DEFAULT
  outputBorder: false,
  borderColor: [0.1, 1, 0.1],
  errorBorderColor: [1, 0, 0],
  plane: null,
};

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  vtkSplineContextRepresentation.extend(publicAPI, model, initialValues);
  macro.get(publicAPI, model, ['mapper']);
  macro.setGet(publicAPI, model, [
    'resolution',
    'boundaryCondition',
    'fill',
    'outputBorder',
    'plane'
  ]);

  vtkSplineTubeCutterContextRepresentation(publicAPI, model);
}

export const newInstance = macro.newInstance(extend, 'vtkSplineTubeCutterContextRepresentation');
// ----------------------------------------------------------------------------

export default { newInstance, extend };
