import splineBehavior from '@kitware/vtk.js/Widgets/Widgets3D/SplineWidget/behavior';

export default function widgetBehavior(publicAPI, model) {
  // Inherit spline behavior
  splineBehavior(publicAPI, model);
  const superClass = { ...publicAPI };

  publicAPI.handleLeftButtonPress = (e) => {
    // First, run SplineWidget's handleLeftButtonPress
    const result = superClass.handleLeftButtonPress(e);
    // isDragging == true => a handle is being moved
    if (model.isDragging) {
      // activeState = current handle
      model.manipulator.setUserOrigin(model.activeState.getOrigin());
    }

    return result;
  };
}
