export default {
  trainingRequired(state) {
    // Take an intervention ID and see if there are any:
    // a) trainings for that intervention
    // b) in the user's region that
    // c) the user still needs to follow.
    return (interventionId, regionId) => {
      const trainings = state.trainings
        .filter((training) => (
          training.interventionId === interventionId
          && training.region === regionId
          && !training.userFollowedTraining));
      return trainings.length > 0;
    };
  },
  trainingForIntervention(state, getters) {
    // Take an intervention code and return the appropriate training
    return (interventionId) => {
      if (getters.user) {
        const trainings = state.trainings
          .filter((training) => (
            training.interventionId === interventionId
            && training.region === getters.user.primaryOrganisation.region
          ));
        if (trainings.length > 0) {
          return trainings[0];
        }
      }
      return undefined;
    };
  },
};
