<template>
<!-- Use d-flex and d-flex-column to make the card-text "grow" the full height -->
  <v-card
    class="mb-2 d-flex flex-column"
    width="345px"
    :loading="hgcase.loading || studyLoading"
    color="white"
    data-cy="case-card"
  >
  <!-- Title -->

    <div :class="getClass" class="pa-3 white--text">
      <div class="d-flex justify-space-between flex-grow-1 align-center">
        <div class="text-h6 d-flex align-center">
          <span class="mr-2" data-cy="case-card-identifier">{{ hgcase.caseIdentifier }}</span>
          <ShowSensitiveDataButton
            v-if="hgcase.canRequestPatientInformation && !hgcase.isSharedToCurrentUser"
            :hgcase="hgcase"
            :inverse="true"
          />
          <v-tooltip bottom color="secondary"
            :open-on-focus="false"
            :open-on-hover="false"
            v-if="hgcase.physicianComment"  >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon plain v-bind="attrs"
                v-on="on">
                <v-icon color="white" class="material-icons-outlined">
                  $infoOutline
                </v-icon>
              </v-btn>
            </template>
            <span>{{ hgcase.physicianComment }}</span>
          </v-tooltip>
        </div>
        <Menu :hgcase="hgcase" />
      </div>

      <div v-if="hgcase.patientInformation && !hgcase.isSharedToCurrentUser">
        <div>
          {{ hgcase.patientInformation.identifier }}
          - {{ sex }} {{ hgcase.patientInformation.age }}y
            <PatientIdentifierChangedTooltip v-if="hgcase.hasUpdatedPatientIdentifier"/>
              <!-- slot -->
        </div>
      </div>

      <div class="mt-2 grey--text text--lighten-1 text-caption">
        <span>
          {{ hgcase.isProspective ? 'Prospective' : 'Retrospective'}}
        </span>
        <span v-if="hgcase.interventionDatetime
          && hgcase.patientInformation
          && !hgcase.isSharedToCurrentUser">
          {{ hgcase.interventionDatetime | localeDateTime }}
        </span>
      </div>
    </div>

    <!-- Body -->
    <v-card-text class="grow d-flex flex-column position-relative">
      <ShareButtons v-if="user.canShareCase || hgcase.isSharedToCurrentUser" :hgCase="hgcase"  />

      <div v-if="hgcase.status===CANCELED" class="text-center my-auto primary--text">
        <p>Analyses canceled</p>
        <v-icon x-large color="error">
          do_disturb_alt
        </v-icon>
      </div>
      <div v-else-if="hgcase.status===REJECTED">
        <ServiceAndOrder :hgcase="hgcase" :rejected="true" />
        <h4 class="primary--text">Could not be processed</h4>

        <ExpandCollapseText :text="hgcase.analystComment || ''" class="mt-4" />

        <div class="text-center my-auto mt-4">
          <v-btn
            v-if="hgcase.study && hgcase.study.dicomVolumeId"
            color="primary" depressed
            :to="{
              name: 'mprviewer',
              params: { id: hgcase.study.id }
            }">View dicom</v-btn>
        </div>
      </div>
      <div v-else>
        <!-- Device and order information -->
        <ServiceAndOrder :hgcase="hgcase" class="mb-2"/>

        <UploadImagesInputBox
          v-if="hgcase.status===CREATED && user.canAddInputData"
          :hgcase="hgcase"
        />

        <v-alert v-if="hasRecentUpload && hgcase.status === IN_PROGRESS" color="success"
          text class="text-center">
          Images uploaded!
        </v-alert>

        <div v-if="hgcase.study && hgcase.hasDeliveredOrder"
          class="py-4 text-center my-auto primary--text">
          <v-btn
            depressed
            :to="{
              name: 'study',
              params: { id: hgcase.study.id }
            }"
            color="primary"
          >
            View result
          </v-btn><br />
        </div>
      </div>
    </v-card-text>

    <v-card-actions class="mt-auto px-4 pt-0 pb-2">
      <div class="text--secondary body-2">Expected Delivery Date
        <strong>{{ hgcase.expectedDeliveryDatetime | localeDateTime }}</strong>
      </div>
    </v-card-actions>

    <v-divider />

    <v-card-actions class="pa-4 text-caption">
      <div>
        <span class="secondary--text ">
          {{ hgcase.organisation && hgcase.organisation.name }}
        </span><br />
        <span class="grey--text text-caption">
          Updated: {{ hgcase.dateModified | localeDateTime }}
        </span>
      </div>
      <v-spacer/>
      <report-button
        :hgcase="hgcase"
        v-if="hgcase.hasPdfReport && (hgcase.hasDeliveredOrder || user.canViewUndeliveredAnalyses)"
        />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import constants from '@/store/modules/cases/constants';
import ShowSensitiveDataButton from '@/components/Cases/ShowSensitiveDataButton.vue';
import UploadImagesInputBox from '@/components/Cases/UploadImagesInputBox.vue';
import ReportButton from '@/components/ReportButton.vue';
import ExpandCollapseText from '@/components/common/ExpandCollapseText.vue';
import PatientIdentifierChangedTooltip from '@/components/common/PatientIdentifierChangedTooltip.vue';
import ShareButtons from './ShareButtons.vue';

import Menu from './CaseCardMenu.vue';
import ServiceAndOrder from './ServiceAndOrder';

export default {
  name: 'CaseCard',
  components: {
    Menu,
    ServiceAndOrder,
    ShowSensitiveDataButton,
    UploadImagesInputBox,
    ReportButton,
    ExpandCollapseText,
    PatientIdentifierChangedTooltip,
    ShareButtons,
  },
  data: () => ({
    CANCELED: constants.CASE_STATUSES.CANCELED,
    CREATED: constants.CASE_STATUSES.CREATED,
    COMPLETED: constants.CASE_STATUSES.COMPLETED,
    REJECTED: constants.CASE_STATUSES.REJECTED,
    IN_PROGRESS: constants.CASE_STATUSES.IN_PROGRESS,
    studyLoading: false,
  }),
  props: [
    'hgcase',
  ],
  computed: {
    ...mapGetters(['user']),
    ...mapState({
      uploadIds: (state) => Object.keys(state.upload.uploadByResourceId),
    }),
    constants() {
      return constants;
    },
    sex() {
      if (this.hgcase.patientInformation) {
        if (this.hgcase.patientInformation.sex === 'M') {
          return 'male';
        } if (this.hgcase.patientInformation.sex === 'F') {
          return 'female';
        }
      }
      return 'unknown';
    },
    identifier() {
      return (this.hgcase.patientInformation && this.hgcase.patientInformation.identifier)
        || this.hgcase.caseIdentifier;
    },
    getClass() {
      return ([
        'TAVI',
        'LAAO',
        'TMVR',
        'TPVR',
        'TTVR',
        'NV',
      ].includes(
        this.hgcase.intervention?.name,
      ) && this.hgcase.intervention.name.toLowerCase()) || 'other';
    },
    hasRecentUpload() {
      return (this.hgcase.inputDataIds || []).some((id) => this.uploadIds.includes(id));
    },
  },
};
</script>

<style lang="scss" scoped>
.light-text--secondary {
  color: rgba(#FFF, 0.7);
}

.position-relative {
  position: relative;
}
</style>
