<template>
  <v-list-item class="pl-0 pr-4">
    <v-list-item-content>
      <v-list-item-title class="wrap">
        <a @click="$store.dispatch('downloadAnalysisResource', { id: analysisResource.id })">
          {{analysisResource.name}}
        </a>
        <template v-if="isLatest">(Latest)</template>
      </v-list-item-title>
      <v-list-item-subtitle>
        Uploaded: {{ analysisResource.dateCreated|localeDateTime }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-btn
      icon
      depressed
      small
      v-if="!hasDelivery && (
        (!isGeometryResource && user.canDeleteAnalysisResource)
        || (isGeometryResource && user.canDeleteVisualisationObject))"
      @click="deleteAnalysisResource(analysisResource)"
    >
      <v-icon>delete</v-icon>
    </v-btn>

    <ConfirmationDialog ref="confirm" />
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfirmationDialog from '../../common/ConfirmationDialog.vue';

export default {
  name: 'AnalysisResource',
  components: {
    ConfirmationDialog,
  },
  props: {
    analysisResource: {
      type: Object,
      required: true,
    },
    hasDelivery: Boolean,
    isLatest: Boolean,
    isGeometryResource: Boolean,
  },
  data() {
    return {
      generateReportDialog: false,
    };
  },
  created() {
  },
  computed: {
    ...mapGetters(['user']),
    ANALYSIS_RESOURCE_TYPES() {
      return this.$store.state.studies.constants.ANALYSIS_RESOURCE_TYPES;
    },
  },
  methods: {
    async deleteAnalysisResource(analysisResource) {
      if (
        await this.$refs.confirm.open(
          'Confirm',
          'Are you sure you want to delete this resource?',
        )
      ) {
        this.$store.dispatch('deleteAnalysisResource', { analysisResource });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  white-space: normal;
}
</style>
