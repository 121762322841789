<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="$store.getters.filteredHgcases"
      :items-per-page="15"
      :loading="isLoading"
      disable-sort
      disable-filtering
      hide-default-footer
      class="elevation-0 pb-14"
    >
      <template v-slot:[`item.devices`]="{ item }" >
        <!-- multiple analysis devices, no simulations -->
        <v-tooltip bottom v-if="!simulationDevices(item).length && itemDevices(item).length > 1"
          max-width="240">
          <template v-slot:activator="{on, attrs}">
            <div v-on="on" v-bind="attrs">Multiple Devices</div>
          </template>
          {{ itemDevices(item).join(', ') }}
        </v-tooltip>

        <!-- for one anatomical device, or one simulation -->
        <template else>
          <div v-if="itemDevices(item).length <= 1">{{ itemDevices(item).join(', ') }}</div>
          <div v-else-if="simulationDevices(item).length <= 1">
            {{ simulationDevices(item).join(', ') }}
          </div>
        </template>

        <div v-if="item.deviceVariantNames.length > 0" class="grey--text text-caption">
          {{ item.deviceVariantNames.join(', ') }}
        </div>
      </template>
      <template v-slot:[`item.services`]="{ item }">
        <div v-if="item.status===REJECTED" class="my-auto primary--text">
          <p class="mb-0">
            Could not be processed<br />
            <v-btn
              v-if="item.study && item.study.dicomVolumeId"
              class="my-2"
              small
              color="primary" depressed
              :to="{
                name: 'mprviewer',
                params: { id: item.study.id }
              }">View dicom
            </v-btn>
          </p>
        </div>
        <div v-else>
          <div v-for="service in item.services.orderBy('id')" :key="service.id">
            <span>
              {{ service.name }}
              <NonClinicalTooltip v-if="!service.isClinical">
                {{ service.degradationRemark }}
              </NonClinicalTooltip>
              :
            </span>
            <span :class="service.color+`--text`">
              {{ service.label }}
        </span>
          </div>
        </div>
      </template>
      <template v-slot:[`item.dateSubmitted`]="{ item }">
          {{ item.dateSubmitted | localeDateTime  }}
      </template>
      <template v-slot:[`item.dateCompleted`]="{ item }">
        <template v-if="item.status === COMPLETED">
          {{ item.dateCompleted | localeDateTime  }}
        </template>
        <template v-else-if="item.status === CANCELED">
          {{ item.dateModified | localeDateTime  }}
        </template>
      </template>
      <template v-slot:[`item.interventionDatetime`]="{ item }">
        <template v-if="item.patientInformation && !item.isSharedToCurrentUser">
          {{ item.interventionDatetime | localeDateTime  }}
        </template>
      </template>

      <template v-slot:[`item.hasPdfReport`]="{ item }" >
        <report-button
          :hgcase="item"
          v-if="item.hasPdfReport && (item.hasDeliveredOrder || user.canViewUndeliveredAnalyses)"
        />
      </template>

      <template v-slot:[`item.results`]="{ item }" >
        <div v-if="item.study && item.hasDeliveredOrder"
          class="py-4 text-center my-auto primary--text">
          <v-btn
            depressed
            :to="{
              name: 'study',
              params: { id: item.study.id }
            }"
            color="primary"
          >
            View result
          </v-btn><br />
        </div>

      </template>
      <template v-slot:[`item.detail`]="{ item }">
        <div class="d-flex">
          <v-btn
            :hgcase="item"
            icon
            :to="{ name: 'case-detail', params: {id: item.id } }"
            v-if="user.canAccessCaseDetails"
          >
            <v-icon small>
              edit
            </v-icon>
          </v-btn>

            <v-btn icon
              v-if="user.canShareCase && !item.isSharedToCurrentUser"
              @click="$store.dispatch('showShareCaseDialog', item.id)"
            >
              <v-icon small>$sharing</v-icon>
            </v-btn>
        </div>
      </template>

      <template v-slot:[`item.caseIdentifier`]="{ item }">
        {{ item.caseIdentifier }}
        <ShareButtons v-if="user.canShareCase || user.canSeeSharedCases" :hgCase="item" mini />
      </template>

      <template v-slot:[`item.patientInformation.identifier`]="{ item }">
        <div class="d-flex align-center">
          <ShowSensitiveDataButton
            v-if="item.canRequestPatientInformation && !item.isSharedToCurrentUser"
            :hgcase="item"
          />
          <span v-if="!item.isSharedToCurrentUser">
            {{ item.patientInformation && item.patientInformation.identifier }}
            <PatientIdentifierChangedTooltip v-if="item.hasUpdatedPatientIdentifier"/>
          </span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ShowSensitiveDataButton from '@/components/Cases/ShowSensitiveDataButton.vue';
import ReportButton from '@/components/ReportButton.vue';
import NonClinicalTooltip from '@/components/common/NonClinicalTooltip.vue';
import ShareButtons from './CaseCard/ShareButtons.vue';
import PatientIdentifierChangedTooltip from '@/components/common/PatientIdentifierChangedTooltip.vue';
import constants from '@/store/modules/cases/constants';

export default {
  name: 'CaseTable',
  components: {
    NonClinicalTooltip,
    PatientIdentifierChangedTooltip,
    ReportButton,
    ShowSensitiveDataButton,
    ShareButtons,
  },
  data() {
    return {
      CANCELED: constants.CASE_STATUSES.CANCELED,
      CREATED: constants.CASE_STATUSES.CREATED,
      COMPLETED: constants.CASE_STATUSES.COMPLETED,
      REJECTED: constants.CASE_STATUSES.REJECTED,
      headers: [
        { text: 'ID', value: 'caseIdentifier' },
        { text: 'Patient', value: 'patientInformation.identifier' },
        { text: 'Devices', value: 'devices' },
        { text: 'Services', value: 'services' },
        { text: 'Upload Date', value: 'dateSubmitted' },
        { text: 'Date Completed', value: 'dateCompleted' },
        { text: 'Intervention Date', value: 'interventionDatetime' },
        { text: 'Results', value: 'results' },
        { text: 'PDF', value: 'hasPdfReport' },
        { text: 'Edit', value: 'detail' },
      ],
    };
  },
  computed: {
    ...mapState({
      constants: (state) => state.cases.constants,
      isLoading: (state) => state.cases.casesPage.isLoading,
    }),
    ...mapGetters(['hidePatientID', 'userHasPermission', 'user']),
  },
  methods: {
    itemDevices(item) {
      return item.devices.orderBy('name').map((d) => d.name);
    },
    simulationDevices(item) {
      return Object.values(item.simulationDevices).map((d) => d.name);
    },
  },
};
</script>
