<template>
  <v-dialog v-model="showDialog" max-width="640">
    <template v-slot:activator="{on, attrs}">
      <v-btn outlined color="primary" class="ml-2" small v-on="on"
        v-bind="attrs">Change Devices</v-btn>
    </template>

    <v-card>
      <v-card-title>Change Devices
        <v-btn class="ml-auto" icon type="button" @click="showDialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-6">
        <p class="mb-0">
          Changing devices will affect available simulation orders and the existing simulation
          model.
        </p>

        <section class="mt-6">
          <h5 class="section-title text--secondary">Devices</h5>

          <p class="mt-6px mb-0">
            Can only select <strong>{{ hgCase.intervention.name }}</strong> devices.
            <template v-if="hgCase.intervention.name === 'LAAO'">
              Once a delivery has been made <strong>AMPLATZER™ Amulet™</strong> can no longer be
              changed or selected.
            </template>
          </p>

          <v-form v-model="isValid" class="d-flex mt-3">
            <v-row v-if="canSelectMultipleDevices">
              <v-col cols="12" class="pb-0">
                <v-autocomplete v-model="form.deviceIds" :items="devices" item-text="name"
                  item-value="id" outlined dense multiple :rules="[hasAtLeastOne, isRequired,]">
                  <template v-slot:label>Selected Devices
                    <span class="red--text">*</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <template v-if="hasSimulationOrder">
                <v-col cols="6" class="pt-0">
                  <v-autocomplete v-model="form.deviceId" :items="selectedDevices" item-text="name"
                    item-value="id" outlined dense :rules="[isRequired]">
                    <template v-slot:label>Simulation Device
                      <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" class="pt-0">
                  <v-select v-model="form.variants" multiple outlined dense :rules="[limitedAmount]"
                    :items="variants" item-text="name" item-value="id" label="Selected Size(s)" />
                </v-col>
              </template>
            </v-row>
            <v-row v-else>
              <v-col sm="6">
                <v-autocomplete v-model="form.deviceId" :items="devices" item-text="name"
                  item-value="id" outlined dense :rules="[isRequired]" item-disabled="disabled">
                  <template v-slot:label>Selected Device
                    <span class="red--text">*</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" v-if="hasSimulationOrder">
                <v-select v-model="form.variants" multiple outlined dense :rules="[limitedAmount]"
                  :items="variants" item-text="name" item-value="id" label="Selected Size(s)" />
              </v-col>
            </v-row>
          </v-form>
        </section>

        <section>
          <h5 class="section-title text--secondary mb-3">Affected Orders</h5>

          <div class="order" v-for="(order, index) in affectedOrders" :key="order.id"
            :class="{ 'mt-4': index > 0 }">
            <div class="order__header pa-3 d-flex justify-space-between text--primary">
              Order ID {{ order.id }}
              <span class="text-capitalize text--secondary">
                {{ SERVICE_NAMES[order.service] }}
              </span>
            </div>
            <div class="order__body pa-3 grey lighten-4" v-if="analysesByOrder(order.id).length">
              <v-data-table :items="analysesByOrder(order.id)" :headers="analysisHeaders"
                item-key="id" hide-default-footer hide-default-header />
            </div>
          </div>
        </section>
      </v-card-text>

      <v-divider />
      <v-card-actions class="px-6 py-3">
        <v-spacer />
        <v-btn @click="showDialog = false" text>Cancel</v-btn>
        <v-btn color="primary" depressed :disabled="!isValid" @click="submit">Change Devices</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import constants from '@/store/modules/cases/constants';

export default {
  name: 'ChangeDevicesDialog',
  props: {
    hgCase: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        deviceIds: [], // for tavi only
        deviceId: null,
        deviceVariantIds: [],
      },
      isValid: false,
      showDialog: false,
      SERVICE_NAMES: constants.SERVICE_NAMES,
      analysisHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'ID', value: 'id' },
      ],
    };
  },
  computed: {
    ...mapGetters(['deviceById']),

    affectedOrders() {
      return this.hgCase.orders.filter((o) => o.status === constants.ORDER_STATUSES.IN_PROGRESS);
    },
    canSelectMultipleDevices() {
      return this.hgCase.intervention.name === 'TAVI' && !this.hasSimulationOrder;
    },

    devices() {
      const availableDevices = this.hgCase?.intervention.devices || [];
      if (this.hgCase.intervention.name === 'LAAO') {
        // disable amulet if anatomical order has been delivered and amulet is not selected
        // if amulet is selected, disable all devices
        const amulet = availableDevices.find((d) => d.name === 'AMPLATZER™ Amulet™');
        if (amulet) {
          if (this.hasDeliveredAnatomical) {
            if (!this.form.deviceIds.includes(amulet.id)) {
              amulet.disabled = true;
            } else {
              availableDevices.forEach((d) => {
                // eslint-disable-next-line no-param-reassign
                if (d.name !== 'AMPLATZER™ Amulet™') d.disabled = true;
              });
            }
          }
        }
      }
      return availableDevices;
    },

    hasDeliveredAnatomical() {
      return this.hgCase.orders.some((o) => o.service === constants.SERVICE.ANATOMICAL
        && o.status === constants.ORDER_STATUSES.DELIVERED);
    },
    hasSimulationOrder() {
      return this.hgCase.orders.some((o) => o.service === constants.SERVICE.SIMULATION);
    },
    selectedDevices() {
      return this.form.deviceIds.map((id) => this.deviceById(id));
    },
    variants() {
      return this.devices.find((d) => d.id === this.form.deviceId)?.variants || [];
    },
  },
  methods: {
    analysesByOrder(orderId) {
      return (this.hgCase?.study?.analyses || []).filter((a) => a.orderId === orderId);
    },
    hasAtLeastOne: (v) => v.length > 0 || 'Choose at least one device',
    isRequired: (v) => !!v || 'This field is required',
    limitedAmount(v) {
      const { intervention } = this.hgCase;
      return v.length <= intervention.variantLimit
        || !intervention.variantLimit
        || `This field is limited to ${intervention.variantLimit} choices.`;
    },
    resetForm() {
      const devices = this.hgCase.devices.all();
      const simulationDevices = Object.keys(this.hgCase.simulationDevices);
      this.form = {
        deviceId: simulationDevices.length ? simulationDevices[0] : (devices[0]?.id || null),
        deviceIds: [...devices.map((d) => d.id)],
        deviceVariantIds: (this.hgCase.deviceVariants || []).map((dv) => dv.id),
      };
    },
    resetSelectedVariants() {
      this.form.variants = [];
    },
    async submit() {
      const { deviceId, deviceIds, variants } = this.form;
      Promise.all([
        ...this.affectedOrders.map((o) => {
          const orderData = { id: o.id };
          orderData.deviceIds = this.canSelectMultipleDevices ? deviceIds : [deviceId];
          if (o.service === constants.SERVICE.SIMULATION) {
            orderData.deviceVariantIds = variants;
          }
          return this.$store.dispatch('changeOrder', orderData);
        }),
      ]).then(() => {
        this.$toasted.global
          .success({ message: 'Successfully changed devices for affected orders' });
        this.$store.dispatch('retrieveCase', this.hgCase.id);
        this.showDialog = false;
      }).catch((error) => {
        this.$toasted.global.error({ error });
      });
    },
  },
  mounted() {
    this.resetForm();
  },
  watch: {
    showDialog(newVal) {
      if (!newVal) return;
      this.resetForm();
    },
    'form.deviceId': 'resetSelectedVariants',
    // // eslint-disable-next-line func-names
    // 'form.deviceIds': function (newVal) {
    //   if (newVal.includes(this.form.deviceId)) return;
    //   this.form.deviceId = null;
    // },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  font-size: inherit;
  font-weight: bold;
}

.mt-6px {
  margin-top: 6px;
}

.order {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  overflow: hidden;
}
</style>
