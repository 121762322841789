import client from '../heartguide/axiosClient';
import groupMemberships from './group-memberships';
import groups from './groups';
import tokens from './tokens';
import userinfo from './userinfo';
import users from './users';

export default {
  groupMemberships,
  groups,
  tokens,
  userinfo,
  users,
  register: (form) => client.post('registration/', form),
  emailVerification: (verificationKey) => client.get(`emailverification/${verificationKey}/`),
  passwordReset: (data) => client.post('passwordreset/', data),
  passwordResetConfirmation: (uid, token, data) => client.post(`passwordreset-confirmation/${uid}/${token}/`, data),
  getSettings: () => client.get('settings/'),
  patchSettings: (patch) => client.patch('settings/', patch),
  acceptTerms: (data) => client.patch('accept-terms/', data),
  passwordChange: (data) => client.patch('passwordchange/', data),
  profileChange: (data) => client.patch('profilechange/', data),
};
