import { configsAPI, visualisationsAPI, visualistionObjectsAPI } from '@/services/heartguide';
import { convertArrayToObject } from '@/utils';

export default {
  async listConfigs({ commit }, params) {
    commit('CONFIGS_ISLOADING', true);
    return configsAPI.get({ params })
      .then((response) => {
        commit('CONFIGS_ISLOADING', false);
        const configs = convertArrayToObject(response.data.results);
        commit('CONFIGS_UPDATE', configs);
        return configs;
      })
      .catch((error) => {
        commit('CONFIGS_ISLOADING', false);
        throw error;
      });
  },
  async retrieveConfig({ commit }, organisationId) {
    return configsAPI.get(organisationId)
      .then((response) => {
        const configs = convertArrayToObject([response.data]);
        commit('CONFIGS_UPDATE', configs);
        return response.data;
      });
  },

  async updateConfig({ commit }, config) {
    return configsAPI.patch(config.id, config)
      .then((response) => {
        const configs = convertArrayToObject([response.data]);
        commit('CONFIGS_UPDATE', configs);
        return response.data;
      });
  },

  async listVisualisations({ commit, state, dispatch }, params) {
    commit('VISUALISATIONS_ISLOADING', true);
    return visualisationsAPI.get({ params })
      .then((response) => {
        commit('VISUALISATIONS_ISLOADING', false);
        const visualisations = convertArrayToObject(response.data.results);
        commit('VISUALISATIONS_UPDATE', visualisations);

        // query visualisation objects
        response.data.results
          .map((v) => dispatch('listVisualisationObjects', { visualisation_id: v.id }));

        if (response.data.next) {
          commit('VISUALISATIONS_NEXTPAGE', state.studies.visualisationsNextPage + 1);
        } else {
          commit('VISUALISATIONS_NEXTPAGE', 1);
        }
        return visualisations;
      })
      .catch((error) => {
        commit('VISUALISATIONS_ISLOADING', false);
        throw error;
      });
  },

  async retrieveVisualisation({ commit }, organisationId) {
    return visualisationsAPI.get(organisationId)
      .then((response) => {
        const configs = convertArrayToObject([response.data]);
        commit('CONFIGS_UPDATE', configs);
        return response.data;
      });
  },

  async createVisualisation({ commit }, visualisationData) {
    return visualisationsAPI.post(visualisationData)
      .then((response) => {
        const visualisation = response.data;
        commit('VISUALISATIONS_UPDATE', convertArrayToObject([visualisation]));
        return visualisation;
      });
  },

  async updateVisualisation({ commit }, visualisationData) {
    return visualisationsAPI.patch(visualisationData.id, {
      ...visualisationData,
    })
      .then((response) => {
        const visualisation = response.data;
        commit('VISUALISATIONS_UPDATE', convertArrayToObject([visualisation]));
        return visualisation;
      });
  },

  async deleteVisualisation({ commit }, { id }) {
    return visualisationsAPI.delete(id)
      .then((response) => {
        commit('VISUALISATIONS_DELETE', id);
        return response.data;
      });
  },

  async listVisualisationObjects({ commit }, params) {
    commit('VISUALISATIONOBJECTS_ISLOADING', true);
    return visualistionObjectsAPI.get({ params })
      .then((response) => {
        commit('VISUALISATIONOBJECTS_ISLOADING', false);
        commit('VISUALISATIONOBJECTS_UPDATE', convertArrayToObject(response.data.results));
        return response.data.results;
      })
      .catch((error) => {
        commit('VISUALISATIONOBJECTS_ISLOADING', false);
        throw error;
      });
  },

  async listCustomVisualisationObjects({ dispatch }, params) {
    const objects = await dispatch('listVisualisationObjects', params);
    return objects;
  },

  async createVisualisationObject({ commit }, visualisationObjectData) {
    return visualistionObjectsAPI.post(visualisationObjectData)
      .then((response) => {
        const visualisationObject = response.data;
        commit('VISUALISATIONOBJECTS_UPDATE', convertArrayToObject([visualisationObject]));
        return visualisationObject;
      });
  },

  async deleteVisualisationObject({ commit }, { id }) {
    return visualistionObjectsAPI.delete(id)
      .then((response) => {
        commit('VISUALISATIONOBJECTS_DELETE', id);
        return response.data;
      });
  },

  async updateVisualisationObject({ commit }, objectData) {
    return visualistionObjectsAPI.patch(objectData.id, { ...objectData })
      .then((response) => {
        const visualisation = response.data;
        commit('VISUALISATIONOBJECTS_UPDATE', convertArrayToObject([visualisation]));
        return visualisation;
      });
  },

  async resetState({ commit }) {
    commit('RESET_STATE_CONFIGS_MODULE');
  },
};
