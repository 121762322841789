import axios from 'axios';
import {
  casesAPI,
  bizDatetimeInAPI,
  degradationReasonsAPI,
  degradationsAPI,
  deliveriesAPI,
  inputDataAPI,
  ordersAPI,
  rejectionReasonsAPI,
  sharedCasesAPI,
  studiesAPI,
} from '@/services/heartguide';

import { convertArrayToObject, downloadResource } from '@/utils';

import {
  CASE_UPDATE,
  CASE_SHARE_DIALOG_SHOW,
  CASE_CANCEL_DIALOG_SHOW,
  CASES_CLEAR,
  CASES_ISLOADING,
  CASES_HIDE_ALL_PATIENT_INFORMATION,
  CASES_NEXTPAGE,
  CASES_SET_ORDER_SIMULATION_DIALOG,
  CASES_SET_CASES_PAGE,
  CASES_SET_STATS,
  CASES_UPDATE,
  DEGRADATION_REASONS_UPDATE,
  INPUT_DATA_ISLOADING,
  INPUT_DATA_NEXTPAGE,
  INPUT_DATA_UPDATE,
  ORDERS_ISLOADING,
  ORDERS_UPDATE,
  REJECTION_REASONS_UPDATE,
  RESET_STATE_CASES_MODULE,
  STUDIES_UPDATE,
  PATCH_CASE_ERROR,
  SET_UPLOAD_IN_PROGRESS,
} from '@/store/mutation-types';

const { CancelToken } = axios;

export default {
  async setCases({ state, commit, dispatch }, { organisationId, searchTerm }) {
    state.casesPage.cancelTokenSource.cancel('Cancel any ongoing requests by the casesPage');
    commit(CASES_CLEAR);
    commit(CASES_NEXTPAGE, 1);
    commit(CASES_SET_CASES_PAGE, { organisationId, searchTerm });
    state.casesPage.cancelTokenSource = CancelToken.source();
    return dispatch('listCases');
  },
  async getCasesPage({ commit, dispatch }, { pageIndex, organisationId, searchTerm }) {
    commit(CASES_CLEAR);
    commit(CASES_NEXTPAGE, pageIndex);
    commit(CASES_SET_CASES_PAGE, { organisationId, searchTerm });

    return dispatch('listCases');
  },
  async listCases({ commit, state, dispatch }) {
    commit(CASES_ISLOADING, true);
    const cancelToken = state.casesPage.cancelTokenSource.token;
    const params = {
      filter: state.casesPage.searchTerm || null,
      organisation_id: state.casesPage.organisationId,
      page: state.casesPage.nextPage,
      page_size: 15,
    };
    return casesAPI.get({ params, cancelToken })
      .then((response) => {
        commit(CASES_ISLOADING, false);
        const cases = convertArrayToObject(response.data.results);
        commit(CASES_UPDATE, cases);
        response.data.results.forEach((hgcase) => dispatch('loadCaseDetails', { hgcase, cancelToken }));

        const orgIds = new Set(response.data.results.map((hgcase) => hgcase.organisationId));
        orgIds.forEach((id) => dispatch('retrieveOrganisationLicense', { id, cancelToken }));
        const pageCount = Math.ceil(response.data.count / params.page_size);
        commit(CASES_SET_STATS, { totalCount: response.data.count, pageCount });
        if (response.data.next) {
          commit(CASES_NEXTPAGE, state.casesPage.nextPage + 1);
        } else {
          commit(CASES_NEXTPAGE, 1);
        }
        return cases;
      })
      .catch((error) => {
        // only set loading as false if request was not cancelled by filtering the list again
        // eslint-disable-next-line
        if (error && !error.__CANCEL__) commit(CASES_ISLOADING, false);
        throw error;
      });
  },
  async setOrderSimulationDialog({ commit }, { show, caseId }) {
    commit(CASES_SET_ORDER_SIMULATION_DIALOG, { show, caseId });
  },
  async retrieveCase({ commit, dispatch }, id) {
    commit(CASES_ISLOADING, true);
    return casesAPI.get(id)
      .then((response) => {
        commit(CASES_ISLOADING, false);
        const updatedCase = response.data;
        commit(CASES_UPDATE, convertArrayToObject([updatedCase]));
        dispatch('loadCaseDetails', { hgcase: updatedCase });
        return updatedCase;
      })
      .catch((error) => {
        commit(CASES_ISLOADING, false);
        throw error;
      });
  },
  async createCase({ commit }, caseData) {
    commit(CASES_ISLOADING, true);
    return casesAPI.post(caseData)
      .then((response) => {
        commit(CASES_ISLOADING, false);
        commit(CASES_UPDATE, { [response.data.id]: response.data });
        return response.data;
      })
      .catch((error) => {
        commit(CASES_ISLOADING, false);
        throw error;
      });
  },
  async patchCase({ commit }, caseData) {
    return casesAPI.patch(caseData.id, caseData)
      .then((response) => {
        const editedCase = response.data;
        commit(CASES_UPDATE, convertArrayToObject([editedCase]));
        return editedCase;
      })
      .catch((error) => {
        commit(PATCH_CASE_ERROR);
        throw error;
      });
  },
  async createDegradation(store, degradationData) {
    return degradationsAPI.post(degradationData);
  },
  async createDelivery(store, deliveryData) {
    return deliveriesAPI.post(deliveryData);
  },
  async deleteDelivery(store, deliveryId) {
    store.commit(ORDERS_ISLOADING, true);
    return deliveriesAPI.delete(deliveryId).then(
      () => {
        store.commit(ORDERS_ISLOADING, false);
      },
    );
  },
  async createOrder({ commit }, orderData) {
    commit(ORDERS_ISLOADING, true);
    return ordersAPI.post(orderData)
      .then((response) => {
        commit(ORDERS_ISLOADING, false);
        commit(ORDERS_UPDATE, { [response.data.id]: response.data });
        return response.data;
      })
      .catch((error) => {
        commit(ORDERS_ISLOADING, false);
        throw error;
      });
  },
  async changeOrder({ commit }, orderData) {
    commit(ORDERS_ISLOADING, true);
    return ordersAPI.changeDevice(orderData.id, orderData)
      .then((response) => {
        commit(ORDERS_ISLOADING, false);
        commit(ORDERS_UPDATE, { [response.data.id]: response.data });
        return response.data;
      })
      .catch((error) => {
        commit(ORDERS_ISLOADING, false);
        throw error;
      });
  },

  async obsoleteDelivery({ commit }, { deliveryId }) {
    commit(ORDERS_ISLOADING, true);
    return deliveriesAPI.obsolete(deliveryId).then(
      () => {
        commit(ORDERS_ISLOADING, false);
      },
    );
  },
  async duplicateOrder({ commit }, { orderId }) {
    commit(ORDERS_ISLOADING, true);
    return ordersAPI.duplicate(orderId)
      .then((response) => {
        commit(ORDERS_ISLOADING, false);
        commit(ORDERS_UPDATE, { [response.data.id]: response.data });
        return response.data;
      });
  },
  async createInputData({ rootState, commit, dispatch }, { caseId, file }) {
    return inputDataAPI.post({
      caseId,
      name: 'Patient Images',
      filename: file.name,
      metadata: { mimeType: file.type },
      sourceVersion: rootState.heartguide.version.frontend.version,
      sourceClient: rootState.heartguide.version.frontend.clientLabel,
    })
      .then((response) => {
        const inputData = response.data;
        commit(INPUT_DATA_UPDATE, inputData);
        dispatch('retrieveCase', caseId);
        return inputData;
      });
  },
  async inputDataConfirmUpload({ commit }, { id }) {
    return inputDataAPI.confirmUpload(id)
      .then((response) => {
        // Upload Complete
        commit(INPUT_DATA_UPDATE, { id, ...response.data });
        commit(SET_UPLOAD_IN_PROGRESS, false);
        return response.data;
      })
      .catch((error) => {
        commit(SET_UPLOAD_IN_PROGRESS, false);
        throw error;
      });
  },
  async listInputData({ commit, state }, { params, cancelToken }) {
    commit(INPUT_DATA_ISLOADING, true);
    return inputDataAPI.get({ params, cancelToken })
      .then((response) => {
        commit(INPUT_DATA_ISLOADING, false);
        const intpudata = convertArrayToObject(response.data.results);
        commit(INPUT_DATA_UPDATE, intpudata);

        if (response.data.next) {
          commit(INPUT_DATA_NEXTPAGE, state.inputDataNextPage + 1);
        } else {
          commit(INPUT_DATA_NEXTPAGE, 1);
        }
        return intpudata;
      })
      .catch((error) => {
        commit(INPUT_DATA_ISLOADING, false);
        throw error;
      });
  },

  async getText(store, { hgcase }) {
    return casesAPI.getText(hgcase.id);
  },

  async downloadInputData(store, { id }) {
    return inputDataAPI.get(id, { params: { download: true } })
      .then(({ data }) => {
        downloadResource(data);
      });
  },
  async cancelCase({ commit }, { id }) {
    return casesAPI.cancel(id)
      .then((response) => {
        commit(CASES_UPDATE, convertArrayToObject([response.data]));
        return response.data;
      });
  },

  async assignCaseResponsible(store, { id, options }) {
    return casesAPI.assignCaseResponsible(id, options);
  },

  rejectCase: (
    { dispatch },
    { caseRejection },
  ) => new Promise((resolve, reject) => {
    casesAPI.reject(caseRejection.id, caseRejection)
      .then(() => {
        dispatch('retrieveCase', caseRejection.id);
        resolve();
      })
      .catch((error) => reject(error));
  }),

  shareCase: (
    { dispatch },
    { id, email },
  ) => new Promise((resolve, reject) => {
    casesAPI.share(id, { email })
      .then(() => {
        dispatch('retrieveCase', id);
        resolve();
      })
      .catch((error) => reject(error));
  }),

  unShareCase: (
    { dispatch },
    { caseId, userId },
  ) => new Promise((resolve, reject) => {
    sharedCasesAPI.unShareCase({ caseId, userId })
      .then(() => {
        dispatch('retrieveCase', caseId);
        resolve();
      })
      .catch((error) => reject(error));
  }),

  async requestSensitiveData({ commit }, { id }) {
    commit(CASES_HIDE_ALL_PATIENT_INFORMATION);
    casesAPI.requestSensitiveData(id)
      .then((response) => {
        commit(CASE_UPDATE, { id, ...response.data });
        return response;
      });
  },
  hideAllPatientIdentifiers: ({ commit }) => commit(CASES_HIDE_ALL_PATIENT_INFORMATION),
  updateRejectionReasons: ({ commit }) => new Promise((resolve, reject) => {
    rejectionReasonsAPI.get()
      .then((response) => {
        const reasons = convertArrayToObject(response.data);
        commit(REJECTION_REASONS_UPDATE, reasons);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  }),
  updateDegradationReasons: ({ commit }) => new Promise((resolve, reject) => {
    degradationReasonsAPI.get()
      .then((response) => {
        const reasons = convertArrayToObject(response.data);
        commit(DEGRADATION_REASONS_UPDATE, reasons);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  }),
  async loadCaseDetails({ commit, getters, dispatch }, { hgcase, cancelToken }) {
    // Not all information is given by the case endpoint
    if (hgcase.studyId && !getters.studyById(hgcase.studyId)) {
      // receiveStudy does not yet support the cancelToken pattern
      studiesAPI.get(hgcase.studyId, { cancelToken })
        .then((response) => {
          const studies = convertArrayToObject([response.data]);
          commit(STUDIES_UPDATE, studies);
          return response;
        });
    }
    if (hgcase.status > 0 && hgcase.inputDataIds) {
      dispatch('listInputData', { cancelToken, params: { case_id: hgcase.id } });
    }
  },
  resetState({ commit }) {
    commit(RESET_STATE_CASES_MODULE);
  },

  // offset in days by default
  async getMinDeliveryDate(_, offset) {
    return bizDatetimeInAPI.get({ params: { offset } }).then((response) => response.data.date);
  },

  async showShareCaseDialog({ commit }, hgcaseId) {
    commit(CASE_SHARE_DIALOG_SHOW, hgcaseId);
  },

  async hideShareCaseDialog({ commit }) {
    commit(CASE_SHARE_DIALOG_SHOW);
  },

  async showCancelCaseDialog({ commit }, hgcaseId) {
    commit(CASE_CANCEL_DIALOG_SHOW, hgcaseId);
  },

  async hideCancelCaseDialog({ commit }) {
    commit(CASE_CANCEL_DIALOG_SHOW);
  },

};
