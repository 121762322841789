import store from '@/store';

export default function requiresAuth(to, from, next) {
  if (store.getters.user.isAuthenticated) {
    if (!store.getters.user.email) {
      store.dispatch('getUserInfo')
        .then(() => next())
        // prevent white screen when token is invalid
        .catch(() => next({ name: 'login', query: { redirect: to.fullPath } }));
    } else {
      next();
    }
  } else {
    next({
      name: 'login',
      query: { redirect: to.fullPath },
    });
  }
}
