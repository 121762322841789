<template>
  <v-dialog max-width="800px" :value="value" @input="emitInput">
    <v-card>
      <v-card-title>Configure Additional Geometries</v-card-title>
      <v-divider />
      <v-card-text class="pt-6">
        <v-select v-model="config" :items="configs" item-text="name" item-value="id" label="Config"
          hide-details />

        <v-tabs v-model="tab" background-color="transparent" color="primary" grow centered
          class="mt-6">
          <v-tab href="#av" class="text-capitalize"
            :disabled="hasDeliveredAnatomicals">Anatomical Visualisations
            <v-chip small dark color="primary" class="ml-2"
              v-if="hasDeliveredAnatomicals">Delivered</v-chip>
          </v-tab>
          <v-tab href="#sv" class="text-capitalize"
            :disabled="hasAllDeliveredSimulations">Simulation Visualisations
            <v-chip small dark color="primary" class="ml-2"
              v-if="hasAllDeliveredSimulations">Delivered</v-chip>
          </v-tab>
        </v-tabs>

        <v-divider></v-divider>

        <v-tabs-items v-model="tab" class="mt-4">
          <v-tab-item value="av" v-if="!hasDeliveredAnatomicals">
            <VisualisationObjectsTabItem :visualisations="anatomicalVisualisations"
              :study="hgCase.study" />
          </v-tab-item>

          <v-tab-item value="sv" v-if="!hasAllDeliveredSimulations">
            <VisualisationObjectsTabItem :visualisations="simulationVisualisations"
              :study="hgCase.study" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { debounce } from 'debounce';
import caseConstants from '@/store/modules/cases/constants';
import constants from '@/store/modules/studies/constants';
import VisualisationObjectsTabItem from './VisualisationObjectsTabItem.vue';

export default {
  props: {
    definedConfig: String,
    value: Boolean,
  },
  components: {
    VisualisationObjectsTabItem,
  },
  data() {
    return {
      config: null,
      debounceGetVisuals: null,
      debounceGetObjects: null,
      tab: null,
    };
  },
  mounted() {
    this.config = this.definedConfig;
  },
  computed: {
    anatomicalVisualisations() {
      return this.visualisations
        .filter((v) => v.analysisType === constants.ANALYSIS_TYPES.ANATOMICAL);
    },
    configs() {
      return this.$store.getters.configs;
    },
    hasDeliveredAnatomicals() {
      const orders = this.hgCase.orders
        .filter((order) => order.service === caseConstants.SERVICE.ANATOMICAL);
      return orders.some((o) => o.status === caseConstants.ORDER_STATUSES.DELIVERED);
    },
    hasAllDeliveredSimulations() {
      const orders = this.hgCase.orders
        .filter((order) => order.service === caseConstants.SERVICE.SIMULATION);
      // return all orders that are Delivered
      return orders.every((o) => o.status === caseConstants.ORDER_STATUSES.DELIVERED);
    },
    hgCase() {
      return this.$store.getters.hgcaseById(this.$route.params.id);
    },
    selectedConfig() {
      return this.$store.getters.configById(this.config);
    },
    simulationVisualisations() {
      return this.visualisations
        .filter((v) => v.analysisType === constants.ANALYSIS_TYPES.SIMULATION);
    },
    visualisations() {
      return this.selectedConfig?.visualisations
        .map((visualisation) => ({
          ...visualisation,
          // Show the missing objects without a custom object
          missingObjects: this.$store.getters
            .studyMissingSceneObjectsByType(
              this.hgCase.study, this.selectedConfig, visualisation.analysisType,
            )
            .filter((name) => !visualisation.customObjects
              .filter((obj) => obj.studyId === this.hgCase.study.id)
              .map((v) => v.name)
              .includes(name)),
          // show the customObjects for this study
          customObjects: visualisation.customObjects?.filter(
            (obj) => obj.studyId === this.hgCase.study.id,
          ),
        })) || [];
    },
  },
  created() {
    this.$store.dispatch('listConfigs', { intervention_id: this.hgCase.study.interventionId });
    this.debounceGetVisuals = debounce(this.getVisualisations, 300);
    this.debounceGetObjects = debounce(this.getVisualisationObjects, 300);
  },
  methods: {
    emitInput(newVal) {
      this.$emit('input', newVal);
    },
    async getVisualisations() {
      if (this.config) {
        await this.$store.dispatch('listVisualisations', this.config);
      }
    },
    async getVisualisationObjects() {
      await this.$store.dispatch('listCustomVisualisationObjects',
        { study_id: this.hgCase.study.id });
    },
    refreshList() {
      this.debounceGetVisuals();
      this.debounceGetObjects();
    },
  },
  watch: {
    config(newVal) {
      if (newVal) this.refreshList();
    },
  },
};
</script>
