import multiguard from 'vue-router-multiguard';

import DocumentationPage from '@/components/pages/DocumentationPage';
import CasesPage from '@/components/pages/CasesPage';
import CaseDetailPage from '@/components/pages/CaseDetailPage';
import LicensesPage from '@/components/pages/LicensesPage';
import PendingRegistrationPage from '@/components/pages/PendingRegistrationPage';
import SettingsPage from '@/components/pages/SettingsPage';
import DeviceAccessGroupPage from '@/components/pages/DeviceAccessGroupPage';
import AdminManagementPage from '@/components/pages/AdminManagementPage/index.vue';
import CaseCanceledPage from '@/components/pages/CaseCanceledPage.vue';
import ResultsPendingPage from '@/components/pages/ResultsPendingPage';

import {
  requiresCaseDetailPermission,
  requiresAddTemplatesPermission,
  requiresManagementPermission,
  requiresPrimaryOrganisation,
  requiresTerms,
  requiresStudyAndCase,
  requiresCaseNotCanceled,
} from './guards';

const hexDec = '[0-9a-fA-F]';
const uuidRegex = `${hexDec}{8}-${hexDec}{4}-${hexDec}{4}-${hexDec}{4}-${hexDec}{12}`;

export default [
  {
    path: 'cases',
    name: 'cases',
    component: CasesPage,
    meta: {
      title: 'FEops HEARTguide - Cases',
    },
    beforeEnter: multiguard([requiresPrimaryOrganisation, requiresTerms]),
  },
  {
    path: 'licenses',
    name: 'licenses',
    component: LicensesPage,
    beforeEnter: multiguard([requiresPrimaryOrganisation, requiresTerms]),
    meta: {
      title: 'FEops HEARTguide - Your Licenses',
    },
  },
  {
    path: 'templates',
    name: 'templates',
    component: DeviceAccessGroupPage,
    beforeEnter: multiguard([
      requiresAddTemplatesPermission, requiresPrimaryOrganisation, requiresTerms]),
    meta: {
      title: 'FEops HEARTguide - Templates',
    },
  },
  {
    path: `study-viewer/:id(${uuidRegex})`,
    name: 'study',
    component: () => import(/* webpackChunkName: "studyPage" */ '@/components/pages/StudyPage.vue'),
    children: [
      {
        path: '3dsurfaceviewer',
        name: '3dsurfaceviewer',
        component: () => import(/* webpackChunkName: "surfaceViewerPage" */ '@/components/pages/SurfaceViewerPage.vue'),
        props: true,
      },
      {
        path: 'mprviewer',
        name: 'mprviewer',
        component: () => import(/* webpackChunkName: "mprViewerPage" */ '@/components/pages/MPRViewerPage.vue'),
        props: true,
      },
      {
        path: 'detail',
        name: 'studydetail',
        component: () => import(/* webpackChunkName: "studyViewerPage" */ '@/components/pages/StudyViewerPage.vue'),
        props: true,
      },
    ],
    beforeEnter: multiguard([
      requiresPrimaryOrganisation, requiresTerms, requiresCaseNotCanceled, requiresStudyAndCase]),
    props: true,
    meta: {
      title: 'FEops HEARTguide',
    },
  },
  {
    path: `iframe-viewer/:id(${uuidRegex})`,
    name: 'iframe-viewer',
    component: () => import(/* webpackChunkName: "iframeViewerPage" */ '@/components/pages/IframeViewerPage'),
    beforeEnter: multiguard([
      requiresPrimaryOrganisation, requiresTerms, requiresCaseNotCanceled, requiresStudyAndCase]),
    props: true,
    meta: {
      title: 'FEops HEARTguide',
    },
  },
  {
    path: `case-detail/:id(${uuidRegex})`,
    name: 'case-detail',
    component: CaseDetailPage,
    children: [],
    beforeEnter: multiguard([
      requiresCaseDetailPermission,
      requiresPrimaryOrganisation,
      requiresTerms,
    ]),
    props: true,
    meta: {
      title: 'FEops HEARTguide - Case Detail',
    },
  },
  {
    path: 'settings',
    name: 'settings',
    component: SettingsPage,
    beforeEnter: requiresPrimaryOrganisation,
    meta: {
      title: 'FEops HEARTguide - Settings',
    },
  },
  {
    path: 'training/:interventionPrefix',
    name: 'training',
    component: () => import(/* webpackChunkName: "trainingPage" */ '@/components/pages/TrainingPage.vue'),
    beforeEnter: multiguard([requiresPrimaryOrganisation, requiresTerms]),
    props: true,
    meta: {
      title: 'FEops HEARTguide - Training',
    },
  },
  {
    path: 'terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "termsPage" */ '@/components/pages/TermsAndConditionsPage.vue'),
    beforeEnter: requiresPrimaryOrganisation,
    meta: {
      title: 'FEops HEARTguide - Terms and Conditions',
    },
  },
  {
    path: 'docs',
    name: 'documentation',
    component: DocumentationPage,
    beforeEnter: multiguard([requiresPrimaryOrganisation, requiresTerms]),
    meta: {
      title: 'FEops HEARTguide - Documentation',
    },
  },
  {
    component: PendingRegistrationPage,
    name: 'pendingregistration',
    path: '/pendingregistration',
  },
  {
    name: 'users-and-organisations',
    component: AdminManagementPage,
    path: '/users-and-organisations',
    beforeEnter: requiresManagementPermission,
    meta: {
      title: 'FEops HEARTguide - Admin Management',
    },
  },
  {
    path: '/results-pending',
    name: 'resultsPending',
    component: ResultsPendingPage,
    meta: {
      title: 'FEops HEARTguide - Results Pending',
    },
  },
  {
    path: '/case-canceled',
    name: 'caseCanceled',
    component: CaseCanceledPage,
    meta: {
      title: 'FEops HEARTguide - Case Canceled',
    },
  },
];
