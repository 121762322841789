<template>
  <div>
    <h5 class="my-4">License template subscriptions:</h5>
    <div
      v-for="deviceAccessGroup in organisation.deviceAccessGroups"
      :key="deviceAccessGroup.name"
    >

      <v-switch
        @change="changeSelected(deviceAccessGroup)"
        hide-details
        :readonly="!$store.getters.user.canSubscibeToTemplate"
        v-model='deviceAccessGroup.isSubscribed'
      >
        <template v-slot:label>
          <v-tooltip color="secondary" bottom open-delay="200">
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >{{ deviceAccessGroup.name }}</span>
            </template>
            <h6 class="text-h6">Devices:</h6>
            <div v-for="device in deviceAccessGroup.devices" :key="device.id">
              {{ device.name }}
            </div>
          </v-tooltip>
        </template>
      </v-switch>

    </div>
  </div>
</template>

<script>

export default {
  name: 'SubscriptionCard',
  components: {
  },
  props: [
    'organisation',
  ],
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    changeSelected(deviceAccessGroup) {
      if (!deviceAccessGroup.isSubscribed) {
        this.$store.dispatch('subscribeToDeviceAccessGroup', { deviceAccessGroupId: deviceAccessGroup.id, organisationId: this.organisation.id })
          .then(() => this.$store.dispatch('updateOrganisationLicense', { id: this.organisation.id }));
      } else {
        this.$store.dispatch('unsubscribeFromDeviceAccessGroup', { deviceAccessGroupId: deviceAccessGroup.id, organisationId: this.organisation.id })
          .then(() => this.$store.dispatch('updateOrganisationLicense', { id: this.organisation.id }));
      }
    },
  },
};
</script>
