<template>
<div>
  <v-btn
    outlined
    @click="dialog=true"
    text
  >
    Upload Legacy Simulation
  </v-btn>
    <UploadLegacySimulationDialog
      v-if="dialog"
      :study="study"
      v-model="dialog"
      :caseIdentifier='study.caseIdentifier'
    />
  </div>
</template>

<script>
import UploadLegacySimulationDialog from './UploadLegacySimulationDialog.vue';

export default {
  name: 'UploadLegacySimulationButton',
  props: [
    'study',
  ],
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    UploadLegacySimulationDialog,
  },
};
</script>
