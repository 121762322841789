<template>
  <div class="my-2">
    <v-form v-if="!upload.inProgress">
      <v-alert type="error" text v-if="uploadFailed">Upload failed! Please try again</v-alert>

      <div v-if="!upload.file">
        <div
          class="c-dropzone primary50 pa-4 primary--text"
          style="border-color: var(--v-primary-base) !important;">
          <input type="file" @change="onFileInputAdd" ref="fileInput" style="display: none"/>
          <div
            class="my-auto"
            v-if="!upload.filename"
            @dragover.prevent
            @drop="onDrop"
            @click="$refs.fileInput.click()"
          >
            Upload your DICOM images!<br />
            <v-icon x-large color="primary">cloud_upload</v-icon>
          </div>
        </div>
        <slot name="extrainstructions">
          <div>
            <v-btn
              class="ma-2"
              @click="uploadImagesDialog=true"
              text
              small
              color="primary">
              Show detailed instructions
            </v-btn>
          </div>
          <DetailedUploadInstructionsDialog
            :hgcase="hgcase"
            :value="uploadImagesDialog" @close="uploadImagesDialog=false"/>
        </slot>
      </div>
      <div v-else>
        <div
          class="c-dropzone primary50 pa-4 primary--text"
          style="border-color: var(--v-primary-base) !important;"
        >

          <div class="d-flex align-center justify-center" style="gap: 0.5em;">

          <span class="text-h6">{{ upload.filename }}</span>
          </div>
        </div>
        <div class="text-center">
          <div class="font-italic">Please verify the file name before starting the upload.</div>
          <div v-for="warning in warnings" :key="warning" >
            <v-icon color="warning">
              warning
            </v-icon>
            {{ warning }}
            <v-icon color="warning">
              warning
            </v-icon>
          </div>
          <v-btn class="ma-1"
            text small outlined
            @click="cancelUpload"
          >
            Cancel
          </v-btn>
          <v-btn
            class="ma-1"
            small depressed color="primary"
            @click="startUpload"
          >
            Upload
          </v-btn>
        </div>
      </div>
    </v-form>

    <div class="text-center" v-else-if="!isUploadComplete">
      <div class="upload-progress" v-if="upload.progress < 100">
        <h4 class="text-h5 text--secondary">Upload in progress</h4>
        <p class="text--disabled mt-2">
          Uploading may take several minutes depending on your connection and the size of the file.
        </p>

        <v-progress-circular
          :text-inside="true"
          :stroke-width="38"
          :value="upload.progress"
          color="#e6a23c"
        />

        <div class="mt-4">
          <v-btn outlined small @click="cancelUpload">
            Cancel
          </v-btn>
        </div>
      </div>

      <div class="processing-loader" v-else>
        <h4 class="text-h5 text--secondary">Processing upload</h4>
        <p class="text--disabled mt-2">
          This might take 5-10 minutes. Please don't refresh this page, close this tab or close the
          window.
        </p>

        <div class="indeterminate">
          <v-progress-circular :stroke-width="38" color="#e6a23c"
            :value="processingLoader.progress" class="mr-3" />
          Processing, please wait...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DetailedUploadInstructionsDialog from '@/components/Cases/DetailedUploadInstructionsDialog.vue';

export default {
  name: 'UploadImagesInputBox',
  props: [
    'hgcase',
  ],
  components: {
    DetailedUploadInstructionsDialog,
  },
  data: () => ({
    upload: {
      inProgress: false,
      file: null,
      progress: 0,
      resourceId: null,
    },
    isUploadComplete: false,
    uploadImagesDialog: false,
    processingLoader: {
      timer: null,
      progress: 0,
    },
    uploadFailed: false,
  }),
  created() {
    // Can we find back an ongoing upload?
    if (this.hgcase.inputDataIds) {
      this.hgcase.inputDataIds.forEach((id) => {
        this.upload = this.$store.getters.uploadByResourceId(id) || this.upload;
      });
    }
  },
  computed: {
    warnings() {
      if (this.upload.hasFile) {
        const warnings = [];
        if (!this.upload.filename.endsWith('.zip')) {
          warnings.push("This doesn't look like a zip file.");
        }
        if (this.upload.file.size < 1000000) {
          warnings.push(`This file seems too small. (${Math.round(this.upload.file.size / 1000)} kB)`);
        }
        if (warnings.length) {
          return warnings;
        }
      }
      return undefined;
    },
  },
  methods: {
    addFile(file) {
      this.$store.dispatch('addFile', { file })
        .then((upload) => {
          this.upload = upload;
        });
    },
    beforeOpen(event) {
      // eslint-disable-next-line
      this.hgcase = event.params.hgcase;
    },
    beforeClose(event) {
      if (!this.upload.inProgress) {
        this.cancelUpload();
        this.$store.dispatch('resetUpload', this.upload);
      } else {
        event.stop();
        this.$toasted.global.info({ message: 'Please cancel your upload before closing the dialog' });
      }
    },
    cancelUpload() {
      this.$store.dispatch('cancelUpload', this.upload);
    },
    confirmUpload(id) {
      this.$store.dispatch('inputDataConfirmUpload', { id })
        .then((response) => {
          if (response.uploadSuccessful) {
            this.isUploadComplete = true;
            this.$store.dispatch('retrieveCase', this.hgcase.id);
          } else {
            this.uploadFailed = true;
            this.resetVars();
          }
        })
        .catch(() => {
          this.uploadFailed = true;
          this.resetVars();
        });
    },
    onDrop(e) {
      /* Triggered by dropping on the dropzone */
      e.preventDefault();
      this.addFile(e.dataTransfer.files[0]);
    },
    onFileInputAdd(e) {
      /* Triggered by clicking the dropzone, which triggers clicking the hidden
      file input */
      e.preventDefault();
      this.addFile(e.target.files[0]);
    },
    resetVars() {
      this.upload = { inProgress: false, file: null, progress: 0 };
      this.isUploadComplete = false;
      clearInterval(this.processingLoader.timer);
      this.processingLoader = { timer: null, progress: 0 };
    },
    startProcessingTimer() {
      // 5mins (300.000 ms) to reach 100, 1% every 3000ms
      this.processingLoader.timer = setInterval(() => {
        if (this.processingLoader.progress > 100) {
          clearInterval(this.processingLoader.timer);
        } else {
          this.processingLoader.progress = this.processingLoader.progress < 100
            ? this.processingLoader.progress + 1
            : 0;
        }
      }, 3000);
    },
    startUpload() {
      this.isUploadComplete = false;
      this.$store.dispatch('createInputData', { caseId: this.hgcase.id, file: this.upload.file })
        .then((inputData) => {
          this.$store.dispatch('startUpload', { upload: this.upload, writeUrl: inputData.writeUrl, resource: inputData })
            .then(() => {
              this.$emit('close');
              this.confirmUpload(inputData.id);
            })
            .catch((error) => {
              this.isUploadComplete = false;

              // eslint-disable-next-line
              if (error && error.__CANCEL__) {
                this.$toasted.global.error({ message: error.message });
                this.resetVars();
                this.$store.dispatch('setUploadInProgress', false);
              } else {
                this.$toasted.global.error({ error });
                this.confirmUpload(inputData.id);
              }
            });
        });
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'upload.progress': function (newVal) {
      if (newVal < 100) return;
      // start only one timer at a time
      if (!this.processingLoader.timer) this.startProcessingTimer();
    },
    isUploadComplete(newVal) {
      if (!newVal) return;
      this.processingLoader.progress = 101;
    },
  },
  beforeDestroy() {
    clearInterval(this.processingLoader.timer);
  },
};
</script>

<style>
.c-dropzone {
  border: 2px dashed;
  cursor: pointer;
  text-align: center;
}
</style>
