<template>
<!--
  Because this is a wrapper component around v-dialog
  we need to pass the signals to the parent components with v-on="$listeners"
-->
<v-dialog
  :value="value"
  @click:outside="hide"
  v-on="$listeners"
  max-width="960"
>
  <v-card>
    <v-card-title class="text-h5 justify-center">
      Upload DICOM images
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pt-4">
      <v-container>
        <v-row>
          <v-col class ="text-center my-auto d-flex flex-column">
            <UploadImagesInputBox :hgcase="hgcase">
              <template #extrainstructions>
                <p>Only a zip file containing all dicom images can be uploaded.</p>
              </template>
            </UploadImagesInputBox>

          </v-col>
          <v-col>
            <!-- Always show instructions in the dialog -->
            <div>
              <v-tabs v-model="activeTab" class="mb-2">
                <v-tab>
                  Windows
                </v-tab>
                <v-tab>
                  Mac
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="activeTab">
                <!-- Windows -->
                <v-tab-item >
                  <ol>
                    <li>In windows explorer, locate the folder containing all images.</li>
                    <!-- eslint-disable-next-line max-len -->
                    <li>Either select all images (ctrl+a) or select the folder containing the images.</li>
                    <li>Right-click the selection</li>
                    <li>Select 'Send to', and then 'Compressed (zipped) folder'.</li>
                  </ol>
                </v-tab-item>
                <!-- Mac -->
                <v-tab-item>
                  <ol>
                    <li>In Finder, locate the folder containing all images</li>
                    <!-- eslint-disable-next-line max-len -->
                    <li>Either select all images (cmd+a) or select the folder containing the images.</li>
                    <li>Right-click the selection</li>
                    <li>Select 'Compress "sample filename"'</li>
                  </ol>
                </v-tab-item>
              </v-tabs-items>
              <p class="mt-2">
                Ensure the images are located on a device with sufficient space,
                because a new zip file will be created in the same location as
                your dicom images.
              </p>
              <p>
                Click the orange zone at the left and select select the newly created zip file,
                or simply drag and drop the file onto it.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn outlined @click="hide">
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>

export default {
  name: 'DetailedUploadInstructionsDialog',
  props: [
    'hgcase',
    'value',
  ],
  components: {
    UploadImagesInputBox: () => import('./UploadImagesInputBox.vue'),
  },
  data: () => ({
    dialog: false,
    activeName: navigator.userAgent.includes('Macintosh') ? 'mac' : 'windows',
    activeTab: navigator.userAgent.includes('Macintosh') ? 1 : 0,
  }),
  methods: {
    hide() {
      this.$emit('close');
    },

  },
};
</script>
