<template>
  <div>
    <v-divider class="mx-n6 max-w" />

    <v-form class="mt-5">
      <v-row>
        <v-col cols="12" md="6" class="align-self-end">
          <v-text-field v-model="form.renderOrder" label="Order" persistent-hint
            hint="Order the objects should be rendered in." type="number" />
        </v-col>
        <v-col cols="12" md="6">
          <v-menu max-width="300" :close-on-content-click="false">
            <template v-slot:activator="{on, attrs}">
              <v-text-field v-model="form.color.hex" persistent-hint hint="Render color"
                readonly v-on="on" class="color-field" v-bind="attrs">
                <template v-slot:prepend-inner>
                  <div class="color-preview"
                    :class="{ 'border-only': form.color.hex === defaultColor }"
                    :style="`background:${form.color.hex}`"></div>
                </template>
                <template v-slot:append>
                  <v-btn small icon @click="form.color.hex = defaultColor"
                    v-if="form.color.hex !== defaultColor && !vObject">
                    <v-icon>close</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </template>

            <v-color-picker mode="hexa" hide-mode-switch v-model="form.color"></v-color-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6">
          <div class="range-field">
            <div class="d-flex align-center">
              <v-text-field v-model="form.opacity" hide-details
                class="w-56px flex-grow-0 pt-0 mt-0" solo flat />
              <v-slider v-model="form.opacity" min="0" max="100" class="grow" hide-details />
            </div>
            <div class="ripple"></div>
            <div class="v-messages mt-2 text--secondary">
              <div class="v-messages__message">Starting opacity</div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="form.maxOpacity" hint="Max opacity" type="number"
            persistent-hint min="0" max="100" />
        </v-col>
        <v-col cols="12" md="6">
          <v-checkbox v-model="form.hasFixedOpacity" hide-details>
            <template v-slot:label>
              <div class="leading-20px text--primary">Fixed Opacity
                <p class="text--secondary mb-0">
                  Hides opacity bar in the viewer.
                </p>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox v-model="form.hasDepthTestEnabled" hide-details>
            <template v-slot:label>
              <div class="leading-20px text--primary">Enable Depth Testing
                <p class="text--secondary mb-0">
                  When depth testing is enabled, OpenGL tests the depth value of a
                  fragment against the content of the depth buffer.
                </p>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox v-model="form.hasFlatShadingEnabled" hide-details>
            <template v-slot:label>
              <div class="leading-20px text--primary">Enable Flat Shading
                <p class="text--secondary mb-0">
                  Use the normals of a face to quickly calculate the shadows.
                </p>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox v-model="form.isAffectedByLighting" hide-details>
            <template v-slot:label>
              <div class="leading-20px text--primary">Affected by Lighting
                <p class="text--secondary mb-0">
                  Is this object affected by lighting.
                </p>
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="6">
          <v-checkbox v-model="form.isDoubleSided" hide-details>
            <template v-slot:label>
              <div class="leading-20px text--primary">Double Sided
                <p class="text--secondary mb-0">
                  Double sided objects can render objects inside them while still
                  being transparent with limited artifacts.
                </p>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox v-model="form.isEditable" hide-details>
            <template v-slot:label>
              <div class="leading-20px text--primary">Editable
                <p class="text--secondary mb-0">
                  User can change the appearance.
                </p>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox v-model="form.isVisible" hide-details>
            <template v-slot:label>
              <div class="leading-20px text--primary">Visible
                <p class="text--secondary mb-0">
                  Is the object visible when the scene is opened.
                </p>
              </div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-form>

    <div class="buttons mt-3 mb-n4 mx-n6 pa-3 d-flex">
      <v-btn text color="error" large class="font-weight-bold" v-if="vObject"
        @click="deleteObject(vObject.id)">Delete</v-btn>

      <v-spacer />
      <v-btn text color="primary" large class="font-weight-bold" v-if="!vObject"
        @click="createObject" :disabled="disableSaveButton">Save Object</v-btn>
      <v-btn v-else text color="primary" large class="font-weight-bold"
        @click="updateObject" :disabled="disableSaveButton">Save Object</v-btn>
    </div>

    <ConfirmationDialog ref="confirm" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';

export default {
  props: {
    visualisationId: String,
    name: String,
    studyId: String,
    vObject: Object,
  },
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      form: {
        renderOrder: 1,
        color: {
          hex: '#E96922',
        },
        opacity: 100,
        maxOpacity: 100,
        hasFixedOpacity: false,
        hasDepthTestEnabled: true,
        hasFlatShadingEnabled: false,
        isAffectedByLighting: true,
        isDoubleSided: true,
        isEditable: true,
        isVisible: true,
      },
      disableSaveButton: false,
      defaultColor: 'Use Object Colouring',
    };
  },
  computed: {
    compiledForm() {
      return {
        ...this.form,
        opacity: this.form.opacity / 100,
        maxOpacity: this.form.maxOpacity / 100,
        visualisationId: this.visualisationId,
        name: this.name,
        studyId: this.studyId,
        color: this.form.color.hex !== this.defaultColor ? this.form.color.hex : '',
      };
    },
  },
  methods: {
    ...mapActions(['deleteVisualisationObject', 'createVisualisationObject']),
    async getVisualisations() {
      await this.$store.dispatch('listVisualisations', this.config);
    },
    async getVisualisationObjects() {
      await this.$store.dispatch('listCustomVisualisationObjects',
        { study_id: this.studyId });
    },
    refreshCustomObjects() {
      this.getVisualisations();
      this.getVisualisationObjects();
    },

    async createObject() {
      this.disableSaveButton = true;
      await this.createVisualisationObject(this.compiledForm);
      this.refreshCustomObjects();
      this.disableSaveButton = false;
    },
    deleteObject(id) {
      this.$refs.confirm.open('Confirm',
        'Are you sure you want to delete this visualisation object?')
        .then(async (confirmation) => {
          if (!confirmation) return;
          await this.deleteVisualisationObject({ id });
          this.refreshCustomObjects();
        });
    },
    updateObject(id) {
      this.$store.dispatch('updateVisualisationObject', { id, ...this.compiledForm });
      this.refreshCustomObjects();
    },
    fillForm() {
      this.form = {
        ...this.form,
        ...this.vObject,
        opacity: this.vObject.opacity * 100,
        maxOpacity: this.vObject.maxOpacity * 100,
        color: {
          hex: this.vObject.color || this.defaultColor,
        },
      };
    },
  },
  created() {
    if (this.vObject) this.fillForm();
  },
  watch: {
    vObject() {
      this.fillForm();
    },
  },
};
</script>

<style lang="scss" scoped>
.max-w {
  max-width: calc(100% + (24px * 2));
}

.color-field ::v-deep .v-input__prepend-inner {
  align-self: center;
  margin-top: 0;
  padding-right: 12px;
}

.color-preview {
  width: 16px;
  height: 16px;
  border-radius: 2px;

  &.border-only {
    background-color: transparent !important;
    border: 1px solid rgba(#000, 0.38);
  }
}

.w-56px {
  width: 56px;
}

.range-field:focus-within,
.range-field:focus,
.range-field:active {
  .ripple::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: currentColor;
    width: 0%;
    height: 2px;
    transform-origin: center;
    animation: mockRipple 300ms ease forwards;
  }
}

.ripple {
  background-color: rgba(#000, 0.42);
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
}

@keyframes mockRipple {
  0% {
    background-color: currentColor;
    width: 0;
  }

  100% {
    background-color: #e96922;
    width: 100%;
  }
}

.leading-20px {
  line-height: 20px;
}

::v-deep .v-input--checkbox .v-input__slot {
  align-items: flex-start;

  .v-input--selection-controls__input {
    margin-right: 12px;
  }
}
</style>
