// SplineTubeWidget/index.js
// ...
import macro from '@kitware/vtk.js/macros';
import vtkSplineWidget from '@kitware/vtk.js/Widgets/Widgets3D/SplineWidget';

import widgetBehavior from './behavior';

function vtkSplineTubeWidget(publicAPI, model) {
  model.classHierarchy.push('vtkNewSplineWidget');
  /* eslint-disable */
  model.behavior = widgetBehavior;
}

const DEFAULT_VALUES = {};

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  vtkSplineWidget.extend(publicAPI, model, initialValues);

  vtkSplineTubeWidget(publicAPI, model);
}

export const newInstance = macro.newInstance(extend, 'vtkNewSplineWidget');
// ----------------------------------------------------------------------------

export default { newInstance, extend };
