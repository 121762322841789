export default [
  {
    path: 'login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/components/pages/LoginPage.vue'),
    meta: {
      title: 'FEops HEARTguide - Login',
    },
  },
  {
    path: 'register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/components/pages/RegisterPage.vue'),
    meta: {
      title: 'FEops HEARTguide - Register',
    },
  },
  {
    path: 'emailverification/:verificationKey',
    name: 'emailverification',
    component: () => import(/* webpackChunkName: "emailverification" */ '@/components/pages/EmailVerificationPage.vue'),
    props: true,
    meta: {
      title: 'FEops HEARTguide - Email verification',
    },
  },
  {
    path: 'forgotpassword',
    name: 'forgotpassword',
    component: () => import(/* webpackChunkName: "forgotpassword" */ '@/components/pages/ForgotPasswordPage.vue'),
    meta: {
      title: 'FEops HEARTguide - Forgot Password',
    },
  },
  {
    path: 'passwordreset/:uid/:token',
    name: 'passswordreset',
    component: () => import(/* webpackChunkName: "passwordreset" */ '@/components/pages/PasswordResetPage.vue'),
    props: true,
    meta: {
      title: 'FEops HEARTguide - Reset Password',
    },
  },
  {
    path: 'cookiepolicy',
    name: 'cookiepolicy',
    component: () => import(/* webpackChunkName: "cookiepolicy" */ '@/components/pages/CookiePolicyPage.vue'),
    meta: {
      title: 'FEops HEARTguide - Cookie Policy',
    },
  },
  {
    path: 'contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '@/components/pages/ContactPage'),
    meta: {
      title: 'FEops HEARTguide - Contact Us',
    },
  },
];
