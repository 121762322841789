<template>
  <v-dialog
    v-model="dialog"
    @keydown.esc="cancel"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @click:outside="cancel"
    data-cy="confirm-dialog"
  >
    <v-card class="confirm-dialog">
      <v-card-title class="text-h5 justify-center">
        <slot ref="title">
          {{ title }}
        </slot>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-6">
        {{ message }}
      </v-card-text>
      <v-card-actions class="px-6 pa-4">
        <v-spacer></v-spacer>
        <v-btn class="confirm-no-btn"
          v-if="!options.noconfirm"
          color="grey"
          outlined
          @click.native="cancel"
        >
          {{ options.noLabel }}
        </v-btn>
        <v-btn
          data-cy="confirm-yes-btn"
          color="primary"
          depressed
          @click.native="agree"
        >
          {{ options.yesLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationDialog',

  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: 'grey lighten-3',
        width: 400,
        zIndex: 200,
        noconfirm: false,
        noLabel: 'Close',
        yesLabel: 'OK',
      },
    };
  },

  methods: {
    open(title, message, options) {
      this.title = title;
      this.message = message;
      this.dialog = true;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};

</script>
