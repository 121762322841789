<template>
  <div class="pt-6">
    <v-card>
      <div class="d-flex align-center gap-8px pa-2">
        <v-text-field v-model.trim="code" outlined dense hide-details clearable
          placeholder="Search by code" @input="resetPageAndSearch" />
        <v-text-field v-model.trim="name" outlined dense hide-details clearable
          placeholder="Search by name" @input="resetPageAndSearch" />
        <v-btn icon @click="resetAndSearch" :disabled="isLoading" color="primary">
          <v-icon :class="{ rotate: isLoading }">sync</v-icon>
        </v-btn>
      </div>

      <v-data-table
        :headers="headers"
        disable-sort
        :items="organisations"
        :items-per-page="pageSize"
        :loading="isLoading"
        class="elevation-1 mb-1"
        no-data-text="No organisations found"
        :footer-props="{ 'disable-items-per-page': true }"
        :server-items-length="count"
        :page.sync="page"
        @update:page="paginate">
        <template v-slot:[`item.name`]="{ item }">
          <template v-if="item">
            <template v-if="selectedOrgId === item.id">
              <v-text-field v-model="form.name" outlined dense hide-details :rules="[isRequired]"
                placeholder="Name" />
            </template>
            <template v-else>{{ item.name }}</template>
          </template>
        </template>

        <template v-slot:[`item.countryName`]="{ item }">
          <template v-if="item">
            <template v-if="selectedOrgId === item.id">
              <v-autocomplete :items="countries" item-value="code" item-text="name" outlined dense
                v-model="form.country" placeholder="Country" :rules="[isRequired]" hide-details />
            </template>
            <template v-else>{{ item.countryName }}</template>
          </template>
        </template>

        <template v-slot:[`item.regionId`]="{ item }">
          {{ item ? getRegionName(item.regionId) : '-' }}
        </template>

        <template v-slot:[`item.edit`]="{ item }">
          <span>
          <v-tooltip bottom open-delay="350" v-if="item && selectedOrgId !== item.id">
            <template v-slot:activator="{on, attrs}">
              <v-btn icon color="primary" v-on="on" v-bind="attrs" @click="selectOrg(item)">
                <v-icon small>edit</v-icon>
              </v-btn>
            </template> Edit
          </v-tooltip>
          <span v-else>
            <v-tooltip bottom open-delay="350" >
              <template v-slot:activator="{on, attrs}">
              <v-btn icon color="primary"  v-on="on" v-bind="attrs" @click="saveEdits"
                :disabled="!formIsValid">
                <v-icon small>save</v-icon>
              </v-btn>
              </template> Save
            </v-tooltip>
            <v-tooltip bottom open-delay="350">
              <template v-slot:activator="{on, attrs}">
                <v-btn icon color="primary"  v-on="on" v-bind="attrs" @click="selectedOrgId = null">
                  <v-icon small>close</v-icon>
                </v-btn>
              </template> Cancel
            </v-tooltip>
          </span>

          <v-tooltip bottom open-delay="350" v-if="item">
            <template v-slot:activator="{on, attrs}">
              <v-btn icon color="red" v-on="on" v-bind="attrs" @click="deleteOrganisation(item.id)">
                <v-icon small>delete</v-icon>
              </v-btn>
            </template> Delete
          </v-tooltip>
        </span>
        </template>
      </v-data-table>
    </v-card>

    <ConfirmationDialog ref="confirm" />
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters, mapState } from 'vuex';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';
import countries from '@/utils/countries';

export default {
  name: 'OrganisationTab',
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      debounceFilter: null,
      headers: [
        { text: 'Code', value: 'code' },
        { text: 'Name', value: 'name' },
        { text: 'Country', value: 'countryName' },
        { text: 'Region', value: 'regionId' },
        { text: '', value: 'edit' },
      ],
      selectedOrgId: null,
      name: '', // filter
      code: '', // filter
      page: 1,
      pageSize: 15,
      form: {
        name: null,
        country: null,
      },
      countries,
    };
  },
  computed: {
    ...mapGetters({
      organisations: 'filteredOrganisations',
      isLoading: 'organisationsIsLoading',
    }),
    ...mapState({
      count: (state) => state.organisations.orgsManagement.count,
      regions: (state) => state.organisations.regions,
    }),
    formIsValid() {
      return this.form.name && this.form.country;
    },
  },
  methods: {
    // rules
    isRequired: (v) => !!v || 'This field is required',

    deleteOrganisation(id) {
      this.$refs.confirm.open(
        'Confirm',
        'Are you sure you want to delete this organisation? This cannot be undone.',
        { yesLabel: 'Delete Organisation' },
      ).then((confirmation) => {
        if (!confirmation) return;
        this.$store.dispatch('deleteOrganisation', id)
          .then(() => {
            this.resetPageAndSearch();
            this.$toasted.global.success({ message: 'Sucessfully deleted organisation' });
          })
          .catch((error) => this.$toasted.global.error({ error }));
      });
    },
    getRegionName(regionId) {
      if (!regionId) return '-';
      return this.regions[regionId]?.name || '-';
    },
    loadOrganisations() {
      this.$store.dispatch('loadOrganisations', {
        page: this.page,
        page_size: this.pageSize,
        name__icontains: this.name || null,
        code__icontains: this.code || null,
      });
    },
    paginate(page) {
      this.page = page;
      this.loadOrganisations();
    },
    resetAndSearch() {
      this.name = '';
      this.resetPageAndSearch();
    },
    resetPageAndSearch() {
      this.page = 1;
      this.selectedOrgId = null;
      this.debounceFilter();
    },
    selectOrg(org) {
      const { name, country, id } = org;
      this.form = { name, country };
      this.selectedOrgId = id;
    },
    saveEdits() {
      this.$refs.confirm.open(
        'Confirm',
        'Are you sure you want to edit this organisation?',
        { yesLabel: 'Edit Organisation' },
      ).then((confirmation) => {
        if (!confirmation) return;
        this.$store.dispatch('patchOrganisation', {
          ...this.form,
          id: this.selectedOrgId,
        })
          .then(() => {
            this.selectedOrgId = null;
            this.resetPageAndSearch();
            this.$toasted.global.success({ message: 'Sucessfully edited organisation' });
          })
          .catch((error) => this.$toasted.global.error({ error }));
      });
    },
  },
  mounted() {
    this.debounceFilter = debounce(this.loadOrganisations, 500);
  },
};
</script>

<style lang="scss" scoped>
.gap-8px {
  gap: 8px;
}

.rotate {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(-360deg); }
}
</style>
