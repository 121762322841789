import { getCountryNames } from '@/utils';
import { devicesFromAccesses } from '@/store/modules/licenses/utils';

export default {

  organisationById: (state, getters) => (id) => {
    const organisation = state.organisations[id];
    if (organisation) {
      return {
        ...organisation,
        deviceAccesses: organisation.deviceAccesses || [],
        get countryName() {
          if (organisation.country) {
            return getCountryNames.of(organisation.country);
          }
          return '';
        },
        get nameAndCountryDisplay() {
          if (organisation.country) {
            return `${organisation.name} (${this.countryName})`;
          }
          return organisation.name;
        },
        get hasLicenseLoaded() {
          // the list view does not have this field, so the license is not yet loaded
          return organisation.deviceAccesses !== undefined;
        },

        get trialLicenses() {
          // Loop through all trials, and filter out it specific devices
          if (!this.trialAccessGroups) return [];
          return this.trialAccessGroups
            .map((trial) => ({
              ...trial,
              deviceAccesses: this.deviceAccesses.filter((dA) => dA.trialId === trial.id),
            }));
        },

        get devices() {
          return devicesFromAccesses(this.deviceAccesses || [])
            .map((d) => ({ ...d, ...getters.deviceById(d.deviceId) }));
        },

        get deviceAccessGroups() {
          if (!organisation.deviceAccessGroupIds) return [];
          return getters.deviceAccessGroups
            .map((deviceAccessGroup) => ({
              ...deviceAccessGroup,
              get isSubscribed() {
                return organisation.deviceAccessGroupIds.includes(deviceAccessGroup.id);
              },
              set isSubscribed(value) {
                // empty setter to hide warning on switch
              },
            }));
        },

        licenseFor(deviceId, service) {
          return this.devices
            .find((device) => device.id === deviceId && service in device.services);
        },
      };
    }
    return undefined;
  },
  organisations: (state, getters) => Object.values(state.organisations)
    .map((o) => getters.organisationById(o.id))
    .filter((o) => o)
    .sort((a, b) => b.name - a.name),
  organisationsIsLoading: (state) => state.isLoading,
  selectedOrganisation: (state, getters) => {
    if (state.selectedOrganisationId) {
      return getters.organisations.find((o) => o.id === state.selectedOrganisationId);
    }
    return undefined;
  },
  selectedOrganisationId: (state) => state.selectedOrganisationId,
  regions: (state) => Object.values(state.regions),
  membershipById: (state) => (id) => state.memberships[id],
  filteredOrganisations: (state, getters) => state.orgsManagement.ids
    .map((id) => getters.organisationById(id)),
};
