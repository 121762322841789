import constants from './constants';

export default () => ({
  analyses: {},
  analysesNextPage: undefined,

  analysisResources: {},
  analysisResourcesNextPage: undefined,

  studyResources: {},
  studyResourcesNextPage: undefined,

  landmarks: {},
  landmarkTypes: {},

  studies: {},
  studiesNextPage: undefined,

  isGeneratingReport: false,
  constants,

});
