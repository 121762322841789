import Vue from 'vue';
import * as types from '@/store/mutation-types';

import initialState from './state';

export default {
  [types.PAGES_SUCCESS]: (state, { pages, slug }) => {
    Vue.set(state.pages, slug, pages);
  },
  [types.RESET_STATE_PAGES_MODULE](state) {
    Object.assign(state, initialState());
  },
};
