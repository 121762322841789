import axios from 'axios';
import Resource from '@/services/resource';
import hgClient from './axiosClient';

const { CancelToken } = axios;
const s3Client = axios.create({});

export default new Resource(hgClient, 'input-data', {
  getCanceller() {
    return CancelToken.source();
  },
  sendToS3({
    signedUrl, file, onUploadProgress, cancelToken,
  }) {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress,
      cancelToken,
    };
    return s3Client.put(signedUrl, file, options);
  },
});
