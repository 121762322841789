/* eslint-disable import/no-named-as-default-member */

import vtkHttpDataSetReader from '@kitware/vtk.js/IO/Core/HttpDataSetReader';
import vtkPLYReader from '@kitware/vtk.js/IO/Geometry/PLYReader';
import vtkResliceCursorWidget from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget';
import vtkAngleWidget from '@kitware/vtk.js/Widgets/Widgets3D/AngleWidget';
import vtkLineWidget from '@kitware/vtk.js/Widgets/Widgets3D/LineWidget';
import vtkSplineWidget from '@kitware/vtk.js/Widgets/Widgets3D/SplineWidget';
import '@kitware/vtk.js/IO/Core/DataAccessHelper/HttpDataAccessHelper';
// TODO: Investigate using less profiles for tree-shaking
import '@kitware/vtk.js/Rendering/OpenGL/Profiles/All';

import {
  studyViewerAPI,
  landmarksAPI, landmarkTypesAPI,
  analysisResourcesAPI,
} from '@/services/heartguide';

import Renderer from '@/services/viewer/Renderer';

import * as types from '@/store/mutation-types';

import { convertArrayToObject } from '@/utils';

import vtkNewSplineWidget from '@/components/DICOM/vtkUtils/NewSplineWidget';

import constants from './constants';

import {
  getResliceRotation,
  matrixFromDirections,
  matrixFromResliceRotation,
  rotatePlanes,
  checkLandmarkVisibility,
  prepareLandmark,
} from './utils';

export default {
  initializeViewer: ({ commit, dispatch }, { id }) => new Promise((resolve, reject) => {
    // case uuid
    commit(types.VIEWER_REQUEST);
    window.landmarkStore = {};
    studyViewerAPI.get(id)
      .then((response) => {
        const {
          dicom, config, study, title, subtitle, analysisResults, warnings, isClinical,
        } = response.data;
        commit(types.VIEWER_SUCCESS, { studyId: id, config });
        commit('STUDIES_UPDATE', { [study.id]: study });
        commit('SET_TITLES', { title, subtitle });
        commit('SET_DICOM_RESULTS', analysisResults);
        commit('SET_DICOM_WARNINGS', warnings);
        commit('SET_DICOM_IS_CLINICAL', isClinical);

        if (dicom) {
          dispatch('initializeDicom', dicom);
        }
        resolve({
          dicom, config, study, title, subtitle, analysisResults, warnings, isClinical,
        });
      })
      .catch((error) => {
        commit(types.VIEWER_ERROR, { error });
        reject(error);
      });
  }),

  async initializeDicom({ commit, dispatch }, dicom) {
    const progressCallback = (progressEvent) => {
      const percent = Math.floor((100 * progressEvent.loaded) / progressEvent.total);
      commit('SET_DICOM_PROGRESS', percent);
    };
    const reader = vtkHttpDataSetReader.newInstance({ fetchGzip: true, progressCallback });

    const dicomConf = dicom.attributes;
    // Set URL to the readUrl
    if (!dicomConf.pointData.arrays[0].data.ref.url) {
      dicomConf.pointData.arrays[0].data.ref.url = dicom.readUrl;
    }

    if (dicomConf.interpolationType === undefined) {
      // Determine which interpolation to use by default
      const spacing = [...dicomConf.spacing];
      const anisotropyThreshold = 0.3;
      let maxSpacingDiff = 0;
      maxSpacingDiff = Math.max(Math.abs(spacing[0] - spacing[1]), maxSpacingDiff);
      maxSpacingDiff = Math.max(Math.abs(spacing[0] - spacing[2]), maxSpacingDiff);
      maxSpacingDiff = Math.max(Math.abs(spacing[1] - spacing[2]), maxSpacingDiff);
      const linear = maxSpacingDiff > anisotropyThreshold;

      // const linear = spacing[0] / spacing[1] < 0.5 || spacing[0] / spacing[2] < 0.5;
      commit('SET_INTERPOLATION_TYPE', linear ? 1 : 0);
    } else {
      commit('SET_INTERPOLATION_TYPE', dicomConf.interpolationType);
    }
    // commit('STUDIES_UPDATE', { [study.id]: study });
    // commit('SET_DICOM_RESULTS', analysisResults);
    // commit('SET_DICOM_WARNINGS', warnings);
    // commit('SET_DICOM_IS_CLINICAL', isClinical);
    // commit('SET_TITLES', { title, subtitle });

    // Default level/window
    let colorLevel = 230;
    let colorWindow = 1075;

    if (dicomConf.levels) {
      // If defined in the dicom config, use instead of the defaults:
      [colorLevel, colorWindow] = dicomConf.levels;
    }

    commit('SET_ORIGINAL_LEVELS', [colorLevel, colorWindow]);
    dispatch('resetLevels');

    reader.parseObject(dicomConf, {
      loadData: true,
    }).then(() => {
      const image = reader.getOutputData();
      const widget = vtkResliceCursorWidget.newInstance();
      widget.setImage(image);

      const colors = constants.VIEW_TYPE_COLORS;
      widget.getWidgetState().getAxisXinY().setColor3(colors[constants.SAGITTAL]);
      widget.getWidgetState().getAxisXinZ().setColor3(colors[constants.SAGITTAL]);
      widget.getWidgetState().getAxisYinX().setColor3(colors[constants.CORONAL]);
      widget.getWidgetState().getAxisYinZ().setColor3(colors[constants.CORONAL]);
      widget.getWidgetState().getAxisZinX().setColor3(colors[constants.AXIAL]);
      widget.getWidgetState().getAxisZinY().setColor3(colors[constants.AXIAL]);

      commit('SET_IMAGE', image);
      commit('SET_WIDGET', widget);
      dispatch('loadActors');
    });
  },

  async loadActors({ commit, getters }) {
    const { widget } = getters;
    // widget.getWidgetState().setKeepOrthogonality(true);
    // widget.getWidgetState().setOpacity(1);
    widget.getWidgetState().getStatesWithLabel('line').forEach(
      (line) => line.setScale3(1, 1, 1),
    );
    // widget.getWidgetState().setSphereRadius(6);
    widget.getWidgetState().getStatesWithLabel('sphere').forEach(
      (handle) => handle.setScale1(8),
    );

    // recolor handles
    const axialColors = constants.VIEW_TYPE_COLORS[constants.AXIAL];
    const coronalColors = constants.VIEW_TYPE_COLORS[constants.CORONAL];
    const sagittalColors = constants.VIEW_TYPE_COLORS[constants.SAGITTAL];
    widget.getWidgetState().getRotationHandleXinY0().setColor3(...sagittalColors);
    widget.getWidgetState().getRotationHandleXinY1().setColor3(...sagittalColors);
    widget.getWidgetState().getRotationHandleXinZ0().setColor3(...sagittalColors);
    widget.getWidgetState().getRotationHandleXinZ1().setColor3(...sagittalColors);
    widget.getWidgetState().getRotationHandleYinX0().setColor3(...coronalColors);
    widget.getWidgetState().getRotationHandleYinX1().setColor3(...coronalColors);
    widget.getWidgetState().getRotationHandleYinZ0().setColor3(...coronalColors);
    widget.getWidgetState().getRotationHandleYinZ1().setColor3(...coronalColors);
    widget.getWidgetState().getRotationHandleZinX0().setColor3(...axialColors);
    widget.getWidgetState().getRotationHandleZinX1().setColor3(...axialColors);
    widget.getWidgetState().getRotationHandleZinY0().setColor3(...axialColors);
    widget.getWidgetState().getRotationHandleZinY1().setColor3(...axialColors);

    // The default scrollingMethod is set to ScrollingMethods.LEFT_MOUSE_BUTTON
    // but this clashes with the panning behaviour we want, so I'm setting this to undefined.
    // The scroll wheel still scrolls through the dataset though.
    widget.getWidgetState().setScrollingMethod(undefined);

    const humanReader = vtkPLYReader.newInstance();
    await humanReader.setUrl('/models/human_compr3.ply').then(() => {
      commit('SET_HUMAN', humanReader);
    });

    const newAngleWidget = vtkAngleWidget.newInstance();
    newAngleWidget.getWidgetState().getMoveHandle().setScale1(15);
    commit('SET_NEW_ANGLE_WIDGET', newAngleWidget);

    const newAreaWidget = vtkSplineWidget.newInstance();
    newAreaWidget.setAllowFreehand(false);
    commit('SET_NEW_AREA_WIDGET', newAreaWidget);

    const newCurveWidget = vtkNewSplineWidget.newInstance();
    newCurveWidget.setAllowFreehand(false);
    commit('SET_NEW_CURVE_WIDGET', newCurveWidget);

    const newDistanceWidget = vtkLineWidget.newInstance();
    newDistanceWidget.getWidgetState().getMoveHandle().setScale1(10);
    newDistanceWidget.getWidgetState().getHandle1().setScale1(10);
    newDistanceWidget.getWidgetState().getHandle2().setScale1(10);
    commit('SET_NEW_DISTANCE_WIDGET', newDistanceWidget);

    widget.getWidgetState().onModified(() => {
      getters.landmarks.forEach((landmark) => {
        checkLandmarkVisibility(landmark, widget);
      });
    });
  },
  async maximizeViewport({ commit }, id) {
    commit('SET_VIEWPORT_MAXIMISE', id);
  },
  async retrieveContour({ commit }, analysisId) {
    await analysisResourcesAPI.get(
      { params: { analysis_id: analysisId } },
    ).then(({ data }) => {
      const contourModel = data.results.filter((ar) => ar.name === 'Atrium' || ar.name === 'Aorta');
      if (contourModel.length > 0) {
        const plyReader = vtkPLYReader.newInstance();
        plyReader.setUrl(contourModel[0].readUrl).then(() => {
          commit('SET_CONTOUR', plyReader);
        });
      }
    });
  },
  resetLevels({ state, commit }) {
    const [colorLevel, colorWindow] = state.color.original;

    commit('SET_COLOR_LEVEL', colorLevel);
    commit('SET_COLOR_WINDOW', colorWindow);
  },
  async resetReslice({ state, dispatch }) {
    const center = state.widget.getWidgetState().getImage().getCenter();
    state.widget.getWidgetState().setPlanes({
      4: { normal: [1, 0, 0], viewUp: [0, 0, 1] },
      5: { normal: [0, -1, 0], viewUp: [0, 0, 1] },
      6: { normal: [0, 0, -1], viewUp: [0, -1, 0] },
    });
    state.widget.setCenter(center);
    dispatch('triggerRefresh');
  },
  async setColorLevel({ commit }, level) {
    commit('SET_COLOR_LEVEL', level);
  },
  async setColorWindow({ commit }, window) {
    commit('SET_COLOR_WINDOW', window);
  },
  triggerRefresh({ commit }) {
    commit('UPDATE_INTERACTION_COUNTER');
  },
  async updateLandmarks({
    commit, dispatch, state, getters,
  }, analysisId) {
    if (getters.user.canSeeContour) {
      dispatch('retrieveContour', analysisId);
    }
    if (!analysisId) {
      return [];
    }
    return landmarksAPI.get({ params: { analysis_id: analysisId } })
      .then((response) => {
        const preparedLandmarks = response.data.results.map(
          (landmark) => {
            const landmarkType = state.landmarkTypes[landmark.landmarkTypeId];
            if (state.landmarkTypeConfigs) {
              const landmarkTypeConf = state.landmarkTypeConfigs[
                landmark.landmarkTypeId
              ];
              if (landmarkTypeConf) {
                landmarkType.color = landmarkTypeConf.color;
              }
            }
            return prepareLandmark(landmark, landmarkType, getters.roiCenter);
          },
        );
        const landmarks = convertArrayToObject(preparedLandmarks);
        commit('LANDMARKS_UPDATE', landmarks);
        if (window.renderer) {
          preparedLandmarks.filter((pl) => constants.GEOMETRY_TYPES_3D.includes(pl.geometryType))
            .forEach((landmark) => window.renderer.addLandmark(landmark));
          dispatch('setVisualisation', {
            visualisationId: getters.sceneList[0].visualisationIds[0],
          });
        }
        return landmarks;
      })
      .catch((error) => {
        throw error;
      });
  },
  async updateLandmarkTypes({ commit }) {
    return landmarkTypesAPI.get()
      .then((response) => {
        const landmarkTypes = convertArrayToObject(response.data);
        commit('LANDMARK_TYPES_UPDATE', landmarkTypes);
      })
      .catch((error) => {
        throw error;
      });
  },
  initNewLandmark({
    state, commit, dispatch, rootState,
  }, {
    name, description, analysisId, geometryType, viewType, landmarkTypeId, copyOf, id,
  }) {
    // Instantiate widget to draw a new landmark
    let widget = state.newAreaWidget;
    let points = [];
    if (
      geometryType === constants.CLOSED_CURVE
      || geometryType === constants.OPEN_CURVE
    ) {
      widget = state.newCurveWidget;
    } else if (
      geometryType === constants.ANGLE
    ) {
      widget = state.newAngleWidget;
    } else if (
      geometryType === constants.DISTANCE
    ) {
      widget = state.newDistanceWidget;
    }

    if (copyOf) {
      points = copyOf.points;
      dispatch('selectLandmark', copyOf.id);
      if (copyOf.geometryType === constants.DISTANCE) {
        const handle1 = widget.getWidgetState().getHandle1();
        const handle2 = widget.getWidgetState().getHandle2();
        // const moveHandle = widget.getWidgetState().getMoveHandle();
        // Set the origin, scale and visibility of the handles
        // using the points based on the index
        handle1.setOrigin(...points[0]);
        handle2.setOrigin(...points[1]);
      } else {
        points.forEach((point) => {
          const lastHandle = widget.getWidgetState().addHandle();
          lastHandle.setOrigin(...point);
          lastHandle.setScale1(10);
          lastHandle.setVisible(true);
        });
      }
    }

    const newLandmark = {
      id,
      analysisId,
      geometryType,
      landmarkTypeId,
      name,
      description,
      points,
      sourceVersion: rootState.heartguide.version.frontend.version,
      sourceClient: rootState.heartguide.version.frontend.clientLabel,
      viewType,
      widget,
    };
    commit('SET_NEW_LANDMARK', newLandmark);
  },
  setNewLandmarkPoints({ state, commit, getters }) {
    // Use the points of the newLandmark widget and the location of the resliceWidget
    // to update the newLandmark _state_. The state is used to later save the landmark
    // if requested
    const { newLandmark } = getters;
    if (newLandmark) {
      const { widget } = newLandmark;
      const landmarkWidgetState = widget.getWidgetState();
      const widgetState = state.widget.getWidgetState();
      let points;
      if (newLandmark.geometryType === constants.DISTANCE) {
        points = [
          landmarkWidgetState.getHandle1().getOrigin(),
          landmarkWidgetState.getHandle2().getOrigin(),
        ];
      } else {
        points = landmarkWidgetState.getHandleList().map((p) => p.getOrigin());
      }
      // Don't store the normal or resliceRotation if it is a 3d curve
      let normal = null;
      let resliceRotation = null;
      if (newLandmark.geometryType !== constants.CLOSED_CURVE
        && newLandmark.geometryType !== constants.OPEN_CURVE) {
        const planeNormal = widgetState.getPlanes()[newLandmark.viewType].normal;
        normal = planeNormal.map((coord) => coord * -1);
        // Derive the resliceRotation from the planes of the resliceWidget
        resliceRotation = getResliceRotation(widgetState.getPlanes());
      }
      const centroid = null;
      const resliceCenter = null;
      commit('NEW_LANDMARK_UPDATE', {
        properties: {
          points, normal, centroid, resliceRotation, resliceCenter,
        },
      });
    }
  },
  async addLandmark({
    commit, dispatch, getters, state,
  }, newLandmark) {
    // POST the landmark to the API, add to the store,
    // instantiate widgets for both viewers
    landmarksAPI.post(newLandmark).then((response) => {
      const landmark = prepareLandmark(
        response.data, state.landmarkTypes[newLandmark.landmarkTypeId],
        getters.roiCenter,
      );
      commit('LANDMARKS_UPDATE', {
        [landmark.id]: landmark,
      });
      dispatch('selectLandmark', landmark.id);
      if (window.renderer) {
        window.renderer.addLandmark(landmark);
      }
      dispatch('triggerRefresh');
    });
  },
  async updateLandmark({
    commit, dispatch, getters, state,
  }, newLandmark) {
    landmarksAPI.patch(newLandmark.id, newLandmark).then((response) => {
      const landmark = prepareLandmark(
        response.data, state.landmarkTypes[newLandmark.landmarkTypeId],
        getters.roiCenter,
      );
      commit('LANDMARKS_UPDATE', {
        [landmark.id]: landmark,
      });
      dispatch('selectLandmark', landmark.id);
      if (window.renderer) {
        window.renderer.addLandmark(landmark);
      }
      dispatch('triggerRefresh');
    });
  },
  async saveNewLandmark({ state, dispatch }) {
    // Trigger addLandmark with the data currently in `newLandmark`
    const { newLandmark } = state;
    dispatch('addLandmark', {
      analysisId: newLandmark.analysisId,
      landmarkTypeId: newLandmark.landmarkTypeId,
      name: newLandmark.name,
      comment: newLandmark.description,
      points: newLandmark.points,
      geometryType: newLandmark.geometryType,
      viewType: newLandmark.viewType,
      normal: newLandmark.normal,
      centroid: newLandmark.centroid,
      resliceRotation: newLandmark.resliceRotation,
      resliceCenter: newLandmark.resliceCenter,
      sourceClient: newLandmark.sourceClient,
      sourceVersion: newLandmark.sourceVersion,

      isEnabled: true,
      isPublished: newLandmark.landmarkTypeId === null,
    });
    dispatch('cancelNewLandmark');
  },
  async editLandmark({ state, dispatch, getters }) {
    const { newLandmark } = state;
    const landmark = getters.landmarkById(newLandmark.id);
    dispatch('setLandmarkVisibility', { landmarkId: landmark.id, isVisible: false });
    dispatch('disableLandmark', landmark.id);
    dispatch('updateLandmark', {
      id: newLandmark.id,
      name: newLandmark.name,
      points: newLandmark.points,
      measurements: null,
      centroid: null,
      comment: newLandmark.description,
    });
    dispatch('cancelNewLandmark');
  },
  cancelNewLandmark({ commit, dispatch, state }) {
    // Clear the currently drawing landmark
    commit('SET_NEW_LANDMARK', undefined);
    state.newAngleWidget.getWidgetState().clearHandleList();
    dispatch('triggerRefresh');
  },
  async selectLandmark({
    commit, dispatch, getters, state,
  }, id) {
    const landmark = getters.landmarkById(id);
    if (landmark) {
      window.landmarkStore[landmark.id].vtkWidget.setVisibility(true);
      commit('LANDMARK_UPDATE', { id, properties: { isEnabled: true } });
      commit('SET_SELECTED_LANDMARK_ID', id);
      if (state.widget) {
        dispatch('goToLandmark', landmark);
      }
    }
  },
  goToLandmark({ state, dispatch }, landmark) {
    let matrix;
    let upViewType;
    if (landmark.resliceRotation) {
      matrix = matrixFromResliceRotation(landmark.resliceRotation);
    } else if (landmark.normal) {
      matrix = matrixFromDirections(landmark.normal, landmark.viewType);
      upViewType = landmark.viewType;
    }
    state.widget.getWidgetState().setPlanes(rotatePlanes(
      matrix, upViewType,
    ));
    if (landmark.geometryType === constants.ANGLE) {
      state.widget.setCenter(landmark.points[1]);
    } else if (landmark.geometryType === constants.AREA) {
      if (landmark.centroid) {
        state.widget.setCenter(landmark.centroid);
      } else {
        state.widget.setCenter(landmark.points[0]);
      }
    } else {
      state.widget.setCenter(landmark.points[0]);
    }
    // Set the scale of the handles to 0 again. The initial value (see prepareLandmark)
    // gets overwritten somewhere.
    window.landmarkStore[landmark.id].vtkWidget.getWidgetState(
    ).getHandleList().forEach((handle) => {
      handle.setScale1(0);
    });
    dispatch('triggerRefresh');
  },
  async deleteLandmark({ commit, dispatch, getters }, id) {
    landmarksAPI.delete(id).then(
      () => {
        const landmark = getters.landmarkById(id);
        dispatch('setLandmarkVisibility', { landmarkId: landmark.id, isVisible: false });
        window.landmarkStore[landmark.id].vtkWidget.setVisibility(false);
        commit('LANDMARK_DELETE', { id });
        dispatch('triggerRefresh');
      },
    );
  },
  disableLandmark({ commit, getters, dispatch }, id) {
    const landmark = getters.landmarkById(id);
    window.landmarkStore[landmark.id].vtkWidget.setVisibility(false);
    commit('LANDMARK_UPDATE', { id, properties: { isEnabled: false } });
    dispatch('triggerRefresh');
  },
  enableLandmark({
    commit, getters, dispatch, state,
  }, id) {
    commit('LANDMARK_UPDATE', { id, properties: { isEnabled: true } });
    const landmark = getters.landmarkById(id);
    checkLandmarkVisibility(landmark, state.widget);
    dispatch('triggerRefresh');
  },
  async publishLandmark({ commit, dispatch }, id) {
    landmarksAPI.publish(id).then(
      () => {
        commit('LANDMARK_UPDATE', { id, properties: { isPublished: true } });
        dispatch('setLandmarkVisibility', { landmarkId: id, isVisible: true });
      },
    );
  },
  async unpublishLandmark({ commit, dispatch }, id) {
    landmarksAPI.unpublish(id).then(
      () => {
        commit('LANDMARK_UPDATE', { id, properties: { isPublished: false } });
        dispatch('setLandmarkVisibility', { landmarkId: id, isVisible: false });
      },
    );
  },
  setEditMode({
    commit,
    getters,
    dispatch,
    state,
  }, editMode) {
    if (!editMode) {
      getters.landmarks.forEach((lm) => {
        if (lm.isEnabled) {
          const isEnabled = lm.isEnabled && lm.isPublished;
          commit('LANDMARK_UPDATE', {
            id: lm.id,
            properties: { isEnabled },
          });
          checkLandmarkVisibility({ ...lm, isEnabled }, state.widget);
        }
        if (window.landmarkStore[lm.id].threeJsMesh.visible) {
          dispatch('setLandmarkVisibility', {
            landmarkId: lm.id, isVisible: lm.isPublished,
          });
        }
      });
    }
    if (window.renderer) {
      window.renderer.render();
    }
    commit('SET_EDIT_MODE', editMode);
  },

  initializeViewport: ({ dispatch, getters, state }, {
    el,
  }) => {
    const renderer = new Renderer({
      target: el,
      ...state.options,
      scenes: getters.sceneList,
      humanModel: state.humanModel,
      report: (report) => {
        dispatch('updateReport', { report });
      },
    });
    renderer.init();
    renderer.setScene(0);
    window.renderer = renderer;
    getters.landmarks.forEach((landmark) => renderer.addLandmark(landmark));
    dispatch('setVisualisation', {
      visualisationId: getters.sceneList[0].visualisationIds[0],
    });
    dispatch('onResize');
  },
  progressLoading: ({ commit }, { url, loaded, total }) => {
    commit(types.VIEWER_OBJECT_LOADED, url);
    commit(types.VIEWER_LOADING_PROGRESS, Math.floor((loaded / total) * 100));
  },
  objectsLoaded: ({ commit }) => {
    commit(types.VIEWER_LOADED);
  },
  resetVisualisation: ({ commit, getters }) => {
    commit(types.VIEWER_RESET_SELECTED_VISUALISATION, getters.currentVisualisation.id);
    window.renderer.setVisualisation(getters.currentVisualisation);
  },
  setScene: ({
    commit, dispatch, getters, state,
  }, { selectedSceneIdx }) => {
    window.renderer.setScene(selectedSceneIdx);
    const scene = getters.scene(selectedSceneIdx);

    // use the same visualisation if it exists
    if (!scene.visualisationIds.includes(getters.currentVisualisation?.id)) {
      const visualisationId = state.selectedVisualisationId[scene.id] || scene.visualisationIds[0];
      dispatch('setVisualisation', { visualisationId, sceneIndex: selectedSceneIdx });
    } else {
      const visualisation = getters.currentVisualisation;
      window.renderer.setVisualisation(getters.currentVisualisation);
      commit(types.VIEWER_SET_VISUALISATION, { visualisation, sceneId: scene.id });
    }

    commit(types.VIEWER_SET_SCENE, { selectedSceneIdx });
  },
  setVisualisation: ({ commit, getters, dispatch }, { visualisationId, sceneIndex }) => {
    const visualisation = getters.visualisation(visualisationId);
    getters.landmarks.forEach((lm) => {
      dispatch('setLandmarkVisibility', { landmarkId: lm.id, isVisible: false });
      const visLand = visualisation.landmarks.find(
        (vlm) => vlm.id === lm.id,
      );
      if (visLand) {
        if (lm.isPublished) {
          dispatch('setLandmarkVisibility', { landmarkId: lm.id, isVisible: visLand.isVisible });
        }
      }
    });
    window.renderer.setVisualisation(visualisation);
    const sceneId = typeof sceneIndex === 'number'
      ? getters.scene(sceneIndex).id : getters.currentScene.id;
    commit(types.VIEWER_SET_VISUALISATION, { visualisation, sceneId });
  },
  setGlobalOpacity: ({ commit, getters }, { visualisationId, opacity }) => {
    window.renderer.setGlobalOpacity(opacity);
    commit(types.SET_GLOBAL_OPACITY,
      { visualisationId, opacity, currentVisualisationId: getters.currentVisualisation.id });
  },
  setObjectOpacity: ({ commit, getters }, { visualisationId, objectName, opacity }) => {
    window.renderer.setObjectOpacity(objectName, opacity);
    commit(types.SET_OBJECT_OPACITY, {
      visualisationId,
      objectName,
      opacity,
      currentVisualisationId: getters.currentVisualisation.id,
    });
  },
  setObjectColor: ({ commit, getters }, { visualisationId, objectName, color }) => {
    window.renderer.setObjectColor(objectName, color);
    commit(types.SET_OBJECT_COLOR, {
      visualisationId,
      objectName,
      color,
      currentVisualisationId: getters.currentVisualisation.id,
    });
  },
  setObjectVisibility: ({ commit, getters }, { visualisationId, objectName, isVisible }) => {
    window.renderer.setObjectVisibility(objectName, isVisible);
    commit(types.SET_OBJECT_VISIBILITY, {
      visualisationId,
      objectName,
      isVisible,
      currentVisualisationId: getters.currentVisualisation.id,
    });
  },
  setObjectDoubleSided: ({ commit, getters }, { visualisationId, objectName, isDoubleSided }) => {
    window.renderer.setObjectDoubleSided(objectName, isDoubleSided);
    commit(types.SET_OBJECT_DOUBLESIDED, {
      visualisationId,
      objectName,
      isDoubleSided,
      currentVisualisationId: getters.currentVisualisation.id,
    });
  },
  setLandmarkVisibility: ({ commit }, { landmarkId, isVisible }) => {
    window.landmarkStore[landmarkId].threeJsMesh.visible = isVisible;
    if (window.renderer) {
      window.renderer.render();
    }
    commit(types.SET_LANDMARK_VISIBILITY, { landmarkId, isVisible });
  },
  onResize: ({ state }) => {
    if (window.renderer) {
      const sidebarOpen = state.sidebar.open;
      window.renderer.onResize(1, sidebarOpen);
    }
  },
  updateReport: ({ commit }, { report }) => {
    commit(types.VIEWER_UPDATE_REPORT, { report });
  },

  cameraReset: () => {
    if (window.renderer) {
      window.renderer.cameraReset();
    }
  },

  alignCarm: () => {
    if (window.renderer) {
      window.renderer.alignCarm();
    }
  },

  predefinedViewTAVI: (e, { hingePoints, viewType }) => {
    if (window.renderer) {
      window.renderer.predefinedViewTAVI(hingePoints, viewType);
    }
  },

  predefinedViewLAAO: (e, { lzOstLandmarks, viewType }) => {
    if (window.renderer) {
      window.renderer.predefinedViewLAAO(lzOstLandmarks, viewType);
    }
  },

  gotoAngles: (e, angles) => {
    if (window.renderer) {
      window.renderer.gotoAngles(angles);
    }
  },

  async resetState({ commit }) {
    commit(types.RESET_STATE_VIEWER_MODULE);
  },
};
