<template>
  <v-row class="py-4">
    <v-col cols="12">
      <h1 class="text-h4">
        <v-tooltip bottom v-if="hgcase.hasUpdatedPatientIdentifier">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" color="warning">
              <v-icon>warning</v-icon>
            </v-btn>
          </template> The patient identifier has been updated since the original request.
        </v-tooltip>

        {{ hgcase.caseIdentifier }}

        <EditCaseDialog :hgCase="hgcase"
          v-if="userHasPermission('cases.change_heartguidecase')" />

        <ChangeDevicesDialog :hgCase="hgcase"
          v-if="userHasPermission('cases.change_requested_device') && !hgcase.isFinished" />
      </h1>
    </v-col>

    <v-col cols="12" sm="6" md="4" xl="3">
      <v-list-item-subtitle class="grey--text grey--darken-1">Status</v-list-item-subtitle>
      <v-list-item-title class="mt-2 text-capitalize">{{ hgcase.statusDisplay }}</v-list-item-title>
    </v-col>
    <v-col cols="12" sm="6" md="4" xl="3">
      <v-list-item-subtitle class="grey--text grey--darken-1">Organisation</v-list-item-subtitle>
      <v-list-item-title class="mt-2 wrap">
        {{ hgcase.organisation && hgcase.organisation.nameAndCountryDisplay || '' }}
      </v-list-item-title>
    </v-col>
    <v-col cols="12" sm="6" md="4" xl="3">
      <v-list-item-subtitle class="grey--text grey--darken-1">Created by</v-list-item-subtitle>
      <v-list-item-title class="mt-2 wrap">
        {{ hgcase.createdBy && hgcase.createdBy.email || '-' }}
      </v-list-item-title>
    </v-col>
    <v-col cols="12" sm="6" md="4" xl="3">
      <v-list-item-subtitle class="grey--text grey--darken-1">Responsible</v-list-item-subtitle>
      <v-list-item-title class="mt-2 wrap">
        <UserAutoComplete v-if="user.canAssignCaseResponsible"
          :organisationId="hgcase.organisationId"
          :value="hgcase.caseResponsible" @input="setCaseResponsible"
          :hideDetails="true"
          primaryMembersOnly
        />
        <template v-else>
          {{ hgcase.caseResponsible && hgcase.caseResponsible.email || '-'}}
        </template>
      </v-list-item-title>
    </v-col>
    <v-col cols="12" sm="6" md="4" xl="3">
      <v-list-item-subtitle class="grey--text grey--darken-1">Confidential</v-list-item-subtitle>
      <v-list-item-title class="mt-2 text-capitalize"
        :class="{ 'red--text': hgcase.isConfidential }">
        {{ hgcase.isConfidential }}
      </v-list-item-title>
    </v-col>
    <v-col cols="12" sm="6" md="4" xl="3">
      <v-list-item-subtitle class="grey--text grey--darken-1">Research</v-list-item-subtitle>
      <v-list-item-title
        class="mt-2 text-capitalize"
        :class="{ 'red--text': hgcase.isResearch }"
        data-cy="is-research"
      >
        {{ hgcase.isResearch }}
      </v-list-item-title>
    </v-col>
    <v-col cols="12" sm="6" md="4" xl="3">
      <v-list-item-subtitle class="grey--text grey--darken-1">Shared with</v-list-item-subtitle>
      <template v-if="(hgcase.sharedWith || []).length > 0">
        <v-list-item-title v-for="shared in hgcase.sharedWith" :key="shared.id" class="wrap">
          {{ shared.email }}
        </v-list-item-title>
      </template>
      <v-list-item-title v-else>-</v-list-item-title>
    </v-col>
    <v-col cols="12" sm="6" md="4" xl="3">
      <v-list-item-subtitle class="grey--text grey--darken-1">
        Expected Delivery Date
      </v-list-item-subtitle>
      <v-list-item-title>{{ hgcase.expectedDeliveryDatetime | localeDateTime }}</v-list-item-title>
    </v-col>
    <v-col cols="12" sm="12" md="4" xl="12">
      <v-list-item-subtitle class="grey--text grey--darken-1">
        Physician comment
      </v-list-item-subtitle>
      <v-list-item-title class="mt-2 wrap">
        <p>{{ hgcase.physicianComment }}</p>
      </v-list-item-title>
    </v-col>

    <!-- Patient/intervention Info -->
    <v-col cols="12" sm="6" md="4" >
      <v-card outlined height="100%">
        <v-card-title>Intervention
          <ShowSensitiveDataButton v-if="hgcase.canRequestPatientInformation"
            :hgcase="hgcase" />
        </v-card-title>
        <v-card-subtitle class="font-italic">
          Intervention details as provided by the physician
        </v-card-subtitle>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-list-item-content>
                <v-list-item-subtitle>Anatomical Analysis Devices</v-list-item-subtitle>
                <v-list-item-title class="wrap font-weight-bold">
                  {{ analysisDevices.join(', ') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-col>
            <v-col cols="12" v-if="simulationDevices.length > 0" class="py-0">
              <v-list-item-content>
                <v-list-item-subtitle>Simulation Devices</v-list-item-subtitle>
                <v-list-item-title class="wrap">
                  <template v-for="d in simulationDevices">
                    <strong :key="d.id">{{ d.name }}</strong>:
                    {{ d.selectedVariants }}
                  </template>
                </v-list-item-title>
              </v-list-item-content>
            </v-col>
            <v-col cols="6">
              <v-list-item-content>
                <v-list-item-subtitle>Intervention</v-list-item-subtitle>
                <v-list-item-title>{{ hgcase.intervention.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle>Retrospective</v-list-item-subtitle>
                <v-list-item-title>{{ !hgcase.isProspective }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-if="hgcase.patientInformation">
                <v-list-item-subtitle>Intervention date</v-list-item-subtitle>
                <v-list-item-title>
                  {{ hgcase.interventionDatetime|localeDateTime }}
                </v-list-item-title>
              </v-list-item-content>
            </v-col>
            <v-col cols="6" v-if="hgcase.patientInformation">
              <v-list-item-content>
                <v-list-item-subtitle>Patient identifier</v-list-item-subtitle>
                <v-list-item-title>{{ hgcase.patientInformation.identifier }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle>Age</v-list-item-subtitle>
                <v-list-item-title>{{ hgcase.patientInformation.age }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle>Sex</v-list-item-subtitle>
                <v-list-item-title>{{ hgcase.patientInformation.sex }}</v-list-item-title>
              </v-list-item-content>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-card class="d-flex flex-column" outlined height="100%">
        <v-card-title>Patient Images</v-card-title>
        <v-card-subtitle class="font-italic">
          Patient Images uploaded by the physician
        </v-card-subtitle>
        <v-card-text class="px-0">
          <template v-if="hgcase.inputData.length">
            <DownloadImagesButton v-for="inputData in [...hgcase.inputData].reverse()"
              :key="inputData.id"
              :inputData="inputData" :caseIdentifier="hgcase.caseIdentifier" />
          </template>
          <p class="px-4" v-else>No images available.</p>
        </v-card-text>
        <v-card-actions class="mt-auto">
          <UploadImagesButton class="ml-auto"
            v-if="hgcase.status===CASE_STATUSES.CREATED && user.canAddInputData"
            :hgcase="hgcase"
          />
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col cols="12" sm="12" md="4">
      <v-card class="d-flex flex-column" outlined height="100%">
        <v-card-title>Results</v-card-title>
        <v-card-subtitle class="font-italic">Results delivered</v-card-subtitle>

        <v-card-text class="px-0">
          <v-list-item v-if="hgcase.hasPdfReport">
            <v-list-item-content class="pt-0">
              <v-list-item-title class="d-flex align-center justify-space-between">
                <a @click="$store.dispatch('downloadStudyResource',
                  { id: hgcase.study.pdfReportId })">Latest Report</a>
                <ReportButton :hgcase="hgcase" />
              </v-list-item-title>
              <v-list-item-subtitle>
                Uploaded: {{ latestReportDate | localeDateTime }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <p class="px-4" v-else>No report available.</p>
        </v-card-text>

        <v-card-text class="mt-auto">
          <v-btn v-if="hgcase.study && hgcase.hasDeliveredOrder"
            depressed
            :to="{
              name: 'study',
              params: { id: hgcase.study.id }
            }"
            color="primary">View result</v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

import ShowSensitiveDataButton from '@/components/Cases/ShowSensitiveDataButton.vue';
import DownloadImagesButton from '@/components/Cases/DownloadImagesButton.vue';
import UploadImagesButton from '@/components/Cases/UploadImagesButton.vue';
import UserAutoComplete from '@/components/UserAutoComplete.vue';
import ReportButton from '@/components/ReportButton.vue';
import ChangeDevicesDialog from './ChangeDevicesDialog.vue';
import EditCaseDialog from './EditCaseDialog.vue';
import constants from '@/store/modules/cases/constants';

export default {
  name: 'CaseDetail',
  components: {
    ChangeDevicesDialog,
    DownloadImagesButton,
    EditCaseDialog,
    UploadImagesButton,
    UserAutoComplete,
    ShowSensitiveDataButton,
    ReportButton,
  },
  data: () => ({
    studyLoading: false,
    CASE_STATUSES: constants.CASE_STATUSES,
  }),
  props: ['hgcase'],
  created() {
    this.$store.dispatch('updateInterventions');
  },
  computed: {
    ...mapGetters(['user', 'userHasPermission']),
    // find report by id
    latestReportDate() {
      const { resources, pdfReportId } = this.hgcase.study;
      return resources.find((r) => r.id === pdfReportId)?.dateCreated ?? null;
    },
    analysisDevices() {
      return Object.values(this.hgcase.anatomicalAnalysisDevices)
        .filter((d) => d)
        .map((d) => d.name);
    },
    hasDelivery() {
      return (this.hgcase.orders || [])
        .some((order) => order.status === constants.ORDER_STATUSES.DELIVERED);
    },
    simulationDevices() {
      return Object.values(this.hgcase.simulationDevices).map((d) => ({
        ...d,
        selectedVariants: this.hgcase.simulationDeviceVariants
          .filter((v) => v.deviceId === d.id).map((v) => v.name).join(', ') || '-',
      }));
    },
  },
  methods: {
    getText() {
      this.$store.dispatch('getText', { hgcase: this.hgcase })
        .catch((error) => {
          this.$toasted.global.error({ message: error });
        });
    },
    setCaseResponsible(caseResponsible) {
      if (caseResponsible && caseResponsible.sub) {
        this.$store.dispatch('assignCaseResponsible', { id: this.hgcase.id, options: { caseResponsible: caseResponsible.sub } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings/colors";

.wrap {
  white-space: normal;
}
</style>
