import * as THREE from 'three';
import GUI from 'lil-gui';

require('./controls/TrackballControls');
require('./controls/OrthographicTrackballControls');

// Polyfill for IE11
if (!Float32Array.prototype.slice) {
  // eslint-disable-next-line
  Object.defineProperty(Float32Array.prototype, 'slice', {
    value(begin, end) {
      return new Float32Array(Array.prototype.slice.call(this, begin, end));
    },
  });
}
const debug = {
  axesHelper: false,
  axesHelperScale: 5,
};

const hideSceneObjects = (scene) => {
  // We look at the current loaded scene scene objects,
  // and look of the matching 3 geometry to hide it.
  // We cannot hide everything as that will hide the landmarks
  if (scene.loadedScene) {
    scene.loadedScene.objects.forEach((sceneObject) => {
      const object = scene.getObjectByName(sceneObject.name);
      if (object) {
        object.visible = false;
      }
    });
  }
};

class Renderer {
  constructor(opts) {
    this.target = opts.target;
    this.calculateSize(1);
    this.scenes = [];
    this.currentScene = 0;
    this.scenesToLoad = opts.scenes;
    this.visualisation = undefined;
    this.backgroundColor = opts.backgroundColor;
    this.cameraType = opts.cameraType;
    this.report = opts.report;
    this.debug = opts.debug;
    this.antialias = true;
    this.humanModel = opts.humanModel;
    this.showAngles = opts.showAngles;
    this.roiCenter = opts.roiCenter;
  }

  init() {
    this.renderer = new THREE.WebGLRenderer({ antialias: this.antialias });
    this.renderer.setClearColor(this.backgroundColor);
    // preload all scenes
    this.scenesToLoad.forEach((sceneToLoad) => {
      const scene = new THREE.Scene({});

      // eslint-disable-next-line no-param-reassign
      sceneToLoad.threeJsScene = scene;
      scene.loadedScene = sceneToLoad;
      sceneToLoad.objects.forEach((object) => {
        this.loadObject(scene, object);
      });
      const light = new THREE.DirectionalLight(0xffffcc, 0.6);
      light.position.set(0, 100, 30);
      scene.add(light);
      const secondLight = new THREE.DirectionalLight(0xffffcc, 0.6);
      secondLight.position.set(0, -100, 30);
      scene.add(secondLight);
      const ambientLight = new THREE.AmbientLight(0xffffff);
      ambientLight.intensity = 0.4;
      scene.add(ambientLight);
      this.scenes.push(scene);

      // add an axis helper to the scene
      const axesHelper = new THREE.AxesHelper(10);
      axesHelper.name = 'AxesHelper';
      scene.add(axesHelper);
      axesHelper.visible = debug.axesHelper;
      axesHelper.scale.set(
        debug.axesHelperScale,
        debug.axesHelperScale,
        debug.axesHelperScale,
      );
    });
    // Show debug if #debug is in the url
    if (window.location.hash === '#debug') {
      const gui = new GUI();
      gui.add(debug, 'axesHelper').onChange((value) => {
        // Update the axis helper visibility in all scenes
        this.scenes.forEach((scene) => {
          const axesHelper = scene.getObjectByName('AxesHelper');
          if (axesHelper) {
            axesHelper.visible = value;
          }
        });
        this.humanScene.getObjectByName('AxesHelper').visible = value;
        this.render();
      });
      gui.add(debug, 'axesHelperScale', 1, 10).onChange((value) => {
        // Update the axis helper size in all scenes
        this.scenes.forEach((scene) => {
          const axesHelper = scene.getObjectByName('AxesHelper');
          if (axesHelper) {
            axesHelper.scale.set(value, value, value);
          }
        });
        this.humanScene.getObjectByName('AxesHelper').scale.set(value, value, value);
        this.render();
      });
    }

    this.humanScene = new THREE.Scene();
    this.initializeCamera();
    this.target.appendChild(this.renderer.domElement);
    this.renderer.setSize(
      this.width,
      this.height,
    );
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }

  addPly = (object, renderScene) => {
    let newMesh = new THREE.Geometry();
    if (object.mesh.attributes && object.mesh.attributes.edge) {
      const attrs = object.mesh.attributes;
      // loop through edges in pairs
      // add a line from vertex[0] to vertex[1]
      let vertexColors = true;
      if (attrs.color) {
        if (attrs.edge.array.length / attrs.color.array.length < 1) {
          vertexColors = false;
        }
      }

      for (let i = 0; i < attrs.edge.array.length; i += 2) {
        const v1 = attrs.edge.array[i];
        const v2 = attrs.edge.array[i + 1];

        const p1 = attrs.position.array.slice(v1 * 3, ((v1 * 3) + 3));
        const p2 = attrs.position.array.slice(v2 * 3, ((v2 * 3) + 3));

        const defaultColor = new THREE.Color('#000000');
        let col1;
        let col2;

        if (attrs.color) {
          if (vertexColors) {
            const c1 = attrs.color.array.slice(v1 * 3, ((v1 * 3) + 3));
            const c2 = attrs.color.array.slice(v2 * 3, ((v2 * 3) + 3));
            col1 = new THREE.Color().fromArray(c1);
            col2 = new THREE.Color().fromArray(c2);
          } else {
            const c = attrs.color.array.slice((i / 2) * 3, (((i / 2) * 3) + 3));
            col1 = new THREE.Color().fromArray(c);
            col2 = new THREE.Color().fromArray(c);
          }
        } else {
          col1 = defaultColor;
          col2 = defaultColor;
        }

        newMesh.colors.push(col1);
        newMesh.colors.push(col2);

        const vect1 = new THREE.Vector3().fromArray(p1);
        const vect2 = new THREE.Vector3().fromArray(p2);

        if (this.roiCenter) {
          vect1.y -= this.roiCenter[1];
          vect1.z -= this.roiCenter[2];
          vect1.x -= this.roiCenter[0];
          vect2.y -= this.roiCenter[1];
          vect2.z -= this.roiCenter[2];
          vect2.x -= this.roiCenter[0];
        }

        newMesh.vertices.push(vect1);
        newMesh.vertices.push(vect2);
      }

      const line = new THREE.LineSegments(
        newMesh,
        new THREE.LineBasicMaterial({ vertexColors: THREE.VertexColors }),
      );

      line.name = object.name;
      // hide the line by default
      line.visible = false;
      renderScene.add(line);
    } else {
      // All other meshed can be doublesided, so put both sides in a THREE.Group node
      // The double side can be disabled in the visualisation
      newMesh = new THREE.Mesh(object.mesh);
      newMesh.geometry.computeVertexNormals();
      newMesh.material = new THREE.MeshPhongMaterial();
      const group = new THREE.Group();
      newMesh.name = 'front';
      group.add(newMesh);
      const secondMesh = new THREE.Mesh(object.mesh);
      secondMesh.geometry.computeVertexNormals();
      secondMesh.material = new THREE.MeshPhongMaterial({ side: THREE.BackSide });
      secondMesh.name = 'back';
      group.add(secondMesh);
      group.name = object.name;
      if (this.roiCenter) {
        group.translateX(-this.roiCenter[0]);
        group.translateY(-this.roiCenter[1]);
        group.translateZ(-this.roiCenter[2]);
      }
      // hide the group by default
      group.visible = false;
      renderScene.add(group);
    }
  }

  loadObject(scene, object) {
    const loader = new THREE.PLYLoader();
    // objects in the scene use filename, where the data objects uses name
    const { file } = object;
    if (file && file.readUrl) {
      const url = file.readUrl;
      loader.load(
        url,
        (loadedObject) => {
          this.addPly({
            mesh: loadedObject,
            name: object.name,
          }, scene);
          this.orientCamera();
          this.applyVisualisation();
        },
      );
    // eslint-disable-next-line no-console
    } else if (file) { console.log('File is missing an url', object.filename); } else { console.log('Scene file not found', object.filename); }
  }

  orientCamera() {
    const scene = this.scenes[this.currentScene];
    const box = new THREE.Box3().setFromObject(scene);
    if (this.cameraType === 'perspective') {
      const dist = (box.max.y - box.min.y) / (2 * Math.tan(this.camera.fov * (Math.PI / 360)));
      this.camera.position.set(0, dist * -2.4, 0);
    } else {
      this.camera.zoom = Math.min(
        this.width / (box.max.x - box.min.x),
        this.height / (box.max.y - box.min.y),
      ) * 0.4;
      this.controls.zoom0 = this.camera.zoom;
    }
    this.updateControls();
    this.updateCamera();
  }

  initializeCamera() {
    this.camera = new THREE.OrthographicCamera(
      this.width / -2, this.width / 2,
      this.height / 2, this.height / -2,
      1, 1000,
    );
    this.humanCamera = new THREE.OrthographicCamera(132 / -2, 132 / 2, 99 / 2, 99 / -2, 1, 1000);
    this.humanCamera.zoom = 0.2;
    this.humanCamera.position.set(0, -500, 0);
    this.humanCamera.up.set(0, 0, 500);
    this.camera.up.set(0, 0, 500);
    this.camera.position.set(0, -500, 0);
    const material = new THREE.MeshStandardMaterial({
      color: new THREE.Color().setHSL(0.1, 0.2, 0.74),
      roughness: 0.5,
      metalness: 0,
      flatShading: true,
    });
    const human = new THREE.Mesh(this.humanModel.mesh, material);
    human.translateZ(-150);
    human.rotation.x = Math.PI / 2;
    this.humanScene.add(human);
    // Add an axis helper to the human scene
    const axesHelper = new THREE.AxesHelper(50);
    axesHelper.name = 'AxesHelper';
    axesHelper.visible = debug.axesHelper;
    axesHelper.scale.set(
      debug.axesHelperScale,
      debug.axesHelperScale,
      debug.axesHelperScale,
    );
    this.humanScene.add(axesHelper);

    const humanLight = new THREE.HemisphereLight(0xaaaaaa, 0x444444);
    humanLight.position.set(0, 0, 30);
    this.humanScene.add(humanLight);
    this.controls = new THREE.OrthographicTrackballControls(this.camera, this.target);
    this.humanControls = new THREE.OrthographicTrackballControls(this.humanCamera, this.target);
    this.controls.minZoom = 1;
    this.controls.maxZoom = 50;
    this.controls.noPan = false;
    this.controls.noRoll = true;
    this.controls.zoomSpeed = 0.3;
    this.controls.dynamicDampingFactor = 0.7;
    this.controls.enableRotate = false;
    this.humanControls.noZoom = true;
    this.humanControls.noPan = true;
    this.humanControls.noRoll = true;
    this.humanControls.zoomSpeed = 0.3;
    this.humanControls.dynamicDampingFactor = 0.7;
    this.controls.addEventListener('change', () => this.render());
  }

  updateCamera() {
    this.camera.left = this.width / -2;
    this.camera.right = this.width / 2;
    this.camera.top = this.height / 2;
    this.camera.bottom = this.height / -2;
    this.camera.updateProjectionMatrix();
  }

  setScene(sceneID) {
    this.currentScene = sceneID;
  }

  calculateAngles(vec) {
    // We subtract the target vector from the camera position to
    // negate panning
    vec.sub(this.controls.target);
    const axis = new THREE.Vector3(0, 0, 1);

    // Get the lr rotation, then apply its negative to the current
    // position
    const lr = Math.atan2(vec.x, -vec.y);
    vec.applyAxisAngle(axis, -lr);

    // Get the cc position on the now negated lr rotation position
    const cc = Math.atan2(vec.z, -vec.y);

    return { cc, lr };
  }

  generateReport() {
    const report = this.calculateAngles(this.camera.position.clone());
    this.report(report, 0);
  }

  toggleCameraType() {
    if (this.cameraType === 'perspective') {
      this.cameraType = 'orthographic';
    } else {
      this.cameraType = 'perspective';
    }
    this.initializeCamera();
    this.render();
  }

  setVisualisation(visualisation) {
    this.visualisation = visualisation;
    this.applyVisualisation();
  }

  setGlobalOpacity(opacity) {
    this.visualisation.opacity = opacity;
    this.applyVisualisation();
  }

  setObjectOpacity(objectName, opacity) {
    const object = this.visualisation.objects.find((obj) => obj.name === objectName);
    object.opacity = opacity;
    this.applyVisualisation();
  }

  setObjectColor(objectName, color) {
    const object = this.visualisation.objects.find((obj) => obj.name === objectName);
    object.color = color;
    this.applyVisualisation();
  }

  setObjectVisibility(objectName, isVisible) {
    const object = this.visualisation.objects.find((obj) => obj.name === objectName);
    object.isVisible = isVisible;
    this.applyVisualisation();
  }

  setObjectDoubleSided(objectName, isDoubleSided) {
    const object = this.visualisation.objects.find((obj) => obj.name === objectName);
    object.isDoubleSided = isDoubleSided;
    this.applyVisualisation();
  }

  calculateSize(viewportCount) {
    this.height = window.innerHeight - 70;
    const width = Math.max(1110, (window.innerWidth * viewportCount));
    this.width = width;
  }

  onResize(viewportCount, sidebarOpen) {
    this.calculateSize(viewportCount, sidebarOpen);
    this.updateCamera();
    this.renderer.setSize(this.width, this.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.controls.handleResize();
    this.humanControls.handleResize();
    this.render();
  }

  cameraReset() {
    this.controls.reset();
    this.humanControls.reset();
    this.render();
  }

  alignCarm() {
    const angles = this.calculateAngles(this.camera.position.clone());
    this.gotoAngles(angles);
  }

  predefinedViewTAVI(hingePoints, viewType) {
    // Find points based on their name property
    const nccH = hingePoints.find((point) => point.name === 'Non Coronary Cusp');
    const rccH = hingePoints.find((point) => point.name === 'Right Coronary Cusp');
    const lccH = hingePoints.find((point) => point.name === 'Left Coronary Cusp');

    // Transform them into THREE.Vector3
    const ncc = new THREE.Vector3(...nccH.points[0]);
    const rcc = new THREE.Vector3(...rccH.points[0]);
    const lcc = new THREE.Vector3(...lccH.points[0]);

    if (this.roiCenter) {
      ncc.x -= this.roiCenter[0];
      ncc.y -= this.roiCenter[1];
      ncc.z -= this.roiCenter[2];

      rcc.x -= this.roiCenter[0];
      rcc.y -= this.roiCenter[1];
      rcc.z -= this.roiCenter[2];

      lcc.x -= this.roiCenter[0];
      lcc.y -= this.roiCenter[1];
      lcc.z -= this.roiCenter[2];
    }
    this.controls.resetPosition();
    this.humanControls.resetPosition();

    if (viewType === 0) {
      const center = new THREE.Vector3().addVectors(ncc, lcc).multiplyScalar(1 / 2);

      const cameraPosition = center.clone().sub(rcc.clone()).normalize();
      cameraPosition.multiplyScalar(-200);

      this.camera.position.copy(cameraPosition);
      this.camera.up.set(0, 0, 1);
      this.humanCamera.position.copy(cameraPosition);
    } else if (viewType === 1) {
      const cameraPosition = rcc.clone().sub(lcc.clone()).normalize();
      cameraPosition.multiplyScalar(200);

      this.camera.position.copy(cameraPosition);
      this.camera.up.set(0, 0, 1);
      this.humanCamera.position.copy(cameraPosition);
      this.humanCamera.up.set(0, 0, 1);
    }

    this.controls.update();
    this.humanControls.update();
    this.render();
  }

  predefinedViewLAAO(lzOstLandmarks, viewType) {
    const ost = lzOstLandmarks.find((area) => area.displayName === 'Anatomical Ostium');
    const lz = lzOstLandmarks.find((area) => area.displayName.startsWith('Landing Zone'));

    const ostNormal = new THREE.Vector3(...ost.normal);
    const lzNormal = new THREE.Vector3(...lz.normal);
    if (viewType === 0) {
      this.controls.resetPosition();
      this.humanControls.resetPosition();
      // Calculate the cross product of the normals to get the vector
      // that is perpendicular to both normals
      const cross = new THREE.Vector3().crossVectors(ostNormal, lzNormal);
      let crossNormal = cross.normalize();
      crossNormal.multiplyScalar(200);
      const angles = this.calculateAngles(crossNormal.clone());
      // check if lao/rao is > 180 degrees
      if (Math.abs(angles.lr) > (Math.PI / 2)) {
        crossNormal = cross.normalize();
        crossNormal.multiplyScalar(-200);
      }
      this.camera.position.copy(crossNormal);
      this.camera.up.set(0, 0, 1);
      this.humanCamera.position.copy(crossNormal);
      this.humanCamera.up.set(0, 0, 1);
    } else if (viewType === 1 || viewType === 2) {
      const angles = {};
      angles.lr = Math.PI / 4;
      if (viewType === 1) {
        angles.lr = -Math.PI / 4;
      }
      angles.cc = -Math.atan2(
        (ostNormal.x * Math.sin(angles.lr) - ostNormal.y * Math.cos(angles.lr)), ostNormal.z,
      );
      this.gotoAngles(angles);
    }

    this.controls.update();
    this.humanControls.update();
    this.render();
  }

  gotoAngles(angles) {
    this.controls.resetPosition();
    this.humanControls.resetPosition();

    this.camera.position.applyAxisAngle(new THREE.Vector3(1, 0, 0), -angles.cc);
    this.camera.position.applyAxisAngle(new THREE.Vector3(0, 0, 1), angles.lr);
    this.camera.up.applyAxisAngle(new THREE.Vector3(1, 0, 0), -angles.cc);
    this.camera.up.applyAxisAngle(new THREE.Vector3(0, 0, 1), angles.lr);

    this.humanCamera.position.copy(this.camera.position);
    this.humanCamera.up.copy(this.camera.up);

    this.controls.update();
    this.humanControls.update();
    this.render();
  }

  applyVisualisation() {
    const { visualisation } = this;
    if (visualisation === undefined) {
      return;
    }
    const baseOpacity = visualisation.opacity || 1;
    const scene = this.scenes[this.currentScene];

    hideSceneObjects(scene);

    // Apply visualisation config to 3D objects
    visualisation.objects.forEach((visObj) => {
      const object = scene.getObjectByName(visObj.name);
      if (object) {
        object.visible = true;
        let objectOpacity = visObj.opacity;
        if (objectOpacity === undefined) {
          objectOpacity = 1.0;
        }
        let objectMaxOpacity = visObj.maxOpacity;
        if (objectMaxOpacity === undefined) {
          objectMaxOpacity = 1.0;
        }
        const materialSettings = {
          color: visObj.color,
          transparent: true,
          opacity: baseOpacity * objectOpacity * objectMaxOpacity,
          visible: visObj.isVisible,
        };
        if (materialSettings.opacity === 0) {
          materialSettings.visible = false;
        }
        if (!materialSettings.color) {
          materialSettings.vertexColors = THREE.VertexColors;
          materialSettings.color = 0xffffff;
        } else {
          materialSettings.vertexColors = THREE.NoColors;
        }
        materialSettings.depthTest = visObj.hasDepthTestEnabled;
        materialSettings.flatShading = visObj.hasFlatShadingEnabled;

        let material = new THREE.MeshPhongMaterial();
        let backMaterial = new THREE.MeshPhongMaterial({ side: THREE.BackSide });

        if (!visObj.isAffectedByLighting) {
          // Turn of lights for this object by switching to the basic material.
          material = new THREE.MeshBasicMaterial();
          backMaterial = new THREE.MeshBasicMaterial({ side: THREE.BackSide });
        }
        // All models can be double sided and put in a group
        // THREE.Group is used for double sided objects

        if (object instanceof THREE.Group) {
          object.traverse((node) => {
            if (node instanceof THREE.Mesh || node instanceof THREE.Line) {
              if (node.name === 'back') {
                node.material = backMaterial;
                node.visible = visObj.isDoubleSided;
              } else {
                node.material = material;
              }

              node.material.setValues(materialSettings);
              node.renderOrder = parseInt(visObj.renderOrder, 10);
              node.material.needsUpdate = true;
              node.normalsNeedUpdate = true;
            }
          });
        } else {
          object.material = material;
          object.material.setValues(materialSettings);
          object.renderOrder = parseInt(visObj.renderOrder, 10);
          object.material.needsUpdate = true;
          object.normalsNeedUpdate = true;
        }
      }
    });
    this.render();
  }

  addLandmark = (landmark) => {
    const anatomicals = this.scenesToLoad.filter((rs) => rs.type === 'Anatomical Analysis');
    if (anatomicals) {
      const renderScene = anatomicals[0].threeJsScene;
      renderScene.add(window.landmarkStore[landmark.id].threeJsMesh);
    }
  };

  render() {
    this.renderer.setScissorTest(false);
    this.renderer.setClearColor(this.backgroundColor, 1);
    this.renderer.setScissor(0, 0, this.width, this.height);
    this.renderer.setViewport(0, 0, this.width, this.height);
    this.renderer.render(this.scenes[this.currentScene], this.camera);
    if (this.showAngles !== false) {
      this.generateReport();
      this.renderer.setScissorTest(true);
      this.renderer.setClearColor(0xffffff, 1);
      this.renderer.setScissor(20, this.height - (99 + 20), 132, 99);
      this.renderer.setViewport(20, this.height - (99 + 20), 132, 99);
      this.renderer.render(this.humanScene, this.humanCamera);
    }
  }

  updateControls() {
    requestAnimationFrame(() => {
      this.updateControls();
    });
    this.controls.update();
    this.humanControls.update();
  }
}

export default Renderer;
