<template>
  <v-row>
    <v-col cols="12">
      <h2>
        <span class="grey--text">Study:</span> {{ study.name }}
      </h2>
      <p class="mt-2 mb-0 font-italic">
        Collection of data provided by FEops on a single series of patient images.
      </p>
    </v-col>
    <v-col cols="12" sm="4">
      <v-list-item-subtitle class="grey--text grey--darken-1">Study ID</v-list-item-subtitle>
      <v-list-item-title class="mt-2 text-wrap">{{ study.id }}</v-list-item-title>
    </v-col>
    <v-col cols="6" sm="4">
      <v-list-item-subtitle class="grey--text grey--darken-1">DICOM</v-list-item-subtitle>
      <v-list-item-title class="mt-2 text-capitalize">
        <v-btn v-if="study.dicomVolumeId" small depressed color="primary"
          :to="{ name: 'mprviewer', params: { id: study.id } }">View DICOM</v-btn>
        <template v-else>
          <v-icon color="error">close</v-icon> No DICOM found
        </template>
      </v-list-item-title>
    </v-col>
    <v-col cols="6" sm="4">
      <v-list-item-subtitle class="grey--text grey--darken-1">3D View</v-list-item-subtitle>
      <v-list-item-title class="mt-2 text-capitalize">
        <v-btn v-if="study.has3d" small depressed color="primary"
          :to="{ name: '3dsurfaceviewer', params: { id: study.id } }">View 3D</v-btn>
        <template v-else>
          <v-icon color="error">close</v-icon> No 3D View found
        </template>
      </v-list-item-title>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-card class="d-flex flex-column" outlined height="100%">
        <v-card-title>Reports</v-card-title>
        <div class="d-flex flex-column px-0" v-if="pdfReports.length > 0">
          <StudyResource v-for="resource in pdfReports" :key="resource.id"
            :studyResource="resource"
            :orders="orders"
            :hasDelivery="hasDelivery"
          />
        </div>
        <v-card-text v-else>
          <p>No Reports</p>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-card class="d-flex flex-column" outlined height="100%">
        <v-card-title>Resources</v-card-title>

        <v-card-text v-if="pseudonymizedDicomImages.length < 1 && vtkResources.length < 1">
          <p>No Resources</p>
        </v-card-text>
        <div v-else class="d-flex flex-column px-0">
          <!-- Pseudomized Dicom Images -->
          <template v-if="pseudonymizedDicomImages.length === 1">
            <StudyResource :studyResource="pseudonymizedDicomImages[0]"
              :orders="orders"
              :hasDelivery="hasDelivery" />
          </template>
          <template v-else-if="pseudonymizedDicomImages.length > 1">
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ PSEUDONYMIZED_DICOM }}</v-list-item-title>
                  <v-list-item-subtitle class="mt-1">Latest
                    {{ pseudonymizedDicomImages[pseudonymizedDicomImages.length - 1]
                      .dateCreated | localeDateTime }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>

              <StudyResource
                v-for="(resource, index) in pseudonymizedDicomImages" :key="resource.id"
                :studyResource="resource"
                :orders="orders"
                :hasDelivery="hasDelivery"
                :isLatest="index === pseudonymizedDicomImages.length - 1"
                :isNested="true"
              />
            </v-list-group>
          </template>

          <!-- VTK.js Resources -->
          <template v-if="vtkResources.length === 1">
            <StudyResource :studyResource="vtkResources[0]"
              :orders="orders"
              :hasDicom="hasDicom(vtkResources[0])"
              :hasDelivery="hasDelivery" />
          </template>
          <template v-else-if="vtkResources.length > 1">
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ VTKJS }}</v-list-item-title>
                  <v-list-item-subtitle>
                    Latest {{ vtkResources[vtkResources.length - 1].dateCreated | localeDateTime }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>

              <StudyResource v-for="(resource, index) in vtkResources" :key="resource.id"
                :studyResource="resource"
                :orders="orders"
                :hasDelivery="hasDelivery"
                :hasDicom="hasDicom(resource)"
                :isLatest="index === vtkResources.length - 1"
                :isNested="true"
              />
            </v-list-group>
          </template>
        </div>
        <v-card-text class="mt-auto"
          v-if="(!hasDelivery && !isFinished) && user.canAddStudyResource"
        >
          <UploadStudyResourceButton :study="study" />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="12" md="4">
      <v-card class="d-flex flex-column" outlined height="100%">
        <v-card-title>Advanced</v-card-title>
        <v-card-text>
          <v-alert type="info" v-if="isFinished" dense text>
            <!-- eslint-disable-next-line max-len -->
            Advanced options are not available after a case has been completed, canceled or rejected.
          </v-alert>
          <v-alert type="info" v-else-if="hasDelivery" dense text>
            Most advanced options are not available after an order has been delivered.
          </v-alert>

          <template v-else-if="user.canUseAdvancedCaseOptions">
            <div>
              <v-list-item-subtitle>Select a config</v-list-item-subtitle>
              <div class="mt-2">
                <ConfigSelect :study="study" />
              </div>
            </div>

            <div class="mt-4">
              <v-list-item-subtitle>Override the config</v-list-item-subtitle>

              <div class="mt-2 d-flex flex-wrap gap-8px">
                <v-btn outlined text @click="hideTransseptalPuncture"
                  :disabled="!intervention || intervention.name !== 'LAAO'"
                  :color="isTransseptalPunctureHidden && 'success' || ''"
                >
                  {{ isTransseptalPunctureHidden ?
                    'Transseptal Puncture is hidden' :
                    'Hide TSP visualisation (LAAO)'
                  }}
                </v-btn>

                <v-btn outlined text color="error" @click="resetConfig">Reset</v-btn>
              </div>
            </div>

            <div class="mt-4">
              <v-list-item-subtitle>Upload a legacy simulation</v-list-item-subtitle>
              <div class="mt-2">
                <UploadLegacySimulationButton :study="study" />
              </div>
            </div>
          </template>

          <template v-if="(!isFinished) && user.canUseAdvancedCaseOptions">
            <div class="mt-4" v-if="user.canChangeVisualisationObject">
              <v-list-item-subtitle>Configure Uploaded Geometries</v-list-item-subtitle>
              <div class="mt-2">
                <v-btn outlined text @click="configureGeometriesDialog = true">
                  Configure Geometries
                </v-btn>
              </div>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col class="pt-16">
      <h2>Orders</h2>
    </v-col>

    <v-col cols="12" v-for="(orderGroup, orderId) in analysesByOrderId" :key="orderId">
      <OrderAndAnalyses
        :order="orderGroup.order"
        :analyses="orderGroup.analyses"
        :study="study"
      />
    </v-col>
    <ConfigureGeometriesDialog
      v-if="configureGeometriesDialog"
      :definedConfig="study.configId"
      :disabled="!study.configId"
      :value="configureGeometriesDialog" />
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfigSelect from './ConfigSelect.vue';
import constants from '@/store/modules/studies/constants';
import caseConstants from '@/store/modules/cases/constants';
import OrderAndAnalyses from './OrderAndAnalyses.vue';
import UploadLegacySimulationButton from './UploadLegacySimulationButton.vue';
import UploadStudyResourceButton from './UploadStudyResourceButton.vue';
import StudyResource from './StudyResource.vue';
import ConfigureGeometriesDialog from './ConfigureGeometriesDialog.vue';

export default {
  name: 'StudyDetail',
  components: {
    ConfigSelect,
    OrderAndAnalyses,
    UploadLegacySimulationButton,
    UploadStudyResourceButton,
    StudyResource,
    ConfigureGeometriesDialog,
  },
  props: ['study'],
  data() {
    return {
      generateReportDialog: undefined,
      configureGeometriesDialog: undefined,
      // referred in template
      PSEUDONYMIZED_DICOM: constants.STUDY_RESOURCE_TYPES[constants.PSEUDONYMIZED_DICOM],
      VTKJS: constants.STUDY_RESOURCE_TYPES[constants.VTKJS],
    };
  },
  created() {
    this.$store.dispatch('listStudyResources', { study_id: this.study.id });
    this.$store.dispatch('listAnalyses', { study_id: this.study.id });
    this.$store.dispatch('listCustomVisualisationObjects', { study_id: this.study.id });
  },
  computed: {
    ...mapGetters(['hgcaseById', 'user']),
    pseudonymizedDicomImages() {
      return this.study.resources.filter((r) => r.type === constants.PSEUDONYMIZED_DICOM);
    },
    vtkResources() {
      return this.study.resources.filter((r) => r.type === constants.VTKJS);
    },
    pdfReports() {
      return this.study.resources.filter((r) => r.type === constants.PDFREPORT);
    },

    hgCase() {
      return this.hgcaseById(this.study.caseId);
    },

    intervention() {
      // Required to upload a legacy simulation, which requires the caseIdentifier
      return this.hgCase && this.hgCase.intervention;
    },

    isTransseptalPunctureHidden() {
      if (this.study.configOverride && this.study.configOverride.hideVisualisations) {
        return this.study.configOverride.hideVisualisations
          .includes('Transseptal Puncture');
      }
      return false;
    },
    orders() {
      const caseOrders = this.hgCase.orders;
      return caseOrders;
    },
    analysesByOrderId() {
      // Sort analyses by orderId
      const orders = {};
      const caseOrders = this.orders;
      // First populate with orders on the case
      caseOrders.forEach((order) => {
        orders[order.id] = {
          analyses: [],
          order,
        };
      });
      // Then add analyses
      this.study.analyses.forEach((analysis) => {
        // If the order is not found, add a dummy order
        if (!orders[analysis.orderId]) {
          orders[analysis.orderId] = {
            analyses: [],
            order: {
              serviceDisplay: 'Order Not Found',
              statusDisplay: analysis.orderId,
            },
          };
        }
        orders[analysis.orderId].analyses.push(analysis);
      });
      return orders;
    },
    hasDelivery() {
      const statuses = this.hgCase.orders.map((order) => order.status);
      return statuses.some((status) => status === caseConstants.ORDER_STATUSES.DELIVERED)
        || this.hgCase.status === caseConstants.CASE_STATUSES.REJECTED;
    },
    isFinished() {
      return [
        caseConstants.CASE_STATUSES.COMPLETED,
        caseConstants.CASE_STATUSES.CANCELED,
        caseConstants.CASE_STATUSES.REJECTED,
      ].includes(this.hgCase.status);
    },
  },
  methods: {
    hideTransseptalPuncture() {
      this.$store.dispatch('updateStudy', {
        id: this.study.id,
        configOverride: {
          hide_visualisations: [
            'Transseptal Puncture',
          ],
        },
      });
    },
    resetConfig() {
      this.$store.dispatch('updateStudy', {
        id: this.study.id,
        configOverride: {
          hide_visualisations: [],
        },
      });
    },
    hasDicom(resource) {
      return this.pseudonymizedDicomImages.some((r) => r.id === resource.attributes?.pseudoId);
    },
  },
};
</script>

<style lang="scss" scoped>
.gap-8px {
  gap: 8px;
}
</style>
