<template>
  <div>
    <v-dialog
      max-width="600px"
      :value="showShareCaseDialog"
      @input="emitInput"
      class="share-dialog-container"
      v-if="hgcase"
    >
      <template v-slot:activator="{on, attrs}">
        <div v-on="on" v-bind="attrs" class="share-dialog-trigger">
          <slot></slot>
        </div>
      </template>

      <v-card class="share-dialog">
        <v-card-title class="d-flex justify-space-between pr-3">Share {{ hgcase.caseIdentifier }}
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text class="pt-5 text--primary">
          <template v-if="hgcase.sharedWith.length && sharedUsers.length > 0">
            <div class="d-flex justify-space-between align-center mb-2">
              <p class="mb-0">The following have access to
                <strong>{{ hgcase.caseIdentifier }}</strong>
              </p>
              <v-btn text color="error" class="ml-4 revoke-all-btn"
                @click="revokeAll">Revoke all access</v-btn>
            </div>

            <v-list class="emails-list rounded" outlined>
              <v-list-item link dense v-for="user in sharedUsers" :key="user.sub">
                <v-list-item-title>{{ user.email }}</v-list-item-title>

                <span class="remove-access-btn my-3 ml-4" role="button" @click="revoke(user.sub)">
                  <span class="status-text text--disabled">Invited</span>
                  <span class="action-text error--text">Revoke Access</span>
                </span>
              </v-list-item>
            </v-list>

            <h3 class="mt-6 mb-4">Send Invites</h3>
          </template>

          <p>
            If the user has an active FHG account, they will be sent an email invite to view this
            case. You can specify multiple email addresses by pressing the <kbd>,</kbd> or
            <kbd>Enter</kbd> key.
          </p>

          <p>If the user's email address is unknown or any assistance is needed, please
            <router-link :to="{ name: 'contact' }">contact us</router-link>.
          </p>

          <section v-if="emails.length > 0" class="mb-4">
            <h4 class="text--secondary">Invites will be sent to</h4>

            <div class="d-flex flex-wrap gap-4px mt-2">
              <v-chip v-for="email in emails" :key="email" close color="primary" label
                @click:close="removeEmail(email)" clearable class="email-chip">
                {{ email }}
              </v-chip>
            </div>
          </section>

          <v-combobox
            class="email-input"
            outlined
            dense
            multiple
            hide-selected
            placeholder="Email"
            :delimiters="[',']"
            @input="resetErrors"
            :error-messages="errors"
            autofocus
            v-model="emails"
            :items="users"
            item-value="email"
            :return-object="false"
            :open-on-clear="false"
            :filter="filterUsers">
            <!-- specify an empty selection to replace default render -->
            <template v-slot:selection></template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}, <em>{{ item.device }} Specialist</em>
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-combobox>
        </v-card-text>

        <v-card-actions class="pb-6 d-flex justify-end">
          <v-btn
            class="cancel-share"
            color="error" text @click="close">Cancel</v-btn>
          <v-btn
            class="send-invites"
            color="primary" depressed :disabled="!emails.length"
            @click="sendInvites">Send Invites</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationDialog ref="confirm" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';

export default {
  name: 'ShareDialog',
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      errors: '',
      emails: [],
    };
  },
  created() {
  },
  computed: {
    ...mapGetters({
      deviceById: 'deviceById',
      userById: 'userById',
      hgcase: 'currentCase',
      showShareCaseDialog: 'showShareCaseDialog',
    }),
    myShowShareCaseDialog: {
      get() {
        return this.showShareCaseDialog;
      },
      set() {
        this.$store.dispatch('hideShareCaseDialog');
      },
    },
    allValidEmails() {
      return this.emails.every(this.isValidEmail);
    },
    sharedUsers() {
      return this.hgcase?.sharedWith.map(({ email, id }) => ({ email, sub: id }));
    },
    users() {
      const sharedUsers = this.sharedUsers?.map((user) => user.sub);
      if (!this.hgcase?.organisation) return [];
      const { specialisations } = this.hgcase.organisation;
      const deviceIds = this.hgcase.devices.all().map((d) => d.id);

      // filter specialists by people who haven't been shared to yet, and by device
      return (specialisations || [])
        .filter((spec) => !sharedUsers.includes(spec.userId) && deviceIds.includes(spec.deviceId))
        .map((spec) => ({
          ...this.userById(spec.userId),
          device: this.deviceById(spec.deviceId)?.name || '',
        }));
    },
  },
  methods: {
    close() {
      this.$store.dispatch('hideShareCaseDialog');
      this.resetErrors();
    },
    emitInput(newVal) {
      this.myShowShareCaseDialog = newVal;
    },
    filterUsers(item, queryText) {
      const { name, device, email } = item;
      const text = queryText.toLowerCase();
      return name.toLowerCase().indexOf(text) >= 0
        || email.toLowerCase().indexOf(text) >= 0
        || device.toLowerCase().indexOf(text) >= 0;
    },
    isValidEmail(v) {
      return (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(v);
    },
    removeEmail(email) {
      this.emails = this.emails.filter((e) => e !== email);
    },
    resetErrors() {
      this.errors = '';
    },
    async revoke(userSub) {
      const confirmation = await this.$refs.confirm.open(
        'Confirm',
        'Are you sure you want to revoke access?',
        { yesLabel: 'Yes, Revoke Access' },
      );

      if (!confirmation) return;
      this.$store.dispatch('unShareCase', { caseId: this.hgcase.id, userId: userSub });
    },
    async revokeAll() {
      const confirmation = await this.$refs.confirm.open(
        'Confirm',
        'Are you sure you want to revoke all access? Accepted users will no longer be able to access this case, and pending invites will no longer work.',
        { yesLabel: 'Yes, Revoke All Access' },
      );

      if (!confirmation) return;
      this.sharedUsers.forEach((u) => this.$store.dispatch('unShareCase',
        { caseId: this.hgcase.id, userId: u.sub }));
    },
    sendInvites() {
      Promise.all(
        this.emails.map((email) => this.$store
          .dispatch('shareCase', { id: this.hgcase.id, email })),
      ).then(() => {
        this.$toasted.global.success({ message: 'Successfully shared case' });
        this.emails = [];
        this.close();
      }).catch((error) => {
        this.errors = error.response?.data?.email || 'Error';
        this.$toasted.global.error({ error });
      });
    },
  },
  watch: {
    allValidEmails(newVal) {
      if (newVal) return;
      this.emails = this.emails.filter(this.isValidEmail);
      this.errors = 'Invalid email address';
    },
  },
};
</script>

<style lang="scss" scoped>
$border: rgba(#000, 0.24);

.gap-4px {
  gap: 4px;
}

.remove-access-btn {
  display: inline-block;
  text-align: right;
  white-space: nowrap;

  .status-text {
    font-style: italic;
  }

  &:hover .status-text {
    display: none;
  }

  &:not(:hover) .action-text {
    display: none;
  }
}

.emails-list {
  max-height: 156px; // 3 items
  overflow-y: auto;
}
</style>
