<template>
  <span>
    <v-btn
      color="primary"
      outlined
      small
      @click="dialog=true"
    >
      <v-icon left>
        add
      </v-icon>
      Add Device
    </v-btn>
    <AddDeviceDialog
      v-if="dialog"
      :value="dialog"
      @close="dialog=false"
      @click:outside="dialog=false"
      :organisation='organisation'
      :trial='trial'
      :deviceAccessGroup='deviceAccessGroup'
    />
  </span>
</template>

<script>
import AddDeviceDialog from './AddDeviceDialog.vue';

export default {
  name: 'AddDeviceButton',
  props: [
    'organisation',
    'deviceAccessGroup',
    'trial',
  ],
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    AddDeviceDialog,
  },
};
</script>
