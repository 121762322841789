import Vue from 'vue';

import * as types from '@/store/mutation-types';

import initialState from './state';

export default {
  [types.INTERVENTIONS_UPDATE](state, incomingInterventions) {
    state.interventions = { ...state.interventions, ...incomingInterventions };
  },
  [types.INTERVENTION_UPDATE](state, { id, properties }) {
    if ('id' in properties && properties.id !== id) {
      throw Error(`ID mismatch. INTERVENTION_UPDATE on "${id}" has "${properties.id}" as property`);
    }
    if (!(id in state.interventions)) {
      throw Error(`INTERVENTION_UPDATE has invalid id. Object not found for "${id}"`);
    }
    Object.entries(properties).forEach(([key, value]) => {
      Vue.set(state.interventions[id], key, value);
    });
  },
  [types.DEVICES_CLEAR](state) {
    Vue.set(state, 'devices', {});
  },
  [types.DEVICES_UPDATE](state, incomingDevices) {
    state.devices = { ...state.devices, ...incomingDevices };
  },
  [types.DEVICE_UPDATE](state, { id, properties }) {
    if ('id' in properties && properties.id !== id) {
      throw Error(`ID mismatch. DEVICE_UPDATE on "${id}" has "${properties.id}" as property`);
    }
    if (!(id in state.devices)) {
      throw Error(`DEVICE_UPDATE has invalid id. Object not found for "${id}"`);
    }
    Object.entries(properties).forEach(([key, value]) => {
      Vue.set(state.devices[id], key, value);
    });
  },
  [types.DEVICE_VARIANTS_CLEAR](state) {
    Vue.set(state, 'deviceVariants', {});
  },
  [types.DEVICE_VARIANTS_UPDATE](state, incomingDeviceVariants) {
    state.deviceVariants = { ...state.deviceVariants, ...incomingDeviceVariants };
  },
  [types.DEVICE_VARIANT_UPDATE](state, { id, properties }) {
    if ('id' in properties && properties.id !== id) {
      throw Error(`ID mismatch. DEVICE_VARIANTS_UPDATE on "${id}" has "${properties.id}" as property`);
    }
    if (!(id in state.deviceVariants)) {
      throw Error(`DEVICE_VARIANT_UPDATE has invalid id. Object not found for "${id}"`);
    }
    Object.entries(properties).forEach(([key, value]) => {
      Vue.set(state.deviceVariants[id], key, value);
    });
  },
  [types.RESET_STATE_INTERVENTIONS_MODULE](state) {
    Object.assign(state, initialState());
  },
};
