<template>
  <p class="mb-0">{{ truncated }}
    <button type="button" class="ml-3" @click="expand = !expand"
      v-if="!textShorterThanCharLimit">
      {{ expand ? 'See Less' : 'Read More' }}
    </button>
  </p>
</template>

<script>
export default {
  name: 'ExpandCollapseText',
  props: {
    text: {
      type: String,
      required: true,
    },
    charLimit: {
      type: Number,
      default: 80,
    },
  },
  data: () => ({
    expand: false,
  }),
  computed: {
    truncated() {
      const text = this.text || '';
      if (this.expand || this.textShorterThanCharLimit) return text;
      return `${text.substr(0, this.charLimit).trim()}...`;
    },
    textShorterThanCharLimit() {
      return this.text && (this.text.length <= this.charLimit);
    },
  },
};
</script>

<style scoped>
button {
  font-size: 0.8em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}

button:hover {
  color: var(--v-info-base);
}
</style>
