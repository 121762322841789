import Cookies from 'js-cookie';

const GOOGLE_ANALYTICS_KEYS = ['_ga', '_gid', '_gat'];
const GA_ACCEPTED_KEY = 'GA_ACCEPTED';
const EXPIRES = 365; // in days

const DOMAIN = process.env.VUE_APP_COOKIES_DOMAIN;
// A cookie with the Secure attribute is only sent to the server
// with an encrypted request over the HTTPS protocol.
// Always set to true, so it works in cypress tests in combination with the sameSite attribute.
const SECURE = true;
// The SameSite attribute of a cookie is used to prevent the browser
// from sending this cookie along with cross-site requests.
// The value of the attribute can be Strict, Lax (default), or None.
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
// We set it to `None` because the site will be loaded as a third-party iframe.
const SAME_SITE = 'None';

export const clearCookies = () => {
  // Remove cookie policy acceptance
  Cookies.remove(GA_ACCEPTED_KEY, { domain: DOMAIN });

  // Remove Google Analytics cookies
  if (window.ga) window.ga('remove');
  GOOGLE_ANALYTICS_KEYS.forEach((key) => Cookies.remove(key, { domain: DOMAIN }));
};

export const getCookie = (key) => Cookies.get(key, { domain: DOMAIN });

export const setCookies = ({ googleAnalyticsCookie }) => {
  Cookies.set(
    GA_ACCEPTED_KEY,
    googleAnalyticsCookie,
    {
      expires: EXPIRES,
      domain: DOMAIN,
      secure: SECURE,
      sameSite: SAME_SITE,
    },
  );
};

export const googleAnalyticsCookieSet = () => getCookie(GA_ACCEPTED_KEY) !== undefined;

export const allowGoogleAnalytics = () => setCookies({ googleAnalyticsCookie: true });

export const rejectGoogleAnalytics = () => setCookies({ googleAnalyticsCookie: false });

export const googleAnalyticsIsAllowed = () => getCookie(GA_ACCEPTED_KEY) === 'true';
