<template>
  <div>
    <div :class="{'mb-4': !rejected}">
      <div v-if="simulationDeviceNames.length == 0" class="text-h6">
        <template v-if="otherDeviceNames.length <= 1">{{ otherDeviceNames.join(', ') }}</template>
        <v-tooltip v-else bottom max-width="240">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">Multiple Devices</span>
          </template>
          {{ otherDeviceNames.join(', ') }}
        </v-tooltip>
      </div>
      <div v-else>
        <div class="text-h6">
          <template v-if="simulationDeviceNames.length <= 1">
            {{ simulationDeviceNames.join(', ') }}
          </template>
          <v-tooltip v-else bottom max-width="240">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">Multiple Devices</span>
            </template>
            {{ simulationDeviceNames.join(', ') }}
          </v-tooltip>
        </div>
      </div>
      <div v-if="hgcase.deviceVariantNames.length > 0">
        {{ hgcase.deviceVariantNames.join(', ') }}
      </div>
    </div>

    <div class="mb-4" v-if="!rejected">
      <div v-for="service in hgcase.services.orderBy('id')" :key="service.id"
        class="d-flex justify-space-between align-center">
        <span>
          {{ service.name }}
          <NonClinicalTooltip v-if="!service.isClinical" >
            {{ service.degradationRemark}}
          </NonClinicalTooltip>
          :
        </span>
        <span :class="service.color+`--text`">
          {{ service.label }}
        </span>

      </div>
      <div v-if="hgcase.hasNoSimulationOrder && !hgcase.hasLegacyOrder">
        <div
          v-if="hgcase.simulationLicenseAvailable"
          class="my-2 d-flex justify-space-between align-center"
        >
          simulation:
          <span>Not requested
            <v-btn
              v-if="$store.getters.user.canAddOrder"
              @click="$store.dispatch('setOrderSimulationDialog', {show: true, caseId: hgcase.id})"
              class="ml-1" color="primary" outlined x-small
            >
              Order
            </v-btn>
          </span>
        </div>
        <div v-else
          class="my-2 d-flex justify-space-between align-center">
          simulation:
          <span>No license available
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NonClinicalTooltip from '@/components/common/NonClinicalTooltip.vue';

export default {
  name: 'ServiceAndOrder',
  components: {
    NonClinicalTooltip,
  },
  props: [
    'hgcase',
    'rejected',
  ],
  computed: {
    otherDevices() {
      return this.devices.filter(
        (device) => !Object.keys(this.hgcase.simulationDevices).includes(device.id),
      );
    },
    simulationDevices() {
      return this.devices.filter(
        (device) => Object.keys(this.hgcase.simulationDevices).includes(device.id),
      );
    },
    devices() {
      return this.hgcase.devices.orderBy('name');
    },
    simulationDeviceNames() {
      return this.simulationDevices.map((device) => device.name);
    },
    otherDeviceNames() {
      return this.otherDevices.map((device) => device.name);
    },
  },
};
</script>
