/* eslint-disable import/no-named-as-default-member */
import * as THREE from 'three';
import vtkMatrixBuilder from '@kitware/vtk.js/Common/Core/MatrixBuilder';
import vtkPlane from '@kitware/vtk.js/Common/DataModel/Plane';
import vtkAngleWidget from '@kitware/vtk.js/Widgets/Widgets3D/AngleWidget';
import vtkSplineTubeWidget from '@/components/DICOM/vtkUtils/SplineTubeWidget';
import vtkSplineTubeCutterWidget from '@/components/DICOM/vtkUtils/SplineTubeCutterWidget';
import constants from './constants';

// Added magic values. These make 'matrixFromDirections' work for all viewTypes
// and I don't know how.
// Mimics has the sagittal normal as [-1, 0, 0]
// const originalPlanes = {
//  4: { normal: [1, 0, 0], viewUp: [0, 0, 1], magic: [-1, 0, 0] },
//  5: { normal: [0, -1, 0], viewUp: [0, 0, 1], magic: [0, 1, 0] },
//  6: { normal: [0, 0, -1], viewUp: [0, -1, 0], magic: [0, 0, -1] },
// };

const getResliceRotation = (planes) => [
  ...planes[constants.SAGITTAL].normal, 0,
  ...planes[constants.CORONAL].normal.map((num) => num * -1), 0,
  ...planes[constants.AXIAL].normal.map((num) => num * -1), 0,
  0, 0, 0, 1,
];

const matrixFromDirections = (normal, viewType) => {
  // TODO: 'Magic' seems to be simply the inverted normal for the viewtype.
  const planes = {
    4: { normal: [1, 0, 0], viewUp: [0, 0, 1], magic: [-1, 0, 0] },
    5: { normal: [0, -1, 0], viewUp: [0, 0, 1], magic: [0, 1, 0] },
    6: { normal: [0, 0, -1], viewUp: [0, -1, 0], magic: [0, 0, 1] },
  };
  const transformMatrix = vtkMatrixBuilder.buildFromDegree();
  transformMatrix.rotateFromDirections(planes[viewType].magic, normal);
  return transformMatrix;
};

const matrixFromResliceRotation = (resliceRotation) => {
  const transformMatrix = vtkMatrixBuilder.buildFromDegree();
  transformMatrix.setMatrix(resliceRotation);
  return transformMatrix;
};

const rotatePlanes = (rotationMatrix, viewType) => {
  const planes = {
    4: { normal: [1, 0, 0], viewUp: [0, 0, 1], magic: [-1, 0, 0] },
    5: { normal: [0, -1, 0], viewUp: [0, 0, 1], magic: [0, 1, 0] },
    6: { normal: [0, 0, -1], viewUp: [0, -1, 0], magic: [0, 0, 1] },
  };
  if (rotationMatrix) {
    rotationMatrix.apply(planes[4].normal);
    rotationMatrix.apply(planes[5].normal);
    rotationMatrix.apply(planes[6].normal);
    if (viewType) {
      rotationMatrix.apply(planes[viewType].viewUp);
    }
  }
  return planes;
};

const checkLandmarkVisibility = (landmark, widget) => {
  if (!landmark.isEnabled) {
    window.landmarkStore[landmark.id].vtkWidget.setVisibility(false);
  } else if (landmark.geometryType === constants.CLOSED_CURVE
      || landmark.geometryType === constants.OPEN_CURVE
  ) {
    window.landmarkStore[landmark.id].vtkWidget.setVisibility(true);
  } else {
    const origin = widget.getWidgetState().getCenter();
    const planeNormal = widget.getWidgetState().getPlanes()[
      landmark.viewType || constants.AXIAL
    ].normal;
    let areOnPlane = true;
    const epsilon = 0.5;
    let { points } = landmark;
    if (landmark.geometryType === constants.ANGLE) {
      points = points.slice(0, 3);
    }
    areOnPlane = points
      .filter((point, index) => (index % 1) === 0)
      .every((point) => Math.abs(vtkPlane.evaluate(
        planeNormal, origin, point,
      )) < epsilon);
    window.landmarkStore[landmark.id].vtkWidget.setVisibility(areOnPlane);
  }
};

const generateWidgetFrom = (landmark) => {
  /* Raises linting error */
  let splineWidget;
  if (
    landmark.geometryType === constants.CLOSED_CURVE
    || landmark.geometryType === constants.OPEN_CURVE
  ) {
    splineWidget = vtkSplineTubeCutterWidget.newInstance();
    if (landmark.geometryType === constants.OPEN_CURVE) {
      splineWidget.getWidgetState().setSplineClosed(false);
    }
  } else if (landmark.geometryType === constants.ANGLE) {
    splineWidget = vtkAngleWidget.newInstance();
  } else {
    splineWidget = vtkSplineTubeWidget.newInstance();
  }
  let landmarkPoints = landmark.points;
  if (landmark.geometryType === constants.ANGLE) {
    landmarkPoints = landmark.points.slice(0, 3);
    splineWidget.getManipulator().setUserOrigin(landmark.points[1]);
  } else {
    splineWidget.getManipulator().setUserOrigin(landmark.centroid);
  }
  landmarkPoints.forEach((point) => {
    const lastHandle = splineWidget.getWidgetState().addHandle();
    lastHandle.setOrigin(...point);
    lastHandle.setScale1(0);
    lastHandle.setVisible(true);
  });
  splineWidget.setPickable(false);
  return splineWidget;
};

const generateMeshFrom = (landmark, roiCenter) => {
  let closed = true;
  // Default colour set for 3D curves
  const material = new THREE.MeshLambertMaterial({ color: 0xffff00 });
  const landmarkPoints = landmark.points;

  if (landmark.geometryType === constants.OPEN_CURVE) {
    material.color.setHex(0xff0000);
    closed = false;
  } else if (landmark.geometryType === constants.AREA) {
    material.color.setHex(0x22ff22);
  } else if (landmark.geometryType === constants.DISTANCE) {
    material.color.setHex(0x2222ff);
  } else if (landmark.geometryType === constants.ANGLE) {
    material.color.setHex(0xeeee00);
    closed = false;
  }

  // make custom landmarks only have one color
  if (!landmark.landmarkTypeId) {
    material.color.setHex(0xe96922); // primary-color
  }

  if (landmark.color) {
    material.color.setStyle(landmark.color);
  }

  const path = landmarkPoints.map((point) => (new THREE.Vector3(
    ...point,
  )));
  let curve;
  if (landmark.geometryType === constants.ANGLE) {
    curve = new THREE.CurvePath();
    curve.add(new THREE.LineCurve3(path[0], path[1]));
    curve.add(new THREE.LineCurve3(path[1], path[2]));
  } else {
    curve = new THREE.CatmullRomCurve3(path, closed);
  }
  const tubeGeometry = new THREE.TubeGeometry(curve, 100, 0.5, 10, closed);
  const mesh = new THREE.Mesh(tubeGeometry, material);
  mesh.name = landmark.id;
  mesh.visible = landmark.landmarkTypeId === null;
  // Never show angles in 3D
  if (landmark.geometryType === constants.ANGLE) {
    mesh.visible = false;
  }
  if (roiCenter) {
    mesh.translateX(-roiCenter[0]);
    mesh.translateY(-roiCenter[1]);
    mesh.translateZ(-roiCenter[2]);
  }
  return mesh;
};

const prepareLandmark = (landmark, landmarkType, roiCenter) => {
  // Sets the landmark's displayName, viewType and geometryType
  // Generates vtk and three js objects
  const landmarkDisplay = {
    displayName: landmark.name,
    viewType: landmark.viewType,
    geometryType: landmark.geometryType,
  };
  if (landmarkType) {
    landmarkDisplay.viewType = landmark.viewType || landmarkType.viewType;
    landmarkDisplay.geometryType = landmark.geometryType || landmarkType.geometryType;
    landmarkDisplay.displayName = landmarkType.name;
    landmarkDisplay.color = landmarkType.color;
  }
  const preparedLandmark = {
    ...landmark,
    isVisible: true,
    isEnabled: true,
    ...landmarkDisplay,
  };
  const vtkWidget = generateWidgetFrom(preparedLandmark);
  const threeJsMesh = generateMeshFrom(preparedLandmark, roiCenter);
  if (window.landmarkStore) {
    window.landmarkStore[landmark.id] = {
      threeJsMesh,
      vtkWidget,
    };
  }
  return {
    ...preparedLandmark,
  };
};

export {
  getResliceRotation,
  matrixFromDirections,
  matrixFromResliceRotation,
  rotatePlanes,
  checkLandmarkVisibility,
  generateWidgetFrom,
  generateMeshFrom,
  prepareLandmark,
};
