<template>
  <v-card outlined>
    <template v-if="trial">
      <v-card-title >
        Trial License
      </v-card-title>
      <v-card-subtitle>
        Cases: {{ trial.numberOfCases }}/{{ trial.limit }}
      </v-card-subtitle>
    </template>
    <v-card-title v-else>
      Devices
    </v-card-title>
    <v-divider />
    <v-card-text>
      <div
        class="mb-6"
        v-for="intervention in groupedByIntervention" :key="intervention.id"
      >
        <h6 class="text-h6">{{ intervention.prefix }}</h6>

        <div
          class="px-4 mb-4"
          :key="device.id"
          v-for="device in intervention.devices"
        >
          <div class="secondary--text">
            {{ device.name }}
            <v-btn icon
              @click="deleteDevice(device)"
              :disabled="!device.isManual && !device.isTrial"
              v-if="$store.getters.user.canEditLicense"
            >
              <v-icon small color="error">delete</v-icon>
            </v-btn>
          </div>
          <div v-for="service in device.services" :key="service.id">
            {{ SERVICE_NAMES[service.service] }}:
            <span v-if="service.isClinical">
              clinical use
            </span>
            <span v-else class="warning--text">
              non-clinical use only
            </span>
          </div>
        </div>
      </div>
      <AddDeviceButton :organisation="organisation" :trial='trial'
        v-if="$store.getters.user.canEditLicense" />
    </v-card-text>
    <v-card-actions class="px-6 pa-4"
      v-if="trial && $store.getters.user.canEditLicense">
      <v-spacer />
      <v-btn color="error" @click="deleteTrial" depressed small>
        <v-icon left>
          delete
        </v-icon>
        delete trial license
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AddDeviceButton from './AddDeviceButton.vue';
import { devicesFromAccesses } from '@/store/modules/licenses/utils';

export default {
  name: 'LicenseCard',
  components: {
    AddDeviceButton,
  },
  props: ['deviceAccesses', 'trial', 'organisation'],
  data() {
    return {
      SERVICE_NAMES: Object.freeze(['Anatomical analysis', 'Simulation', 'Results']),
    };
  },
  computed: {
    interventions() {
      return this.$store.getters.interventions;
    },
    devices() {
      return devicesFromAccesses(this.deviceAccesses)
        .map((d) => ({ ...d, ...this.$store.getters.deviceById(d.deviceId) }));
    },
    groupedByIntervention() {
      return this.interventions
        .map((intervention) => ({
          ...intervention,
          devices: this.devices.filter((d) => d.interventionId === intervention.id),
        }))
        .filter((i) => i.devices.length > 0);
    },
  },
  methods: {
    deleteDevice(device) {
      device.deviceAccesses
        .forEach((dA) => {
          this.$store.dispatch('deleteDeviceAccess', { deviceAccess: dA, organisationId: this.organisation.id })
            .then(() => this.$store.dispatch('updateOrganisationLicense', { id: this.organisation.id }));
        });
    },
    deleteTrial() {
      this.$store.dispatch('deleteTrialLicense', { trial: this.trial })
        .then(() => this.$store.dispatch('updateOrganisationLicense', { id: this.organisation.id }));
    },

  },
};
</script>
