import * as types from '@/store/mutation-types';

import initialState from './state';

export default {
  [types.TRAININGS_REQUEST]: (state) => {
    state.loading = true;
  },
  [types.TRAININGS_SUCCESS]: (state, trainings) => {
    state.trainings = trainings;
    state.loading = false;
  },
  [types.RESET_STATE_TRAININGS_MODULE](state) {
    Object.assign(state, initialState());
  },
};
