/* eslint-disable */
import * as THREE from 'three';
require('./PLYLoader');

class Human {
  constructor() {
    this.loader = new THREE.PLYLoader();
    this.loadObject();
  }

  loadObject() {
    this.loader.load(
      '/models/human_compr3.ply',
      (loadedObject) => {
        this.mesh = loadedObject;
      },
      () => {},
      (error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      },
    );
  }
}

export default Human;