<template>
  <v-dialog v-model="showDialog" max-width="720px">
    <template v-slot:activator="{on, attrs}">
      <div v-on="on" v-bind="attrs">
        <slot></slot>
      </div>
    </template>

    <v-card>
      <v-card-title>Add new organisation
        <v-btn class="ml-auto" icon type="button" @click="showDialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4">
        <v-form v-model="isValid" ref="orgForm">
          <v-alert type="error" class="mb-4" v-if="otherErrors.length">
            <span>
              <strong>Error:</strong>
              <ul>
                <li v-for="(error, key) in otherErrors" :key="key" >
                  <span>
                    {{ error[0] }}
                  </span>
                </li>
              </ul>
            </span>
          </v-alert>
          <v-row>
            <v-col sm="8">
              <v-text-field v-model="form.name" :rules="[isRequired]" counter maxlength="255"
                outlined dense :error-messages="errors.name">
                <template v-slot:label>
                  Name <span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.code" :rules="[isAZ]" maxlength="3" counter
                :error-messages="errors.code" outlined dense
                hint="leave blank to autogenerate">
                <template v-slot:label>
                  Code
                </template>
              </v-text-field>
            </v-col>
            <v-col sm="6">
              <v-autocomplete :items="countries" item-value="code" item-text="name"
                v-model="form.country" label="Country" :rules="[isRequired]"
                :error-messages="errors.country" outlined dense>
                <template v-slot:label>
                  Country <span class="red--text">*</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col sm="6">
              <v-autocomplete :items="regions" item-value="id" item-text="name"
                :rules="[isRequired]" v-model="form.regionId" :error-messages="errors.regionId"
                outlined dense>
                <template v-slot:label>
                  Region <span class="red--text">*</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-checkbox v-model="form.hasConfidentialityAgreement" hide-details class="mt-0"
                label="Has confidentiality agreement" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex justify-end pa-4">
        <v-btn text @click="showDialog = false">Cancel</v-btn>
        <v-btn text color="primary" :disabled="!isValid" @click="submit">Create Organisation</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import countries from '@/utils/countries';

export default {
  name: 'CreateOrganisationDialog',
  data() {
    return {
      showDialog: false,
      errors: {},
      form: {
        code: undefined,
        country: null,
        hasConfidentialityAgreement: false,
        name: null,
        regionId: null,
      },
      countries,
      isValid: false,
    };
  },
  methods: {
    isEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    isRequired: (v) => !!v || 'This field is required',
    isAZ: (v) => !v || /^[A-Z]{3}$/.test(v) || 'Must be 3 capital letters.',
    resetFieldValidation(key) {
      if (!this.errors || !this.errors[key]) return;
      delete this.errors[key];
    },
    resetForm() {
      this.form = {
        code: undefined,
        country: null,
        hasConfidentialityAgreement: false,
        name: null,
        regionId: null,
      };
      this.errors = {};
      this.$refs.orgForm.resetValidation();
    },
    submit() {
      // Remove code if empty
      if (this.form.code === '') {
        this.form.code = undefined;
      }

      this.$store.dispatch('createOrganisation', this.form)
        .then(() => {
          this.$toasted.global.success({ message: 'Organisation successfully created' });
          this.resetForm();
          this.showDialog = false;
          this.$emit('success');
        })
        .catch((error) => {
          this.errors = error.response?.data;
        });
    },
  },
  computed: {
    regions() {
      return this.$store.getters.regions;
    },
    otherErrors() {
      const errors = [];
      if (this.errors.nonFieldErrors) {
        errors.push(this.errors.nonFieldErrors);
      }
      if (this.errors.distributorId) {
        errors.push(this.errors.distributorId);
      }
      return errors.filter((error) => error);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.code': function () {
      this.resetFieldValidation('code');
    },
  },
};
</script>
