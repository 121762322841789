<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="$store.dispatch('downloadStudyResource', { id: hgcase.study.pdfReportId })"
        icon v-bind="attrs" v-on="on"
      >
        <v-icon>
          picture_as_pdf
        </v-icon>
      </v-btn>
    </template>
    Download the report.
  </v-tooltip>
</template>

<script>

export default {
  name: 'ReportButton',
  props: ['hgcase'],
};
</script>
