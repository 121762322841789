<template>
<!--
  Because this is a wrapper component around v-dialog
  we need to pass the signals to the parent components with v-on="$listeners"
-->
  <v-dialog
    :value="value"
    @click:outside="hide"
    v-on="$listeners"
    max-width="650px"
  >
    <v-form
      @submit.native.prevent="addDevice"
      v-model="isValid">
      <v-card >
        <v-card-title class="text-h5 justify-center">
          Add a device
        </v-card-title>
        <v-divider />
        <v-card-text class="px-6">
          <v-select
            outlined
            dense
            label="Device"
            :items="devices"
            item-text="name"
            item-value="id"
            v-model="form.deviceId"
            :rules="[isRequired]"
          />

          <p class="mb-0 text-overline">Services</p>
          <div class="no-gutters my-auto pb-2"
            v-for="service, index in form.services" :key="service.id">
            <v-row>
              <v-col>
                <v-checkbox
                  :disabled="!!!form.deviceId "
                  v-model="form.services[index].enabled"
                  :label="constants.SERVICE_NAMES[service.id]"
                />
              </v-col>
              <v-col>
                <v-checkbox
                  :ripple="false"
                  :disabled="!!!form.deviceId || !form.services[index].enabled"
                  v-model="form.services[index].isClinical"
                  label="Clinical use"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-alert class="text--body2" dense outlined type="warning" v-if="!service.isClinical">
              All results will be indicated as non-clinical.
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions class="px-6 pa-4">
          <v-spacer />
          <v-btn outlined @click="hide">
            Close
          </v-btn>
          <v-btn color="primary" depressed :disabled="!canAdd" type="submit">
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import constants from '@/store/modules/cases/constants';

export default {
  name: 'AddDeviceDialog',
  components: {
  },
  props: [
    'value',
    'organisation',
    'trial',
    'deviceAccessGroup',
  ],
  data: () => ({
    dialog: false,
    isValid: false,
    form: {
      deviceId: undefined,
      services: [
        {
          id: constants.SERVICE.ANATOMICAL,
          isClinical: true,
          enabled: true,
        },
        {
          id: constants.SERVICE.SIMULATION,
          isClinical: true,
          enabled: true,
        },
      ],
    },

    isRequired: (v) => !!v || 'This field is required',
  }),
  computed: {
    constants() {
      return constants;
    },

    devices() {
      if (this.organisation) {
        return this.$store.getters.devices
          .filter((d) => !this.organisation.deviceAccesses
            .map((dA) => dA.deviceId)
            .includes(d.id));
      }
      return this.$store.getters.devices
        .filter((d) => !this.deviceAccessGroup.deviceAccesses
          .map((dA) => dA.deviceId)
          .includes(d.id));
    },
    organisationId() {
      // Pass an organisation for custom device access to an organisation
      if (this.organisation) {
        return this.organisation.id;
      }
      return null;
    },
    deviceAccessGroupId() {
      // Pass the trial ID device access that belongs to a trial organisation
      if (this.deviceAccessGroup) {
        return this.deviceAccessGroup.id;
      }
      return null;
    },
    trialId() {
      // Pass the trial ID device access that belongs to a trial organisation
      if (this.trial) {
        return this.trial.id;
      }
      return null;
    },

    canAdd() {
      // Must select at least anatomical
      return this.isValid && this.form.services
        .some((s) => s.enabled && s.id === this.constants.SERVICE.ANATOMICAL);
    },
  },
  methods: {
    hide() {
      this.$emit('close');
    },
    addDevice() {
      this.form.services
        .filter((service) => service.enabled)
        .forEach((service) => {
          this.$store.dispatch('createDeviceAccess', {
            deviceAccess: {
              // Service and Device:
              isClinical: service.isClinical,
              deviceId: this.form.deviceId,
              service: service.id,

              // Access to:
              organisationId: this.organisationId,
              deviceAccessGroupId: this.deviceAccessGroupId,
              trialId: this.trialId,
            },
          });
        });
      this.hide();
    },
  },
};
</script>
