<template>
  <div>
    <v-menu dark offset-y style="z-index: 200">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text height="auto" v-bind="attrs" v-on="on" class="text-body-2 user-menu-button">
          <v-gravatar
            :email="$store.getters.user.email"
            :size="40"
            default-img="mm"
            class="mr-2"
            />
          <div class="text-left">
            <div class="text-h6 text-none" style="line-height: normal;">{{ userNameDisplay }}</div>
            <div class="text-subtitle-2 text-none secondary--text text--lighten-5">
              <span v-if="$store.getters.user.hasRole">
                {{ $store.getters.user.roles[0] }}
              </span>@ {{ organisationDisplay }}
            </div>
          </div>
          <v-icon class="ml-4" color="white">expand_more</v-icon>
        </v-btn>
      </template>
      <v-list class="links-list">
        <v-list-item-group>
          <template v-if="!insideIframe">
            <v-list-item
              v-if="$store.getters.user.hasRole"
              :to="{
                name: 'cases',
                query: {
                  org: $store.state.cases.casesPage.organisationId,
                  search: $store.state.cases.casesPage.searchTerm,
                },
              }"
            >
              <v-list-item-title >
                Analyses
              </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'settings' }" v-if="$store.getters.user.hasRole">
              <v-list-item-title class="settings-link">
                Settings
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$store.getters.user.hasRole"
              :to="{ name: 'licenses', query: { org: $store.getters.selectedOrganisationId } }"
            >
              <v-list-item-title>
                Licenses
              </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'templates'}" v-if="$store.getters.user.canAddTemplate">
              <v-list-item-title>
                License templates
              </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'users-and-organisations'}"
              v-if="$store.getters.user.canEditUsersAndOrganisations">
              <v-list-item-title>
                Users & Organisations
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item :to="{ name: 'documentation' }"
            v-if="$store.getters.user.hasRole">
            <v-list-item-title>
              Documentation
            </v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'contact' }">
            <v-list-item-title>
              Contact Us
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-if="!insideIframe">
        <v-divider class="secondary lighten-1"></v-divider>
        <v-list class="logout-list">
          <v-list-item-group>
            <v-list-item @click.prevent="logout">
              <v-list-item-title>
                Log out
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </v-menu>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'UserMenu',
  computed: {
    ...mapGetters([
      'adminUrl',
      'userNameDisplay',
    ]),
    ...mapState({
      organisationDisplay: (state) => {
        if (state.auth.user.primaryOrganisation) {
          return state.auth.user.primaryOrganisation.name;
        }
        return 'Organisation pending';
      },
      insideIframe: (state) => state.layout.insideIframe,
    }),
  },
  methods: {
    async logout() {
      await this.$router.push({ name: 'login' });
      this.$store.dispatch('logout');
    },
  },
};
</script>
