<template>
  <v-card class="d-flex flex-column relative" outlined height="100%">
    <v-row
      v-if="order" class="ma-0"
      :class="order.delivery && order.delivery.isObsoleted && 'text-decoration-line-through'"
    >
      <v-col cols="12" md="5" class="pa-0">
        <v-card-title
          class="text-capitalize"
        >
          {{ order.serviceDisplay }} - {{ order.statusDisplay }}
          <NonClinicalTooltip v-if="!order.isClinical">
            {{ order.degradationRemark }}
          </NonClinicalTooltip>
        </v-card-title>

        <v-card-subtitle class="pt-0" v-if="latestReport">
          <v-list-item-title>
            <a :href="latestReport.readUrl">Download latest report</a>
          </v-list-item-title>
          <v-list-item-subtitle>
            Uploaded: {{ latestReport.dateCreated|localeDateTime }}
          </v-list-item-subtitle>
        </v-card-subtitle>
      </v-col>
      <v-col cols="12" md="7" class="pa-0">
        <div class="buttons px-4 py-md-4 pb-4 pb-md-0 d-flex align-center justify-md-end flex-wrap
          my-auto">
          <MarkAsNonClinicalDialog v-if="showMarkAsClinicalDialog" :order="order" />
          <CreateDeliveryDialog v-if="showDeliveryDialog" :order="order" :studyId="study.id" />
          <RemoveDeliveryDialog v-if="showUnDeliverDialog" :order="order" />

          <template
            v-if="user.canManageReports && !order.delivery && hgCaseStatus === CASE_IN_PROGRESS"
          >
            <v-btn small outlined text @click="generateReportDialog = true">Generate Report</v-btn>
            <UploadStudyResourceButton :study="study" buttonLabel="Upload Custom Report"
              :buttonSmall="true" :orderId="order.id" />
          </template>

          <template
            v-if="order.serviceDisplay === 'simulation'
              && order.delivery
              && !order.delivery.isObsoleted"
          >
            <DuplicateOrderDialog :order="order" v-if="showDuplicateOrderDialog" />
            <ObsoleteDeliveryDialog :order="order" v-if="showObsoleteDeliveryDialog" />
          </template>
        </div>
      </v-col>
    </v-row>

    <v-card-title v-else>No Order</v-card-title>

    <template v-if="analyses.length > 0">
      <v-divider />

      <v-card-text class="grey lighten-4">
        <v-expansion-panels multiple>
          <AnalysisDetail v-for="analysis in analyses"
            :key="analysis.id"
            :analysis="analysis"
            :order="order"
            :canUploadImages="canUploadFigures" />
        </v-expansion-panels>
      </v-card-text>
    </template>

    <GenerateReportDialog
      v-if="generateReportDialog"
      v-model="generateReportDialog"
      :study="study"
      :orderId="order.id"
      :withSimulations="order.serviceDisplay == 'simulation'"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import AnalysisDetail from '@/components/pages/CaseDetailPage/AnalysisDetail.vue';
import constants from '@/store/modules/cases/constants';
import CreateDeliveryDialog from '@/components/pages/CaseDetailPage/CreateDeliveryDialog.vue';
import DuplicateOrderDialog from '@/components/pages/CaseDetailPage/DuplicateOrderDialog.vue';
import GenerateReportDialog from '@/components/pages/CaseDetailPage/GenerateReportDialog.vue';
import MarkAsNonClinicalDialog from '@/components/pages/CaseDetailPage/MarkAsNonClinicalDialog.vue';
import NonClinicalTooltip from '@/components/common/NonClinicalTooltip.vue';
import ObsoleteDeliveryDialog from '@/components/pages/CaseDetailPage/ObsoleteDeliveryDialog.vue';
import RemoveDeliveryDialog from './RemoveDeliveryDialog.vue';
import UploadStudyResourceButton from './UploadStudyResourceButton.vue';

export default {
  name: 'OrderAndAnalyses',
  components: {
    AnalysisDetail,
    CreateDeliveryDialog,
    DuplicateOrderDialog,
    GenerateReportDialog,
    MarkAsNonClinicalDialog,
    NonClinicalTooltip,
    ObsoleteDeliveryDialog,
    RemoveDeliveryDialog,
    UploadStudyResourceButton,
  },
  props: {
    order: {
      type: Object,
    },
    analyses: {
      type: Array,
    },
    study: {
      type: Object,
    },
  },
  data() {
    return {
      generateReportDialog: false,
      ORDER_IN_PROGRESS: constants.ORDER_STATUSES.IN_PROGRESS,
      CASE_IN_PROGRESS: constants.CASE_STATUSES.IN_PROGRESS,
    };
  },
  computed: {
    ...mapGetters(['user', 'hgcaseById']),
    canUploadFigures() {
      return (
        this.order.status === this.ORDER_IN_PROGRESS
        && this.hgCaseStatus === this.CASE_IN_PROGRESS
      );
    },
    hgCaseStatus() {
      return this.hgcaseById(this.study.caseId)?.status || null;
    },
    // get the latest report for this order
    latestReport() {
      const reports = this.pdfReports.filter((r) => r.orderId === this.order.id);
      if (reports.length === 0) {
        return undefined;
      }
      return reports.reduce((a, b) => (a.dateCreated > b.dateCreated ? a : b));
    },
    pdfReports() {
      return this.study.resources.filter((r) => r.type === 3);
    },
    showMarkAsClinicalDialog() {
      return this.user.canDegradeOrder && this.order.isClinical && this.order.canBeDelivered;
    },
    showDeliveryDialog() {
      return this.user.canDeliverResults && this.order.canBeDelivered;
    },
    showUnDeliverDialog() {
      return this.user.canUnDeliverResults && this.order.canBeUnDelivered;
    },
    showDuplicateOrderDialog() {
      return this.user.canDuplicateOrder;
    },
    showObsoleteDeliveryDialog() {
      return this.user.canDeliverResults && this.order.delivery;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  gap: 8px;
}
</style>
