const roleOrder = [
  'Superuser',
  'Clinical Services Manager',
  'Case Manager',
  'Case Analyst',
  'Customer Support',
  'Venus Sales',
  'Venus Support',
  'Physician',
  'Field Clinical Specialist',
  'Terarecon Admin',
  'Development',
  'TemplateManager',
  'RnD Engineer',
  'Development',
];

export default {

  authStatus: (state) => state.status,
  authLoading: (state) => state.status === 'loading',
  casesTableView: (state) => state.casesTableView,
  hasApprovedTerms: (state) => state.user && state.user.hasApprovedTerms,
  hidePatientID: (state) => {
    if (state.user && state.user.settings) {
      return state.user.settings.hide_patient_identifier || false;
    }
    return false;
  },
  termsUpdated: (state) => state.user && (
    !state.user.hasApprovedTerms && state.user.previously_approved),

  user: (state, getters) => ({
    ...state.user,

    get roles() {
      const groups = state.groups
        .filter((g) => state.user.groups.includes(g.id))
        .map((g) => g.name)
        .sort((a, b) => {
          if (roleOrder.indexOf(a) === -1) return 1;
          if (roleOrder.indexOf(b) === -1) return -1;
          return roleOrder.indexOf(a) - roleOrder.indexOf(b);
        });
      if (state.user.isSuperuser) {
        groups.splice(0, 0, 'Superuser');
        return groups;
      }
      return groups;
    },

    get canAccessCaseDetails() {
      return getters.userHasPermission('cases.view_case_details');
    },
    get canAddAnalysisResource() {
      return getters.userHasPermission('simulations.add_analysisresource');
    },
    get canAddCase() {
      return getters.userHasPermission('cases.add_heartguidecase');
    },
    get canAddInputData() {
      return getters.userHasPermission('cases.add_inputdata');
    },
    get canAddOrder() {
      return getters.userHasPermission('cases.add_order');
    },
    get canAddStudyResource() {
      return getters.userHasPermission('simulations.add_studyresource');
    },
    get canAddTemplate() {
      return getters.userHasPermission('licenses.add_deviceaccessgroup');
    },
    get canAddVisualisationObject() {
      return getters.userHasPermission('viewer.add_visualisationobject');
    },
    get canAssignCaseResponsible() {
      return getters.userHasPermission('cases.assign_case_responsible');
    },
    get canCancelHeartGuideCase() {
      return getters.userHasPermission('cases.cancel_heartguidecase');
    },
    get canCancelUnderEvaluationCase() {
      return getters.userHasPermission('cases.cancel_underevaluation_heartguidecase');
    },
    get canChangeVisualisationObject() {
      return getters.userHasPermission('viewer.change_visualisationobject');
    },
    get canCreateUndeliveredLandmarks() {
      return getters.userHasPermission('simulations.add_landmark_undelivered_aa');
    },
    get canCreateDeliveredLandmarks() {
      return getters.userHasPermission('simulations.add_landmark_delivered_aa');
    },
    get canDeleteDeliveredLandmarks() {
      return getters.userHasPermission('simulations.delete_landmark_delivered_aa');
    },
    get canDeleteUndeliveredLandmarks() {
      return getters.userHasPermission('simulations.delete_landmark_undelivered_aa');
    },
    get canDuplicateOrder() {
      return getters.userHasPermission('cases.duplicate_order');
    },
    get canChangeDeliveredLandmarks() {
      return getters.userHasPermission('simulations.change_landmark_delivered_aa');
    },
    get canChangeUndeliveredLandmarks() {
      return getters.userHasPermission('simulations.change_landmark_undelivered_aa');
    },
    get canDegradeOrder() {
      return getters.userHasPermission('cases.add_degradation');
    },
    get canDeliverResults() {
      return getters.userHasPermission('cases.add_delivery');
    },
    get canEditUsersAndOrganisations() {
      return getters.userHasPermission('accounts.view_all_users')
        && getters.userHasPermission('accounts.add_user')
        && getters.userHasPermission('accounts.add_groupmembership')
        && getters.userHasPermission('accounts.add_organisationmembership')
        && getters.userHasPermission('licenses.add_organisation');
    },

    get canDeleteAnalysisResource() {
      return getters.userHasPermission('simulations.delete_analysisresource');
    },
    get canDeleteStudyResource() {
      return getters.userHasPermission('simulations.delete_studyresource');
    },
    get canDeleteVisualisationObject() {
      return getters.userHasPermission('viewer.delete_visualisationobject');
    },
    get canEditLicense() {
      return getters.userHasPermission('licenses.add_deviceaccess');
    },
    get canManageReports() {
      return getters.userHasPermission('simulations.add_studyresource');
    },
    get canPublishLandmarks() {
      return getters.userHasPermission('simulations.publish_landmark');
    },
    get canRejectCase() {
      return getters.userHasPermission('cases.add_caserejection');
    },
    get canRequestPatientInformation() {
      return getters.userHasPermission('cases.request_patient_information')
        || (getters.userHasPermission('cases.view_patient_information') && getters.hidePatientID);
    },
    get canRequestPatientInformationUnderEvaluation() {
      return getters.userHasPermission('cases.request_patient_information_under_evaluation');
    },
    get canSeeContour() {
      return this.CanViewUnpublishedResults;
    },
    get canSetInterpolation() {
      return getters.userHasPermission('simulations.change_studyresource');
    },
    get canShareCase() {
      return getters.userHasPermission('cases.add_sharedcase');
    },
    get canSkipTraining() {
      return getters.userHasPermission('trainings.skip_training');
    },
    get canSubscibeToTemplate() {
      return getters.userHasPermission('licenses.subscribe_deviceaccessgroup');
    },
    get canTogglePreview() {
      return this.CanViewUnpublishedResults;
    },
    get canUseAdvancedCaseOptions() {
      return getters.userHasPermission('simulations.change_study');
    },
    get canUnDeliverResults() {
      return getters.userHasPermission('cases.delete_delivery');
    },
    get canViewUndeliveredAnalyses() {
      return getters.userHasPermission('simulations.view_undelivered_analyses');
    },
    get CanViewUnpublishedResults() {
      return getters.userHasPermission('simulations.view_unpublished_results');
    },
    get hasManyOrgs() {
      return state.user
      && state.user.organisationIds
      && state.user.organisationIds.length > 1;
    },
    get hasRole() {
      return state.user.isSuperuser || state.user.groups.length > 0;
    },
    get isAuthenticated() { return !!localStorage.getItem('accessToken'); },
  }),
  userById: (state) => (userId) => state.users[userId],
  userItem: (_, getters) => (userId) => {
    const user = getters.userById(userId);
    if (!user) return null;
    return {
      ...user,
      get organisationMemberships() {
        return (user.organisationMembershipIds || []).map((id) => getters.membershipById(id))
          .filter((m) => m);
      },
      get specialisations() {
        return (user.specialisationIds || []).map((id) => getters.specialisationById(id))
          .filter((s) => s);
      },
    };
  },
  userHasPermission: (state) => (permission) => {
    if (state.permissions) {
      return state.permissions.indexOf(permission) > -1;
    }
    return null;
  },
  userNameDisplay: (state) => {
    /* Try showing first name + last name. If only one available, show that.
    Otherwise, fall back on user's email */
    if (!state.user) {
      return undefined;
    }
    let username;
    if (state.user.givenName) {
      username = state.user.givenName;
    }
    if (state.user.middleName && username) {
      username = `${username} ${state.user.middleName}`;
    }
    if (state.user.familyName) {
      if (username) {
        username = `${username} ${state.user.familyName}`;
      } else {
        username = state.user.familyName;
      }
    }
    if (state.user.title && username) {
      username = `${state.user.title} ${username}`;
    }
    if (username) {
      return username;
    }
    return state.user.email;
  },
  usersList: (state, getters) => state.usersList.ids.map((id) => getters.userItem(id)),
  incomingList: (state, getters) => state.incomingList.ids.map((id) => getters.userItem(id)),
  selectValidOrganisation: (state, getters) => (id, defaultToPrimary = true) => {
    // return the organisation, if its available for this user
    if (getters.user.hasManyOrgs) {
      if (getters.user.organisationIds.includes(id)) {
        return id;
      }
    }
    if (defaultToPrimary && getters.user.primaryOrganisation) {
      // users with only one organisation can only select their primary organisation
      return getters.user.primaryOrganisation.id;
    }

    return null;
  },
  permissionsLoaded: (state) => state.permissions.length > 0,
  groupsLoaded: (state) => state.groups.length > 0,
  specialisationById: (state) => (id) => state.specialisations[id],
  groupById: (state) => (id) => state.groups.find((g) => g.id === id),
};
