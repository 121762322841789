export default {
  // eslint-disable-next-line no-unused-vars
  licenseLoading(state) {
    return state.deviceAccessGroupsIsLoading;
  },

  deviceAccessGroups: (state, getters) => Object.keys(state.deviceAccessGroups)
    .map((id) => getters.deviceAccessGroupById(id)),

  deviceAccessGroupById: (state, getters) => (orgId) => {
    const deviceAccessGroup = state.deviceAccessGroups[orgId];
    if (deviceAccessGroup) {
      return {
        ...deviceAccessGroup,
        get devices() {
          let devices = deviceAccessGroup.deviceAccesses.map((dA) => dA.deviceId);
          devices = [...new Set(devices)];
          return devices.map((deviceId) => ({
            ...getters.deviceById(deviceId),

            get deviceAccesses() {
              return deviceAccessGroup.deviceAccesses.filter((da) => da.deviceId === deviceId);
            },

            get services() {
              return deviceAccessGroup.deviceAccesses.filter((da) => da.deviceId === deviceId);
            },
          }));
        },
      };
    }
    return {};
  },

};
