import Vue from 'vue';
import * as types from '@/store/mutation-types';

import initialState from './state';

export default {

  [types.CONFIGS_ISLOADING]: (state, value) => {
    Vue.set(state, 'isConfigsLoading', value);
  },
  [types.CONFIGS_UPDATE]: (state, configs) => {
    state.configs = { ...state.configs, ...configs };
  },
  [types.CONFIG_UPDATE]: (state, { id, properties }) => {
    if ('id' in properties && properties.id !== id) {
      throw Error(`ID mismatch. CONFIG_UPDATE on "${id}" has "${properties.id}" as property`);
    }
    if (!(id in state.configs)) {
      throw Error(`CONFIG_UPDATE has invalid id. Object not found for "${id}"`);
    }
    Object.entries(properties).forEach(([key, value]) => {
      Vue.set(state.configs[id], key, value);
    });
  },

  VISUALISATIONS_ISLOADING: (state, value) => {
    Vue.set(state, 'isVisualisationsLoading', value);
  },

  VISUALISATIONS_UPDATE: (state, visualisations) => {
    state.visualisations = { ...state.visualisations, ...visualisations };
  },

  VISUALISATIONS_DELETE: (state, id) => {
    Vue.delete(state.visualisations, id);
  },

  VISUALISATIONS_NEXTPAGE: (state, nextPage) => {
    Vue.set(state, 'visualisationsNextPage', nextPage);
  },

  VISUALISATIONOBJECTS_ISLOADING: (state, value) => {
    Vue.set(state, 'isVisualisationObjectsLoading', value);
  },

  VISUALISATIONOBJECTS_UPDATE: (state, visualisationObjects) => {
    state.visualisationObjects = { ...state.visualisationObjects, ...visualisationObjects };
  },

  VISUALISATION_OBJECT_UPDATE: (state, { id, properties }) => {
    if ('id' in properties && properties.id !== id) {
      throw Error(`ID mismatch. VISUALISATION_OBJECT_UPDATE on "${id}" has "${properties.id}" as property`);
    }
    if (!(id in state.visualisationObjects)) {
      throw Error(`VISUALISATION_OBJECT_UPDATE has invalid id. Object not found for "${id}"`);
    }
    Object.entries(properties).forEach(([key, value]) => {
      Vue.set(state.visualisationObjects[id], key, value);
    });
  },

  VISUALISATIONOBJECTS_DELETE: (state, id) => {
    Vue.delete(state.visualisationObjects, id);
  },

  [types.RESET_STATE_CONFIGS_MODULE](state) {
    Object.assign(state, initialState());
  },
};
