<template>
  <v-dialog class="case-create-dialog" :value="value" @input="emitInput" max-width="650px">
    <v-form
      class="case-create-form"
      ref="caseCreateForm" v-model="isValid" @submit.native.prevent="submit"
    >
      <v-card>
        <v-card-title class="text-h5 justify-center">
          New analysis
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="px-6">
          <v-alert type="error" text v-if="!hasLicense">
            A license is required to request an analysis. To acquire a license, please
            <router-link :to="{ name: 'contact' }">contact us</router-link>.
          </v-alert>

          <div v-if="errors && errors.nonFieldErrors">
            <v-alert :key=error v-for="error in errors.nonFieldErrors" type="error" dense>
              {{ error }}
            </v-alert>
          </div>

          <div v-if="user.hasManyOrgs || user.canAssignCaseResponsible">
            <p class="mb-0 text-overline">Organisation</p>
            <OrganisationSearch v-if="user.hasManyOrgs" :solo="false"
              v-model="selectedOrganisationId" :outlined="true" class="mb-4"
              @input="doSetOrganisation" />
          </div>

          <UserAutoComplete
            v-if="user.canAssignCaseResponsible"
            :key="selectedOrganisationId"
            :organisationId="selectedOrganisationId"
            v-model="caseResponsible"
            :rules="[isRequired]"
            primaryMembersOnly
          >
            <template v-slot:label>
              <span class="red--text">* </span>Case responsible
            </template>
          </UserAutoComplete>

          <div class="mb-6">
            <p class="mb-0 text-overline">Analysis type</p>
            <v-alert v-if="!canOrderSimulation" type="info" outlined dense>
              Your license does not support simulations for the selected device(s).
            </v-alert>
            <v-radio-group class="ma-0" v-model="form.aaOnly" row>
              <v-radio
                label="Anatomical analysis + simulation"
                :value="false"
                :disabled="!canOrderSimulation || !hasLicense"
                :readonly="!canOrderSimulation"
              ></v-radio>
              <v-radio
                label="Anatomical analysis only"
                :value="true"
                :disabled="!hasLicense"
              ></v-radio>
            </v-radio-group>
          </div>

          <div class="mb-6">
            <p class="mb-0 text-overline">Device details</p>
            <v-row>
              <v-alert
                class="ma-2"
                color="warning"
                dense
                data-cy="training-required-card"
                v-if="selectedIntervention.trainingRequired"
              >
                <span>
                  A new required training is available for this intervention. Click
                  <span>
                    <router-link
                      :to="{
                        name: 'training',
                        params:{interventionPrefix: selectedIntervention.prefix}
                      }"
                      class="white--text"
                    >here</router-link> to follow it.
                  </span>
                </span>
              </v-alert>
              <v-col cols="12" sm="6">
                <v-select
                  class="select-intervention"
                  :items="interventions"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  v-model="form.interventionId"
                  clearable
                  hide-details="auto"
                  label="Select intervention"
                  :disabled="Boolean(form.device1)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  class="select-device-1"
                  :items="devices"
                  outlined
                  dense
                  v-model="form.device1"
                  clearable
                  hide-details="auto"
                  :error-messages="errors.deviceIds"
                  :rules="[isRequired]"
                  @change="handleDevice1Change"
                  :disabled="disableDeviceSelect"
                >
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ data.item.name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should
                    render items when the select is open -->
                    {{ data.item.name }}
                  </template>
                  <template v-slot:label>
                    <span class="red--text">* </span>Select device
                  </template>
                </v-select>
              </v-col>
              <v-col v-if="!form.aaOnly" cols="12" sm="6">
                <v-select
                  class="select-device-variants"
                  :items="variants"
                  item-value="id"
                  item-text="name"
                  label="Select size(s)"
                  outlined
                  dense
                  v-model="form.deviceVariantIds"
                  multiple
                  :rules="[limitedAmount]"
                  hide-details="auto"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="showadditionalDeviceselect">
              <v-col cols="12" sm="6">
                <v-select
                  class="select-device-2"
                  :items="devicesForSelect2"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  v-model="form.additionalDevices"
                  clearable
                  multiple
                  hide-details="auto"
                  label="Select extra devices"
                  :disabled="!form.device1"
                />
              </v-col>
            </v-row>

            <v-alert v-if="areServicesNonClinical" text dense type="warning" class="mt-2">
              Analyses for selected device{{ selectedDevices.length > 1 ? 's' : '' }}
              will be non-clinical only.
            </v-alert>
          </div>

          <div class="mb-6">
            <p class="mb-0 text-overline">Patient details</p>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined dense hide-details="auto"
                  :rules="[isRequired]"
                  v-model="form.patientInformation.identifier">
                  <template v-slot:label>
                    <span class="red--text">* </span>Patient identifier
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      class="select-sex"
                      :items="['F', 'M']"
                      :rules="[isRequired]"
                      v-model="form.patientInformation.sex"
                      outlined
                      dense
                      hide-details="auto">
                      <template v-slot:label>
                        <span class="red--text">* </span>Sex
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      class="share-case-list-item"
                      type="number" outlined dense hide-details="auto"
                      v-model="form.patientInformation.age"
                      :rules="[isRequired, isNotNegative]
                    ">
                      <template v-slot:label>
                        <span class="red--text">* </span>Age
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <div class="mb-6">
            <p class="mb-0 text-overline">Intervention details</p>
            <v-radio-group class="ma-0" v-model="form.isProspective" row>
              <v-radio
                label="Prospective"
                :value="true"
              ></v-radio>
              <v-radio
                label="Retrospective"
                :value="false"
              ></v-radio>
            </v-radio-group>
            <v-row>
              <v-col cols="6">
                <v-menu v-model="openIddMenu" :close-on-content-click="false"
                  transition="scale-transition" min-width="auto" ref="iddMenu">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="form.interventionDate" readonly v-bind="attrs"
                      v-on="on" outlined dense clearable label="Intervention Date"></v-text-field>
                  </template>

                  <v-date-picker v-model="form.interventionDate" no-title color="primary"
                    :min="form.isProspective ? today : null"
                    :max="form.isProspective ? null : today">
                    <v-spacer />
                    <v-btn text @click="openIddMenu = false">Cancel</v-btn>
                    <v-btn text color="primary"
                      @click="$refs.iddMenu.save(form.interventionDate)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-select :items="times" label="Time (optional)" outlined dense
                  v-model="form.interventionTime" />
              </v-col>
            </v-row>
          </div>

          <div class="mb-6">
            <p class="mb-0 text-overline">Expected Delivery Date</p>
            <p>Delivery date should at least be
              <template v-if="!selectedIntervention.prefix">24 - 48hrs</template>
              <template v-else>
                {{ selectedIntervention.prefix === 'LAAO' ? 24 : 48 }}hrs
              </template> from submission.

              Please consult the official list of Public Holidays in Belgium.</p>
            <v-row>
              <v-col cols="12"
                v-if="!eddWithinMinDeadline && expectedDeliveryDateTimeISO
                  && form.interventionId">
                <v-alert type="error">Selected date is less than
                  {{ selectedIntervention.prefix === 'LAAO' ? 24 : 48 }}hrs. We cannot guarantee
                  the results will be delivered by the date set.
                </v-alert>
              </v-col>
              <v-col>
                <v-menu v-model="openEddMenu" :close-on-content-click="false"
                  transition="scale-transition" min-width="auto" ref="eddMenu">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="form.expectedDelivery.date" readonly v-bind="attrs"
                      v-on="on" outlined dense clearable :rules="[isRequired]">
                      <template v-slot:label>
                        <span class="red--text">*</span> Delivery Date
                      </template>
                    </v-text-field>
                  </template>

                  <v-date-picker v-model="form.expectedDelivery.date" no-title color="primary"
                    :min="today">
                    <v-spacer />
                    <v-btn text @click="openEddMenu = false">Cancel</v-btn>
                    <v-btn text color="primary"
                      @click="$refs.eddMenu.save(form.expectedDelivery.date)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-select :items="times" outlined dense v-model="form.expectedDelivery.time"
                  label="Delivery Time" />
              </v-col>
            </v-row>
          </div>

          <v-textarea
            auto-grow
            label="Remark"
            name="input-7-4"
            outlined
            placeholder="Type something you think is helpful to our analysts."
            rows=2
            row-height="16"
            v-model="form.physicianComment"
          ></v-textarea>

          <div class="mb-6" v-if="users.length && form.device1">
            <p class="mb-0">
              Once created, this case will be automatically shared to the following specialists
            </p>

            <div class="d-flex justify-end mt-2">
              <v-btn text small color="error" @click="deselectUsers">Deselect All</v-btn>
              <v-btn text small color="primary" @click="selectAllUsers">Select All</v-btn>
            </div>

            <v-list outlined class="users-list mt-2 rounded">
              <v-list-item-group v-model="selectedEmails" multiple>
                <v-list-item v-for="user in users" :key="user.sub" dense
                  :value="user.email" color="primary">
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ user.name }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ user.deviceNames }} Specialist
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card-text>

        <v-card-actions class="px-6 pa-4">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="emitInput(false)">
            Close
          </v-btn>
          <v-btn color="primary" depressed type="submit"
            :disabled="!canRequestAnalysis"
          >Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OrganisationSearch from '@/components/common/OrganisationSearch.vue';
import UserAutoComplete from '@/components/UserAutoComplete.vue';
import constants from '@/store/modules/cases/constants';

function initialState() {
  return {
    caseResponsible: null,
    errors: {},
    form: {
      aaOnly: null,
      device1: null,
      additionalDevices: [],
      deviceVariantIds: [],
      isProspective: true,
      interventionDate: null,
      interventionTime: null,
      interventionId: null,
      expectedDelivery: {
        date: null,
        time: '07:00',
      },
      patientInformation: {
        identifier: null,
        sex: null,
        age: null,
      },
      physicianComment: null,
    },
    isValid: false,
    minDeadline: null,
    selectedEmails: [],
  };
}

export default {
  name: 'CaseCreateDialog',
  props: ['value'],
  components: {
    UserAutoComplete,
    OrganisationSearch,
  },
  data() {
    return {
      today: new Date().toISOString(),
      ...initialState(),
      // Don't reset the selected organisation
      selectedOrganisationId: null,
      openIddMenu: false,
      openEddMenu: false,
    };
  },
  created() {
    // Set the selected organisation
    this.selectedOrganisationId = this.selectValidOrganisation(
      this.$route.query.org,
    );

    // Update the selected org manually
    this.doSetOrganisation(this.selectedOrganisationId);
  },
  computed: {
    ...mapGetters({
      allInterventions: 'interventions',
      deviceById: 'deviceById',
      interventionById: 'interventionById',
      organisationById: 'organisationById',
      selectValidOrganisation: 'selectValidOrganisation',
      user: 'user',
      userById: 'userById',
    }),
    areServicesNonClinical() {
      // check for selected services only
      const { aaOnly } = this.form;
      let deviceServices = [];

      if (aaOnly) {
        deviceServices = this.selectedDevices.map((d) => d.services
          .find((s) => s.service === constants.SERVICE.ANATOMICAL));
      } else if (aaOnly === false) { // explicitly check for false value only, not null or undefined
        deviceServices = this.selectedDevices.map((d) => d.services).flat();
      }

      return deviceServices.filter((ds) => !!ds).some((s) => !s.isClinical);
    },
    selectedOrganisation() {
      return this.organisationById(this.selectedOrganisationId);
    },
    canRequestAnalysis() {
      return this.isValid && !this.selectedIntervention.trainingRequired;
    },
    canOrderSimulation() {
      // If all devices allow for a simulation
      return this.selectedDevices.every(
        (device) => device.services.some((service) => service.service === 1),
      );
    },
    disableDeviceSelect() {
      // Only disable the selection in :
      // - if a device is selected
      // - and the mode is aaOnly
      // - and other devices are selected
      // Note [Reinjan]: Not sure if we even need to disable this selection
      // but this comes closes to what was intended without getting stuck in certain situations
      return this.form.device1 && this.form.aaOnly && this.form.additionalDevices.length > 0;
    },
    devices() {
      if (this.selectedOrganisation) {
        const { devices } = this.selectedOrganisation;
        if (this.form.interventionId) {
          return devices.filter(
            (device) => device.interventionId === this.form.interventionId,
          );
        }
        return devices;
      }
      return [];
    },
    devicesForSelect2() {
      return this.devices
        .filter((d) => !this.form.device1 || d.id !== this.form.device1.id);
    },
    eddWithinMinDeadline() {
      if (!this.expectedDeliveryDateTimeISO) return false;
      const edd = new Date(this.expectedDeliveryDateTimeISO);
      const minDate = new Date(this.minDeadline);
      return edd >= minDate;
    },
    expectedDeliveryDateTimeISO() {
      const { date, time } = this.form.expectedDelivery;
      if (!date) return undefined;
      return new Date(`${date}T${time}`).toISOString();
    },
    hasLicense() {
      return this.selectedOrganisation?.devices.length > 0 || false;
    },
    interventions() {
      // Show only interventions with a license
      return this.allInterventions.map((intervention) => ({
        ...intervention,
        devices: this.devices.filter((d) => d.interventionId === intervention.id),
      })).filter((i) => i.devices.length > 0);
    },
    interventionDatetimeISO() {
      if (this.form.interventionDate) {
        // Set a default time if not specified
        let interventionTime = '08:00';
        if (this.form.interventionTime) interventionTime = this.form.interventionTime;
        return new Date(`${this.form.interventionDate}T${interventionTime}`).toISOString();
      }
      return undefined;
    },
    times() {
      return [
        ...[...Array(24)].map((_, idx) => `${String(idx).padStart(2, '0')}:00`)];
    },
    locale() {
      return navigator.language;
    },
    showadditionalDeviceselect() {
      return this.form.aaOnly && this.selectedIntervention.name === 'TAVI';
    },
    selectedIntervention() {
      return this.interventionById(this.form.interventionId);
    },
    selectedDevices() {
      const devices = this.devices.filter(
        (d) => this.form.additionalDevices.includes(d.id),
      );
      // .map((id) => this.$store.state.interventions.devices[id]);
      devices.splice(0, 0, this.form.device1);
      return devices.filter((d) => d !== null);
    },
    selectedDeviceIds() {
      let deviceIds = this.selectedDevices.map((device) => device.id);
      if (!this.form.aaOnly && deviceIds.length) {
        // if we changed the form back to aa Only, select only the first device
        deviceIds = [deviceIds[0]];
      }
      return deviceIds;
    },
    users() {
      if (!this.selectedOrganisation) return [];
      const { specialisations } = this.selectedOrganisation;
      const specialisationObj = (specialisations || [])
        .filter((spec) => spec.userId !== this.user.sub)
        .filter((spec) => !this.selectedDeviceIds.length
          || this.selectedDeviceIds.includes(spec.deviceId))
        .reduce((acc, spec) => {
          const user = this.userById(spec.userId);
          const device = this.deviceById(spec.deviceId);
          if (user && device) {
            const devices = acc[spec.userId]?.devices || [];
            devices.push(device);

            acc[spec.userId] = {
              sub: spec.userId,
              name: user.name,
              deviceNames: devices.map((d) => d.name).join(', '),
              devices,
              email: user.email,
            };
          }
          return acc;
        }, {});

      return Object.values(specialisationObj);
    },
    variants() {
      if (this.form.device1) {
        const { variants } = this.deviceById(this.form.device1.id);
        return variants.sort((a, b) => parseInt(a.name, 10) - parseInt(b.name, 10));
      }
      return [];
    },
  },
  methods: {
    ...mapActions([
      'createCase',
      'createOrder',
      'getMinDeliveryDate',
      'retrieveCase',
      'retrieveOrganisation',
      'shareCase',
      'updateOrganisationLicense',
    ]),
    emitInput(newVal) {
      this.$emit('input', newVal);
    },
    reset() {
      Object.assign(this.$data, initialState());
    },
    handleDevice1Change() {
      this.form.deviceVariantIds = [];
      if (this.form.device1) {
        this.form.interventionId = this.form.device1.interventionId;
      } else {
        this.form.interventionId = null;
      }
    },
    async submit() {
      const deviceIds = this.selectedDeviceIds;

      try {
        const orders = [
          {
            deviceIds,
            service: 0,
          },
        ];
        if (!this.form.aaOnly) {
          orders.push({
            service: 1,
            deviceIds,
            deviceVariantIds: this.form.deviceVariantIds,
          });
        }

        const caseData = {
          caseResponsibleId: this.caseResponsible?.sub,
          patientInformation: this.form.patientInformation,
          physicianComment: this.form.physicianComment,
          organisationId: this.selectedOrganisation.id,
          interventionDatetime: this.interventionDatetimeISO,
          isProspective: this.form.isProspective,
          expectedDeliveryDatetime: this.expectedDeliveryDateTimeISO,
          orders,
        };
        const hgcase = await this.createCase(caseData);

        if (this.selectedEmails.length) {
          this.selectedEmails.forEach((email) => this.shareCase({ id: hgcase.id, email }));
        }

        this.emitInput(false);
        this.$refs.caseCreateForm.resetValidation();
        this.reset();
      } catch (error) {
        this.errors = error.response && error.response.data;
      }
    },
    async doSetOrganisation(org) {
      this.caseResponsible = null;
      this.form.interventionId = null;
      this.form.device1 = null;
      this.form.additionalDevices = [];
      this.form.deviceVariantIds = [];
      this.updateOrganisationLicense({ id: org });
    },
    limitedAmount(v) {
      return v.length <= this.selectedIntervention.variantLimit
        || !this.selectedIntervention.variantLimit
        || `This field is limited to ${this.selectedIntervention.variantLimit} choices.`;
    },
    isRequired(v) {
      return !!v || 'This field is required';
    },
    isRequiredProspective(v) {
      return !!v || 'This field is required for prospective';
    },
    isNotNegative(v) {
      return v >= 0 || 'This field cannot be negative';
    },

    // samples
    deselectUsers() {
      this.selectedEmails = [];
    },
    selectAllUsers() {
      this.selectedEmails = this.users.map((u) => u.email);
    },
  },
  watch: {
    canOrderSimulation(value) {
      if (!value) {
        this.form.aaOnly = true;
      }
    },
    // eslint-disable-next-line func-names
    'form.interventionId': async function (newVal) {
      if (!newVal) return;
      let offset = 2;
      if (this.selectedIntervention.prefix === 'LAAO') offset = 1;
      this.minDeadline = await this.getMinDeliveryDate(offset);
    },
    hasLicense(newVal) {
      if (!newVal) return;
      this.form.aaOnly = false;
    },
    selectedOrganisationId: 'retrieveOrganisation',
    // reset selected specialists when devices change
    selectedDevices: 'selectAllUsers',
  },
};
</script>

<style lang="scss" scoped>
.users-list {
  max-height: 162px; // 3 items
  overflow-y: auto;
}

.font-16px {
  font-size: 16px;
}
</style>
