import axios from 'axios';
import constants from './constants';

const { CancelToken } = axios;

export default () => ({
  caseRejection: undefined,
  cases: {},
  casesPage: {
    cancelTokenSource: CancelToken.source(),
    searchTerm: '',
    organisationId: null,
    nextPage: 1,
    pageCount: 1,
    totalCount: 1,
    isLoading: false,
  },
  dialogs: {
    showCancelCaseDialog: false,
    showShareCaseDialog: false,
    currentCaseId: undefined,
  },
  constants,
  degradationReasons: {},
  error: '',
  inputData: {},
  inputDataIsLoading: false,
  inputDataNextPage: 1,
  orders: {},
  orderIsLoading: false,
  orderSimulationDialog: {
    show: false,
    caseId: null,
  },
  rejectionReasons: {},
});
