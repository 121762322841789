<template>
  <v-dialog v-model="dialog" max-width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text outlined small
        v-bind="attrs"
        v-on="on"
      >
        Mark as non-clinical
      </v-btn>
    </template>
    <v-form
      ref="degradationCreateForm"
      v-model="isValid"
      @submit.native.prevent="submit"
    >
      <v-card>
        <v-card-title class="text-h5 justify-center">
          Mark {{ order.serviceDisplay }} order as non-clinical
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-6">
          <v-select
            outlined
            dense
            label="Reason"
            :items="degradationReasons"
            v-model="degradationReason"
            @input="selectReason"
            item-text="name"
            item-value="id"
          >
          </v-select>
          <v-textarea
            outlined
            name="remark"
            label="Remark"
            v-model="remark"
            :error-messages="errors && errors['remark']"
            :rules="[isRequired]"
          ></v-textarea>
        </v-card-text>

        <v-card-actions class="px-6 pa-4">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" depressed type="submit" :disabled="!isValid">
            Mark as non-clinical
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'MarkAsNonClinicalDialog',
  data() {
    return {
      errors: {},
      degradationReason: null,
      dialog: false,
      remark: '',
      isValid: true,
      isRequired: (v) => !!v || 'This field is required',
    };
  },
  computed: {
    hgcase() {
      return this.$store.getters.hgcaseById(this.order.caseId);
    },
    degradationReasons() {
      return this.$store.getters.degradationReasons
        .filter((r) => r.interventionId === this.hgcase.interventionId);
    },
    selectedReason() {
      return this.$store.getters.degradationReasonById(this.degradationReason);
    },
  },
  props: ['order'],
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('createDegradation', {
          orderId: this.order.id,
          degradationReasonId: this.degradationReason,
          remark: this.remark || '',
        });
        this.$store.dispatch('retrieveCase', this.order.caseId);
        this.dialog = false;
        this.$refs.degradationCreateForm.reset();
      } catch (error) {
        this.errors = error.response && error.response.data;
      }
    },
    selectReason() {
      if (this.selectedReason) {
        this.remark = this.selectedReason.message;
      } else {
        this.remark = '';
      }
    },
  },
};
</script>
