import Vue from 'vue';

import * as types from '@/store/mutation-types';

import initialState from './state';

export default {

  [types.ANALYSES_NEXTPAGE]: (state, value) => {
    Vue.set(state, 'analysesNextPage', value);
  },
  [types.ANALYSES_ISLOADING]: (state, value) => {
    Vue.set(state, 'isLoading', value);
  },
  [types.ANALYSES_UPDATE]: (state, analyses) => {
    state.analyses = { ...state.analyses, ...analyses };
  },
  [types.ANALYSIS_UPDATE]: (state, { id, properties }) => {
    if ('id' in properties && properties.id !== id) {
      throw Error(`ID mismatch. ANALYSIS_UPDATE on "${id}" has "${properties.id}" as property`);
    }
    if (!(id in state.analyses)) {
      throw Error(`ANALYSIS_UPDATE has invalid id. Object not found for "${id}"`);
    }
    Object.entries(properties).forEach(([key, value]) => {
      Vue.set(state.analyses[id], key, value);
    });
  },

  [types.ANALYSIS_RESOURCES_NEXTPAGE]: (state, value) => {
    Vue.set(state, 'analysisResourcesNextPage', value);
  },
  [types.ANALYSIS_RESOURCES_ISLOADING]: (state, value) => {
    Vue.set(state, 'isLoading', value);
  },
  [types.ANALYSIS_RESOURCES_UPDATE]: (state, analysisResources) => {
    state.analysisResources = { ...state.analysisResources, ...analysisResources };
  },
  [types.ANALYSIS_RESOURCE_DELETE]: (state, { analysisResource }) => {
    Vue.delete(state.analysisResources, analysisResource.id);
  },

  [types.GENERATING_REPORT]: (state, value) => {
    Vue.set(state, 'isGeneratingReport', value);
  },

  [types.STUDIES_ISLOADING]: (state, value) => {
    Vue.set(state.studies, 'isLoading', value);
  },
  [types.STUDIES_UPDATE]: (state, studies) => {
    state.studies = { ...state.studies, ...studies };
  },
  [types.STUDY_RESOURCES_NEXTPAGE]: (state, value) => {
    Vue.set(state, 'studyResourcesNextPage', value);
  },
  [types.STUDY_RESOURCES_ISLOADING]: (state, value) => {
    // TODO: This shares state with ANALYSIS_ISLOADING etc... acceptable?
    Vue.set(state, 'isLoading', value);
  },
  [types.STUDY_RESOURCE_DELETE]: (state, { studyResource }) => {
    Vue.delete(state.studyResources, studyResource.id);
  },
  [types.STUDY_RESOURCES_UPDATE]: (state, studyResources) => {
    state.studyResources = { ...state.studyResources, ...studyResources };
  },

  [types.RESET_STATE_STUDIES_MODULE]: (state) => {
    Object.assign(state, initialState());
  },
};
