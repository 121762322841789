<template>
  <div>
    <transition-group style="gap: 1em;" class="caselist" name="casetrans" appear tag="div">
      <CaseCard
        v-for="hgcase in $store.getters.filteredHgcases"
        :key="hgcase.id"
        class="caseitem"
        :hgcase="hgcase"
      />

      <div :key="0" class="caseitem">
        <!-- This was v-intersect.quiet previously, but that caused no further cases to be loaded
          on larger screens. Before putting back, try to increase window size so all initial cases
          are at least partially visible, and see if loading can be triggered. -->
        <div
          v-if="$store.state.cases.casesPage.nextPage > 1" v-intersect="infiniteScrolling"
          style="width: 345px; height: 25px; margin: 10px;" >
        </div>
      </div>
    </transition-group>

  </div>
</template>

<script>

import CaseCard from './CaseCard';

export default {
  name: 'CaseCards',
  components: {
    CaseCard,
  },
  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.$store.dispatch('listCases');
      }
    },
  },
};
</script>

<style>
.caselist {
  display: flex;
  flex-wrap: wrap;
}
.casetrans-enter-active, .casetrans-leave-active {
  transition: opacity .5s, transform .5s;
}
.casetrans-enter, .casetrans-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(20px);
}
</style>
