import store from '@/store';
import constants from '@/store/modules/cases/constants';

const generalRedirect = (condition, next) => {
  if (condition) next();
  else next({ name: 'notFound' });
};
export function requiresPrimaryOrganisation(to, from, next) {
  if (store.getters.user.primaryOrganisation) {
    next();
  } else {
    next({
      name: 'pendingregistration',
    });
  }
}

export function requiresTerms(to, from, next) {
  if (store.getters.user.hasApprovedTerms) {
    next();
  } else {
    next({
      name: 'terms',
      query: { redirect: to.fullPath },
    });
  }
}

export function requiresCaseDetailPermission(to, from, next) {
  if (!store.getters.permissionsLoaded) {
    const permFunc = () => store.getters.permissionsLoaded;
    const watcher = store.watch(permFunc, () => {
      watcher(); // stop watcher
      generalRedirect(store.getters.user.canAccessCaseDetails, next);
    });
  } else {
    generalRedirect(store.getters.user.canAccessCaseDetails, next);
  }
}

export function requiresAddTemplatesPermission(to, from, next) {
  if (!store.getters.permissionsLoaded) {
    const permFunc = () => store.getters.permissionsLoaded;
    const watcher = store.watch(permFunc, () => {
      watcher(); // stop watcher
      generalRedirect(store.getters.user.canAddTemplate, next);
    });
  } else {
    generalRedirect(store.getters.user.canAddTemplate, next);
  }
}

export function requiresManagementPermission(to, from, next) {
  // wait for permissions to be loaded before next()
  if (!store.getters.permissionsLoaded) {
    // mock function that returns getter value
    const permFunc = () => store.getters.permissionsLoaded;
    const watcher = store.watch(permFunc, () => {
      watcher(); // stop watcher
      generalRedirect(store.getters.user.canEditUsersAndOrganisations, next);
    });
  } else {
    generalRedirect(store.getters.user.canEditUsersAndOrganisations, next);
  }
}

export async function requiresStudyAndCase(to, from, next) {
  let study = store.getters.studyById(to.params.id);
  if (!study) study = await store.dispatch('retrieveStudy', to.params.id);
  let hgCase = store.getters.hgcaseById(study.caseId);
  if (!hgCase) hgCase = await store.dispatch('retrieveCase', study.caseId);

  if (store.getters.user.canViewUndeliveredAnalyses) {
    next();
  } else {
    // check if any deliverables has been made
    const orderStatuses = hgCase.orders.map((o) => o.status);
    if (orderStatuses.some((s) => s === constants.ORDER_STATUSES.DELIVERED)) {
      next();
      return;
    }

    // if there's no delivery, check case status
    const allowedStatus = [
      constants.CASE_STATUSES.COMPLETED,
      constants.CASE_STATUSES.REJECTED,
    ];

    if (allowedStatus.includes(hgCase.status)) next();
    else next({ name: 'resultsPending' });
  }
}

export async function requiresCaseNotCanceled(to, from, next) {
  let study = store.getters.studyById(to.params.id);
  if (!study) study = await store.dispatch('retrieveStudy', to.params.id);
  let hgCase = store.getters.hgcaseById(study.caseId);
  if (!hgCase) hgCase = await store.dispatch('retrieveCase', study.caseId);

  if (hgCase.status !== constants.CASE_STATUSES.CANCELED) next();
  else {
    next({ name: 'caseCanceled' });
  }
}
