<template>
  <v-app-bar
      app
      color="secondary"
      dark
      :class="{ collapsed: insideIframe }"
    >
    <router-link v-if="!insideIframe" :to="{ name: 'cases' }">
      <img src='@/assets/images/logo.png' class="logo-link d-block mr-4" style="height: 32px;" />
    </router-link>

    <img v-else
      src='@/assets/images/logo.png'
      class="logo-link d-block mr-4"
      style="height: 32px;"
    />

    <slot>
    </slot>

    <UserMenu class="ml-auto" v-if="$store.getters.user.email" />
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';
import UserMenu from '@/components/UserMenu.vue';
// import LogoIcon from '@/components/Icons/LogoIcon.vue';

export default {
  name: 'AppBar',
  components: {
    UserMenu,
  },
  computed: {
    ...mapState({
      insideIframe: (state) => state.layout.insideIframe,
    }),
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 42px;
  height: 42px;
}

.logo ::v-deep svg {
  width: 100%;
  height: 100%;
}
</style>
