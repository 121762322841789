<template>
  <div>
    <AppBar />
    <v-main>
      <v-alert type="info">
        Your registration has been received but is not yet approved.
        Please wait while we process your registration request. If you
        think this is an error, please
        <router-link class="white--text" :to="{ name: 'contact' }">contact us.</router-link>
      </v-alert>
    </v-main>
  </div>
</template>

<script>

import AppBar from '@/components/layout/AppBar.vue';

export default {
  name: 'PendingRegistrationPage',
  components: {
    AppBar,
  },
};
</script>
