import Vue from 'vue';
import * as types from '@/store/mutation-types';

import initialState from './state';

export default {
  [types.CASES_ISLOADING]: (state, value) => {
    Vue.set(state.casesPage, 'isLoading', value);
  },
  [types.CASES_UPDATE]: (state, cases) => {
    state.cases = { ...state.cases, ...cases };
  },
  [types.CASE_UPDATE]: (state, updatedCase) => {
    Vue.set(state.cases, updatedCase.id, {
      ...state.cases[updatedCase.id],
      ...updatedCase,
      loading: false,
    });
  },
  [types.CASES_CLEAR]: (state) => {
    Vue.set(state, 'cases', {});
  },
  [types.CASES_NEXTPAGE]: (state, value) => {
    Vue.set(state.casesPage, 'nextPage', value);
  },
  CASES_SET_STATS: (state, { totalCount, pageCount }) => {
    Vue.set(state.casesPage, 'pageCount', pageCount);
    Vue.set(state.casesPage, 'totalCount', totalCount);
  },
  [types.CASES_SET_CASES_PAGE]: (state, { searchTerm, organisationId }) => {
    Vue.set(state.casesPage, 'searchTerm', searchTerm || '');
    Vue.set(state.casesPage, 'organisationId', organisationId || null);
  },
  [types.CASES_HIDE_ALL_PATIENT_INFORMATION]: (state) => {
    Object.values(state.cases).forEach((hgcase) => {
      Vue.set(hgcase, 'patientInformation', null);
    });
  },
  REJECTION_REASONS_UPDATE: (state, rejectionReasons) => {
    state.rejectionReasons = { ...state.rejectionReasons, ...rejectionReasons };
  },
  DEGRADATION_REASONS_UPDATE: (state, degradationReasons) => {
    state.degradationReasons = { ...state.degradationReasons, ...degradationReasons };
  },
  [types.CASEREJECTION_SUCCESS]: (state, rejection) => {
    state.caseRejection = rejection;
  },
  [types.CASEREJECTION_ERROR]: (state) => {
    state.caseRejection = undefined;
  },
  [types.CASES_SET_ORDER_SIMULATION_DIALOG]: (state, { show, caseId }) => {
    Vue.set(state.orderSimulationDialog, 'show', show);
    Vue.set(state.orderSimulationDialog, 'caseId', caseId);
  },
  [types.INPUT_DATA_UPDATE]: (state, inputData) => {
    state.inputData = { ...state.inputData, ...inputData };
  },
  [types.INPUT_DATA_ISLOADING]: (state, value) => {
    Vue.set(state, 'inputDataIsLoading', value);
  },
  [types.INPUT_DATA_NEXTPAGE]: (state, value) => {
    Vue.set(state, 'inputDataNextPage', value);
  },
  [types.RESET_STATE_CASES_MODULE](state) {
    Object.assign(state, initialState());
  },
  [types.ORDERS_UPDATE]: (state, orders) => {
    state.orders = { ...state.orders, ...orders };
  },
  [types.ORDERS_ISLOADING]: (state, value) => {
    Vue.set(state, 'orderIsLoading', value);
  },

  [types.CASE_CANCEL_DIALOG_SHOW]: (state, hgcaseId) => {
    Vue.set(state.dialogs, 'showCancelCaseDialog', Boolean(hgcaseId));
    Vue.set(state.dialogs, 'currentCaseId', hgcaseId);
  },

  [types.CASE_SHARE_DIALOG_SHOW]: (state, hgcaseId) => {
    Vue.set(state.dialogs, 'showShareCaseDialog', Boolean(hgcaseId));
    Vue.set(state.dialogs, 'currentCaseId', hgcaseId);
  },
};
