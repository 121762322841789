import constants from './constants';

export default {
  // Simple state deductors
  hgcaseById: (state, getters) => (id) => {
    // return case by its actual ID, not caseIdentifier
    const hgcase = state.cases[id];
    if (hgcase) {
      return {
        ...hgcase,
        get canBeCanceled() {
          const { CREATED, SUBMITTED } = constants.CASE_STATUSES;
          return getters.user.canCancelHeartGuideCase
            && [CREATED, SUBMITTED].includes(hgcase.status);
        },
        get canDownloadInputData() {
          // TODO: Determine who can download input data
          return this.hasInputData;
        },
        get isRejectable() {
          return hgcase.status === constants.CASE_STATUSES.IN_PROGRESS;
        },
        get canSeeReport() {
          return hgcase.hasReport && this.canSeeViewer;
        },
        get canSeeViewer() {
          // Can only be true if the case has a study
          if (hgcase.studyId) {
            // If the case is confidential and user has no access then no
            if (hgcase.accessDenied) {
              return false;
            }
            // If the case is 'COMPLETED', the user can view the simulation.
            if (hgcase.status === constants.CASE_STATUSES.COMPLETED) {
              return true;
            // Everyone can see the delivered results, even if not all services are delivered
            } if (hgcase.status === constants.CASE_STATUSES.IN_PROGRESS) {
              return true;
            }
          }
          return false;
        },
        get canRequestPatientInformation() {
          return getters.user.canRequestPatientInformation || (
            getters.user.canRequestPatientInformationUnderEvaluation
            && [constants.CASE_STATUSES.IN_PROGRESS, constants.CASE_STATUSES.SUBMITTED]
              .includes(hgcase.status)
          );
        },
        get caseResponsible() {
          return hgcase.caseResponsibleId && getters.userById(hgcase.caseResponsibleId);
        },
        get createdBy() {
          return hgcase.createdById && getters.userById(hgcase.createdById);
        },
        get devices() {
          const devicesMap = this.orders.reduce((acc, order) => {
            order.deviceIds.map((deviceId) => {
              const device = getters.deviceById(deviceId);
              if (device) {
                return Object.assign(acc, { [deviceId]: device });
              }
              return acc;
            });

            return acc;
          }, {});
          // Augment the map with methods that return an array
          return {
            ...devicesMap,
            all: () => Object.values(devicesMap),
            orderBy: (param) => Object.values(devicesMap).sort((a, b) => {
              const nameA = a[param].toUpperCase(); // ignore upper and lowercase
              const nameB = b[param].toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            }),
          };
        },

        get deviceVariantNames() {
          return this.simulationDeviceVariants.map((deviceVariant) => deviceVariant.name);
        },
        get hasInputData() {
          return hgcase.inputDataIds.length > 0;
        },
        get hasPdfReport() {
          return this.study && this.study.hasPdfReport;
        },
        get hasAnatomicalOrder() {
          return this.orders.some(
            (order) => order.service === constants.SERVICE.ANATOMICAL,
          );
        },
        get hasLegacyOrder() {
          return this.orders.some(
            (order) => order.service === constants.SERVICE.LEGACY,
          );
        },
        get hasNoSimulationOrder() {
          return !this.orders.some(
            (order) => order.service === constants.SERVICE.SIMULATION,
          );
        },
        get hasDeliveredOrder() {
          const deliveredOrders = hgcase.orders.filter(
            (order) => order.status === constants.ORDER_STATUSES.DELIVERED,
          );
          return Boolean(deliveredOrders.length);
        },
        get isUndelivered() {
          return !this.hasDeliveredOrder && !this.isFinished;
        },
        get isFinished() {
          return [
            constants.CASE_STATUSES.COMPLETED,
            constants.CASE_STATUSES.CANCELED,
            constants.CASE_STATUSES.REJECTED,
          ].includes(hgcase.status);
        },
        get inputData() {
          return hgcase.inputDataIds.map(
            (inputDataId) => getters.inputDataById(inputDataId),
          ).filter((i) => i);
        },
        get intervention() {
          return hgcase.interventionId && getters.interventionById(hgcase.interventionId);
        },
        get orders() {
          return hgcase.orders.map((order) => ({
            ...order,
            serviceDisplay: constants.SERVICE_NAMES[order.service],
            statusDisplay: constants.ORDER_STATUS_LABELS[order.status],
            get canBeDelivered() {
              const orderIsInProgress = order.status === constants.ORDER_STATUSES.IN_PROGRESS;
              const caseIsInProgress = hgcase.status === constants.CASE_STATUSES.IN_PROGRESS;
              return orderIsInProgress && caseIsInProgress;
            },
            get canBeUnDelivered() {
              const orderIsDelivered = order.status === constants.ORDER_STATUSES.DELIVERED;
              const caseIsResearch = hgcase.isResearch;
              return orderIsDelivered && caseIsResearch;
            },
            get degradationRemark() {
              // If there is no degradation, the order was created
              // non-clinical because of the license
              if (!order.isClinical) {
                return (order.degradation && order.degradation.remark)
                || 'The results of this case cannot be used for clinical purposes because the organization only has a non-clinical device license for this device.';
              }
              return '';
            },
          }));
        },
        get lastInputData() {
          return this.inputData.sort(
            (a, b) => {
              if (a.dateCreated < b.dateCreated) {
                return 1;
              }
              if (a.dateCreated > b.dateCreated) {
                return -1;
              }
              return 0;
            },
          )[0];
        },
        get organisation() {
          return hgcase.organisationId && getters.organisationById(hgcase.organisationId);
        },
        // maybe I'm a bit reduce happy and this could be done with a constants.service.map()
        get services() {
          // returns a dict of services
          // loop through the orders and add or change a service based on those statuses
          const servicesMap = this.orders.reduce((services, curOrder) => {
            if (curOrder.service in services) {
              services[curOrder.service].orders.push(curOrder);
            } else {
              // eslint-disable-next-line no-param-reassign
              services[curOrder.service] = {
                id: curOrder.service,
                name: constants.SERVICE_NAMES[curOrder.service],
                orders: [curOrder],
                get color() {
                  return constants.SERVICE_STATUS_COLORS[this.status];
                },
                get devices() {
                  return this.orders.reduce((acc, order) => {
                    order.deviceIds.map((deviceId) => {
                      const device = getters.deviceById(deviceId);
                      return Object.assign(acc, {
                        [deviceId]: {
                          ...device,
                        },
                      });
                    });
                    return acc;
                  }, {});
                },
                get selectedVariants() {
                  // Normally only the simulation service has deviceVariantIds
                  return this.orders
                  // get a flat list of the variants
                    .map((order) => order.deviceVariantIds)
                    .flat(1)
                  // make it unique
                    // eslint-disable-next-line max-len
                    .filter((deviceVariantId, index, self) => self.indexOf(deviceVariantId) === index)
                  // return the variant object, if it exists
                    .map((deviceVariantId) => getters.deviceVariantById(deviceVariantId))
                    .filter((variant) => variant)
                  // sort by name
                    .sort((a, b) => parseInt(a.name, 10) - parseInt(b.name, 10));
                },
                get orderStatuses() {
                  return this.orders.map((order) => {
                    if (order.delivery && order.delivery.isObsoleted) {
                      return constants.ORDER_STATUSES.OBSOLETED;
                    }
                    return order.status;
                  });
                },
                get isClinical() {
                  return this.orders.map((order) => order.isClinical).every((v) => v === true);
                },
                get degradationRemark() {
                  return this.orders
                    .filter((order) => !order.isClinical)
                    .map((order) => order.degradationRemark)
                    .join(',');
                },
                get status() {
                  // If all of the orders are obsoleted, the service is obsoleted.
                  if (this.orderStatuses.every((os) => os === constants.ORDER_STATUSES.OBSOLETED)) {
                    return constants.SERVICE_STATUSES.OBSOLETED;
                  }

                  // If one of the orders is canceled, the whole service is canceled.
                  if (this.orderStatuses.some((os) => os === constants.ORDER_STATUSES.CANCELED)) {
                    return constants.SERVICE_STATUSES.CANCELED;
                  }

                  const hasDeliveredOrder = this.orderStatuses
                    .some((os) => os === constants.ORDER_STATUSES.DELIVERED);
                  const hasInProgressOrder = this.orderStatuses
                    .some((os) => os === constants.ORDER_STATUSES.IN_PROGRESS);

                  if (hasDeliveredOrder) {
                    // If there is a delivered order and an in progress order,
                    // the service is under evaluation.
                    if (hasInProgressOrder) {
                      return constants.SERVICE_STATUSES.UNDER_EVALUATION;
                    }
                    return constants.SERVICE_STATUSES.DELIVERED;
                  }

                  if (!hasDeliveredOrder && hasInProgressOrder) {
                    // 'under evaluation', which means it's in the FEops pipeline
                    if (hgcase.status === constants.CASE_STATUSES.IN_PROGRESS) {
                      return constants.SERVICE_STATUSES.UNDER_EVALUATION;
                    }
                    // waiting for the user to upload dicom images OR
                    return constants.SERVICE_STATUSES.AWAITING_INPUTDATA;
                  }

                  return constants.SERVICE_STATUSES.UNKNOWN;
                },
                get label() {
                  return constants.SERVICE_STATUS_LABELS[this.status];
                },
              };
            }
            return services;
          }, {});
          // Augment the map with methods that return an array:
          return {
            ...servicesMap,
            orderBy: (param) => Object.values(servicesMap).sort((a, b) => a[param] - b[param]),
          };
        },
        get simulationDevices() {
          if (constants.SERVICE.SIMULATION in this.services) {
            return this.services[constants.SERVICE.SIMULATION].devices;
          }
          return {};
        },
        get anatomicalAnalysisDevices() {
          if (constants.SERVICE.ANATOMICAL in this.services) {
            return this.services[constants.SERVICE.ANATOMICAL].devices;
          }
          return {};
        },
        get simulationDeviceVariants() {
          if (constants.SERVICE.SIMULATION in this.services) {
            return this.services[constants.SERVICE.SIMULATION].selectedVariants;
          }
          return [];
        },
        get simulationLicenseAvailable() {
          // IF there is not simulation
          // hgcase.organisation has simulation license
          // has simulation license for the selected devices
          return this.organisation && this.hasAnatomicalOrder && Object.values(
            this.services[constants.SERVICE.ANATOMICAL].devices,
          )
            .filter((d) => d)
            .some(
              (device) => this.organisation.licenseFor(device.id, constants.SERVICE.SIMULATION),
            );
        },
        get statusDisplay() {
          return constants.CASE_STATUS_LABELS[hgcase.status];
        },
        get study() {
          return hgcase.studyId && getters.studyById(hgcase.studyId);
        },
        get isSharedToCurrentUser() {
          return hgcase.sharedWith.map((u) => u.id).includes(getters.user.sub);
        },
      };
    }
    return undefined;
  },

  hgcases: (state, getters) => Object.keys(state.cases).map((id) => getters.hgcaseById(id)),
  inputDataById: (state) => (id) => {
    const inputData = state.inputData[id];
    if (inputData) {
      return inputData;
    }
    return undefined;
  },
  inputData: (state, getters) => Object.keys(state.inputData)
    .map((id) => getters.inputDataById(id)),

  filteredHgcases: (state, getters) => getters.hgcases
    .filter(
      (hgcase) => {
        // filter by active organisation
        if (getters.selectedOrganisation) {
          return hgcase.organisationId === getters.selectedOrganisation.id;
        }
        return true;
      },
    )
    .sort((a, b) => {
      // TODO: Figure out why ths is `.toLowerCase`d
      const dateCreatedA = a.dateCreated.toLowerCase();
      const dateCreatedB = b.dateCreated.toLowerCase();

      if (dateCreatedA < dateCreatedB) {
        return 1;
      }
      if (dateCreatedA > dateCreatedB) {
        return -1;
      }
      return 0;
    }),

  // rejectionReasons
  rejectionReasonById: (state) => (id) => {
    const reason = state.rejectionReasons[id];
    if (reason) {
      return {
        ...reason,
      };
    }
    return undefined;
  },
  rejectionReasons: (state, getters) => Object
    .keys(state.rejectionReasons)
    .map((id) => getters.rejectionReasonById(id))
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (b.name < a.name) {
        return 1;
      }
      return 0;
    }),

  // degradationReasons
  degradationReasonById: (state) => (id) => {
    const reason = state.degradationReasons[id];
    if (reason) {
      return {
        ...reason,
      };
    }
    return undefined;
  },
  degradationReasons: (state, getters) => Object
    .keys(state.degradationReasons).map((id) => getters.degradationReasonById(id)),
  getCompletedCases: (state, getters) => getters.filteredHgcases.filter((hgcase) => (
    hgcase.status === constants.CASE_STATUSES.COMPLETED
  )),
  orderSimulationDialog(state) {
    return state.orderSimulationDialog;
  },
  getSearchFilters(state) {
    return {
      searchTerm: state.casesPage.searchTerm,
      organisationId: state.casesPage.organisationId,
    };
  },
  showCancelCaseDialog: (state) => state.dialogs.showCancelCaseDialog,
  showShareCaseDialog: (state) => state.dialogs.showShareCaseDialog,
  currentCase: (state, getters) => getters.hgcaseById(state.dialogs.currentCaseId),
};
