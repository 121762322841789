<template>
  <v-dialog v-model="dialog" max-width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text outlined small
        v-bind="attrs"
        v-on="on"
      >
        Obsolete Delivery
      </v-btn>
    </template>
    <v-form
      ref="obsoleteDeliveryForm"
      @submit.native.prevent="submit"
    >
      <v-card>
        <v-card-title class="text-h5 justify-center">
          Obsolete {{ order.serviceDisplay }} delivery
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-6">
          WARNING: This will obsolete the delivery and all its associated
          results. The customer will not be automatically notified of this action.
        </v-card-text>

        <v-card-actions class="px-6 pa-4">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" depressed type="submit">
            Obsolete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'ObsoleteDeliveryDialog',
  data() {
    return {
      errors: {},
      dialog: false,
    };
  },
  props: ['order'],
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('obsoleteDelivery', {
          deliveryId: this.order.delivery.id,
        });
        this.$store.dispatch('retrieveCase', this.order.caseId);
        this.dialog = false;
      } catch (error) {
        this.errors = error.response && error.response.data;
      }
    },
  },
};
</script>
