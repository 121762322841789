import countries from './countries';

export const convertArrayToObject = (array, key = 'id') => array
  .filter((item) => key in item)
  .reduce(
    (acc, curr) => ({
      ...acc,
      [curr[key]]: curr,
    }), {},
  );

export const downloadResource = (resource) => {
  // Download in new tab
  window.location.assign(resource.readUrl, '_blank');
};

export const getCountryNames = new Intl.DisplayNames(['en'], { type: 'region' });

export default {
  convertArrayToObject,
  downloadResource,
  getCountryNames,
  countries,
};
