import Resource from '@/services/resource';
import client from './axiosClient';

export default new Resource(client, 'orders', {
  duplicate(id) {
    const url = `/${this.base}/${id}/duplicate/`;
    return this.client.post(url);
  },
  changeDevice(id, data) {
    const url = `/${this.base}/${id}/change-requested-device/`;
    return this.client.put(url, data);
  },
});
