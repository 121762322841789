<template>
  <div>

    <AppBar>
      <!-- Case Search  -->
      <v-text-field
        class="mr-4 search-field"
        hide-details
        single-line
        dense
        solo
        v-model="searchTerm"
        label="Search"
        background-color="secondary lighten-2"
        prepend-inner-icon="search"
        @input="doSearch"
      >
      </v-text-field>
      <v-responsive v-if="user.hasManyOrgs" max-width="250">
        <OrganisationSearch v-model="selectedOrganisationId" @input="doSetOrganisation"
          backgroundColor="secondary lighten-2" />
      </v-responsive>

      <v-tooltip bottom v-if="hasFiltersApplied">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="ml-1 white-icon"
            v-bind="attrs" v-on="on" @click="resetFilters">
            <v-icon>cancel</v-icon>
          </v-btn>
        </template>
        Reset Filters
      </v-tooltip>

      <v-btn color="primary"
        depressed
        v-if="user.canAddCase"
        @click='requestAnalysis'
        class="ml-4 request-analysis">
        <v-icon left>
          add
        </v-icon>
        Request analysis
      </v-btn>

      <v-btn icon plain @click='toggleTableView' class="view-switch">
        <v-icon v-if="showTableView">
          grid_view
        </v-icon>
        <v-icon v-else>
          table_rows
        </v-icon>
      </v-btn>

    </AppBar>
    <v-main>

      <v-alert
        class ="ma-2"
        color="warning"
        v-for="intervention in $store.getters.interventionsForPrimary.filter(i=>i.trainingRequired)"
        :key="intervention.id"
        data-cy="training-required-page"
      >
        <span>
          {{ intervention.name }}: A new training is available and required. Click
          <span>
            <router-link class="white--text"
              :to="{name: 'training', params:{interventionPrefix: intervention.prefix}}"
            >here</router-link> to follow it.
          </span>
        </span>
      </v-alert>

      <v-container fluid>
        <CaseTable v-if="showTableView" />
        <CaseCards v-else />
        <v-alert
          v-if="!isLoading && $store.getters.filteredHgcases.length == 0"
          type="info"
        >
          No cases match your search criteria
        </v-alert>
        <v-footer bottom fixed fixed-bottom>
          <v-pagination
            v-if="this.$store.getters.casesTableView"
            class="ma-auto"
            total-visible="10"
            v-model="pageIndex"
            :value="pageIndex"
            :length="pageCount"
            @input="doSetPage"
          />
          <div class="ma-auto" v-else>
            <v-progress-linear
              v-if="$store.state.cases.casesPage.isLoading"
              indeterminate
              color="primary"
            ></v-progress-linear>
            <div
              v-if="$store.state.cases.casesPage.nextPage"
              class='caption small'>
              Showing {{ $store.getters.filteredHgcases.length }}
              out of {{ $store.state.cases.casesPage.totalCount }} cases. Scroll down to see more.
            </div>
          </div>
        </v-footer>
      </v-container>
    </v-main>

    <CaseCreateDialog v-model="showCaseCreateDialog" />
    <OrderSimulationDialog v-if="user.canAddOrder" />
    <ShareDialog v-if="user.canShareCase"/>
    <CaseCancelDialog v-model="myShowCancelCaseDialog"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { debounce } from 'debounce';

import OrganisationSearch from '@/components/common/OrganisationSearch.vue';
import AppBar from '@/components/layout/AppBar.vue';

import CaseCreateDialog from './CaseCreateDialog';
import CaseCancelDialog from '@/components/Cases/CaseCancelDialog.vue';
import CaseCards from './CaseCards';
import CaseTable from './CasesTable';
import OrderSimulationDialog from './OrderSimulationDialog';
import ShareDialog from '@/components/Cases/ShareDialog.vue';

export default {
  name: 'CasesPage',
  components: {
    AppBar,
    CaseCards,
    CaseCreateDialog,
    CaseTable,
    OrderSimulationDialog,
    OrganisationSearch,
    CaseCancelDialog,
    ShareDialog,
  },
  data() {
    return {
      searchTerm: null,
      pageIndex: 1,
      selectedOrganisationId: null,
      showCaseCreateDialog: false,
    };
  },
  async created() {
    // Lowered the total delay to 100
    // any new search will cancel all running request
    this.debounceListCases = debounce(this.listCases, 100);

    this.selectedOrganisationId = this.$store.getters.selectValidOrganisation(
      this.$route.query.org, false,
    ) || this.getSearchFilters.organisationId;

    this.searchTerm = this.$route.query.search || this.getSearchFilters.searchTerm;

    // Determine table or cards view
    this.pageIndex = Number(this.$route.query.page) || 1;
    if (this.$route.query.view) {
      if (this.$route.query.view === 'table') {
        await this.$store.dispatch('setTableView', { tableView: true });
      }
      if (this.$route.query.view === 'cards') {
        await this.$store.dispatch('setTableView', { tableView: false });
      }
    } else if (this.$store.getters.casesTableView) {
      this.$router.push(
        { path: this.$route.path, query: { ...this.$route.query, view: 'table', page: 1 } },
      );
    }
    this.debounceListCases();
    this.$store.dispatch('getTrainings');
    this.$store.dispatch('loadDevices');
    this.$store.dispatch('loadDeviceVariants');
    this.$store.dispatch('updateDegradationReasons');
    this.$store.dispatch('updateInterventions');
    this.$store.dispatch('updateRejectionReasons');
  },
  computed: {
    ...mapGetters(['user', 'canAddCase', 'getSearchFilters', 'showCancelCaseDialog', 'showShareCaseDialog']),
    myShowCancelCaseDialog: {
      get() {
        return this.showCancelCaseDialog;
      },
      set() {
        this.$store.dispatch('hideCancelCaseDialog');
      },
    },
    myShowShareCaseDialog: {
      get() {
        return this.showShareCaseDialog;
      },
      set() {
        this.$store.dispatch('hideShareCaseDialog');
      },
    },
    ...mapState({
      constants: (state) => state.cases.constants,
      isLoading: (state) => state.cases.casesPage.isLoading,
      pageCount: (state) => state.cases.casesPage.pageCount,
    }),
    showTableView() {
      return this.$store.getters.casesTableView;
    },
    hasFiltersApplied() {
      return this.getSearchFilters.searchTerm || this.getSearchFilters.organisationId;
    },
  },
  methods: {
    async toggleTableView() {
      this.$store.dispatch('setTableView', { tableView: !this.showTableView });
      // If we go back to the gridview, remove the `page` query, and reload the cards
      if (!this.$store.getters.casesTableView) {
        this.$router.push(
          { path: this.$route.path, query: { ...this.$route.query, view: 'cards', page: undefined } },
        );
      } else {
        this.$router.push(
          { path: this.$route.path, query: { ...this.$route.query, view: 'table', page: 1 } },
        );
      }
    },

    async requestAnalysis() {
      this.showCaseCreateDialog = true;
    },
    async doSearch() {
      this.debounceListCases();
    },
    async doSetOrganisation() {
      this.debounceListCases();
    },

    doSetPage(index) {
      this.pageIndex = index;
      if (this.$store.getters.casesTableView) {
        if (this.$route.query.page !== String(index)) {
          this.$router.push({
            path: this.$route.path,
            query: { ...this.$route.query, page: this.pageIndex },
          });
        }
      }
      this.debounceListCases();
    },

    updateRouter() {
      if (
        this.selectedOrganisationId !== this.$route.query.org
        || this.searchTerm !== this.$route.query.search
      ) {
        this.$router.push(
          {
            path: this.$route.path,
            query: {
              ...this.$route.query,
              org: this.selectedOrganisationId,
              search: this.searchTerm,
            },
          },
        );
      }
    },
    async listCases() {
      // Only update the router query when we do a new request
      this.updateRouter();

      // only called by debounceListCases
      if (this.$store.getters.casesTableView) {
        this.$store.dispatch('getCasesPage', {
          pageIndex: this.pageIndex,
          organisationId: this.selectedOrganisationId,
          searchTerm: this.searchTerm,
        })
          .catch(
            (error) => {
              if (error.response && error.response.data.detail === 'Invalid page.') {
                // slightly different procedure then doSetPage, see below
                this.pageIndex = 1;
                // replace to hide this in the history
                this.$router.replace({
                  path: this.$route.path,
                  query: { ...this.$route.query, page: this.pageIndex },
                });
              } else {
                throw (error);
              }
            },
          );
      } else {
        this.$store.dispatch('setCases', { searchTerm: this.searchTerm, organisationId: this.selectedOrganisationId });
      }
    },
    resetFilters() {
      this.searchTerm = null;
      this.selectedOrganisationId = null;
      this.debounceListCases();
    },
  },
  watch: {
    $route(newRoute) {
      // any changes to the route will be passed to the appropriate functions
      // they will call a (dbounced) call to the cases endpoint if there is an actual update
      // this will be needed when a user goes 'back` and 'forward` in the browser
      if (newRoute.query.search !== this.searchTerm) {
        this.searchTerm = newRoute.query.search || this.getSearchFilters.searchTerm;
        this.debounceListCases();
      }
      if (newRoute.query.org !== this.selectedOrganisationId) {
        this.selectedOrganisationId = newRoute.query.org
          || this.getSearchFilters.organisationId
          || null;
        this.debounceListCases();
      }

      if (newRoute.query.view) {
        if (newRoute.query.view === 'table') {
          this.$store.dispatch('setTableView', { tableView: true });
          if (Number(this.$route.query.page) !== this.pageIndex) {
            this.doSetPage(Number(this.$route.query.page));
          }
        }
        if (newRoute.query.view === 'cards') {
          this.$store.dispatch('setTableView', { tableView: false });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.white-icon {
  color: #FFF !important;
}
</style>
