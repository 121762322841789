<template>
  <v-dialog :value="value" @input="emitInput" max-width="600px">
    <v-card>
      <v-card-title class="text-h5 justify-center">
        {{ study.name }} - Upload {{ !orderId ? 'Study Resource' : 'PDF Report' }}
      </v-card-title>
      <v-divider />
      <v-card-text class="text-center pt-4">
        <v-form
          @submit.native.prevent="handleSubmit"
          v-model="isValid"
          v-if="!upload.inProgress"
        >
          <v-select v-if="!orderId"
            outlined
            dense
            label="Type of Study Resource"
            :items="resourceTypes"
            item-text="name"
            item-value="id"
            v-model="form.type"
            :rules="[isRequired]"
          >
          </v-select>
          <v-alert v-if="upload.hasFile && !hasCaseIdInFilename" type="error" dense>
            The file does not match the case-identifier.
          </v-alert>
          <!-- Show warning if uploading Pseudonimyzed DICOM and one already exists -->
          <v-alert
            v-if="form.type === constants.PSEUDONYMIZED_DICOM && hasPseudonymizedDicom"
            type="warning"
            dense
          >
            A pseudonymized DICOM already exists for this study.
          </v-alert>
          <div
            class="c-dropzone primary50 pa-4 primary--text"
            style="border-color: var(--v-primary-base) !important;"
          >
            <input
              type="file"
              @change="onFileInputAdd"
              ref="fileInput"
              style="display: none"
            />
            <div
              class="my-auto"
              v-if="!upload.filename"
              @dragover.prevent
              @drop="onDrop"
              @click="$refs.fileInput.click()"
            >
              Upload your {{ !orderId ? 'resource' : 'report' }}!<br />
              <v-icon x-large color="primary">cloud_upload</v-icon>
            </div>
            <div
              v-if="upload.filename && !upload.inProgress"
              class="d-flex align-items-vertical"
            >
              <h4 class="mx-auto">{{ upload.filename }}</h4>
            </div>
          </div>

          <div v-if="this.upload.file">
            <div>
              <div v-if="hasCaseIdInFilename" class="font-italic">
                Please verify the file name before starting the upload.
              </div>
              <div v-for="warning in warnings" :key="warning" >
                <v-icon color="warning">
                  warning
                </v-icon>
                {{ warning }}
                <v-icon color="warning">
                  warning
                </v-icon>
              </div>
              <v-btn class="ma-1" outlined @click="cancelUpload">
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                depressed
                color="primary"
                @click="startUpload"
                :disabled="!hasCaseIdInFilename"
              >
                Upload
              </v-btn>
            </div>
          </div>
        </v-form>

        <div v-else-if="!upload.isComplete">
          <h3 class="text-h5">Upload in progress</h3>
          <p>
            Uploading may take several minutes depending on your connection and
            the size of the file.
          </p>
          <v-progress-circular
            :text-inside="true"
            :stroke-width="38"
            :value="upload.progress"
            color="#e6a23c"
          />
          <p></p>
          <v-btn outlined v-if="upload.inProgress" @click="cancelUpload">
            Cancel
          </v-btn>
        </div>
        <div v-else>
          <v-alert color="info" text> Images uploaded! </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined @click="hide">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>

    <ConfirmationDialog ref="confirm" />
  </v-dialog>
</template>

<script>
import constants from '@/store/modules/studies/constants';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';

export default {
  name: 'UploadStudyResourceDialog',
  props: {
    study: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: null,
    },
  },
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      isRequired: (v) => !!v || 'This field is required',
      dialog: false,
      isValid: false,
      processing: false,
      form: {
        type: !this.orderId ? constants.PSEUDONYMIZED_DICOM : constants.PDFREPORT,
        // name: 'Pseudonymized DICOM2',
      },
      resourceTypes: [
        {
          id: constants.PSEUDONYMIZED_DICOM,
          name: 'Pseudonymized DICOM',
        }, {
          id: constants.VTKJS,
          name: 'VTK.js',
        },
      ],
      upload: {
        inProgress: false,
        file: null,
        progress: 0,
      },
    };
  },
  computed: {
    constants() {
      return constants;
    },
    hasCaseIdInFilename() {
      return this.upload.hasCaseIdInFilename(this.study.caseIdentifier);
    },
    hasPseudonymizedDicom() {
      return this.study.resources.some(
        (r) => r.type === constants.PSEUDONYMIZED_DICOM,
      );
    },
    warnings() {
      if (this.upload.hasFile) {
        const warnings = [];
        const zipFiles = [constants.PSEUDONYMIZED_DICOM, constants.VTKJS];

        if (zipFiles.includes(this.form.type)) {
          if (!this.upload.filename.endsWith('.zip')) {
            warnings.push("This doesn't look like a zip file.");
          }
          if (this.upload.file.size < 1000000) {
            warnings.push('This file seems too small.');
          }
        } else if (!this.upload.filename.endsWith('.pdf')
          && this.form.type === constants.PDFREPORT) {
          warnings.push("This doesn't look like a pdf file.");
        }

        if (warnings.length) {
          return warnings;
        }
      }
      return undefined;
    },
    metadata() {
      if (this.upload.hasFile) {
        return { mimeType: this.upload.file.type };
      }
      return {};
    },
  },
  methods: {
    addFile(file) {
      this.$store.dispatch('addFile', { file }).then((upload) => {
        this.upload = upload;
      });
    },
    hide() {
      this.emitInput(false);
    },
    emitInput(newVal) {
      this.$emit('input', newVal);
    },
    beforeClose(event) {
      if (!this.inProgress) {
        this.cancelUpload();
        this.$store.dispatch('resetUpload', this.upload);
      } else {
        event.stop();
        this.$toasted.global.info({
          message: 'Please cancel your upload before closing the dialog',
        });
      }
    },
    cancelUpload() {
      this.$store.dispatch('cancelUpload', this.upload);
    },
    async startUpload() {
      if (this.form.type === constants.PSEUDONYMIZED_DICOM && this.hasPseudonymizedDicom) {
        const response = await this.$refs.confirm.open('Confirm',
          'Uploading a new Pseudonymized DICOM image will change the data available in the DICOM MPR view. Please make sure that this remains consistent with any existing results. Are you sure you want to continue?');
        if (!response) return;
      }

      const model = {
        ...this.form,
        studyId: this.study.id,
        metadata: this.metadata,
        name: this.upload.filename,
      };

      if (this.orderId) model.orderId = this.orderId;

      this.processing = true;
      this.$store.dispatch('createStudyResource', model)
        .then((studyResource) => {
          this.$store
            .dispatch('startUpload', {
              upload: this.upload,
              writeUrl: studyResource.writeUrl,
              resource: studyResource,
            })
            .then(() => {
              this.$store.dispatch('studyResourceConfirmUpload', { id: studyResource.id });
              this.processing = false;
              this.hide();
              const message = !this.orderId ? 'Study Resource uploaded!' : 'PDF Report uploaded!';
              this.$toasted.global.success({ message });
              this.$store.dispatch('retrieveStudy', this.study.id);
              this.$store.dispatch('listStudyResources', {
                study_id: this.study.id,
              });
            })
            .catch((error) => {
              this.processing = false;
              // eslint-disable-next-line
              if (error && error.__CANCEL__) {
                this.$toasted.global.error({ message: error.message });
              } else {
                this.$toasted.global.error({ error });
              }
            });
        });
    },
    onFileInputAdd(e) {
      /* Triggered by clicking the dropzone, which triggers clicking the hidden
      file input */
      e.preventDefault();
      this.addFile(e.target.files[0]);
    },
    onDrop(e) {
      /* Triggered by dropping on the dropzone */
      e.preventDefault();
      this.addFile(e.dataTransfer.files[0]);
    },
  },
};
</script>
