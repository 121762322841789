const ANGLE = 0;
const AREA = 1;
const CLOSED_CURVE = 2;
const DISTANCE = 3;
const POINT = 4;
const OPEN_CURVE = 5;
const CENTERLINE = 6;

const SAGITTAL = 4;
const CORONAL = 5;
const AXIAL = 6;

const AORTIC_ROOT = 0;
const FEMORAL = 1;

export default {
  ANGLE,
  AREA,
  CLOSED_CURVE,
  DISTANCE,
  POINT,
  OPEN_CURVE,
  CENTERLINE,
  SAGITTAL,
  CORONAL,
  AXIAL,
  AORTIC_ROOT,
  FEMORAL,

  GEOMETRY_TYPES_3D: [
    AREA, CLOSED_CURVE, DISTANCE, OPEN_CURVE,
  ],

  LANDMARK_GEOMETRY_TYPES: {
    [ANGLE]: 'angle',
    [AREA]: 'area',
    [CLOSED_CURVE]: 'closed curve',
    [DISTANCE]: 'distance',
    [POINT]: 'point',
    [OPEN_CURVE]: 'open_curve',
    [CENTERLINE]: 'centerline',
  },

  VIEW_TYPES: {
    [SAGITTAL]: 'sagittal',
    [CORONAL]: 'coronal',
    [AXIAL]: 'axial',
  },

  VIEW_TYPE_COLORS: {
    [SAGITTAL]: [0, 255, 0],
    [CORONAL]: [255, 153, 0],
    [AXIAL]: [255, 0, 0],
  },
};
