<template>
  <div class="grey lighten-5">
    <AppBar>
      <h4 class="text-h5">Settings</h4>
    </AppBar>
    <v-main>
      <v-container>
        <v-card class="mt-4" outlined max-width="840">
          <v-card-title>User Details
            <v-btn v-if="!isEditable" small outlined @click="toggleEditable"
              class="ml-auto edit-button">Edit</v-btn>
          </v-card-title>

          <v-form @submit.native.prevent="changeProfile"
            :class="{'pb-4': !isEditable}" class="user-details-form">
            <div v-if="userErrors.nonFieldErrors">
              <v-alert :key="error" v-for="error in userErrors.nonFieldErrors"
                type="error">{{ error }}</v-alert>
            </div>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Email: </v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Primary organisation: </v-list-item-title>
                <v-list-item-subtitle>
                  {{ user.primaryOrganisation && user.primaryOrganisation.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-col cols=3 md=2>
                <v-list-item two-line class="user-title">
                  <v-list-item-content>
                    <v-list-item-title>Title: </v-list-item-title>
                    <v-select
                      v-if="isEditable"
                      outlined
                      dense
                      v-model="userForm.title"
                      :error-messages="userErrors.title"
                      :items="titleChoices"
                    ></v-select>
                    <v-list-item-subtitle v-else>{{ user.title || '--' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols=9 md=4>
                <v-list-item two-line class="first-name">
                  <v-list-item-content>
                    <v-list-item-title>First name: </v-list-item-title>
                    <v-text-field
                      v-if="isEditable"
                      outlined
                      dense
                      v-model="userForm.givenName"
                      :error-messages="userErrors.givenName"
                    ></v-text-field>
                    <v-list-item-subtitle v-else>
                      {{ user.givenName || '--' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col md=2>
                <v-list-item two-line class="middle-name">
                  <v-list-item-content>
                    <v-list-item-title>Middle: </v-list-item-title>
                    <v-text-field
                      v-if="isEditable"
                      outlined
                      dense
                      v-model="userForm.middleName"
                      :error-messages="userErrors.middleName"
                    ></v-text-field>
                    <v-list-item-subtitle v-else>
                      {{ user.middleName || " -- " }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col md=4>
                <v-list-item two-line class="last-name">
                  <v-list-item-content>
                    <v-list-item-title>Last Name: </v-list-item-title>
                    <v-text-field
                      v-if="isEditable"
                      outlined
                      dense
                      v-model="userForm.familyName"
                      :error-messages="userErrors.familyName"
                    ></v-text-field>
                    <v-list-item-subtitle v-else>
                      {{ user.familyName || '--' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-list-item two-line class="phone-number">
              <v-list-item-content>
                <v-list-item-title>Phone Number: </v-list-item-title>
                <v-text-field
                  v-if="isEditable"
                  outlined
                  dense
                  v-model="userForm.phoneNumber"
                  :error-messages="userErrors.phoneNumber"
                ></v-text-field>
                <v-list-item-subtitle v-else>
                  {{ user.phoneNumber || '--' }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions v-if="isEditable" class="px-4 pb-4 pt-0">
              <v-btn color="secondary" outlined @click="toggleEditable">
                Cancel
              </v-btn>
              <v-btn color="primary" depressed type="submit">
                {{ loading ? 'Loading...' : 'Save Changes' }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>

        <v-card class="mt-4" outlined max-width="840">
          <v-card-title>Change password</v-card-title>
          <v-card-text>
            <template v-if="!passwordSubmitted">
              <div v-if="passwordErrors.nonFieldErrors">
                <v-alert :key="error" v-for="error in passwordErrors.nonFieldErrors"
                  type="error">{{ error }}</v-alert>
              </div>

              <v-form
                class="password-reset-form"
                v-model="passwordFormIsValid"
                ref="passwordreset-form"
                @submit.native.prevent="changePassword"
              >
                <v-text-field
                  outlined
                  dense
                  v-model="passwordForm.oldPassword"
                  :rules="[isRequired, min8Chars]"
                  type="password"
                  :error-messages="passwordErrors.oldPassword"
                  autocomplete="off"
                  required
                  minlength="8"
                  class="old-password">
                  <template v-slot:label>
                    <span class="red--text">* </span>Old password
                  </template>
                </v-text-field>

                <v-text-field
                  class="new-password"
                  outlined
                  dense
                  v-model="passwordForm.newPassword"
                  :rules="[isRequired, min13Chars]"
                  type="password"
                  :error-messages="passwordErrors.newPassword"
                  autocomplete="new-password"
                  required
                  minlength="13"
                >
                  <template v-slot:label>
                    <span class="red--text">* </span>New password
                  </template>
                </v-text-field>

                <v-text-field
                  class="retype-password"
                  outlined
                  dense
                  v-model="passwordForm.newPassword2"
                  :rules="[isRequired, confirmPassword]"
                  type="password"
                  :error-messages="passwordErrors.newPassword2"
                  autocomplete="off"
                  required
                  minlength="13"
                  ref="newPassword2">
                  <template v-slot:label>
                    <span class="red--text">* </span>Re-type password
                  </template>
                </v-text-field>

                <v-btn color="primary" depressed
                  :disabled="!passwordFormIsValid" :loading="loading" type="submit">
                  {{ loading ? 'Loading...' : 'Change' }}
                </v-btn>
              </v-form>
            </template>

            <v-alert v-else type="info" class="mb-0">Password successfully changed</v-alert>
          </v-card-text>
        </v-card>

        <v-card class="mt-4" outlined max-width="840">
          <v-card-title>Heartguide Settings</v-card-title>

          <v-card-text>
            <v-switch class="toggle-identifiers" v-model="hidePatientID"
              label="Hide patient identifiers" />

            <v-radio-group label="Default view for cases" v-model="defaultCasesView"
              class="cases-view">
              <v-radio label="Cards" value="cards"></v-radio>
              <v-radio label="Table" value="table"></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppBar from '@/components/layout/AppBar.vue';

export default {
  name: 'settings',
  components: {
    AppBar,
  },
  data() {
    return {
      titleChoices: [
        'Dr.',
        'Prof. Dr.',
        'Mr.',
        'Mrs.',
        'Ms.',
      ],
      userForm: {},
      userErrors: {},
      passwordErrors: {},
      passwordForm: {
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
      },
      passwordFormIsValid: false,
      passwordSubmitted: false,
      isEditable: false,
      isRequired: (v) => !!v || 'This field is required',
      min8Chars: (v) => v.length >= 8 || 'Must contain at least 8 characters',
      min13Chars: (v) => v.length >= 13 || 'Must contain at least 13 characters',
      confirmPassword: (v) => this.passwordForm.newPassword === v || 'Passwords don\'t match',
    };
  },
  computed: {
    ...mapGetters({
      loading: 'authLoading',
      user: 'user',
    }),
    hidePatientID: {
      get() { return this.$store.getters.hidePatientID; },
      set(value) {
        this.$store.dispatch('patchSettings', { patch: { hide_patient_identifier: value } });
      },
    },
    defaultCasesView: {
      get() { return (this.user.settings.cases_default_table_view ? 'table' : 'cards'); },
      set(value) {
        this.$store.dispatch('patchSettings', { patch: { cases_default_table_view: value === 'table' || false } });
      },
    },
  },
  methods: {
    toggleEditable() {
      this.isEditable = !this.isEditable;
      this.userForm = { ...this.$store.state.auth.user };
      this.userErrors = {};
    },
    changePassword() {
      if (!this.passwordFormIsValid) return;
      this.$store.dispatch('changePassword', { data: this.passwordForm })
        .then(() => {
          this.passwordSubmitted = true;
        })
        .catch((error) => {
          if (error.response) {
            this.passwordErrors = error.response.data;
          } else {
            // catch all for other errors
            this.passwordErrors = {
              nonFieldErrors: ['Something went wrong. Please try again later'],
            };
          }
        });
    },
    changeProfile() {
      this.$store.dispatch(
        'changeProfile',
        { data: this.userForm },
      )
        .then(() => {
          this.toggleEditable();
        })
        .catch((error) => {
          if (error.response) {
            this.userErrors = error.response.data;
          } else {
            this.userErrors = {
              nonFieldErrors: ['Something went wrong. Please try again later'],
            };
          }
        });
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'passwordForm.newPassword': function (newVal) {
      if (!this.passwordForm.newPassword2) return;
      if (newVal) this.$refs.newPassword2.validate();
    },
    // eslint-disable-next-line func-names
    'passwordForm.oldPassword': function () {
      if (!this.passwordErrors.oldPassword) return;
      delete this.passwordErrors.oldPassword;
    },
  },
};
</script>
