export default {
  CASE_STATUSES: Object.freeze({
    CREATED: 0,
    SUBMITTED: 1,
    IN_PROGRESS: 2,
    COMPLETED: 3,
    CANCELED: 4,
    INVALID_INPUT: 5,
    COULD_NOT_BE_PROCESSED: 6,
    REJECTED: 7,
  }),
  CASE_STATUS_LABELS: Object.freeze([
    'created',
    'submitted', // Deprecated
    'in progress',
    'complete',
    'canceled',
    'invalid input', // Deprecated
    'could not be processed', // Deprecated
    'rejected',
  ]),
  SERVICE: {
    ANATOMICAL: 0,
    SIMULATION: 1,
    LEGACY: 2,
  },
  SERVICE_NAMES: Object.freeze(['anatomical analysis', 'simulation', 'results']),
  SERVICE_STATUSES: Object.freeze({
    AWAITING_INPUTDATA: 0,
    UNDER_EVALUATION: 1,
    CANCELED: 2,
    DELIVERED: 3,
    OBSOLETED: 4,
    UNKNOWN: 5,
  }),
  SERVICE_STATUS_LABELS: Object.freeze([
    'awaiting DICOM images',
    'under evaluation',
    'canceled',
    'ready',
    'obsoleted',
    'unknown',
  ]),
  SERVICE_STATUS_COLORS: Object.freeze([
    'red',
    'warning',
    'gray',
    'success',
    'gray',
    'warning',
  ]),
  ORDER_STATUSES: Object.freeze({
    IN_PROGRESS: 0,
    CANCELED: 1,
    DELIVERED: 2,
    OBSOLETED: 3,
  }),
  ORDER_STATUS_LABELS: Object.freeze([
    'In Progress', // replaced "Waiting" to be more consistent
    'Canceled',
    'Delivered',
    'Obsoleted',
  ]),
};
