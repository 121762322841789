// eslint-disable-next-line import/named
import constants from '@/store/modules/cases/constants';

const deviceFromAccesses = (deviceId, allDeviceAccesses) => ({
  deviceId,
  get deviceAccesses() {
    return allDeviceAccesses.filter((da) => da.deviceId === deviceId);
  },

  get services() {
    return [constants.SERVICE.ANATOMICAL, constants.SERVICE.SIMULATION]
      .map((service) => ({
        service,
        isClinical: this.deviceAccesses
          .filter((da) => da.service === service)
          .some((da) => da.isClinical),
      }))
      .filter((s) => this.deviceAccesses.filter(
        (da) => da.service === s.service,
      ).length > 0);
  },

  get isTrial() {
    return this.deviceAccesses.some((da) => da.trialId);
  },

  get isSubscription() {
    return this.deviceAccesses.some((da) => !da.organisationId);
  },

  get isManual() {
    return this.deviceAccesses.every((da) => da.organisationId);
  },
});

export const devicesFromAccesses = (allDeviceAccesses) => {
  if (allDeviceAccesses) {
    return Object.values(allDeviceAccesses.reduce(
      (old, incoming) => {
        // eslint-disable-next-line no-param-reassign
        old[incoming.deviceId] = deviceFromAccesses(incoming.deviceId, allDeviceAccesses);
        return old;
      },
      {},
    ));
  }
  return [];
};

export default {
  devicesFromAccesses,
  deviceFromAccesses,
};
