<template>
  <v-autocomplete
    clearable
    single-line
    dense
    full-width
    outlined
    hide-no-data
    :hide-details="hideDetails"
    item-text="email"
    item-value="sub"
    placeholder="Start typing to search a user"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    return-object
    :value="value"
    @input="$emit('input', $event)"
    class="text-body-2"
    :rules="rules"
  >
    <template v-slot:label>
      <!-- Pass the label slot -->
      <!-- Or user the label prop as backup -->
      <slot name="label">{{ label }}</slot>
    </template>
  </v-autocomplete>
</template>

<script>
import Auth from '@/services/auth';

export default {
  name: 'UserAutoComplete',
  props: {
    value: Object,
    rules: Array,
    organisationId: String,
    label: {
      type: String,
      default: 'User',
    },
    hideDetails: {
      default: false,
      type: Boolean,
    },
    primaryMembersOnly: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      entries: [],
      isLoading: false,
      search: null,
    };
  },
  methods: {
    async querySelections() {
      this.isLoading = true;
      const response = await Auth.users.get({ params: { organisation_id: this.organisationId } });
      this.entries = response.data.results;
      if (this.primaryMembersOnly) {
        this.entries = this.entries
          .filter((entry) => (
            entry.primaryOrganisation
            && entry.primaryOrganisation.id === this.organisationId
          ));
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.querySelections();
  },
  computed: {
    items() {
      return [...this.entries, this.value];
    },
  },
};
</script>
