<template>
  <div>
    <AppBar />
    <v-main>
      <v-container>
        <h2 class="text-h4">Page Not Found!</h2>
        <p>This page does not exist. Please navigate back using the browser's 'back' button or
          <a href="/">go to the homepage.</a>
        </p>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AppBar from '@/components/layout/AppBar.vue';

export default {
  components: {
    AppBar,
  },
};
</script>
