<template>
  <v-dialog v-model="showDialog" max-width="640px">
    <template v-slot:activator="{on, attrs}">
      <v-btn outlined color="primary" v-on="on" v-bind="attrs">Change Password</v-btn>
    </template>

    <v-card>
      <v-card-title>Change password
        <v-btn class="ml-auto" icon type="button" @click="showDialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5 pb-3">
        <v-form v-model="isValid" ref="passwordForm">
          <v-text-field type="password" v-model="form.newPassword" outlined dense
            :rules="[isRequired, mustBe13Chars]">
            <template v-slot:label>
              Password <span class="red--text">*</span>
            </template>
          </v-text-field>
          <v-text-field type="password" v-model="form.newPassword2" outlined dense
            :rules="[isRequired, mustMatchPassword]" ref="newPassword2" class="mt-2">
            <template v-slot:label>
              Confirm Password <span class="red--text">*</span>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex justify-end pa-4">
        <v-btn text @click="showDialog = false">Cancel</v-btn>
        <v-btn text color="primary" :disabled="!isValid" @click="submit">Update Password</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ChangePasswordDialog',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isValid: false,
      showDialog: false,
      form: {
        newPassword: null,
        newPassword2: null,
      },
    };
  },
  methods: {
    isRequired: (v) => !!v || 'This field is required',
    mustBe13Chars: (v) => (v || '').length >= 13 || 'Password must be at least 13 characters',
    mustMatchPassword(v) {
      return v === this.form.newPassword || 'Passwords must match';
    },
    submit() {
      this.$store.dispatch('changeUserPassword', {
        userId: this.user.sub,
        data: { ...this.form },
      })
        .then(() => {
          this.$toasted.global.success({ message: 'Password changed successfully' });
          this.showDialog = false;
        })
        .catch((error) => this.$toasted.global.error({ error }));
    },
  },
  watch: {
    showDialog(value) {
      if (!value || !this.$refs.passwordForm) return;
      this.$refs.passwordForm.reset();
    },
    // eslint-disable-next-line func-names
    'form.newPassword': function (newVal) {
      if (!this.form.newPassword2 || !newVal) return;
      this.$refs.newPassword2.validate();
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  font-size: inherit;
  font-weight: bold;
}
</style>
