<template>
  <v-dialog :value="value" @input="emitInput" max-width="650px">
    <v-form
      ref="deviceAccessGroupCreateForm"
      @submit.native.prevent="submit"
    >
      <v-card>
        <v-card-title class="text-h5 justify-center">
          Add a template
        </v-card-title>
        <v-divider />
        <v-card-text class="px-6">
          <!-- Name -->
          <v-text-field type="text" outlined dense :hide-details="false"
            v-model="form.name" :rules="[isRequired]" :error-messages="errors['name']">
            <template v-slot:label>
              <span class="red--text">* </span>Name
            </template>
          </v-text-field>
        </v-card-text>
        <v-card-actions class="px-6 pa-4">
          <v-spacer />
          <v-btn
            outlined
            @click="emitInput(false)"
          >
            Close
          </v-btn>
          <v-btn color="primary"
            depressed type="submit" >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
function initialState() {
  return {
    errors: {},
    form: {
      name: '',
    },
    isRequired: (v) => !!v || 'This field may not be blank.',
  };
}

export default {
  name: 'DeviceAccessGroupCreateDialog',
  props: ['value'],
  data() {
    return initialState();
  },
  computed: {
  },
  methods: {
    emitInput(newVal) {
      this.$emit('input', newVal);
    },
    reset() {
      Object.assign(this.$data, initialState());
    },

    async submit() {
      try {
        await this.$store.dispatch('createDeviceAccessGroup', { deviceAccessGroup: this.form });
        this.emitInput(false);
        this.$refs.deviceAccessGroupCreateForm.resetValidation();
        this.reset();
      } catch (error) {
        this.errors = error.response && error.response.data;
      }
    },
  },
};
</script>
