import axios from 'axios';
import {
  analysesAPI,
  studiesAPI,
  studyResourcesAPI,
  studyReportAPI,
  analysisResourcesAPI,
} from '@/services/heartguide';

import { convertArrayToObject, downloadResource } from '@/utils';

import {
  ANALYSES_ISLOADING,
  ANALYSES_NEXTPAGE,
  ANALYSES_UPDATE,
  ANALYSIS_RESOURCE_DELETE,
  ANALYSIS_RESOURCES_ISLOADING,
  ANALYSIS_RESOURCES_NEXTPAGE,
  ANALYSIS_RESOURCES_UPDATE,
  GENERATING_REPORT,
  STUDIES_ISLOADING,
  STUDIES_UPDATE,
  STUDY_RESOURCE_DELETE,
  STUDY_RESOURCES_NEXTPAGE,
  STUDY_RESOURCES_ISLOADING,
  STUDY_RESOURCES_UPDATE,
  SET_UPLOAD_IN_PROGRESS,
} from '@/store/mutation-types';

export default {
  async listStudies({ commit, state }, params) {
    commit(STUDIES_ISLOADING, true);
    return studiesAPI.get({ params })
      .then((response) => {
        commit(STUDIES_ISLOADING, false);
        const studies = convertArrayToObject(response.data.results);
        commit(STUDIES_UPDATE, studies);

        if (response.data.next) {
          commit('STUDIES_NEXTPAGE', state.studies.nextPage + 1);
        } else {
          commit('STUDIES_NEXTPAGE', 1);
        }
        return studies;
      })
      .catch((error) => {
        commit(STUDIES_ISLOADING, false);
        throw error;
      });
  },
  retrieveStudy: ({ commit }, id) => new Promise((resolve) => {
    studiesAPI.get(id)
      .then((response) => {
        const studies = convertArrayToObject([response.data]);
        commit(STUDIES_UPDATE, studies);
        resolve(response.data);
      });
  }),
  updateStudy: ({ commit }, study) => new Promise((resolve, reject) => {
    studiesAPI.patch(study.id, study)
      .then((response) => {
        commit(STUDIES_UPDATE, convertArrayToObject([response.data]));
        resolve(study);
      })
      .catch((err) => {
        reject(err);
      });
  }),
  async createStudyResource({ rootState, commit }, studyResourceData) {
    return studyResourcesAPI.post({
      ...studyResourceData,
      sourceVersion: rootState.heartguide.version.frontend.version,
      sourceClient: rootState.heartguide.version.frontend.clientLabel,
    })
      .then((response) => {
        const studyResource = response.data;
        commit(STUDY_RESOURCES_UPDATE, convertArrayToObject([studyResource]));
        return (studyResource);
      });
  },

  async studyResourceConfirmUpload({ commit }, { id }) {
    return studyResourcesAPI.confirmUpload(id)
      .then((response) => {
        commit(SET_UPLOAD_IN_PROGRESS, false);
        return response;
      })
      .catch((error) => {
        commit(SET_UPLOAD_IN_PROGRESS, false);
        throw error;
      });
  },

  async listStudyResources({ commit, state }, params) {
    commit(STUDY_RESOURCES_ISLOADING, true);
    return studyResourcesAPI.get({ params })
      .then((response) => {
        commit(STUDY_RESOURCES_ISLOADING, false);
        const studyResources = convertArrayToObject(response.data.results);
        commit(STUDY_RESOURCES_UPDATE, studyResources);

        if (response.data.next) {
          commit(STUDY_RESOURCES_NEXTPAGE, state.studies.studyResourcesNextPage + 1);
        } else {
          commit(STUDY_RESOURCES_NEXTPAGE, 1);
        }
        return studyResources;
      })
      .catch((error) => {
        commit(STUDY_RESOURCES_ISLOADING, false);
        throw error;
      });
  },

  async changeStudyResourceAttribute({ commit }, { id, attributes }) {
    commit('SET_INTERPOLATION_TYPE', attributes.interpolationType ? 1 : 0);
    return studyResourcesAPI.partialUpdateAttributes(id, attributes);
  },

  async deleteStudyResource({ commit }, { studyResource }) {
    return studyResourcesAPI.delete(studyResource.id)
      .then(() => {
        commit(STUDY_RESOURCE_DELETE, { studyResource });
      });
  },

  async downloadStudyResource(store, { id }) {
    return studyResourcesAPI.get(id, { params: { download: true } })
      .then(({ data }) => {
        downloadResource(data);
      });
  },

  async downloadAnalysisResource(store, { id }) {
    return analysisResourcesAPI.get(id, { params: { download: true } })
      .then(({ data }) => {
        downloadResource(data);
      });
  },

  async listAnalyses({ commit, state }, params) {
    commit(ANALYSES_ISLOADING, true);
    return analysesAPI.get({ params })
      .then((response) => {
        commit(ANALYSES_ISLOADING, false);
        const analyses = convertArrayToObject(response.data.results);
        commit(ANALYSES_UPDATE, analyses);

        if (response.data.next) {
          commit(ANALYSES_NEXTPAGE, state.studies.analysesNextPage + 1);
        } else {
          commit(ANALYSES_NEXTPAGE, 1);
        }
        return analyses;
      })
      .catch((error) => {
        commit(ANALYSES_ISLOADING, false);
        throw error;
      });
  },

  async retrieveAnalysis({ commit }, id) {
    return analysesAPI.get(id)
      .then((response) => {
        const analyses = convertArrayToObject([response.data]);
        commit(ANALYSES_UPDATE, analyses);
        return response;
      });
  },

  async createAnalysis({ commit }, analysisData) {
    return analysesAPI.post({
      ...analysisData,
    })
      .then((response) => {
        const analysis = response.data;
        commit(ANALYSES_UPDATE, convertArrayToObject([analysis]));
        return analysis;
      });
  },
  async createAnalysisResource({ rootState, commit }, analysisResourceData) {
    return analysisResourcesAPI.post({
      ...analysisResourceData,
      sourceVersion: rootState.heartguide.version.frontend.version,
      sourceClient: rootState.heartguide.version.frontend.clientLabel,
    })
      .then((response) => {
        const analysisResource = response.data;
        commit(ANALYSIS_RESOURCES_UPDATE, convertArrayToObject([analysisResource]));
        return analysisResource;
      });
  },

  async analysisResourceConfirmUpload({ commit }, { id }) {
    return analysisResourcesAPI.confirmUpload(id)
      .then((response) => {
        commit(SET_UPLOAD_IN_PROGRESS, false);
        return response;
      })
      .catch((error) => {
        commit(SET_UPLOAD_IN_PROGRESS, false);
        throw error;
      });
  },

  async listAnalysisResources({ commit, state }, params) {
    commit(ANALYSIS_RESOURCES_ISLOADING, true);
    return analysisResourcesAPI.get({ params })
      .then((response) => {
        commit(ANALYSIS_RESOURCES_ISLOADING, false);
        const analysisResources = convertArrayToObject(response.data.results);
        commit(ANALYSIS_RESOURCES_UPDATE, analysisResources);

        if (response.data.next) {
          commit(ANALYSIS_RESOURCES_NEXTPAGE, state.studies.analysisResourcesNextPage + 1);
        } else {
          commit(ANALYSIS_RESOURCES_NEXTPAGE, 1);
        }
        return analysisResources;
      })
      .catch((error) => {
        commit(ANALYSIS_RESOURCES_ISLOADING, false);
        throw error;
      });
  },

  async deleteAnalysisResource({ commit }, { analysisResource }) {
    return analysisResourcesAPI.delete(analysisResource.id)
      .then(() => {
        commit(ANALYSIS_RESOURCE_DELETE, { analysisResource });
      });
  },

  uploadConfig: (
    { dispatch }, { studyId, config, caseIdentifier },
  ) => new Promise((resolve, reject) => {
    const fd = new FormData();
    fd.append('configFile', config);
    fd.append('caseIdentifier', caseIdentifier);
    studiesAPI.patchConfig(studyId, fd)
      .then((response) => {
        dispatch('retrieveStudy', studyId);
        resolve(response.data);
      })
      .catch((err) => {
        dispatch('retrieveStudy', studyId);
        reject(err);
      });
  }),

  // eslint-disable-next-line no-unused-vars
  async generateReportConfig({ state }, { study, withSimulations }) {
    return studyReportAPI.get(study.id, { params: { with_simulations: withSimulations } });
  },

  async generateReport({ dispatch, commit }, { study, reportConf, orderId }) {
    // - Create the PDF report
    // - Create the study resource
    // - Upload the study resource
    // - Confirm the upload

    commit(GENERATING_REPORT, true);
    let report;
    let filename;

    try {
      await axios.post(
        process.env.VUE_APP_REPORT_API_URL,
        reportConf,
        {
          responseType: 'blob',
          headers: {
            'content-type': 'application/json',
            accept: 'application/pdf',
          },
        },
      ).then((response) => {
        filename = `${study.caseIdentifier}-report.pdf`;
        report = new File([response.data], filename);
      });

      const upload = await dispatch('addFile', { file: report });

      const studyResource = await dispatch('createStudyResource', {
        studyId: study.id,
        orderId,
        metadata: { mimeType: 'application/pdf' },
        name: filename,
        filename,
        type: 3,
      });

      await dispatch('startUpload', {
        upload,
        writeUrl: studyResource.writeUrl,
        resource: studyResource,
      });

      dispatch('studyResourceConfirmUpload', { id: studyResource.id });
      dispatch('retrieveStudy', study.id);
      dispatch('listStudyResources', { study: study.id });

      commit(GENERATING_REPORT, false);
      return report;
    } catch (error) {
      commit(GENERATING_REPORT, false);
      throw error;
    }
  },

};
