<template>
  <v-list-item :two-line="!studyResource.orderId" :three-line="!!studyResource.orderId">
    <v-list-item-icon v-if="isNested" class="mr-4">
      <v-icon v-if="isLatest" color="primary">check_circle</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="d-flex align-center justify-space-between wrap">
        <a @click="$store.dispatch('downloadStudyResource', { id: studyResource.id })">
          {{studyResource.name}}
        </a>
        <div>
          <v-tooltip v-if="error" bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="error">error</v-icon>
            </template>
            <span>{{ error }}</span>
          </v-tooltip>
          <v-tooltip
            v-if="!hasDicom && studyResource.type === 2 && studyResource.attributes
              && studyResource.attributes.pseudoId"
            bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="error">error</v-icon>
            </template>
            <span>No matching pseudonymized DICOM</span>
          </v-tooltip>
          <v-tooltip
            v-if="studyResource.type === 2 && studyResource.attributes
              && !studyResource.attributes.pseudoId"
            bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="warning">warning</v-icon>
            </template>
            <span>Pseudonymized DICOM implied</span>
          </v-tooltip>
          <v-btn
            icon
            depressed
            small
            v-if='showDeleteButton'
            @click="deleteStudyResource(studyResource)"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </div>
      </v-list-item-title>
      <!-- Show order if present -->
      <v-list-item-subtitle v-if="studyResource.orderId">
        Service: {{ order.serviceDisplay }}
      </v-list-item-subtitle>
      <v-list-item-subtitle :class="{'mt-1': !studyResource.orderId}">
        <strong>{{STUDY_RESOURCE_TYPES[studyResource.type]}}</strong>
        <span class="text-truncate ml-2">{{ studyResource.id }}</span><br />
        Uploaded: {{ studyResource.dateCreated|localeDateTime }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <ConfirmationDialog ref="confirm"/>
  </v-list-item>
</template>

<script>
import ConfirmationDialog from '../../common/ConfirmationDialog.vue';
import constants from '@/store/modules/cases/constants';

export default {
  name: 'StudyResource',
  components: {
    ConfirmationDialog,
  },
  props: {
    studyResource: {
      type: Object,
      required: true,
    },
    orders: {
      type: Array,
      default: () => {},
    },
    hasDelivery: {
      type: Boolean,
      default: false,
    },
    isLatest: {
      type: Boolean,
      default: false,
    },
    isNested: {
      type: Boolean,
      default: false,
    },
    hasDicom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      generateReportDialog: false,
    };
  },
  computed: {
    STUDY_RESOURCE_TYPES() {
      return this.$store.state.studies.constants.STUDY_RESOURCE_TYPES;
    },
    order() {
      // Find the order in the orders array by id
      return this.orders.find((order) => order.id === this.studyResource.orderId);
    },
    showDeleteButton() {
      if (!this.$store.getters.user.canDeleteStudyResource) {
        return false;
      }
      if (this.hasDelivery) {
        if (this.order && this.order.status !== constants.ORDER_STATUSES.DELIVERED) {
          return true;
        }
        return false;
      }
      return true;
    },
    error() {
      return this.studyResource.attributes && this.studyResource.attributes.error;
    },
  },
  methods: {
    async deleteStudyResource(studyResource) {
      if (
        await this.$refs.confirm.open(
          'Confirm',
          'Are you sure you want to delete this resource?',
        )
      ) {
        this.$store.dispatch('deleteStudyResource', { studyResource });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  white-space: normal;
}
</style>
