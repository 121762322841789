<template>
<v-dialog
  :value="value"
  @input="emitInput"
  max-width="600px"
>
  <v-card>
    <v-card-title>
      {{ study.name }} - Legacy Simulation
    </v-card-title>
    <v-divider/>
    <v-card-text>
      <v-container>
        <v-row v-if="study.analysisIds">
          <v-col>
            <LegacyConfigCreator :study="study" :caseIdentifier="caseIdentifier" />
          </v-col>
          <v-col>
            <div class="u-padding">
              <p v-if="!study.analysisIds">
                Either provide a json file with a results definition, or a zip file with
                the configuration json and all models inside.
              </p>
              <p v-else>
                Drag all model and image files to the large orange area.
                Optionally, provide a report using the report uploader.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn outlined @click="hide">
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>

import LegacyConfigCreator from './LegacyConfigCreator.vue';

export default {
  name: 'UploadLegacySimulationDialog',
  components: {
    LegacyConfigCreator,
  },
  props: [
    'study',
    'caseIdentifier',
    'value',
  ],
  data: () => ({
    dialog: false,
    isValid: false,
    showInstructions: false,
  }),
  computed: {
    processing() {
      return this.study.uploadingLegacySimulation;
    },
  },
  methods: {
    emitInput(newVal) {
      this.$emit('input', newVal);
    },
    hide() {
      this.emitInput(false);
    },
  },
};
</script>
