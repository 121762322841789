import Resource from '@/services/resource';
import client from './axiosClient';

export default new Resource(client, 'landmarks', {
  publish(id, options) {
    const url = `/${this.base}/${id}/publish/`;
    return this.client.post(url, options);
  },
  unpublish(id, options) {
    const url = `/${this.base}/${id}/unpublish/`;
    return this.client.post(url, options);
  },
});
