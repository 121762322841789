<template>
<!--
  Because this is a wrapper component around v-dialog
  we need to pass the signals to the parent components with v-on="$listeners"
-->
<v-dialog
  :value="value"
  @click:outside="hide"
  v-on="$listeners"
  max-width="560"
>
  <v-card >
    <v-form
      @submit.native.prevent="addTrialLicense"
      v-model="isValid">
      <v-card-title class="text-h5 justify-center">
        Add trial license
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-2 pt-4">
        <v-container>
          <v-row class="my-auto">
            <v-col cols="10">
              <p>How many cases can be created with this trial license?</p>
            </v-col>
            <v-col>
              <v-text-field autofocus class="text-right"
                outlined dense
                type='number' v-model="form.limit" :rules="[isRequired, isGreaterThenZero]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="px-6 pa-4">
        <v-spacer/>
        <v-btn outlined @click="hide" >
          Close
        </v-btn>
        <v-btn depressed color="primary" :disabled="!isValid" type="submit">
          Add
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</v-dialog>
</template>

<script>

export default {
  name: 'AddTrialLicense',
  components: {

  },
  props: [
    'value',
    'organisation',
  ],
  data: () => ({
    dialog: false,
    isValid: false,
    form: {
      limit: 3,
    },
    isRequired: (v) => !!v || 'This field is required',
    isGreaterThenZero: (v) => v > 0 || 'It should allow for at least one case',

  }),
  computed: {
  },
  methods: {
    hide() {
      this.$emit('close');
    },
    addTrialLicense() {
      // TODO: Patch License
      this.$store.dispatch('createTrialLicense', { trial: { ...this.form, organisationId: this.organisation.id } })
        .then(() => this.$store.dispatch('updateOrganisationLicense', { id: this.organisation.id }));
      this.hide();
    },
  },
};
</script>

<style scoped>
.right-input >>> input {
  text-align: right
}
</style>
