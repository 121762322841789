<template>
  <v-dialog :value="value" @input="emitInput" max-width="680px">
    <v-card>

      <v-card-title class="text-h5 justify-center">
        {{ analysis.name }} - Upload Images
      </v-card-title>
      <v-divider />

      <v-card-text class="text-center pt-4">
        <p class="caption">
          Upload images for the report.
        </p>
        <v-form
          @submit.native.prevent="handleSubmit"
          v-model="isValid"
          ref="UploadScreenshotForm"
          lazy-validation
        >

          <!-- Drop Zone -->
          <div
            class="c-dropzone primary50 pa-4 primary--text"
            style="border-color: var(--v-primary-base) !important;"
          >
            <input
              type="file"
              @change="onFileInputAdd"
              ref="fileInput"
              style="display: none"
              multiple="multiple"
            />
            <div
              class="my-auto"
              @dragover.prevent
              @drop="onDrop"
              @click="$refs.fileInput.click()"
            >
             Click here or drag files into this area.<br />
              <v-icon x-large color="primary">cloud_upload</v-icon>
            </div>
          </div>
        </v-form>

        <!-- Filelist -->
        <div v-if="uploads.length > 0" class="mt-2">
          <div class="upload d-flex" v-for="upload in uploads"
            :key="upload.id">
            <div class="upload__preview flex-shrink-0 d-flex align-center">
              <v-img
                max-width="50" max-height="50" contain
                :src="upload.preview"
              />
            </div>
            <div class="upload__contents px-4 py-2 text-left d-flex flex-column justify-center">
              <strong>{{ upload.filename }}</strong>

              <div class="red--text"
                v-if="upload.hasFile && !upload.hasCaseIdInFilename(study.caseIdentifier)">
                The file does not match the case-identifier.
              </div>
            </div>
            <div class="upload__actions ml-auto pr-2 py-2 d-flex align-center">
              <v-tooltip bottom v-if="!upload.hasFile
                || !upload.hasCaseIdInFilename(study.caseIdentifier)">
                <template v-slot:activator="{on, attrs}">
                  <v-btn icon v-on="on" v-bind="attrs" @click="removeUpload(upload)">
                    <v-icon>cancel</v-icon>
                  </v-btn>
                </template> Remove Upload
              </v-tooltip>

              <v-progress-circular v-else
                :text-inside="true"
                :stroke-width="38"
                :value="upload.progress"
                color="primary"
              />
            </div>
          </div>

          <p class="mt-4">
            Uploading may take several minutes depending on your connection and
            the size of the file.
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined @click="hide">
          Close
        </v-btn>
        <v-btn outlined @click="cancelUpload" :disabled="uploads.length===0">
          Clear
        </v-btn>
        <v-btn
          depressed
          color="primary"
          @click="startUpload"
          :disabled="!canUploadFiles"
        >
          Upload all images
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UploadScreenshotDialog',
  props: ['analysis', 'value'],
  data: () => ({
    isRequired: (v) => !!v || 'This field is required',
    dialog: false,
    isValid: false,
    processing: false,
    uploads: [],
    inProgress: false,
  }),
  computed: {
    canUploadFiles() {
      return !this.processing && this.uploads.length > 0
        && this.uploads.some((u) => u.hasCaseIdInFilename(this.study.caseIdentifier));
    },
    study() {
      return this.$store.getters.studyById(this.analysis.studyId);
    },
  },
  methods: {
    addFile(file) {
      this.$store.dispatch('addFile', { file }).then((upload) => {
        // eslint-disable-next-line no-param-reassign
        upload.preview = window.URL.createObjectURL(file);
        this.uploads.push(upload);
      });
    },
    addFiles(files) {
      for (let i = 0; i < files.length; i += 1) {
        const file = files.item(i);
        this.addFile(file);
      }
    },
    beforeClose(event) {
      if (!this.inProgress) {
        this.cancelUpload();
        this.uploads.forEach((upload) => {
          this.$store.dispatch('resetUpload', upload);
        });
      } else {
        event.stop();
        this.$toasted.global.info({
          message: 'Please cancel your upload before closing the dialog',
        });
      }
    },
    cancelUpload() {
      this.uploads.forEach((upload) => {
        this.$store.dispatch('cancelUpload', upload);
      });
      this.uploads = [];
    },
    emitInput(newVal) {
      this.$emit('input', newVal);
    },
    hide() {
      this.emitInput(false);
    },
    metadata(upload) {
      if (upload.hasFile) {
        return { mimeType: upload.file.type };
      }
      return {};
    },
    onDrop(e) {
      /* Triggered by dropping on the dropzone */
      e.preventDefault();
      this.addFiles(e.dataTransfer.files);
    },
    onFileInputAdd(e) {
      /* Triggered by clicking the dropzone, which triggers clicking the hidden
      file input */
      e.preventDefault();
      this.addFiles(e.target.files);
    },
    removeUpload(upload) {
      this.uploads = this.uploads.filter((u) => u.id !== upload.id);
    },
    startUpload() {
      this.processing = true;
      this.uploads
        .filter((upload) => upload.hasCaseIdInFilename(this.study.caseIdentifier))
        .forEach((upload) => {
          this.$store
            .dispatch('createAnalysisResource', {
              type: this.$store.state.studies.constants.IMAGE,
              analysisId: this.analysis.id,
              metadata: this.metadata(upload),
              name: upload.filename,
              filename: upload.filename,
            })
            .then((analysisResource) => {
              this.$store
                .dispatch('startUpload', {
                  upload,
                  writeUrl: analysisResource.writeUrl,
                  resource: analysisResource,
                })
                .then(() => {
                  this.$store.dispatch('analysisResourceConfirmUpload', { id: analysisResource.id });
                  this.$toasted.global.success({
                    message: 'Analysis Resource uploaded!',
                  });
                  this.$store.dispatch('retrieveAnalysis', this.analysis.id);
                  this.$store.dispatch('listAnalysisResources', {
                    analysis_id: this.analysis.id,
                  });

                  this.hide();
                })
                .catch((error) => {
                // eslint-disable-next-line
              if (error && error.__CANCEL__) {
                    this.$toasted.global.error({ message: error.message });
                  } else {
                    this.$toasted.global.error({ error });
                  }
                });
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.upload {
  border: 1px solid rgba(#000, 0.12);
  border-radius: 4px;

  &:not(:first-child) {
    margin-top: 8px;
  }
}
</style>
