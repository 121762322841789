var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 pb-14",attrs:{"headers":_vm.headers,"items":_vm.$store.getters.filteredHgcases,"items-per-page":15,"loading":_vm.isLoading,"disable-sort":"","disable-filtering":"","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.devices`,fn:function({ item }){return [(!_vm.simulationDevices(item).length && _vm.itemDevices(item).length > 1)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"240"},scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v("Multiple Devices")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.itemDevices(item).join(', '))+" ")]):_vm._e(),[(_vm.itemDevices(item).length <= 1)?_c('div',[_vm._v(_vm._s(_vm.itemDevices(item).join(', ')))]):(_vm.simulationDevices(item).length <= 1)?_c('div',[_vm._v(" "+_vm._s(_vm.simulationDevices(item).join(', '))+" ")]):_vm._e()],(item.deviceVariantNames.length > 0)?_c('div',{staticClass:"grey--text text-caption"},[_vm._v(" "+_vm._s(item.deviceVariantNames.join(', '))+" ")]):_vm._e()]}},{key:`item.services`,fn:function({ item }){return [(item.status===_vm.REJECTED)?_c('div',{staticClass:"my-auto primary--text"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Could not be processed"),_c('br'),(item.study && item.study.dicomVolumeId)?_c('v-btn',{staticClass:"my-2",attrs:{"small":"","color":"primary","depressed":"","to":{
              name: 'mprviewer',
              params: { id: item.study.id }
            }}},[_vm._v("View dicom ")]):_vm._e()],1)]):_c('div',_vm._l((item.services.orderBy('id')),function(service){return _c('div',{key:service.id},[_c('span',[_vm._v(" "+_vm._s(service.name)+" "),(!service.isClinical)?_c('NonClinicalTooltip',[_vm._v(" "+_vm._s(service.degradationRemark)+" ")]):_vm._e(),_vm._v(" : ")],1),_c('span',{class:service.color+`--text`},[_vm._v(" "+_vm._s(service.label)+" ")])])}),0)]}},{key:`item.dateSubmitted`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("localeDateTime")(item.dateSubmitted))+" ")]}},{key:`item.dateCompleted`,fn:function({ item }){return [(item.status === _vm.COMPLETED)?[_vm._v(" "+_vm._s(_vm._f("localeDateTime")(item.dateCompleted))+" ")]:(item.status === _vm.CANCELED)?[_vm._v(" "+_vm._s(_vm._f("localeDateTime")(item.dateModified))+" ")]:_vm._e()]}},{key:`item.interventionDatetime`,fn:function({ item }){return [(item.patientInformation && !item.isSharedToCurrentUser)?[_vm._v(" "+_vm._s(_vm._f("localeDateTime")(item.interventionDatetime))+" ")]:_vm._e()]}},{key:`item.hasPdfReport`,fn:function({ item }){return [(item.hasPdfReport && (item.hasDeliveredOrder || _vm.user.canViewUndeliveredAnalyses))?_c('report-button',{attrs:{"hgcase":item}}):_vm._e()]}},{key:`item.results`,fn:function({ item }){return [(item.study && item.hasDeliveredOrder)?_c('div',{staticClass:"py-4 text-center my-auto primary--text"},[_c('v-btn',{attrs:{"depressed":"","to":{
            name: 'study',
            params: { id: item.study.id }
          },"color":"primary"}},[_vm._v(" View result ")]),_c('br')],1):_vm._e()]}},{key:`item.detail`,fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[(_vm.user.canAccessCaseDetails)?_c('v-btn',{attrs:{"hgcase":item,"icon":"","to":{ name: 'case-detail', params: {id: item.id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" edit ")])],1):_vm._e(),(_vm.user.canShareCase && !item.isSharedToCurrentUser)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$store.dispatch('showShareCaseDialog', item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$sharing")])],1):_vm._e()],1)]}},{key:`item.caseIdentifier`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.caseIdentifier)+" "),(_vm.user.canShareCase || _vm.user.canSeeSharedCases)?_c('ShareButtons',{attrs:{"hgCase":item,"mini":""}}):_vm._e()]}},{key:`item.patientInformation.identifier`,fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center"},[(item.canRequestPatientInformation && !item.isSharedToCurrentUser)?_c('ShowSensitiveDataButton',{attrs:{"hgcase":item}}):_vm._e(),(!item.isSharedToCurrentUser)?_c('span',[_vm._v(" "+_vm._s(item.patientInformation && item.patientInformation.identifier)+" "),(item.hasUpdatedPatientIdentifier)?_c('PatientIdentifierChangedTooltip'):_vm._e()],1):_vm._e()],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }