<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title :class="{ 'text--disabled': !inputData.exists }">
        <a @click="$store.dispatch('downloadInputData', {id: inputData.id})"
          v-if="inputData.exists">
          {{ caseIdentifier }}.zip
        </a>
        <template v-else>{{ caseIdentifier }}.zip</template>
      </v-list-item-title>
      <v-list-item-subtitle>{{ inputData.dateCreated | localeDateTime }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>

export default {
  name: 'DownloadImagesButton',
  props: ['inputData', 'caseIdentifier'],
};
</script>
