export default () => ({
  backendUrl: process.env.VUE_APP_FEOPSAPI_BACKEND_URL,
  version: {
    frontend: {
      version: process.env.VUE_APP_VERSION || 'not defined',
      clientLabel: 'CM',
    },
    backend: {},
  },
  error: '',
  status: '',
});
