<template>
  <v-tooltip bottom>
    <template v-slot:activator="{on, attrs}">
      <v-btn icon plain @click="toggle" v-on="on" v-bind="attrs"
        class="toggle-sensitive-button"
        :class="hgcase.patientInformation ? 'active' : 'inactive'">
        <v-icon :color="inverse ? 'white' : ''">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ hgcase.patientInformation ? 'Hide' : 'Request' }} Sensitive Information</span>
  </v-tooltip>
</template>

<script>

export default {
  name: 'ShowSensitiveDataButton',
  props: ['hgcase', 'inverse'],
  computed: {
    icon() {
      if (this.hgcase.patientInformation) {
        return 'face_retouching_off';
      }
      return 'face';
    },
  },
  methods: {
    toggle() {
      if (this.hgcase.patientInformation) {
        this.hidePatientIdentifier();
      } else {
        this.requestSensitiveData();
      }
    },
    requestSensitiveData() {
      this.$store.dispatch('requestSensitiveData', { id: this.hgcase.id });
    },
    hidePatientIdentifier() {
      this.$store.dispatch('hideAllPatientIdentifiers');
    },
  },
};

</script>
