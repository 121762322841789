<template>
  <v-dialog v-model="showDialog" max-width="720px">
    <template v-slot:activator="{ on, attrs }">
      <div v-on="on" v-bind="attrs">
        <slot></slot>
      </div>
    </template>

    <v-card>
      <v-card-title>Add new user
        <v-btn class="ml-auto" icon type="button" @click="showDialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4">
        <v-form v-model="isValid" ref="newUserForm">
          <section>
            <h5 class="section-title primary--text">User Details</h5>

            <v-row class="mt-3">
              <v-col sm="4">
                <v-text-field v-model="form.firstName" :rules="[isRequired]"
                  :error-messages="errors.firstName" outlined dense>
                  <template v-slot:label>
                    First Name <span class="red--text">*</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col sm="4">
                <v-text-field v-model="form.middleName" label="Middle Name"
                  :error-messages="errors.middleName" maxlength="30" outlined dense />
              </v-col>
              <v-col sm="4">
                <v-text-field v-model="form.lastName" :rules="[isRequired]"
                  :error-messages="errors.lastName" outlined dense>
                  <template v-slot:label>
                    Last Name <span class="red--text">*</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col sm="4">
                <v-select v-model="form.title" label="Title" outlined dense :items="titleChoices"
                  :error-messages="errors.title"/>
              </v-col>
              <v-col sm="8">
                <v-text-field v-model="form.phoneNumber" :rules="[isRequired]"
                  :error-messages="errors.phoneNumber" maxlength="150" outlined dense>
                  <template v-slot:label>
                    Phone number <span class="red--text">*</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </section>

          <section class="mt-4">
            <h5 class="section-title primary--text">Account Details</h5>
            <v-row class="mt-3">
              <v-col cols="12">
                <v-text-field type="email" v-model="form.email" outlined dense
                  :rules="[isRequired, isValidEmailAddress]" :error-messages="errors.email">
                  <template v-slot:label>
                    Email <span class="red--text">*</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col sm="6">
                <v-text-field type="password" v-model="form.password" outlined dense
                  :rules="[isRequired, mustBe13Chars]">
                  <template v-slot:label>
                    Password <span class="red--text">*</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col sm="6">
                <v-text-field type="password" v-model="form.password2" outlined dense
                  :rules="[isRequired, mustMatchPassword]" ref="password2">
                  <template v-slot:label>
                    Confirm Password <span class="red--text">*</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </section>

          <section class="mt-4">
            <h5 class="section-title primary--text">Organisation Details</h5>
            <v-row class="mt-3">
              <v-col sm="8">
                <OrganisationSearch v-model="form.organisationId" outlined required :solo="false"
                  hideAll :hideDetails="false" :singleLine="false">
                  Organisation <span class="red--text">*</span>
                </OrganisationSearch>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="form.hasApprovedTerms"
                  hide-details class="mt-0"
                  :error-messages="errors.hasApprovedTerms"
                  label="Has approved terms"
                />
              </v-col>
            </v-row>
          </section>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex justify-end pa-4">
        <v-btn text @click="showDialog = false">Cancel</v-btn>
        <v-btn text color="primary" :disabled="!isValid" @click="submit">Create User</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OrganisationSearch from '@/components/common/OrganisationSearch.vue';
import countries from '@/utils/countries';

export default {
  name: 'CreateUserDialog',
  components: {
    OrganisationSearch,
  },
  data() {
    return {
      form: {
        firstName: null,
        middleName: '',
        lastName: null,
        phoneNumber: null,
        email: null,
        password: null,
        password2: null,
        title: '',
        organisationId: null,
        hasApprovedTerms: false,
      },
      isValid: false,
      showDialog: false,
      errors: {},
      countries,
      titleChoices: [
        '',
        'Dr.',
        'Prof. Dr.',
        'Mr.',
        'Mrs.',
        'Ms.',
      ],
    };
  },
  computed: {
    regions() {
      return this.$store.getters.regions;
    },
  },
  methods: {
    // rules
    isRequired: (v) => !!v || 'This field is required',
    isValidEmailAddress: (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    mustBe13Chars: (v) => (v || '').length >= 13 || 'Password must be at least 13 characters',
    mustMatchPassword(v) {
      return v === this.form.password || 'Passwords must match';
    },
    resetFieldValidation(key) {
      if (!this.errors || !this.errors[key]) return;
      delete this.errors[key];
    },
    resetForm() {
      this.form = {
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        password: '',
        password2: '',
        title: '',
        organisationId: null,
        hasApprovedTerms: false,
      };
      this.errors = {};
      this.$refs.newUserForm.resetValidation();
    },
    submit() {
      this.$store.dispatch('createUser', this.form)
        .then(() => {
          this.$toasted.global.success({ message: 'User successfully created' });
          this.showDialog = false;
          this.resetForm();
        })
        .catch((error) => {
          this.errors = error.response?.data;
        });
    },
  },
  watch: {
    showDialog(value) {
      if (!value || !this.$refs.newUserForm) return;
      if (this.resetForm) this.resetForm();
    },
    // eslint-disable-next-line func-names
    'form.email': function () {
      this.resetFieldValidation('email');
    },

    // eslint-disable-next-line func-names
    'form.password': function (newVal) {
      if (!this.form.password2 || !newVal) return;
      this.$refs.password2.validate();
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  font-size: inherit;
  font-weight: bold;
}

.col {
  padding: 4px;
}

.row {
  margin: -8px;
}
</style>
