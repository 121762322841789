<template>
  <v-dialog
    stacked
    persistent
    v-model="showDialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    data-cy="cookie-dialog">
    <v-form class="cookie-form" ref="cookieForm" @submit.native.prevent="submit">
      <v-card class="cookie-dialog">
        <v-card-title class="text-h5 justify-left">
          <img src='@/assets/images/logo.svg' class="mr-4" style="height: 40px;" />
          We value your privacy.
        </v-card-title>

        <v-divider></v-divider>

        <v-card-subtitle class="pt-3">
          FEops uses cookies to improve your experience. Strictly necessary cookies
          are essential for the correct functioning of the website and cannot be refused.
          Additionally, we employ analytical cookies to assess site usage. If you give your
          consent, certain data (such as browser, device information, etc.) will be used to
          generate aggregated statistics. <b>No personally identifiable information nor
          sensitive data are used or stored as result of the use of cookies on our website.</b>
          <br />
          <br />
          You can refuse cookies through the "Manage cookies" button of this dialogue. You can
          withdraw your consent anytime by clicking on the "Cookie Policy" link at the bottom
          of our login page or via the "Documentation" page if you are logged in.
          <br />
          <br />
          If you need more information, you can find it in our
          <a @click="redirectToCookiePolicy">Cookie Policy</a>.
        </v-card-subtitle>

        <!-- First view with simple buttons... -->

        <v-card-text v-if="!showExtraOptions" class="px-6">
          <div class="d-flex justify-end mb-1 mt-1">
            <v-btn plain small @click="expandExtraOptions">Manage cookies</v-btn>
            <v-btn tonal small color="primary" @click="acceptAllCookies">Accept all</v-btn>
          </div>
        </v-card-text>

        <!-- Second view for more options... -->
        <v-expand-transition>
          <v-card-text v-if="showExtraOptions" class="px-6 mt-3">
            <div class="font-weight-black text-high-emphasis">
              Functional cookies (strictly necessary)
            </div>

            <div class='d-flex'>
              <p class="mb-2 ">
                These cookies are needed to let the website function correctly.
                They allow you to remember your preferences and settings, to login
                and use our services.
              </p>
              <div class="ml-4 px-2 justify-end">
                <v-switch label="On" :disabled=true input-value="1" hide-details inline></v-switch>
              </div>
            </div>

            <v-divider class="pt-2 ma-3"></v-divider>

            <div class="font-weight-black text-high-emphasis">
              Analytical cookies (optional)
            </div>

            <div class="d-flex">
              <p class="mb-2">
                Analytics or performance cookies are used to analyze the usage of the website.
                This data is then used to improve FEops HEARTguide™ services and in turn,
                improve the way the website works and the user experience.
              </p>
              <div class="ml-4 px-2 justify-end">
                <v-switch
                  v-model="form.analyticalCookies"
                  :label="`${form.analyticalCookies ? 'On' : 'Off'}`"
                  hide-details
                  inline
                ></v-switch>
              </div>
            </div>

            <div class="d-flex justify-end mt-6">
              <v-btn plain small @click="saveCookiePreferences">Save my preferences</v-btn>
              <v-btn tonal small color="primary" @click="acceptAllCookies">Accept all</v-btn>
            </div>
          </v-card-text>
        </v-expand-transition>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { bootstrap } from 'vue-gtag';
import {
  googleAnalyticsCookieSet,
  googleAnalyticsIsAllowed,
  allowGoogleAnalytics,
  rejectGoogleAnalytics,
} from '@/utils/cookies';

export default {
  name: 'CookieDialog',

  data() {
    return {
      showDialog: googleAnalyticsCookieSet() === false,
      showExtraOptions: false,
      form: {
        analyticalCookies: false,
      },
      options: {
        width: 600,
        zIndex: 1000,
      },
    };
  },

  methods: {
    // ------- GUI Flow methods ------- //
    expandExtraOptions() {
      this.showExtraOptions = true;
    },
    // ------- Form methods ------- //
    redirectToCookiePolicy() {
      this.saveCookiePreferences();
      this.$router.push({ name: 'cookiepolicy' });
    },
    acceptAllCookies() {
      this.form.analyticalCookies = true;
      this.saveCookiePreferences();
    },
    saveCookiePreferences() {
      this.showDialog = false;
      if (this.form.analyticalCookies) {
        allowGoogleAnalytics();
      } else {
        rejectGoogleAnalytics();
      }
      this.bootstrapAnalytics();
    },
    // ------- Google Analytics methods ------- //
    bootstrapAnalytics() {
      if (googleAnalyticsIsAllowed()) {
        bootstrap();
      }
    },
  },
  computed: {
    ...mapState({
      insideIframe: (state) => state.layout.insideIframe,
    }),
  },
  // ------- Vue Lifecycle methods ------- //
  mounted() {
    this.bootstrapAnalytics();
  },
};
</script>
