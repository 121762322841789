import Resource from '@/services/resource';
import client from './axiosClient';

export default new Resource(client, 'device-access-groups', {
  subscribe(id, { organisationId }) {
    const url = `/${this.base}/${id}/subscribe/`;
    const axiosOptions = { organisationId };
    return this.client.put(url, axiosOptions);
  },
  unsubscribe(id, { organisationId }) {
    const url = `/${this.base}/${id}/unsubscribe/`;
    // next = next page with same options
    const axiosOptions = { organisationId };
    return this.client.put(url, axiosOptions);
  },
});
