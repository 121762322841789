export default () => ({
  isLoading: false,
  organisations: {},
  selectedOrganisationId: null,
  error: '',
  nextPage: 1,
  regions: {},
  memberships: {},
  orgsManagement: {
    // in admin management
    ids: [],
    count: 0,
  },
});
