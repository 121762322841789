<template>
  <div class="ribbon-container" :class="{ 'static': mini }">
    <v-tooltip bottom
      v-if="$store.getters.user.canShareCase && !hgCase.isSharedToCurrentUser
      && hgCase.sharedWith.length"
    >
      <template v-slot:activator="{ on, attrs }">
        <!-- card -->
        <div
          v-if="!mini"
          class="ribbon" v-on="on" v-bind="attrs"
        >
          <div class="ribbon__contents">
            <v-btn
              text rounded x-small class="ml-1"
              @click="$store.dispatch('showShareCaseDialog', hgCase.id)"
            >
              <v-icon small color="white">$sharing</v-icon>
            </v-btn>
            <span class="ribbon__text">{{ hgCase.sharedWith.length }}</span>
          </div>
        </div>

        <!-- table -->
        <v-btn
          v-else
          v-on="on" v-bind="attrs" class="ml-1"
          text rounded x-small
          @click="$store.dispatch('showShareCaseDialog', hgCase.id)"
        >
          <v-icon small>$sharing</v-icon> {{ hgCase.sharedWith.length }}
        </v-btn>
      </template>
      Currently shared with {{ hgCase.sharedWith.length }}
      user{{ hgCase.sharedWith.length !== 1 ? 's' : '' }}
    </v-tooltip>

    <v-tooltip bottom v-else-if="hgCase.isSharedToCurrentUser">
      <template v-slot:activator="{ on, attrs }">
        <div class="ribbon ribbon--shared-by" v-on="on" v-bind="attrs" v-if="!mini">
          <div class="ribbon__contents">
            <v-icon color="white">$sharedToUser</v-icon>
          </div>
        </div>

        <v-btn icon small v-else v-on="on" v-bind="attrs" class="ml-1">
          <v-icon small>$sharedToUser</v-icon>
        </v-btn>
      </template> Shared by {{ hgCase.sharedBy[0].fullNameWithTitle }}
    </v-tooltip>
  </div>
</template>

<script>

export default {
  name: 'ShareButtons',
  components: {
  },
  props: {
    hgCase: {
      type: Object,
      required: true,
    },
    mini: Boolean,
  },
};
</script>

<style lang="scss" scoped>
$ribbon: #9F9F9F;
$ribbon-dark: #7F7F7F;

.ribbon-container:not(.static) {
  position: absolute;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  top: -16px;
  right: -8px;
  gap: 4px;
}

.ribbon-container.static {
  display: inline-block;
}

.ribbon {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 0;
    left: -16px;
    width: 0;
    height: 0;
    border: 16px solid $ribbon;
    border-left-color: transparent !important;
    z-index: 1;
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: -8px;
    border: 4px solid $ribbon-dark;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
  }
}

.ribbon__contents {
  background-color: $ribbon;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 6px;
  width: max-content;
  height: 32px;
  z-index: 2;
  color: #FFF;
}

.ribbon .v-icon {
  margin-right: 2px;
}
</style>
