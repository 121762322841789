import Resource from '@/services/resource';
import client from './axiosClient';

const FileDownload = require('js-file-download');

export default new Resource(client, 'cases', {
  get(id, options) {
    let axiosOptions = options;
    let url = `/${this.base}/`;
    let next;
    // If only an object is passed, those will be options
    if (typeof id === 'object') {
      axiosOptions = id;
      ({ next } = axiosOptions);
    // Anything else will be the id of the resource
    } else if (id !== undefined) {
      url += `${id}/`;
    }
    if (next !== undefined) {
      // next = next page with same options
      return this.client.get(next);
    }
    // next = next page with same options
    return this.client.get(url, axiosOptions);
  },
  getText(id) {
    const url = `/${this.base}/${id}.txt`;
    this.client.get(url).then((response) => {
      const caseId = response.data.split('\n')[0];
      FileDownload(response.data, `${caseId}.txt`);
    });
  },
  reject(id, options) {
    const url = `/${this.base}/${id}/reject/`;
    return this.client.put(url, options);
  },
  cancel(id, options) {
    const url = `/${this.base}/${id}/cancel/`;
    return this.client.put(url, options);
  },
  assignCaseResponsible(id, options) {
    const url = `/${this.base}/${id}/assign-case-responsible/`;
    return this.client.put(url, options);
  },
  // deprecated
  requestPatientInformation(id, options) {
    const url = `/${this.base}/${id}/patient-information/`;
    return this.client.get(url, options);
  },
  requestSensitiveData(id, options) {
    const url = `/${this.base}/${id}/sensitive-data/`;
    return this.client.get(url, options);
  },
  share(id, options) {
    // options = { email }
    const url = `/${this.base}/${id}/share/`;
    return this.client.put(url, options);
  },
});
