import axios from 'axios';
import { convertArrayToObject } from '@/utils';

import {
  UPLOAD_ADD_FILE,
  SET_UPLOAD_IN_PROGRESS,
} from '@/store/mutation-types';

const s3Client = axios.create({});
function getCanceller() {
  const { CancelToken } = axios;
  return CancelToken.source();
}

function sendToS3({
  signedUrl, file, onUploadProgress, cancelToken,
}) {
  const options = {
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress,
    cancelToken,
  };
  return s3Client.put(signedUrl, file, options);
}

export default {

  addFile: ({ commit }, { file }) => {
    const upload = {
      id: (Math.random() + 1).toString(36).substring(7),
      file,
      progress: 0,
      canceller: null,
      inProgress: false,

      get hasFile() {
        return this.file !== null;
      },
      get filename() {
        return this.file ? this.file.name : '';
      },
      get isComplete() {
        return this.progress === 100;
      },

      hasCaseIdInFilename(caseIdentifier) {
        if (this.hasFile && this.filename && caseIdentifier) {
          return this.filename.toLowerCase().indexOf(caseIdentifier.toLowerCase()) >= 0;
        }
        return false;
      },

    };
    commit(UPLOAD_ADD_FILE, convertArrayToObject([upload]));
    return upload;
  },
  startUpload: ({ commit, dispatch }, { upload, writeUrl, resource }) => new Promise(
    (resolve, reject) => {
      commit('UPLOAD_FILE_CANCELLER', { upload, canceller: getCanceller() });
      const cancelToken = upload.canceller.token;

      commit('UPLOAD_SET_RESOURCE', { upload, resource });
      commit('UPLOAD_START', upload);
      commit(SET_UPLOAD_IN_PROGRESS, true);

      return sendToS3({
        signedUrl: writeUrl,
        file: upload.file,
        cancelToken,
        onUploadProgress: (progressEvent) => {
          dispatch('updateUploadProgress', { upload, progressEvent });
        },
      })
        // Upload complete, no reason to reset.
        .then(() => resolve())
        .catch((error) => reject(error));
    },
  ),
  cancelUpload: ({ dispatch }, upload) => {
    if (upload && upload.canceller) {
      upload.canceller.cancel('Upload cancelled by user');
    }
    dispatch('resetUpload', upload);
  },
  resetUpload: ({ commit }, upload) => {
    commit('UPLOAD_RESET', upload);
  },
  updateUploadProgress: ({ commit }, { upload, progressEvent }) => {
    let percent = (progressEvent.loaded / progressEvent.total) * 100;
    percent = Math.round(percent);
    commit('UPLOAD_PROGRESS', { upload, progress: percent });
  },
  async resetState({ commit }) {
    commit('RESET_STATE_UPLOAD_MODULE');
  },
  setUploadInProgress({ commit }, value) {
    commit(SET_UPLOAD_IN_PROGRESS, value);
  },
};
