import constants from './constants';

export default {
  study: (state, getters) => getters.studyById(state.studyId),
  // eslint-disable-next-line arrow-body-style
  viewedCase: (state, getters) => {
    return (getters.study ? getters.hgcaseById(getters.study.caseId) : undefined);
  },
  // TIP: to avoid infinite loops,
  // we avoid loading scenes in visualisations and visualisations in scenes
  scene: (state, getters) => (index) => getters.sceneList[index],
  sceneList: (state) => state.scenes.map((scene) => ({
    ...scene,
    get isFullyLoaded() {
      return Object.values(scene.objects).every(
        (object) => state.loadedFiles.includes(object.file.readUrl),
      );
    },
    get objectNames() {
      return this.objects.map((o) => o.name);
    },
  })),
  currentScene: (state, getters) => getters.sceneList[state.selectedSceneIdx] || {},
  visualisation: (state, getters) => (id) => {
    const visualisation = state.visualisationsById[id];
    if (visualisation) {
      return {
        ...visualisation,
        get editableLandmarks() {
          const landmarkList = [
            ...this.landmarks.map(
              (lm) => getters.landmarkById(lm.id),
            ),
            // angles should not be available in the 3d viewer
            ...getters.customLandmarks.filter((cl) => cl.geometryType !== constants.ANGLE),
          ];
          if (getters.editMode) {
            return landmarkList;
          }
          return landmarkList.filter((lm) => lm.isPublished);
        },
        get landmarkTypeList() {
          const landmarkTypeList = [...new Set(this.landmarks.map((lm) => lm.landmarkTypeId))];
          return landmarkTypeList.map((lmt) => getters.landmarkTypeById(lmt));
        },
        sortedEditableObjectsInCurrentScene(currentSceneObjectNames) {
          if (!currentSceneObjectNames) {
            return [];
          }
          const editableObjects = visualisation.objects
            .filter((object) => currentSceneObjectNames.includes(object.name))
            .filter((object) => object.isEditable);

          if (visualisation.objectOrder) {
            const order = visualisation.objectOrder;
            const sortedObjects = editableObjects
              .filter((o) => order.includes(o.name))
              .sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));

            return sortedObjects;
          }
          return editableObjects;
        },
      };
    }
    return undefined;
  },
  currentVisualisation: (state, getters) => {
    const selectedVisualisationId = state.selectedVisualisationId[getters.currentScene.id];
    return getters.visualisation(selectedVisualisationId);
  },
  currentTabName: (state) => {
    if (state.sidebar && state.sidebar.sections.length) {
      return state.sidebar.sections[state.sidebar.current].component;
    }
    return undefined;
  },
  roiCenter: (state) => state.options.roiCenter,
  showResults: (state) => state.options.showResults,
  showImages: (state) => state.options.showImages,
  showImagesIcon: (state) => state.options.showImages && state.scenes.some((scene) => {
    if (scene.images && scene.images.length > 0) {
      return true;
    } return false;
  }),
  showSliders: (state) => state.options.showSliders,
  colorLevel: (state) => state.color.level,
  colorWindow: (state) => state.color.window,
  contour: (state) => state.contour,
  dicomProgress: (state) => state.dicomProgress,
  human: (state) => state.human,
  image: (state) => state.image,
  interactionCounter: (state) => state.interactionCounter,
  interpolationType: (state) => state.interpolationType,
  landmarkById: (state, getters) => (id) => {
    const landmark = state.landmarks[id];
    if (landmark) {
      return {
        ...landmark,
        get createdByCurrentUser() {
          return landmark.createdById === getters.user.sub;
        },
        get canEdit() {
          return this.createdByCurrentUser;
        },
        get canDelete() {
          if (getters.viewedCase?.isUndelivered) {
            return getters.user.canDeleteUndeliveredLandmarks;
          }
          return this.createdByCurrentUser;
        },
      };
    }
    return undefined;
  },
  landmarkIds: (state) => Object.keys(state.landmarks),
  landmarks: (state, getters) => getters.landmarkIds.map(
    (landmarkId) => getters.landmarkById(landmarkId),
  ),
  customLandmarks: (state, getters) => getters.landmarks.filter(
    (lm) => lm.landmarkTypeId === null,
  ),
  landmarkTypeById: (state, getters) => (id) => {
    const landmarkType = state.landmarkTypes[id];
    if (landmarkType) {
      return {
        ...landmarkType,
        landmarks: getters.landmarks.filter((landmark) => (
          landmark.landmarkTypeId === landmarkType.id)),
      };
    }
    return undefined;
  },
  landmarkTypeIds: (state) => Object.keys(state.landmarkTypes),
  landmarkTypes: (state, getters) => getters.landmarkTypeIds.map((landmarkTypeId) => {
    const landmarkType = getters.landmarkTypeById(landmarkTypeId);
    return landmarkType;
  }).sort((a, b) => {
    const aConf = state.landmarkTypeConfigs[
      a.id
    ];
    const bConf = state.landmarkTypeConfigs[
      b.id
    ];
    if (aConf && bConf) {
      return aConf.order - bConf.order;
    }
    if (aConf) {
      return -1;
    }
    if (bConf) {
      return 1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return -1;
  }),
  layout: (state) => state.layout,
  publishedLandmarks: (state, getters) => getters.landmarks.filter(
    (landmark) => landmark.isPublished,
  ),
  hingePoints: (state, getters) => getters.landmarks.filter(
    // Get published landmarks of type POINT and with name ending with 'Cusp'
    (landmark) => landmark.geometryType === constants.POINT
      && landmark.displayName.endsWith('Cusp') && landmark.isPublished,
  ),
  centerlineLandmarks: (state, getters) => getters.landmarks.filter(
    (landmark) => landmark.geometryType === constants.CENTERLINE
      && landmark.isPublished,
  ),
  lzOstLandmarks: (state, getters) => getters.landmarks.filter(
    (landmark) => landmark.geometryType === constants.AREA
      && (landmark.displayName.endsWith('Ostium') || landmark.displayName.startsWith('Landing Zone'))
      && landmark.isPublished,
  ),
  lMButton: (state) => state.lMButton,
  newAngleWidget: (state) => state.newAngleWidget,
  newAreaWidget: (state) => state.newAreaWidget,
  newCurveWidget: (state) => state.newCurveWidget,
  newDistanceWidget: (state) => state.newDistanceWidget,
  newLandmark: (state) => state.newLandmark,
  selectedLandmark: (state, getters) => getters.landmarkById(state.selectedLandmarkId),
  showContour: (state) => state.showContour,
  editMode: (state) => state.editMode,
  widget: (state) => state.widget,
  viewports: (state) => state.layout,

  // eslint-disable-next-line max-len
  canCreateNewVersion: (state, getters) => (
    getters.viewedCase?.isUndelivered && getters.user.canCreateUndeliveredLandmarks),
  // eslint-disable-next-line max-len
  canPublishLandmarks: (state, getters) => (
    getters.viewedCase?.isUndelivered && getters.user.canPublishLandmarks),
  // eslint-disable-next-line max-len
  canCreateLandmarks: (state, getters) => (
    getters.viewedCase?.isUndelivered && getters.user.canCreateUndeliveredLandmarks
  ) || (
    (getters.viewedCase?.hasDeliveredOrder || getters.viewedCase?.isFinished)
      && getters.user.canCreateDeliveredLandmarks
  ),
  canSetInterpolation: (state, getters) => (
    getters.viewedCase?.isUndelivered && !getters.viewedCase?.isFinished
      && getters.user.canSetInterpolation
  ),
};
