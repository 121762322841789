<template>
  <div>
    <AppBar />
    <v-main class="h-screen-max grey lighten-5">
      <v-container class="pt-8 h-full d-flex flex-column justify-center text-center">
        <h1 class="text-h4">This case is still being processed
          <v-icon large class="ml-2 rotate">settings</v-icon>
        </h1>
        <p class="py-4 text-h5 text--secondary">Please come back later.</p>

        <v-btn
          v-if="!insideIframe"
          color="primary"
          to="/cases"
          class="w-max mx-auto"
        >Back to Home</v-btn>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import AppBar from '@/components/layout/AppBar.vue';

export default {
  name: 'ResultsPendingPage',
  components: {
    AppBar,
  },
  computed: {
    ...mapState({
      insideIframe: (state) => state.layout.insideIframe,
    }),
  },
};
</script>

<style lang="scss" scoped>
.rotate {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.h-screen-max {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.w-max {
  width: max-content;
}
</style>
