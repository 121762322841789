import Vue from 'vue';
import Router from 'vue-router';

import NoLayout from '@/components/layout/NoLayout';
import NotFoundPage from '@/components/pages/NotFoundPage';

import appRoutes from './routes/app/routes';
import authRoutes from './routes/auth/routes';
import requiresAuth from './routes/auth/guards';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: () => ({
        name: 'cases',
      }),
    },
    {
      path: '/',
      component: NoLayout,
      children: appRoutes,
      beforeEnter: requiresAuth,
    },
    {
      path: '/',
      component: NoLayout,
      children: authRoutes,
    },
    {
      path: '*',
      name: 'notFound',
      component: NotFoundPage,
      meta: {
        title: 'Page Not Found',
      },
    },
  ],
});

// This callback runs before after route change is resolved,
// including on page load.
router.afterEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

export default router;
