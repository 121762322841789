import { cloneDeep } from 'lodash';
import Vue from 'vue';

import { convertArrayToObject } from '@/utils';
import * as types from '@/store/mutation-types';
import initialState from './state';
import constants from './constants';

export default {
  [types.RESET_STATE_VIEWER_MODULE](state) {
    Object.assign(state, initialState());
  },
  [types.VIEWER_REQUEST]: (state) => {
    state.loading = true;
  },
  [types.VIEWER_SUCCESS]: (state, { studyId, config }) => {
    // destructure config
    const {
      scenes,
      options,
      visualisations,
      warnings,
      landmarkTypeConfigs,
      // TODO: deprecated fields, lets remove somehow
      comments,
      metadata,
    } = config;
    if (landmarkTypeConfigs) {
      Vue.set(state, 'landmarkTypeConfigs', convertArrayToObject(
        landmarkTypeConfigs, 'landmarkTypeId',
      ));
    }
    Vue.set(state, 'studyId', studyId);

    Vue.set(state, 'scenes', scenes);
    visualisations.forEach((visualisation) => {
      Vue.set(state.visualisationsById, visualisation.id, visualisation);
    });
    Vue.set(state, 'options', options);
    Vue.set(state, 'comments', comments);
    Vue.set(state, 'warnings', warnings);
    Vue.set(state, 'metadata', metadata || {});

    // ORGINAL VISUALISATIONS
    state.originalVisualisations = cloneDeep(visualisations);

    state.loading = false;

    // TODO: set document title, maybe in the component???
    // Does this require an update to patientInformation.identifier
    const identifier = state.metadata.patientIdentifier || state.metadata.caseIdentifier;
    if (identifier) {
      document.title = `${identifier} - ${document.title}`;
    }
  },
  [types.VIEWER_LOADING_PROGRESS]: (state, progress) => {
    state.loadingProgress = progress;
  },
  [types.VIEWER_LOADED]: (state) => {
    state.loadingProgress = 0;
  },

  [types.VIEWER_SET_SCENE]: (state, { selectedSceneIdx }) => {
    state.selectedSceneIdx = selectedSceneIdx;
  },

  [types.VIEWER_SET_VISUALISATION]: (state, { visualisation, sceneId }) => {
    Vue.set(state.selectedVisualisationId, sceneId, visualisation.id);
  },

  [types.VIEWER_RESET_SELECTED_VISUALISATION]: (state, visualisationId) => {
    const originalVisualisation = cloneDeep(
      state.originalVisualisations.find((v) => v.id === visualisationId),
    );
    Vue.set(state.visualisationsById, visualisationId, originalVisualisation);
  },

  [types.SET_GLOBAL_OPACITY]: (state, { visualisationId, opacity, currentVisualisationId }) => {
    // If visualisationId is not set, use the selected visualisation
    const id = visualisationId || currentVisualisationId;
    Vue.set(state.visualisationsById[id], 'opacity', opacity);
  },
  [types.SET_OBJECT_OPACITY]: (state, {
    visualisationId, objectName, opacity, currentVisualisationId,
  }) => {
    // If visualisationId is not set, use the selected visualisation
    const id = visualisationId || currentVisualisationId;
    const visualisation = state.visualisationsById[id];
    const object = visualisation.objects.find((o) => o.name === objectName);
    Vue.set(object, 'opacity', opacity);
  },
  [types.SET_OBJECT_COLOR]: (state, {
    visualisationId, objectName, color, currentVisualisationId,
  }) => {
    // If visualisationId is not set, use the selected visualisation
    const id = visualisationId || currentVisualisationId;
    const visualisation = state.visualisationsById[id];
    const object = visualisation.objects.find((o) => o.name === objectName);
    Vue.set(object, 'color', color);
  },
  [types.SET_OBJECT_VISIBILITY]: (state, {
    visualisationId, objectName, isVisible, currentVisualisationId,
  }) => {
    // If visualisationId is not set, use the selected visualisation
    const id = visualisationId || currentVisualisationId;
    const visualisation = state.visualisationsById[id];
    const object = visualisation.objects.find((o) => o.name === objectName);
    Vue.set(object, 'isVisible', isVisible);
  },
  [types.SET_OBJECT_DOUBLESIDED]: (state, {
    visualisationId, objectName, isDoubleSided, currentVisualisationId,
  }) => {
    // If visualisationId is not set, use the selected visualisation
    const id = visualisationId || currentVisualisationId;
    const visualisation = state.visualisationsById[id];
    const object = visualisation.objects.find((o) => o.name === objectName);
    Vue.set(object, 'isDoubleSided', isDoubleSided);
  },
  [types.SET_LANDMARK_VISIBILITY]: (state, { landmarkId, isVisible }) => {
    const landmark = state.landmarks[landmarkId];
    Vue.set(landmark, 'isVisible', isVisible);
  },
  [types.VIEWER_OBJECT_LOADED]: (state, url) => {
    state.loadedFiles.push(url);
    // we now check the scenes loaded status in the getters
  },
  /* Angle report */
  [types.VIEWER_UPDATE_REPORT]: (state, { report }) => {
    state.report = report;
  },
  [types.LANDMARKS_CLEAR]: (state) => {
    Vue.set(state, 'landmarks', {});
  },
  [types.LANDMARKS_UPDATE]: (state, incomingLandmarks) => {
    state.landmarks = { ...state.landmarks, ...incomingLandmarks };
  },
  [types.LANDMARK_TYPES_UPDATE]: (state, incomingLandmarkTypes) => {
    state.landmarkTypes = { ...state.landmarkTypes, ...incomingLandmarkTypes };
  },
  [types.LANDMARK_UPDATE]: (state, { id, properties }) => {
    if ('id' in properties && properties.id !== id) {
      throw Error(`ID mismatch. LANDMARK_UPDATE on "${id}" has "${properties.id}" as property`);
    }
    Object.entries(properties).forEach(([key, value]) => {
      Vue.set(state.landmarks[id], key, value);
    });
  },
  [types.LANDMARK_DELETE]: (state, { id }) => {
    Vue.delete(state.landmarks, id);
  },
  [types.NEW_LANDMARK_UPDATE]: (state, { properties }) => {
    Object.entries(properties).forEach(([key, value]) => {
      Vue.set(state.newLandmark, key, value);
    });
  },
  SET_COLOR_LEVEL: (state, level) => {
    Vue.set(state.color, 'level', level);
  },
  SET_COLOR_WINDOW: (state, window) => {
    Vue.set(state.color, 'window', window);
  },
  SET_ORIGINAL_LEVELS: (state, levels) => {
    Vue.set(state.color, 'original', levels);
  },
  SET_CONTOUR: (state, contour) => {
    Vue.set(state, 'contour', contour);
  },
  SET_DICOM_PROGRESS: (state, progress) => {
    Vue.set(state, 'dicomProgress', progress);
  },
  SET_DICOM_RESULTS: (state, results) => {
    Vue.set(state, 'dicomResults', results);
  },
  SET_DICOM_WARNINGS: (state, warnings) => {
    Vue.set(state, 'dicomWarnings', warnings);
  },
  SET_DICOM_IS_CLINICAL: (state, isClinical) => {
    Vue.set(state, 'dicomIsClinical', isClinical);
  },
  SET_TITLES: (state, { subtitle, title }) => {
    Vue.set(state, 'title', title);
    Vue.set(state, 'subtitle', subtitle);
  },
  SET_HUMAN: (state, human) => {
    Vue.set(state, 'human', human);
  },
  SET_IMAGE: (state, image) => {
    Vue.set(state, 'image', image);
  },
  SET_INTERPOLATION_TYPE: (state, interpolationType) => {
    Vue.set(state, 'interpolationType', interpolationType);
  },
  SET_DICOM_MODE: (state, mode) => {
    Vue.set(state, 'dicomMode', mode);
  },
  SET_NEW_ANGLE_WIDGET: (state, widget) => {
    Vue.set(state, 'newAngleWidget', widget);
  },
  SET_NEW_AREA_WIDGET: (state, widget) => {
    Vue.set(state, 'newAreaWidget', widget);
  },
  SET_NEW_CURVE_WIDGET: (state, widget) => {
    Vue.set(state, 'newCurveWidget', widget);
  },
  SET_NEW_DISTANCE_WIDGET: (state, widget) => {
    Vue.set(state, 'newDistanceWidget', widget);
  },
  SET_NEW_LANDMARK: (state, newLandmark) => {
    Vue.set(state, 'newLandmark', newLandmark);
    // The widget is pickable (can be rotated etc) only if not drawing a landmark,
    // or while drawing a 3d curve landmark
    state.widget.setPickable(
      newLandmark === undefined
      || newLandmark.geometryType === constants.CLOSED_CURVE
      || newLandmark.geometryType === constants.OPEN_CURVE,
    );
  },
  SET_LM_BUTTON: (state, value) => {
    Vue.set(state, 'lMButton', value);
  },
  CREATE_LANDMARK: (state, landmark) => {
    Vue.set(state.landmarks, landmark.id, landmark);
  },
  SET_SELECTED_LANDMARK_ID: (state, landmarkId) => {
    Vue.set(state, 'selectedLandmarkId', landmarkId);
  },
  SET_SHOW_CONTOUR: (state, value) => {
    Vue.set(state, 'showContour', value);
  },
  SET_EDIT_MODE: (state, value) => {
    Vue.set(state, 'editMode', value);
  },
  SET_VIEWPORT_MAXIMISE: (state, id) => {
    Vue.set(state, 'maximisedViewId', id);
  },
  SET_WIDGET: (state, widget) => {
    Vue.set(state, 'widget', widget);
  },
  UPDATE_INTERACTION_COUNTER: (state) => {
    Vue.set(state, 'interactionCounter', state.interactionCounter + 1);
  },
};
