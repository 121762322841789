import {
  trialLicensesAPI,
  licensedDevicesAPI,
  templateDevicesAPI,
  deviceAccessGroupsAPI,
} from '@/services/heartguide';

import { convertArrayToObject } from '@/utils';

export default {

  async createDeviceAccess({ dispatch }, { deviceAccess }) {
    if (deviceAccess.deviceAccessGroupId) {
      return templateDevicesAPI.post(deviceAccess)
        .then(() => {
          dispatch('retrieveDeviceAccessGroup', deviceAccess.deviceAccessGroupId);
        });
    }
    return licensedDevicesAPI.post(deviceAccess)
      .then(() => {
        dispatch('retrieveOrganisation', deviceAccess.organisationId);
      });
  },

  async deleteDeviceAccess({ dispatch }, { deviceAccess }) {
    if (deviceAccess.deviceAccessGroupId) {
      return templateDevicesAPI.delete(deviceAccess.id)
        .then(() => {
          dispatch('retrieveDeviceAccessGroup', deviceAccess.deviceAccessGroupId);
        });
    }
    return licensedDevicesAPI.delete(deviceAccess.id)
      .then(() => {
        dispatch('retrieveOrganisation', deviceAccess.organisationId);
      });
  },

  // eslint-disable-next-line no-unused-vars
  async createTrialLicense({ dispatch }, { trial }) {
    return trialLicensesAPI.post(trial);
  },

  // eslint-disable-next-line no-unused-vars
  async deleteTrialLicense({ dispatch }, { trial }) {
    return trialLicensesAPI.delete(trial.id);
  },

  async listDeviceAccessGroups({ commit }, params) {
    commit('DEVICE_ACCESS_GROUPS_ISLOADING', true);
    return deviceAccessGroupsAPI.get({ params })
      .then((response) => {
        commit('DEVICE_ACCESS_GROUPS_ISLOADING', false);
        const deviceAccessGroups = convertArrayToObject(response.data);
        commit('DEVICE_ACCESS_GROUPS_UPDATE', deviceAccessGroups);
        return deviceAccessGroups;
      })
      .catch((error) => {
        commit('DEVICE_ACCESS_GROUPS_ISLOADING', false);
        throw error;
      });
  },

  async retrieveDeviceAccessGroup({ commit }, deviceAccessGroupId) {
    return deviceAccessGroupsAPI.get(deviceAccessGroupId)
      .then((response) => {
        commit('DEVICE_ACCESS_GROUPS_UPDATE', convertArrayToObject([response.data]));
        return response.data;
      });
  },

  async createDeviceAccessGroup({ commit }, { deviceAccessGroup }) {
    return deviceAccessGroupsAPI.post(deviceAccessGroup)
      .then((response) => {
        const deviceAccessGroupResult = response.data;
        commit('DEVICE_ACCESS_GROUPS_UPDATE', convertArrayToObject([deviceAccessGroupResult]));
        return deviceAccessGroupResult;
      });
  },

  async deleteDeviceAccessGroup({ commit }, { deviceAccessGroup }) {
    return deviceAccessGroupsAPI.delete(deviceAccessGroup.id)
      .then(() => commit('DEVICE_ACCESS_GROUP_DELETE', { deviceAccessGroup }));
  },

  // eslint-disable-next-line no-unused-vars
  async subscribeToDeviceAccessGroup({ dispatch }, { deviceAccessGroupId, organisationId }) {
    return deviceAccessGroupsAPI.subscribe(deviceAccessGroupId, { organisationId });
  },

  // eslint-disable-next-line no-unused-vars
  async unsubscribeFromDeviceAccessGroup({ dispatch }, { deviceAccessGroupId, organisationId }) {
    return deviceAccessGroupsAPI.unsubscribe(deviceAccessGroupId, { organisationId });
  },

  async resetState({ commit }) {
    commit('RESET_STATE_LICENSES_MODULE');
  },

};
