import Vue from 'vue';

import initialState from './state';

export default {

  DEVICE_ACCESS_GROUPS_UPDATE: (state, deviceAccessGroups) => {
    state.deviceAccessGroups = { ...state.deviceAccessGroups, ...deviceAccessGroups };
  },
  DEVICE_ACCESS_GROUPS_ISLOADING: (state, value) => {
    Vue.set(state, 'deviceAccessGroupsIsLoading', value);
  },
  DEVICE_ACCESS_GROUP_DELETE: (state, { deviceAccessGroup }) => {
    Vue.delete(state.deviceAccessGroups, deviceAccessGroup.id);
  },

  RESET_STATE_LICENSES_MODULE(state) {
    Object.assign(state, initialState());
  },

};
