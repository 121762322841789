<template>
  <v-dialog :value="value" @input="emitInput" max-width="680px">
    <v-card>
      <v-card-title class="text-h5 justify-center">
        {{ study.name }} - Generate Report
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div v-if="config">
          <!-- Filelist -->
          <div v-if="config.anatomical_analysis.analyses.length > 0" class="mt-2">
            <v-container>
              <v-row>
                <v-col cols="2">
                  <b>Image</b>
                </v-col>
                <v-col cols=8>
                  <b>Title</b>
                </v-col>
                <v-col cols="2">
                  <b>Values</b>
                </v-col>
              </v-row>
              <v-row v-for="item in config.anatomical_analysis.analyses" :key="item.filename" >
                <v-col cols="2">
                  <v-icon color="error" v-if="item.missing_image">
                    close
                  </v-icon>
                  <v-img
                    v-else
                    max-width="50" max-height="50" contain
                    :src="item.image_path"
                  />
                </v-col>
                <v-col cols=8>
                  {{ item.title }}
                </v-col>
                <v-col cols="2">
                  <v-icon v-if="item.missing_landmark" color="error" >
                    close
                  </v-icon>
                  <v-icon v-else color="success" >
                    check
                  </v-icon>
                </v-col>
              </v-row>
            </v-container>
          </div>

          <!-- Filelist -->
          <div
            v-if="config.other_analysis.analyses.length > 0"
            class="mt-2"
          >
            <v-container>
              <v-row>
                <v-col cols="2">
                  <b>Image</b>
                </v-col>
                <v-col cols=8>
                  <b>Title</b>
                </v-col>
                <v-col cols="2">
                  <b>Values</b>
                </v-col>
              </v-row>
              <v-row
                v-for="item in config.other_analysis.analyses"
                :key="item.filename"
              >
                <v-col cols="2">
                  <v-icon color="error" v-if="item.missing_image">
                    close
                  </v-icon>
                  <v-img
                    v-else
                    max-width="50" max-height="50" contain
                    :src="item.image_path"
                  />
                </v-col>
                <v-col cols=8>
                  {{ item.title }}
                </v-col>
                <v-col cols="2">
                  <v-icon v-if="item.missing_landmark" color="error" >
                    close
                  </v-icon>
                  <v-icon v-else color="success" >
                    check
                  </v-icon>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <!-- Filelist -->
          <div
            v-if="config.additional_anatomical_analysis.analyses.length > 0"
            class="mt-2"
          >
            <v-container>
              <v-row>
                <v-col cols="2">
                  <b>Image</b>
                </v-col>
                <v-col cols=8>
                  <b>Title</b>
                </v-col>
                <v-col cols="2">
                  <b>Values</b>
                </v-col>
              </v-row>
              <v-row
                v-for="item in config.additional_anatomical_analysis.analyses"
                :key="item.filename"
              >
                <v-col cols="2">
                  <v-icon color="error" v-if="item.missing_image">
                    close
                  </v-icon>
                  <v-img
                    v-else
                    max-width="50" max-height="50" contain
                    :src="item.image_path"
                  />
                </v-col>
                <v-col cols=8>
                  {{ item.title }}
                </v-col>
                <v-col cols="2">
                  <v-icon v-if="item.missing_landmark" color="error" >
                    close
                  </v-icon>
                  <v-icon v-else color="success" >
                    check
                  </v-icon>
                </v-col>
              </v-row>

              <v-row
                v-if="config.other_information"
              >
                <v-col cols="2">
                  <v-icon color="error" v-if="config.other_information.missing_image">
                    close
                  </v-icon>
                  <v-img
                    v-for="image in config.other_information.images" :key="image"
                    max-width="50" max-height="50" contain
                    :src="image"
                    class="ml-auto"
                  />
                </v-col>
                <v-col cols=8>
                  Grade of Calcium Image(s)
                </v-col>
                <v-col cols="2">

                </v-col>
              </v-row>
            </v-container>
          </div>

        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined @click="hide">
          Close
        </v-btn>
        <v-btn
          depressed
          color="primary"
          :disabled="isGeneratingReport"
          :loading="isGeneratingReport"
          @click="generateReport"
        >
          Generate a new PDF
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'GenerateReportDialog',
  props: ['study', 'value', 'orderId', 'withSimulations'],
  data: () => ({
    isRequired: (v) => !!v || 'This field is required',
    dialog: false,
    processing: false,
    config: undefined,
  }),
  created() {
    this.$store.dispatch('generateReportConfig', {
      study: this.study, withSimulations: this.withSimulations,
    }).then((response) => {
      this.config = response.data;
    });
  },
  computed: {
    isGeneratingReport() {
      return this.$store.state.studies.isGeneratingReport;
    },
  },
  methods: {
    hide() {
      this.emitInput(false);
    },
    emitInput(newVal) {
      this.$emit('input', newVal);
    },
    generateReport() {
      this.$store.dispatch('generateReport', {
        study: this.study, reportConf: this.config, orderId: this.orderId,
      })
        .then(this.hide)
        .catch((error) => {
          // eslint-disable-next-line
          if (error && error.__CANCEL__) {
            this.$toasted.global.error({ message: error.message });
          } else {
            this.$toasted.global.error({ error });
            throw error;
          }
        });
    },
  },
};
</script>

<style scoped>
.v-image {
  display: inline-block;
}
</style>
