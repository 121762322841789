<template>
<v-dialog
  :value="value"
  @input="emitInput"
  max-width="600px"
  v-if="hgcase"
>
  <v-card>
    <v-card-title class="text-h5 justify-center">
      Cancel analysis request
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-6">
      <p>
        Are you sure you want to abort the simulation request for case {{ hgcase.caseIdentifier }}?
      </p>
      <p>
        This action cannot be undone.
      </p>
    </v-card-text>

    <v-card-actions class="px-6 pa-4">
      <v-spacer />
      <v-btn outlined @click="close">
        No
      </v-btn>
      <v-btn
        color="primary" depressed
        :loading="processing"
        @click="handleCancel"
      >
        Yes, Cancel
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
/**
 * This dialog component cancels a case
 */
import { mapGetters } from 'vuex';

export default {
  name: 'CaseCancelDialog',
  props: {
    value: {
      type: Boolean,
    },
  },
  data: () => ({
    processing: false,
  }),
  computed: {
    ...mapGetters({
      hgcase: 'currentCase',
    }),
  },
  methods: {
    close() {
      this.emitInput(false);
    },
    emitInput(newVal) {
      this.$emit('input', newVal);
    },
    handleCancel() {
      this.processing = true;
      this.$store.dispatch('cancelCase', {
        id: this.hgcase.id,
      }).then(() => {
        this.close();
      });
    },
  },
};
</script>
