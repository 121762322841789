import Vue from 'vue';
import * as types from '@/store/mutation-types';

import initialState from './state';

export default {
  [types.ORGANISATIONS_ISLOADING]: (state, value) => {
    Vue.set(state, 'isLoading', value);
  },
  [types.ORGANISATIONS_UPDATE]: (state, organisations) => {
    Object.keys(organisations).forEach((key) => {
      Vue.set(
        state.organisations,
        key,
        { ...state.organisations[key], ...organisations[key] },
      );
    });
  },
  [types.ORGANISATIONS_ERROR]: (state) => {
    Vue.set(state, 'error', 'There was a problem!');
  },
  [types.ORGANISATIONS_CLEAR]: (state) => {
    Vue.set(state, 'organisations', {});
  },
  [types.ORGANISATIONS_NEXTPAGE]: (state, value) => {
    Vue.set(state, 'nextPage', value);
  },

  [types.REGIONS_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [types.REGIONS_UPDATE]: (state, regions) => {
    state.regions = { ...state.regions, ...regions };
    state.isLoading = false;
  },
  [types.REGIONS_ERROR]: (state) => {
    state.isLoading = false;
  },

  [types.ORG_MEMBERSHIP_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [types.ORG_MEMBERSHIP_UPDATE]: (state, memberships) => {
    state.memberships = { ...state.memberships, ...memberships };
    state.isLoading = false;
  },
  [types.ORG_MEMBERSHIP_ERROR]: (state) => {
    state.isLoading = false;
  },
  [types.ORG_MEMBERSHIP_REMOVE]: (state, id) => {
    const memberships = { ...state.memberships };
    delete memberships[id];
    state.memberships = { ...memberships };
    state.isLoading = false;
  },
  [types.MANAGEMENT_ORGS_INSERT]: (state, organisation) => {
    state.orgsManagement.ids.splice(0, 0, organisation.id);
    state.orgsManagement.count += 1;
  },
  [types.MANAGEMENT_ORGS_UPDATE]: (state, data) => {
    state.orgsManagement = { ...data };
  },
  [types.DELETE_ORGANISATION_SUCCESS]: (state, id) => {
    const organisations = { ...state.organisations };
    delete organisations[id];
    state.organisations = { ...organisations };
    state.isLoading = false;
  },

  [types.RESET_STATE_ORGANISATIONS_MODULE](state) {
    Object.assign(state, initialState());
  },
};
