import { pagesAPI } from '@/services/heartguide';

import {
  PAGES_SUCCESS,
  RESET_STATE_PAGES_MODULE,
} from '@/store/mutation-types';

export default {
  async getPages({ commit }, { slug }) {
    const { data: pages } = await pagesAPI.get(slug);
    commit(PAGES_SUCCESS, { pages, slug });
    return pages;
  },
  async resetState({ commit }) {
    commit(RESET_STATE_PAGES_MODULE);
  },
};
