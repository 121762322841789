<template>
  <v-dialog v-model="dialog" max-width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text outlined small
        v-bind="attrs"
        v-on="on"
      >
        Duplicate Order
      </v-btn>
    </template>
    <v-form
      ref="obsoleteDeliveryForm"
      @submit.native.prevent="submit"
    >
      <v-card>
        <v-card-title class="text-h5 justify-center">
          Duplicate Order
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-6">
          WARNING: This is only intended for use after communication with the customer.
          This will create a new "In Progress" order for the same service.
          The original order may still be viewed until it is obsoleted.
          Before delivering the new order to the customer, the original order must be
          obsoleted.
          Please refer to the relevant work instructions for more information.
        </v-card-text>

        <v-card-actions class="px-6 pa-4">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" depressed type="submit">
            Duplicate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'DupliateOrderDialog',
  data() {
    return {
      errors: {},
      dialog: false,
    };
  },
  props: ['order'],
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('duplicateOrder', {
          orderId: this.order.id,
        });
        this.$store.dispatch('retrieveCase', this.order.caseId);
        this.dialog = false;
      } catch (error) {
        this.errors = error.response && error.response.data;
      }
    },
  },
};
</script>
