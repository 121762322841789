const UNDEFINED = 0;
const PSEUDONYMIZED_DICOM = 1;
const VTKJS = 2;
const PDFREPORT = 3;

const SCENE_OBJECT = 1;
const IMAGE = 2;
const SEGMENTATION_VOLUME = 3;
const SEGMENTATION_INDICES = 4;

export default {
  UNDEFINED,
  PSEUDONYMIZED_DICOM,
  VTKJS,
  PDFREPORT,
  SCENE_OBJECT,
  IMAGE,
  SEGMENTATION_VOLUME,
  SEGMENTATION_INDICES,

  STUDY_RESOURCE_TYPES: {
    [UNDEFINED]: 'Undefined',
    [PSEUDONYMIZED_DICOM]: 'Pseudonymized DICOM',
    [VTKJS]: 'vtk.js DICOM volume',
    [PDFREPORT]: 'PDF Report',
  },
  ANALYSIS_RESOURCE_TYPES: {
    [UNDEFINED]: 'Undefined',
    [SCENE_OBJECT]: 'Scene Object',
    [IMAGE]: 'Image',
    [SEGMENTATION_VOLUME]: 'Segmentation Volume',
    [SEGMENTATION_INDICES]: 'Segmentation Indices',
  },
  ANALYSIS_TYPES: {
    LEGACY: 0,
    ANATOMICAL: 1,
    SIMULATION: 2,
  },
  ANALYSIS_TYPE_LABELS: Object.freeze([
    'Legacy Simulation',
    'Anatomical Analysis',
    'Simulation',
  ]),
};
