<template>
  <v-dialog v-model="showDialog" max-width="640px">
    <template v-slot:activator="{on, attrs}">
      <v-btn outlined color="primary" class="ml-2" small v-on="on"
        v-bind="attrs">Edit Case</v-btn>
    </template>

    <v-card>
      <v-card-title>Edit {{ hgCase.caseIdentifier }}
        <v-btn class="ml-auto" icon type="button" @click="showDialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4 pb-0">
        <v-form v-model="isValid">
          <section>
            <h5 class="section-title text--secondary">Patient Details</h5>
            <div class="d-flex mt-3 gap-8px">
              <v-text-field v-model="form.patientInformation.identifier" label="Identifier"
                :rules="[isRequired]" outlined dense />
              <v-text-field type="number" v-model.number="form.patientInformation.age" label="Age"
                :rules="[isRequired]" outlined dense class="max-w-160px" />
              <v-select v-model="form.patientInformation.sex" :rules="[isRequired]" label="Sex"
                :items="sexOptions" item-value="id" item-text="text" outlined dense
                class="max-w-160px" />
            </div>
          </section>
          <section class="mt-1">
            <h5 class="section-title text--secondary">Physician comments</h5>
            <v-textarea v-model="form.physicianComment" outlined dense class="mt-3" height="80px"
              no-resize />
          </section>
          <section class="mt-1">
            <h5 class="section-title text--secondary">Expected Delivery Date</h5>
            <div class="d-flex gap-8px mt-3">
              <v-menu v-model="openEDDMenu" :close-on-content-click="false"
                transition="scale-transition" min-width="auto" ref="eddMenu">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.expectedDelivery.date"
                    readonly
                    v-bind="attrs" v-on="on"
                    outlined dense clearable label="Expected Delivery Date"
                    :rules="[isRequired]" />
                </template>

                <v-date-picker v-model="form.expectedDelivery.date" no-title color="primary"
                  first-day-of-week="1">
                  <v-spacer />
                  <v-btn text @click="openEDDMenu = false">Cancel</v-btn>
                  <v-btn text color="primary"
                    @click="$refs.eddMenu.save(form.expectedDelivery)">OK</v-btn>
                </v-date-picker>
              </v-menu>

              <v-select :items="times" label="Time" outlined dense :rules="[isRequired]"
                v-model="form.expectedDelivery.time" />
            </div>
          </section>
          <section class="mt-1">
            <h5 class="section-title text--secondary">Intervention Details</h5>
            <v-radio-group v-model="form.isProspective" row class="mt-3">
              <v-radio label="Prospective" :value="true" />
              <v-radio label="Retrospective" :value="false" />
            </v-radio-group>
            <div class="d-flex gap-8px">
              <v-menu v-model="openIntDMenu" :close-on-content-click="false"
                transition="scale-transition" min-width="auto" ref="iddMenu">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.intervention.date" readonly v-bind="attrs"
                    v-on="on" outlined dense clearable label="Intervention Date" />
                </template>

                <v-date-picker v-model="form.intervention.date" no-title color="primary"
                  first-day-of-week="1">
                  <v-spacer />
                  <v-btn text @click="openIntDMenu = false">Cancel</v-btn>
                  <v-btn text color="primary"
                    @click="$refs.iddMenu.save(form.interventionDate)">OK</v-btn>
                </v-date-picker>
              </v-menu>

              <v-select :items="times" label="Time" outlined dense
                v-model="form.intervention.time" />
            </div>
          </section>
          <section class="mt-1">
          <!-- change isResearch bool -->
            <h5 class="section-title text--secondary">Research</h5>
            <v-checkbox
              v-model="form.isResearch"
              :disabled="!canEditResearchFlag"
      label="This case can be edited/processed by the RnD team and will be treated as non-clinical."
              data-cy="research-case-checkbox"
            />
          </section>

        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex justify-end">
        <v-btn text @click="showDialog = false">Cancel</v-btn>
        <v-btn
          depressed
          color="primary"
          :disabled="!isValid"
          @click="submit"
          data-cy="submit-edit-case"
        >Save</v-btn>
      </v-card-actions>
    </v-card>

    <ConfirmationDialog ref="confirm" />
  </v-dialog>
</template>

<script>
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';

export default {
  name: 'EditCaseDialog',
  props: {
    hgCase: {
      type: Object,
      required: true,
    },
  },
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      isValid: false,
      form: {
        patientInformation: {
          identifier: null,
          age: null,
          sex: null,
        },
        physicianComment: null,
        isProspective: false,
        isResearch: false,
        intervention: {
          date: null,
          time: null,
        },
        expectedDelivery: {
          date: null,
          time: '07:00',
        },
      },
      openIntDMenu: false,
      openEDDMenu: false,
      sexOptions: [
        { id: 'M', text: 'Male' },
        { id: 'F', text: 'Female' },
      ],
      showDialog: false,
      canEditResearchFlag: true,
    };
  },
  computed: {
    times() {
      return [
        ...[...Array(24)].map((_, idx) => `${String(idx).padStart(2, '0')}:00`),
      ];
    },
  },
  methods: {
    isRequired: (v) => !!v || 'This field is required',
    resetForm() {
      const {
        patientIdentifier, patientAge, patientSex, physicianComment, isProspective,
        interventionDatetime, isResearch, expectedDeliveryDatetime,
      } = this.hgCase;

      this.canEditResearchFlag = !this.hgCase.isResearch;

      this.form = {
        ...this.form,
        patientInformation: {
          identifier: patientIdentifier,
          age: patientAge,
          sex: patientSex,
        },
        physicianComment,
        isProspective,
        isResearch,
      };

      if (interventionDatetime) {
        const intD = new Date(interventionDatetime);
        this.form = {
          ...this.form,
          intervention: {
            date: `${intD.getFullYear()}-${intD.getMonth() + 1}-${intD.getDate()}`,
            time: `${String(intD.getHours()).padStart(2, '0')}:00`,
          },
        };
      }

      if (expectedDeliveryDatetime) {
        const edd = new Date(expectedDeliveryDatetime);
        this.form = {
          ...this.form,
          expectedDelivery: {
            date: `${edd.getFullYear()}-${edd.getMonth() + 1}-${edd.getDate()}`,
            time: `${String(edd.getHours()).padStart(2, '0')}:00`,
          },
        };
      }
    },
    async submit() {
      const {
        intervention, physicianComment, isProspective, patientInformation,
        expectedDelivery, isResearch,
      } = this.form;
      const data = {
        physicianComment,
        isProspective,
        isResearch,
        patientInformation,
        id: this.hgCase.id,
      };

      if (intervention.date) {
        const time = intervention.time || '00:00';
        const dateTime = new Date(`${intervention.date}`);
        dateTime.setHours(time.split(':')[0]);
        data.interventionDatetime = dateTime.toISOString();
      }
      if (expectedDelivery.date && expectedDelivery.time) {
        const dateTime = new Date(`${expectedDelivery.date}`);
        dateTime.setHours(expectedDelivery.time.split(':')[0]);
        data.expectedDeliveryDatetime = dateTime.toISOString();
      }

      this.$store.dispatch('patchCase', data)
        .then(() => {
          this.$toasted.global.success({ message: 'Case edited successfully' });
          this.showDialog = false;
        })
        .catch((error) => {
          this.$toasted.global.error({ error });
        });
    },
  },
  watch: {
    hgCase: 'resetForm',
    showDialog(value) {
      if (value && !this.hgCase.interventionDatetime) {
        this.$store.dispatch('requestSensitiveData', { id: this.hgCase.id });
      }
    },
  },
  mounted() {
    this.resetForm();
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  font-size: inherit;
  font-weight: bold;
}

.gap-8px {
  gap: 8px;
}

.max-w-160px {
  max-width: 160px;
}
</style>
