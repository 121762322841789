<template>
  <v-dialog v-model="dialog" max-width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text outlined small
        v-bind="attrs"
        v-on="on"
        color="error"
      >
        Undeliver
      </v-btn>
    </template>
    <v-form
      ref="deliveryCreateForm"
      @submit.native.prevent="submit"
      lazy-validation>
      <v-card>
        <v-card-title class="text-h5 justify-center">
          Undeliver {{ order.serviceDisplay }} order
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-6">
          <!-- eslint-disable-next-line max-len  -->
          This will remove the {{ order.serviceDisplay }} results from being viewable by the physician.
          <p>Are you sure?</p>
        </v-card-text>

        <v-card-actions class="px-6 pa-4">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="error" depressed type="submit">Undeliver</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'RemoveDeliveryDialog',
  data() {
    return {
      dialog: false,
      errors: {},
    };
  },
  props: ['order', 'studyId'],
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('deleteDelivery', this.order.deliveryId);
        this.$store.dispatch('retrieveCase', this.order.caseId);
        if (this.studyId) this.$store.dispatch('retrieveStudy', this.studyId);
        this.dialog = false;
      } catch (error) {
        this.errors = error.response && error.response.data;
      }
    },
  },
};
</script>
