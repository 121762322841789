import Vue from 'vue';
import * as types from '@/store/mutation-types';
import initialState from './state';

export default {
  [types.AUTH_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [types.AUTH_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [types.AUTH_ERROR]: (state) => {
    state.status = 'error';
  },
  [types.AUTH_REQUEST_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [types.USERINFO_REQUEST]: (state) => {
    state.userinfoIsLoading = true;
  },
  [types.USERINFO_SUCCESS]: (state, userinfo) => {
    state.user = userinfo;
    state.userinfoIsLoading = false;
    if (state.user && state.user.settings) {
      state.casesTableView = userinfo.settings.cases_default_table_view || state.casesTableView;
    }
  },
  [types.USERINFO_ERROR]: (state) => {
    state.userinfoIsLoading = false;
  },
  [types.USERS_REQUEST]: (state) => {
    state.usersIsLoading = true;
  },
  [types.USERS_SUCCESS]: (state, users) => {
    state.users = users;
    state.usersIsLoading = false;
  },
  [types.USERS_UPDATE]: (state, users) => {
    state.users = { ...state.users, ...users };
    state.usersIsLoading = false;
  },
  [types.USERS_UPDATE_LIST]: (state, params) => {
    state.usersList = { ...state.usersList, ...params };
  },
  [types.INCOMING_UPDATE_LIST]: (state, params) => {
    state.incomingList = { ...state.incomingList, ...params };
  },
  [types.USERS_ERROR]: (state) => {
    state.usersIsLoading = false;
  },
  [types.PERMISSIONS_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [types.PERMISSIONS_SUCCESS]: (state, perms) => {
    state.status = '';
    Vue.set(state, 'permissions', perms);
  },
  [types.PERMISSIONS_ERROR]: (state) => {
    state.status = '';
  },

  [types.SETTINGS_PATCH]: (state, { patch }) => {
    Object.keys(patch).forEach((setting) => {
      // make sure the new setting is reactive
      Vue.set(state.user.settings, setting, patch[setting]);
    });
  },
  [types.PROFILE_PATCH]: (state, { data }) => {
    Object.keys(data).forEach((detail) => {
      // make sure the new detail is reactive
      Vue.set(state.user, detail, data[detail]);
    });
  },
  [types.USER_SET_TABLEVIEW]: (state, tableView) => {
    Vue.set(state, 'casesTableView', tableView);
  },
  [types.GROUPS_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [types.GROUPS_UPDATE]: (state, groups) => {
    state.groups = groups;
    state.status = 'success';
  },
  [types.GROUPS_ERROR]: (state) => {
    state.status = 'error';
  },
  [types.GROUP_MEMBERSHIP_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [types.GROUP_MEMBERSHIP_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [types.GROUP_MEMBERSHIP_ERROR]: (state) => {
    state.status = 'error';
  },
  [types.DELETE_USER_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [types.DELETE_USER_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [types.SPECIALISATIONS_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [types.SPECIALISATIONS_SUCCESS]: (state, specialisations) => {
    state.specialisations = {
      ...state.specialisations,
      ...specialisations,
    };
    state.status = 'success';
  },
  [types.SPECIALISATIONS_ERROR]: (state) => {
    state.status = 'error';
  },
  [types.CREATE_USER_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [types.CREATE_USER_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [types.CHANGE_USER_PASSWORD_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [types.CHANGE_USER_PASSWORD_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [types.CHANGE_USER_PASSWORD_ERROR]: (state) => {
    state.status = 'error';
  },
  [types.PATCH_USER_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [types.PATCH_USER_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [types.PATCH_USER_ERROR]: (state) => {
    state.status = 'error';
  },

  [types.RESET_STATE_AUTH_MODULE](state) {
    Object.assign(state, initialState());
  },
};
