import HumanLoader from '@/services/viewer/loaders/HumanLoader';
import constants from './constants';

export default () => ({
  studyId: undefined,
  scenes: [],
  visualisationsById: {},
  comments: [],
  options: [],

  renderer: {},

  loading: false,
  loadingProgress: 0,
  loadedFiles: [],

  // currently selected scene & visualisation
  selectedSceneIdx: 0, // index of the scene in the list
  selectedVisualisationId: {}, // object for tracking current selected visualisations

  // turn on in the vue editor to show debugging tools
  debuggingMode: false,

  title: undefined,
  subtitle: undefined,

  metadata: {},
  humanModel: new HumanLoader(),
  sidebar: {
    current: 0,
    open: true,
    sections: [
      {
        component: 'ObjectSettings',
        tooltip: 'Object Settings',
      },
      {
        component: 'SimulationResults',
        tooltip: 'Details',
      },
      {
        component: 'SimulationImages',
        tooltip: 'Figures',
      },
      {
        component: 'SimulationComments',
        tooltip: 'Comments',
      },
    ],
  },
  report: {},
  warnings: [],
  interactionCounter: 0,
  color: {
    level: undefined,
    window: undefined,
    original: undefined,
  },
  contour: undefined,
  dicomMode: constants.AORTIC_ROOT,
  dicomProgress: 0,
  dicomResults: undefined,
  dicomWarnings: [],
  dicomIsClinical: undefined,
  geometryConstants: constants,
  human: undefined,
  landmarks: {},
  landmarkTypes: [],
  interpolationType: 0,
  layout: [
    {
      id: 0,
      gridRow: '1/2',
      viewType: constants.CORONAL,
    },
    {
      id: 1,
      gridRow: '1/3',
      viewType: constants.AXIAL,
    },
    {
      id: 2,
      gridRow: '2/3',
      viewType: constants.SAGITTAL,
    },
  ],
  lMButton: 'contrast',
  maximisedViewId: undefined,
  newAngleWidget: undefined,
  newAreaWidget: undefined,
  newCurveWidget: undefined,
  newDistanceWidget: undefined,
  newLandmark: undefined,
  selectedLandmarkId: undefined,
  showContour: false,
  editMode: false,
});
