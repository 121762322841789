import Vue from 'vue';
import Toasted from 'vue-toasted';

Vue.use(Toasted, {
  iconPack: 'custom-class',
  theme: 'toasted-feops',
  position: 'top-right',
  singleton: true,
});

const successOptions = {
  duration: 3000,
  type: 'success',
};

const infoOptions = {
  type: 'info',
  action: [{
    text: 'Close',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  }],
};

const errorOptions = {
  type: 'error',
  action: {
    text: 'Close',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
};

Vue.toasted.register('success', (payload) => {
  if (!payload.message) {
    return 'Success!';
  }
  return payload.message;
}, successOptions);

Vue.toasted.register('info', (payload) => payload.message, infoOptions);

Vue.toasted.register('error', (payload) => {
  if (payload.message) {
    return payload.message;
  }
  if (payload.error) {
    if (!payload.error.response && payload.error.request) {
      return 'Lost connection to server. Please try again later.';
    }
    if (payload.error.response && payload.error.response.status === 401) {
      return 'Session expired, please log in again.';
    }
    const { message } = payload.error;
    return message;
  }
  return 'Ooops... Something went wrong.';
}, errorOptions);
